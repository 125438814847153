<template>
    <div class="wrapper" :class="{ 'compiling-wrapper': showCompilingData, 'pricing-wrapper': showPricingData }">
        <div ref="maindiv" id="pricing-main">
            <div class="md-layout steps">
                <div class="md-layout-item step" :class="{ selected: second < 7 }">
                   
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 7"></md-progress-spinner>
                        <md-icon v-if="second >= 7">check</md-icon>
                   
                   
                        Search
                   
                </div>
                <div class="md-layout-item step" :class="{selected: second > 7}">
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second > 7 && second < 18"></md-progress-spinner>
                    <md-icon v-else-if="second >= 12">check</md-icon>
                    Best Price
                </div>
            </div>
        


            <div class="title">
                <transition name="fade">
                    <h1 v-show="showKeywordScanTitle" style="transition-delay: 0s">Keyword Scan</h1>
                </transition>
                <transition name="fade">
                    <h1 v-show="showCompilingDataTitle" style="transition-delay: 0.25s">Compiling Data</h1>
                </transition>
                <transition name="fade">
                    <h1 v-show="showBestPriceAvailableTitle" style="transition-delay: 0.25s">Best Price Available</h1>
                </transition>
                <transition :name="goPremiumFunnelInfo.keywordScreenCompleted ? '' : 'fade'">
                    <h1 v-show="showPricingDataTitle" style="transition-delay: 2s">Loading Best Price</h1>
                </transition>
                <transition :name="goPremiumFunnelInfo.keywordScreenCompleted ? '' : 'fade'">
                    <h1 v-show="showLockInBestPriceTitle" style="transition-delay: 1s">Lock-in Best Price</h1>
                </transition>
                <h1 v-if="!showPricingDataTitle && !showCompilingDataTitle && !showKeywordScanTitle"></h1>
            </div>


            <div class="subtitle1">{{ subtitle1 }}</div>
            <div class="subtitle2">{{ subtitle2 }}</div>


            <keyword-scan-body v-if="showKeywordScan"></keyword-scan-body>
            <compiling-data v-if="showCompilingData" @next="showBestPrice"></compiling-data>
            <pricing-results
                    v-if="showPricingData"
                    :click-price="clickPrice"
                    @next="$emit('next')"
                    @back="$emit('back')"
            ></pricing-results>

            <div class="skip" @click="skip"></div>

        </div>
    </div>

</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import KeywordScanBody from './KeywordScanBody'
    import CompilingData from "./CompilingData";
    import PricingResults from "./PricingResults";
    export default {
        name: "PricingLoader",
        components: {KeywordScanBody, CompilingData, PricingResults},
        data(){
            return {
                title: "Comparing price",
                subtitle1: "Gathering keyword data for:",
                subtitle2: "",
                progressValue: 0,
                // progressValue: 33.3333334,
                second: 0,
                // second: 35,
                interval: null,
                // showKeywordScan: false,
                showKeywordScanTitle: false,
                // showKeywordScanTitle: false,
                showKeywordScan: false,
                showCompilingData: false,
                // showCompilingData: true,
                showCompilingDataTitle: false,
                // showCompilingDataTitle: true,
                showPricingData: false,
                showPricingDataTitle: false,
                showBestPriceAvailableTitle: false,
                showLockInBestPriceTitle: false,
            }
        },
        computed: {
            ...mapGetters(['facilities', 'goPremiumFunnelInfo']),
            clickPrice(){
                return this.goPremiumFunnelInfo.facilityPricing.singleClickCost;
            },
            funnelFacility() {
                return this.facilities.find(facility => parseInt(facility.attributes.facilityId) === parseInt(this.goPremiumFunnelInfo.funnelFacility))
            }
        },
        props: ['pricing'],
        watch: {
            progressValue(newVal, oldVal){
                if(newVal >= 99){
                    // console.log('FINISHED PRICING SCREENS')
                    this.$emit('keywordScreenCompleted')
                }
            }
        },

        mounted(){

            this.subtitle2 = this.funnelFacility.attributes.name;
            // console.log('MOUNTED', JSON.parse(JSON.stringify(this.goPremiumFunnelInfo)));
           if(this.goPremiumFunnelInfo.keywordScreenCompleted){
                this.progressValue = 100;
                this.subtitle1 = 'Offering best price for:'
                this.second = 20;
                this.showCompilingDataTitle = false;
                this.showPricingDataTitle = false;
                this.showCompilingData = false;
                this.showPricingData = true;
                this.showLockInBestPriceTitle = true
            } else {
                this.showKeywordScanTitle = true;
                this.showKeywordScan = true;
                this.interval = setInterval(() => {
                    this.change();
                }, 100)
            }

        },
        beforeDestroy(){
            clearInterval(this.interval);
        },
        methods: {
            ...mapActions(['updateGoPremiumFunnelInfo']),
            change(){

                if( this.second <= 7) { // Compiling Data Screen
                    this.second += .1;
                    this.showKeywordScan = false;
                    this.showKeywordScanTitle = this.second < 2;
                    this.showCompilingDataTitle = this.second > 2.5 && this.second < 4.5;
                    this.showBestPriceAvailableTitle = this.second > 5.5;
                    this.showCompilingData = true;

                    this.subtitle1 = "Gathering market data for:"
                    this.progressValue += (33 / 18 * .38);
                    if(this.second >= 7) {
                        this.progressValue = 50
                        this.second = 7;
                        clearInterval(this.interval);
                    }
                } else if (this.second > 7 && this.showPricingData){
                    this.second += .1;
                    this.subtitle1 = 'Offering best price for:'
                    this.progressValue += (33 / 18 * .38);

                    //this.showPricingDataTitle = this.second > 37 && this.second <= 49;
                    this.showLockInBestPriceTitle = this.second >= 9;
                    //
                    if(this.second >= 18){
                        clearInterval(this.interval);
                        this.progressValue = 100;
                    }
                }
            },

            showBestPrice(){
                this.showBestPriceAvailableTitle = false
                this.second += .1;
                this.showCompilingData = false;
                this.showPricingData = true;
                this.interval = setInterval(() => {
                    this.change();
                }, 100)
            },

            skip() {
                if(window.location.origin !== "https://connect.rehab.com") {
                    clearInterval(this.interval);
                    this.progressValue = 100;
                    this.subtitle1 = 'Offering best price for:'
                    this.second = 52;
                    this.showCompilingDataTitle = false;
                    this.showPricingDataTitle = false;
                    this.showCompilingData = false;
                    this.showPricingData = true;
                    this.showLockInBestPriceTitle = true

                    this.showKeywordScanTitle = false;
                    this.showKeywordScan = false;
                    this.$store.dispatch('createStatusLog', {
                        type: 'ViewedFunnelPricing',
                        action: 'viewed'
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper{
        width: 860px;
        height: 548px;
        /*min-height: 530px;*/
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
    }
    .compiling-wrapper{
        height: 548px;
        .step{
            font-size: 14px;
            display: inline-block;
        }

    }

    .pricing-wrapper{
        height: 724px;
        overflow: hidden;
    }

    #pricing-main{
        /*padding-left: 5%;*/
        /*padding-right: 5%;*/
        /*padding-top: 125px;*/
        .steps{
            border-bottom: 1px solid #E2E8E8;
            .md-icon {
                font-size: 20px !important;
            }
            .step {
                line-height:40px;
                height: 40px;
                .md-progress-spinner{
                    position: relative;
                    top: 5px;
                }
            }
            .step:nth-child(2),.step:nth-child(3) {
                border-left: 1px solid #E2E8E8;
            }
            .selected{
                background-color: #F5F5F5
            }
        }
        .title {
            height: 120px;
            background-color: #DFEFFA;
            h1{
                transition-delay: .5s;
                font-size: 36px;
                font-weight: 300;
                /*letter-spacing: .005em;*/
                line-height: 120px;
                margin: 0;
                color: #838D97;
            }

        }
        .subtitle1{
            margin-top: 40px;
            font-size: 25px;
            font-weight: 300;
            letter-spacing: -0.3px;
        }
        .subtitle2{
           margin-top: 17px;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: -0.3px;
        }

        .next-div{
            position: absolute;
            bottom: 36px;
            right: 5%;
        }
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-leave-to{
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity 3s ease-out;
    }
    .fade-leave-active {
        transition: opacity 1s ease-out;
    }
    .skip {
        width: 25px;
        height: 25px;
        background-color: transparent;
        position: absolute;
        right: 25px;
    }

    .icon-container{
        width: 30px;
        display: inline-block;
        height: 40px;
        overflow: hidden;
    }

    .search-container{
        display: inline-block;
        position: relative;
        overflow: hidden;
    }
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #pricing-main .title{
            display: none;
        }
   }

</style>