import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    descriptionPageInfo: {}
  }),
  actions: {
    async fetchDescriptionPageInfo({dispatch, getters, commit}, facilityId) {
      const promises = []
      promises.push(dispatch('fetchFacilityOverviewInfo', facilityId))
      promises.push(dispatch('fetchFacilityDataChildNames'));
      promises.push(dispatch('fetchFacilityDescription', facilityId))
      promises.push(dispatch('fetchFacilityTreatmentFocuses', facilityId));

      await Promise.all(promises);

      const data = {
        facilityDataChildNames: [],
        philosophyChildNames: [],
        overviewForm: {
          overview: ""
        },
        descriptionForm: {
          facilityDescription: ""
        },
        originalOverview: null,
        originalHighlights: null,
        highlights: null,
        origfacilityDescription: null,
        originalDescription: null,
        treatmentFocuses: null,
        descriptionPendingUpdates: false,
        overviewPendingUpdates: false
      };

      data.facilityDataChildNames = getters.facilityDataChildNames(12);
      data.philosophyChildNames = getters.facilityDataChildNames(13);

      // LOAD PHILOSOPHY
      data.originalOverview = JSON.parse(JSON.stringify(getters.facilityPhilosophy(facilityId)));

      // CHECK IF OVERVIEW STATUS IS 1 BEFORE SHOWING.
      data.overviewForm.overview = data.originalOverview && data.originalOverview.status ? JSON.parse(JSON.stringify(data.originalOverview.content)) : null;
      
      // HANDLE OVERVIEW PENDING UPDATES
      if(data.originalOverview.pendingUpdates){
        if(data.originalOverview.pendingUpdates[0].action === "value_change"){
          data.overviewForm.overview = data.originalOverview.pendingUpdates[0].changes.newValue;
          data.overviewPendingUpdates = true;
        }
        if(data.originalOverview.pendingUpdates[0].action === 'created'){
          data.overviewForm.overview = data.originalOverview.pendingUpdates[0].changes.attributes.content;
          data.overviewPendingUpdates = true;
        }
      }

      // LOAD HIGHLIGHTS
      data.originalHighlights = JSON.parse(JSON.stringify(getters.facilityHighlights(facilityId)))

      if(data.originalHighlights && !data.originalOverview.pendingUpdates){
        data.highlights = JSON.parse(JSON.stringify(data.originalHighlights));
        if(data.highlights.length){
          data.highlights = data.highlights.filter(highlight => highlight.id)
        }

        for (const highlight of data.highlights){
          if(data.overviewForm.overview){
            data.overviewForm.overview += `\n\n${highlight.content}`
          }else {
            data.overviewForm.overview = highlight.content
          }

        }
      }

      // LOAD DESCRIPTION
      data.origfacilityDescription = JSON.parse(JSON.stringify(getters.facilityDescription(facilityId)));
      data.descriptionForm.facilityDescription = (' ' + data.origfacilityDescription.facilityDescription).slice(1); // make sure to not get a pointer, deep clone
      data.originalDescription = (' ' + data.origfacilityDescription.facilityDescription).slice(1);
      data.treatmentFocuses = JSON.parse(JSON.stringify(getters.facilityTreatmentFocuses(facilityId)));

      // HANDLE DESCRIPTION PENDING UPDATES.
      if(data.origfacilityDescription.pendingUpdates && data.origfacilityDescription.pendingUpdates.length > 0){
        for(const update of data.origfacilityDescription.pendingUpdates){
          if(update.action === 'value_change'){
            data.descriptionForm.facilityDescription = update.changes.newValue;
          }
        }

        data.descriptionPendingUpdates = true;
      }

      commit('setDescriptionPageInfo', {facilityId, data})

      return data;

    },

    // SAVE A FACILITY'S DESCRIPTION
    async saveFacilityDescription(context, {facilityId, facilityDescription}) {
      const url = `${apiUrl}/getfacilitydescriptionapis/${facilityId}`
      const response = await axios.patch(url, {facilityDescription});

      context.commit('setFacilityDescription', {facilityId, facilityDescription})

      return response;
    },

    // SAVE FACILITY DESCRIPTION AND DELETE ANY OLD PENDING UPDATES.
    async updateFacilitySummary({dispatch, getters, state}, {facilityId, facilityDescription}){
      await dispatch('fetchDescriptionPageInfo', facilityId);

      const facilityDescriptionInfo = state.descriptionPageInfo[facilityId];

      const promises = []

      promises.push(dispatch('saveFacilityDescription', {
        facilityId,
        facilityDescription: facilityDescription
      }))

      if(facilityDescriptionInfo.origfacilityDescription.pendingUpdates && facilityDescriptionInfo.origfacilityDescription.pendingUpdates.length > 0) {
        for(const update of facilityDescriptionInfo.origfacilityDescription.pendingUpdates){
          promises.push(dispatch('deleteContentUpdate', update.id))
        }
      }

      await Promise.all(promises);


    },

    async updateFacilityOverview({dispatch, getters, state}, {facilityId, formData}){
      await dispatch('fetchDescriptionPageInfo', facilityId);

      const data = state.descriptionPageInfo[facilityId];
      // console.log('THE ORIG DATA', data, facilityId, formData)

      const promises = []

      if(formData.overview.id !== data.originalOverview.content){
        // CREATE PHILOSOPHY
        const child = data.philosophyChildNames.find(item => item.sectionLabel === 'rehab_facility_philosophy')

        const overview = data.originalOverview;
        // console.log('ORIG OVERVIEW IN OVERVIEW UPDATE', overview);



        if(!overview || (overview && !overview.id)){
          promises.push(dispatch('createAdminItem', {
            facilityId,
            type: 'philosophy',
            content: formData.overview,
            childId: child.id
          }))
        }else {
          // UPDATE PHILOSOPHY
          promises.push(dispatch('updateAdminItem', {
            facilityId,
            type: 'philosophy',
            childId: overview.id,
            content: formData.overview
          }))
        }

        if(!data.originalOverview.pendingUpdates){
          // console.log('ORIG HIGHLIGHTS', JSON.parse(JSON.stringify(data.originalHighlights)));
          for(const highlight of data.originalHighlights){
            if(highlight && highlight.id){
              // console.log('DELETE HIGHLIGHT', JSON.parse(JSON.stringify(highlight)))
              promises.push(dispatch('deleteAdminItem', {
                facilityId,
                type: 'highlights',
                childId: highlight.id
              }))
            }
          }
        }
      }

      // DELETE PENDING UPDATES
      if(data.originalOverview.pendingUpdates){
        for(const update of data.originalOverview.pendingUpdates){
          // console.log('DELETING PENDING UPDATE ' , update.id);
          promises.push(dispatch('deleteContentUpdate', update.id));
        }
      }

      await Promise.all(promises);
    }

  },
  mutations: {
    setDescriptionPageInfo(state, {facilityId, data}){
      state.descriptionPageInfo[facilityId] = data;
    }
  },
  getters: {

  }
}