import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {parse, isAfter, addMinutes, format} from 'date-fns'

export default {
  // namespaced: true,
  state: () => ({
    creditCards: [],
    bankAccounts: [],
    defaultPaymentMethod: null,
    failedPayments: [],
  }),
  actions: {

    // PAYMENTS
    async getPayments(context) {
      try{
        const user = context.getters.user;
        const url = `${apiUrl}/payments?accountId=${user.accountId}`

        const response = await axios.get(url)
        if(response.data.length !== 0){
          // console.log('PAYMENTS', response.data.data);
          const payments = response.data.data.map(payment => {
            return {
              accountId: payment.attributes.accountId,
              data: payment.attributes.data,
              id: payment.id,
              date: new Date(payment.attributes.createdAt),
              amount: payment.attributes.amount,
              success: payment.attributes.success,
              paymentTerm: payment.attributes.paymentTerm
            }
          })
          // console.log('getPayments result', JSON.parse(JSON.stringify(payments)));

          return payments;
        }

        return []

      }catch (e) {
        console.error('ERROR GETTING PAYMENTS', e);
      }
    },

    async getPayment(context, paymentId) {
      const url = `${apiUrl}/payments/${paymentId}`

      const response = await axios.get(url)

      return response.data

    },

    async makePayment(context) {
      const url = `${apiUrl}/payments`
      const response = await axios.post(url)

      return response.body;
    },

    // CREDIT CARDS
    async fetchCreditCards(context) {
      const user = context.getters.user;
      const url = `${apiUrl}/cards?user_id=${user.id}`
      const result = await axios.get(url);

      // console.log('CC RESULT', JSON.stringify(result.data));
      context.commit('setCreditCards', result.data)
      return result.data;
    },

    async createCreditCard(context, {type, token, endNumbers, expireDate, nameOnCard, zipCode}) {
      const url = `${apiUrl}/cards`
      const result = await axios.post(url, {
        data: {
          type,
          attributes: {
            token,
            endNumbers,
            expireDate,
            nameOnCard,
            zipCode
          }
        }
      })
      await this.dispatch('createNotifications', {description: 'Your credit card was added.', status: "0", data: result.data, datatype: "Billing"})

      return result.data
    },

    async deleteCreditCard(context, id){
      const url = `${apiUrl}/cards/${id}`;
      const result = await axios.delete(url);

      const deleteCard = context.state.creditCards.find(card => card.id === id);

      const remainingCreditCards = context.state.creditCards.filter(card => card.id !== id);
      const cardRemoved = context.state.creditCards.filter(card => card.id === id);
      context.commit('setCreditCards', remainingCreditCards);

      // DON'T SEND MESSAGE IF CARD WAS CREATED IN THE PAST 10 MINUTES, IN CASE PAYMENT FAILURE DELETES A CARD.
      const cardCreated = parse(deleteCard.createdAt);
      const now = new Date();
      const cutoff = addMinutes(cardCreated, 10);
      if(isAfter(now, cutoff)){
        await this.dispatch('createNotifications', {description: 'Your credit card was deleted.', status: "0", data: cardRemoved, datatype: "Billing"})
      }

      return result
    },

    async updateCreditCard(context, {id, cardData}){
      // console.log('updating card with data', cardData);
      const url = `${apiUrl}/cards/${id}`
      const result = await axios.patch(url, cardData);
    
      await this.dispatch('createNotifications', {description: 'Your credit card was updated.', status: "0", data: cardData, datatype: "Billing"})

      return result;
    },

    // BANK ACCOUNTS
    async createBankAccount(context, {stripeToken}){
      const url = `${apiUrl}/bank-accounts`
      const result = await axios.post(url, {
        data: {
          attributes: {
            token: stripeToken.id,
            endNumbers: stripeToken.bank_account.last4,
            nameOnAccount: stripeToken.bank_account.account_holder_name
          }
        }
      })
      await this.dispatch('createNotifications', {description: 'Bank account created', status: "0", data: result.data, datatype: "payment method"})

      return result.data
    },

    async fetchBankAccounts(context) {
      const url = `${apiUrl}/bank-accounts`
      const result = await axios.get(url);

      context.commit('setBankAccounts', result.data)

      return result.data;
    },

    async deleteBankAccount(context, id){
      const url = `${apiUrl}/bank-accounts/${id}`
      const result = await axios.delete(url);

      const remainingBankAccounts = context.state.bankAccounts.filter(bankAccount => bankAccount.id !== id)
      const accountRemoved = context.state.bankAccounts.filter(bankAccount => bankAccount.id === id)
      context.commit('setBankAccounts', remainingBankAccounts)

      await this.dispatch('createNotifications', {description: 'Bank account deleted', status: "0", data: accountRemoved, datatype: "payment method"})

      return result;
    },

    async verifyBankAccount(context, {verifyAmount1, verifyAmount2, id}){
      const url = `${apiUrl}/bank-accounts/${id}`
      const data = {
        verifyAmount1,
        verifyAmount2
      }

      try {
        const verified = await axios.patch(url, data)
        // console.log('Verify Attempt!', verified);
        await context.dispatch('fetchBankAccounts');
        return verified;
      }catch(e){
        console.error(e.response);
        throw new Error(e.response.data.error);
      }
    },


    // DEFAULT PAYMENT METHOD
    async fetchDefaultPaymentMethod(context) {
      const user = context.getters.user;
      const url = `${apiUrl}/default-payment-methods/${user.accountId}`
      try{
        const result = await axios.get(url);
        if(Object.keys(result.data).length === 0){
          // NO DEFAULT PAYMENT METHOD
          return null
        }

        context.commit('setDefaultPaymentMethod', result.data);

        return result.data;
      }catch (e) {
        console.error('ERROR GETTING DEFAULT PAYMENT METHOD', e);
        if(e.response && e.response.status && e.response.status === 404){
          // console.log('Got a 404, returning empty object');
        }
        throw e
      }
    },

    async updateDefaultPaymentMethod(context, {paymentMethod, id}){
      // console.log('UPDATING DEFAULT PAYMENT METHOD', paymentMethod, id)
      const user = context.getters.user;
      const url = `${apiUrl}/default-payment-methods/${user.accountId}`
      const result = await axios.patch(url, {paymentMethod, id});
      // console.log('UPDATED DEFAULT PAYMENT METHOD', JSON.parse(JSON.stringify(result.data)))
      await this.dispatch('createNotifications', {description: 'Your payment method was updated.', status: "0", data: paymentMethod, datatype: "Billing"})
      await context.dispatch('fetchDefaultPaymentMethod')
    },

    async downloadReceipt(context, payment){
      const url = `${apiUrl}/payments/${payment.id}?pdf=true`
      const result = await axios.get(url, {responseType: 'blob'})
      var fileURL = window.URL.createObjectURL(new Blob([result.data]));
      var fileLink = document.createElement('a');

      console.log('PAYMNET', payment);

      const monthYear = format(parse(payment.date), 'MM-YYYY')

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `Rehab.com-receipt-${monthYear}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
      return true;
    },

    async makeInitialPayment(context){
      const user = context.getters.user;
      const url = `${apiUrl}/initial-payment`
      const result = await axios.post(url)
      // console.log('MADE INITIAL PAYMENT', result.data);
      return result.data
    },

    async fetchAccountTotal(context) {
      const url = `${apiUrl}/account-balance`
      const result = await axios.get(url);
      // console.log('ACCOUNT TOTAL RESPONSE', result)
      return result.data;
    },

    async retryPayment(context, paymentId) {
      const url = `${apiUrl}/retry-payment/${paymentId}`
      const result = await axios.post(url)
      await context.commit('setFailedPayments', []);
      return result.data;
    }


  },
  mutations: {
    setCreditCards(state, creditCards){
      state.creditCards = creditCards
    },
    setBankAccounts(state, bankAccounts){
      state.bankAccounts = bankAccounts
    },
    setDefaultPaymentMethod(state, defaultPaymentMethod) {
      state.defaultPaymentMethod = defaultPaymentMethod
    },
    setFailedPayments(state, failedPayments) {
      state.failedPayments = failedPayments
    }
  },
  getters: {
    creditCards: state => state.creditCards,
    bankAccounts: state => state.bankAccounts,
    defaultPaymentMethod: state => state.defaultPaymentMethod,
    failedPayments: state => state.failedPayments
  }
}