<template>
    <div ref="maindiv" id="pricing-main">
        <div class="progress-bar-container">
            <md-progress-bar md-mode="determinate" :md-value="progressValue"></md-progress-bar>
        </div>

        <div id="waiting">
            <p v-if="!mobile">Advertise your program with the best price on the web.</p>
            <p v-else>Advertise with the best price.</p>
        </div>

        <div class="md-layout md-gutter pricing-data">
            <div class="md-layout-item price-google">
                <div class="google-price-div">
                    <div class="features">
                        <transition name="fade">
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '5s' }">
<!--                                <md-progress-spinner :md-diameter="12" :md-stroke="1" md-mode="indeterminate" class="spinner" v-if="second <= 4"></md-progress-spinner>-->
                                <md-icon>check</md-icon>Get website visits</p>
                        </transition>
                        
                        <transition name="fade">
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '6.5s' }">
<!--                                <md-progress-spinner :md-diameter="12" :md-stroke="1" md-mode="indeterminate" class="spinner" v-if="second <= 5"></md-progress-spinner>-->
                                <md-icon>check</md-icon><template v-if="!mobile">Get more phone calls</template><template v-else>More phone calls</template> 
                            </p>
                        </transition>
                        
                        <transition name="fade" >
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '8s' }">
<!--                                <md-progress-spinner :md-diameter="12" :md-stroke="1" md-mode="indeterminate" class="spinner" v-if="second <= 6"></md-progress-spinner>-->
                                <md-icon>check</md-icon>Custom budget
                            </p>
                        </transition>
                        
                    </div>
                    <transition name="fade" >
                        <div style="width:100%" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '8s' }">
                            <hr class="pricing-hr" >
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="pricing" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '10s' }">
                            <span class="dollar-sign">$</span>
                            <span class="cpc">{{ clickPrice / .9 | formatClickPrice }}</span>
                            <span class="per-click-call">per click</span>
                        </div>
                    </transition>

                </div>
                <div class="logo-div">
                    <img class="google-logo" src="@/assets/google-ads.png"/>
                </div>
            </div>
            <div class="md-layout-item price-rehab">
                <div class="rehab-price-div">
                    <transition name="fade">
                        <div class="ribbon ribbon-small ribbon-orange" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '14.5s' }">
                            <div class="banner">
                                <div class="text">10% OFF</div>
                            </div>
                        </div>
                    </transition>

                    <div class="features">
                        <transition name="fade">
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '5s' }"><md-icon>check</md-icon>Get website visits</p>
                        </transition>

                        <transition name="fade">
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '6.5s' }">
<!--                                <md-progress-spinner :md-diameter="12" :md-stroke="1" md-mode="indeterminate" class="spinner" v-if="second <= 5"></md-progress-spinner>-->
                                <md-icon>check</md-icon><template v-if="!mobile">Get more phone calls</template><template v-else>More phone calls</template>
                            </p>
                        </transition>

                        <transition name="fade" >
                            <p v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '8s' }">
<!--                                <md-progress-spinner :md-diameter="12" :md-stroke="1" md-mode="indeterminate" class="spinner" v-if="second <= 6"></md-progress-spinner>-->
                                <md-icon>check</md-icon>Custom budget
                            </p>
                        </transition>
                    </div>

                    <transition name="fade" >
                        <div style="width:100%" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '8s' }">
                            <hr class="pricing-hr" >
                        </div>
                    </transition>


                    <div class="pricing">
                        <transition name="fade">
                            <span class="dollar-sign" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '13s' }">$</span>
                        </transition>

                        <transition name="fade">
                            <span class="cpc" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '13s' }">{{ clickPrice | formatClickPrice }}</span>
                        </transition>

                        <div class="best-price-wrapper">
                            <transition name="fade">
                                <p class="best-price" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '11s' }">${{ clickPrice / .9 | formatClickPrice}}</p>
                            </transition>

                            <transition name="fade">
                                <p class="per-click-call" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '11s' }">per click</p>
                            </transition>

                        </div>

                    </div>

                </div>
                <div class="logo-div">
                    <img class="rehab-logo" src="@/assets/rehab-logo.svg"/>
                </div>
            </div>
        </div>

        <md-dialog-actions class="back-div">
          
        </md-dialog-actions>
        <md-dialog-actions class="next-div">
            <transition name="fade">
                <div class="next-caption" v-show="showStuff" :style="{ transitionDelay: goPremiumFunnelInfo.keywordScreenCompleted ? 0 : '14.9s' }">Lock-in best price:</div>
            </transition>

            <md-button
                    :disabled="progressValue !== 100"
                    class="md-primary md-raised next-btn"
                    :to="{name: 'premium.funnel.locked-in-price'}"
            >Continue</md-button>
        </md-dialog-actions>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {

        name: "PricingResults",
        props: ['clickPrice'],
        data(){
            return {
                title: "Comparing price",
                progressValue: 0,
                interval: null,
                showStuff: false,
                second: 0,
                mobile: false
            }
        },

        computed: {
            ...mapGetters(['goPremiumFunnelInfo'])
        },
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            this.showStuff = true;
            this.second = 0;

            if(this.goPremiumFunnelInfo.keywordScreenCompleted){
                this.second = 12;
                this.progressValue = 100;
            }else {
                this.interval = setInterval(() => {
                    this.progressValue += 100 / 15 * .1; // move the progress bar randomly between 0 and 5 percent
                    this.second += .1;
                    if(this.progressValue > 100) this.progressValue = 100;
                    if(this.progressValue === 100){
                        this.$store.dispatch('createStatusLog', {
                            type: 'ViewedFunnelPricing',
                            action: 'viewed'
                        })
                        clearInterval(this.interval)
                    }
                }, 100)
            }

            this.goPremiumFunnelInfo.keywordScreenCompleted = true;
        },

        filters: {
            formatClickPrice(price){
                if(price % 1 === 0){
                    return price
                } else {
                    return price.toFixed(2);
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity .5s;
    }


    #pricing-main {
        .progress-bar-container{
            padding-top: 25px;
            width: 95%;
            margin-right: auto;
            margin-left: auto;
            .md-progress-bar{
                height: 11px;
                border-radius: 3px;
            }
        }

        #waiting {
            margin-left: 2.5%;
            padding-top:9px;
            text-align: left;
            font-weight: 400;
            min-height: 51px;
            p{
                 font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.3px;
            }
        }

        .pricing-data{
            width: 80%;
            margin-top: 25px;
            margin-right: auto;
            margin-left: auto;

            .pricing-hr{
                width: 95%;
                border-top: 1px solid #7D8385;
                /*height:1px;*/
                opacity: .4;
            }

            .features{
                p{
                    margin: 5px;
                    .md-progress-spinner{
                        margin-right: 5px;
                    }
                }
            }

            .md-icon {
                font-size: 15px !important;
            }

            .google-price-div {
                height: 190px;
                text-align: left;
                color: #7D8385;
                background-color: #EBECED;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 15px;
                .pricing{
                    margin-top:32px;
                    .dollar-sign {
                        font-weight: bold;
                        font-size: 30px;
                        position: relative;
                        top: -18px;
                    }
                    .cpc {
                        font-weight: 500;
                        font-size: 50px;
                        letter-spacing: -2px;
                    }
                    .per-click-call{
                        font-size: 13px;
                        position: relative;
                        top: -1px;
                        left: 3px;
                    }
                }
            }

            .logo-div {
                height: 60px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
                text-align: left;
                .google-logo{
                    height: 45px;
                    position: relative;
                    top: 10px;
                    left: 7px;
                }
                .rehab-logo{
                    height: 25px;
                    position: relative;
                    top: 19px;
                    left: 20px;
                }

                .next-btn{
                    position: relative;
                    left: 135px;
                    top: 5px;
                }
            }

            .rehab-price-div {
                height: 190px;
                text-align: left;
                color: #fff;
                background-color: #0B62D0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 15px;
                .md-icon{
                    color:#fff;
                    padding-right: 2px;
                }
                .pricing{
                    margin-top:27px;
                    .dollar-sign {
                        font-weight: bold;
                        font-size: 30px;
                        position: relative;
                        top: -18px;
                    }
                    .cpc {
                        font-weight: 500;
                        font-size: 50px;
                        letter-spacing: -2px;
                    }
                    .best-price-wrapper{
                        display: inline-block;
                        margin-left: 8px;
                        p{
                            margin:0;
                        }
                        .per-click-call{
                            font-size: 13px;
                        }
                        .best-price{
                            /*display: block;*/
                            font-size: 16px;
                            position: relative;
                            letter-spacing: 0px;
                            top: -1px;
                            text-decoration: line-through;

                        }
                    }

                }


            }
        }
    }

    .back-div {
        position: absolute;
     
        left: 14%;
        padding-left: 0;
        padding-top: 32px;
    }

    .md-button{
         height: 44px;
         width: 130px;
    }
    .next-div{
        position: absolute;
        padding-top: 32px;
       
        right: 13%;
        .next-caption{
            margin-right: 5px;
            color: #7D8385;
        }
    }

    .md-layout.md-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }

    .md-layout.md-gutter>.md-layout-item {
        padding-right: 5px;
        padding-left: 5px;
    }

    .md-primary, .md-raised{
        padding-right: 20px;
        padding-left: 20px;
    }

</style>

<style lang="scss">
    .rehab-price-div{
        .features{
            .md-progress-spinner.md-theme-default .md-progress-spinner-circle {
                stroke: #fff;
            }
        }
    }

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #pricing-main .pricing-data{
            width: 100%!important;
            .google-price-div, .rehab-price-div{
                padding: 10px!important;
                padding-left: 5px!important;
                padding-right: 5px!important;
                 .pricing .cpc{
                     font-size: 36px!important;
                 }
                 .dollar-sign{
                     font-size: 24px!important;
                 }
            }
            .google-price-div{
                height: 172px!important;
                .pricing{
                    margin-top: 28px!important;
                    margin-left: 8px;
                }
            }
            .rehab-price-div{
                height: 172px!important;
                .pricing{
                    margin-top: 16px!important;
                    margin-left: 8px;
                }
            }
         
        }
    }

</style>

<style scoped>
    /* RIBBON BANNERS */

    .ribbon {
        position: absolute;
        top: 373px;
        right: 127px;
        text-align: center;
    }

    /* reset certain elements (in case of conflicting CSS for classes, links, etc.) */
    .ribbon .text,
    .ribbon .banner,
    .ribbon a {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }

    .ribbon a {
        color: #fff;
        text-decoration: none;
    }

    .no-css-transforms .ribbon {
        font-size: 1em;
        position: relative;
        width: 100%;
    }

    .ribbon .banner {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        color: #fff;
        -webkit-font-smoothing: antialiased;
        display: block;
        float: right;
        position: relative;
        right: 0px;
        top: 22px;
        width: 100px;
    }

    .ribbon-small .banner {
        right: -8px;
        top: 33px;
        width: 65px;
    }

    .ribbon-large .banner {
        right: -20px;
        top: 32px;
        width: 150px;
    }

    .ribbon .banner::after,
    .ribbon .banner::before {
        content: '';
        display: block;
        height: 12px;
        position: absolute;
        width: 30px;
    }

    .ribbon-small .banner::after,
    .ribbon-small .banner::before {
        height: 6px;
        width: 20px;
    }

    .ribbon-large .banner::after,
    .ribbon-large .banner::before {
        height: 18px;
        width: 45px;
    }

    .ribbon .banner::before {
        -webkit-transform: skewY(-45deg) translate(50%,15px);
        -moz-transform: skewY(-45deg) translate(50%,15px);
        -ms-transform: skewY(-45deg) translate(50%,15px);
        -o-transform: skewY(-45deg) translate(50%,15px);
        -webkit-transform-origin: 100% center;
        -moz-transform-origin: 100% center;
        -ms-transform-origin: 100% center;
        -o-transform-origin: 100% center;
        left: -45px;
    }

    .ribbon-small .banner::before {
        top: -5px;
        left: -30px;
    }

    .ribbon-large .banner::before {
        top: 9px;
        left: -68px;
    }

    .ribbon .banner::after {
        -webkit-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -moz-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -ms-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -o-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -webkit-transform-origin: 0 center;
        -moz-transform-origin: 0 center;
        -ms-transform-origin: 0 center;
        -o-transform-origin: 0 center;
        right: -17px;
    }

    .ribbon-small .banner::after {
        top: 18px;
        right: -12px;
    }

    .ribbon-large .banner::after {
        top: 45px;
        right: -26px;
    }


    .no-css-transforms .ribbon .banner {
        position: static;
        width: 100%;
        float: none;
        font-size: 10px;
    }

    .ribbon .text {
        position: relative;
        z-index: 2;
        padding: 6px 0px;
        font-size: 14px;
        font-weight: bold;
        min-height: 18px;
        line-height: 18px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.20);
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /* small text */
    .ribbon-small .text {
        padding: 3px 0;
        font-size:12px;
        min-height: 14px;
        line-height: 14px;
    }

    /* large text */
    .ribbon-large .text {
        padding: 9px 0;
        font-size: 18px;
        min-height: 28px;
        line-height: 28px;
    }


    .ribbon .text::before,
    .ribbon .text::after {
        content: '';
        display: block;
        height: 30px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    /* small text */
    .ribbon.ribbon-small .text::before,
    .ribbon.ribbon-small .text::after {
        height: 20px;
    }

    /* large text */
    .ribbon.ribbon-large .text::before,
    .ribbon.ribbon-large .text::after {
        height: 46px;
    }

    .ribbon .text::before {
        -webkit-transform: translateX(-15%) skewX(-45deg);
        -moz-transform: translateX(-15%) skewX(-45deg);
        -ms-transform: translateX(-15%) skewX(-45deg);
        -o-transform: translateX(-15%) skewX(-45deg);
    }

    .ribbon .text::after {
        -webkit-transform: translateX(15%) skewX(45deg);
        -moz-transform: translateX(15%) skewX(45deg);
        -ms-transform: translateX(15%) skewX(45deg);
        -o-transform: translateX(15%) skewX(45deg);
    }

    .no-css-transforms .ribbon .text {
        height: 25px;
        padding: 3px;
    }

    /* orange */
    .ribbon-orange .banner::after,
    .ribbon-orange .banner::before {
        background-color: #BE420C;
    }
    .ribbon-orange .text::before,
    .ribbon-orange .text::after,
    .no-css-transforms .ribbon-orange .text {
        background-color: #EC771B;
    }

    .md-primary{
        margin-left: 6px;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #pricing-main{
            padding-right: 10px;
            padding-left: 10px;
        }
        .md-layout.md-gutter{
            padding-right: 10px;
            padding-left: 10px;
        }
        p{
            font-size: 13px;
        }

        .ribbon {
            position: absolute;
            top: 234px;
            right: 21px;
            text-align: center;
        }
        #pricing-main #waiting{
            min-height: 52px;
        }
        #pricing-main .pricing-data .logo-div .google-logo{
            height: 34px;
            left: 4px;
            margin-top: 4px;
        }
        #pricing-main .pricing-data .logo-div .rehab-logo{
            height: 19px;
            left: 13px;
        }
        .next-div{
            right: 20px;
        }
        .md-layout.md-gutter > .md-layout-item{
            padding: 0;
        }
        .price-google{
            margin-right: 5px;
        }
         .price-rehab{
            margin-left: 5px;
        }
    }
</style>