<template>
    <div class="premium-table" :class="{'center' : facilities.length>1}">
        <!-- TABLE DATA -->
        <md-table
                v-model="tableData"
                md-card
                :md-selected-value.sync="selectedCampaigns"
                @md-selected="onCampaignsSelect"
                :class="{ 'alternateheader' : alternateheader }"
        >
            <md-table-toolbar slot="md-table-alternate-header" slot-scope="stuff">
                <div class="md-toolbar-section-start">{{ getAlternateLabel() }}</div>
                <!--                <div class="md-toolbar-section-start">{{ stuff }}</div>-->

                <div class="md-toolbar-section-end">
                    <md-button class="md-button" @click="massChangeStatus(true)">
                        Enable
                    </md-button>
                    <md-button class="md-button" @click="massChangeStatus(false)">
                        Pause
                    </md-button>
                </div>
            </md-table-toolbar>
            <md-table-row>
                <md-table-head>
                    <div class="fakeCircle"><md-icon>fiber_manual_record</md-icon></div>
                </md-table-head>
                <md-table-head>Listing</md-table-head>
                <md-table-head>
                    {{budgetLabel}}
                    <div class="headerToolTipIcon" @mouseover="showBudgetTooltip = true" @mouseout="showBudgetTooltip = false">
                        <md-icon>help_outline</md-icon>
                        <md-tooltip md-direction="top">{{budgetTooltipText}}</md-tooltip>
                    </div>
                </md-table-head>
                <md-table-head v-if="!mobile">Status</md-table-head>
                <md-table-head v-if="!mobile">
                    Market price
                    <div class="headerToolTipIcon" @mouseover="showMKPTooltip = true" @mouseout="showMKPTooltip = false">
                        <md-icon>help_outline</md-icon>
                        <md-tooltip md-direction="top">{{marketPriceTooltipText}}</md-tooltip>
                    </div>
                </md-table-head>
                <md-table-head v-if="!mobile">
                    User actions
                    <div class="headerToolTipIcon" @mouseover="showUserActionsTooltip = true" @mouseout="showUserActionsTooltip = false">
                        <md-icon>help_outline</md-icon>
                        <md-tooltip md-direction="top">{{userActionsTooltipText}}</md-tooltip>
                    </div>
                </md-table-head>
                <md-table-head v-if="!mobile">
                    Average CPC
                    <div class="headerToolTipIcon" @mouseover="showCPCTooltip = true" @mouseout="showCPCTooltip = false">
                        <md-icon>help_outline</md-icon>
                        <md-tooltip md-direction="top">{{CPCTooltipText}}</md-tooltip>
                    </div>
                </md-table-head>
                <md-table-head v-if="!mobile">Spend</md-table-head>
            </md-table-row>
            <md-table-row
                    v-for="(item, index) in tableData"
                    :key="index"
                    :class="{
                            grey: !item.attributes,
                            'headerRow': item.attributes,
                            'header-open': item.attributes && showRows.includes(item.attributes.facilityId),
                            'header-closed': item.attributes && !showRows.includes(item.attributes.facilityId)
                        }"
                    :ref="'row'+item.counter"
                    @click="toggleRow(item)"
            >
                <!-- STATE - SHOW GREY OR GREEN STATUS & DROPDOWN -->
                <md-table-cell
                        class="state"
                >
                    <!--                    <md-table-cell class="checkbox"><md-checkbox></md-checkbox></md-table-cell>-->
                    <!-- FACILITY STATE -->
                    <div md-size="small" class="md-menu" v-if="item.attributes" @click.stop="">
                        <div md-menu-trigger>
                            <template
                                    v-if="getFacilityStatus(item.attributes.facilityId)"
                            >
                                <div class="green">
                                    <md-icon>fiber_manual_record</md-icon>
                                    <md-icon class="dropdown" v-show="showByIndex === item.attributes.facilityId">arrow_drop_down</md-icon>
                                </div>
                            </template>
                            <template v-else>

                                <md-icon class="pause" v-if="getFacilityStatusText(item.attributes.facilityId) == 'Paused'">pause_circle_filled</md-icon>
                                <md-icon class="dropdown" v-show="showByIndex === item.attributes.facilityId">arrow_drop_down</md-icon>
                                <md-icon v-if="getFacilityStatusText(item.attributes.facilityId) == 'Inactive'">fiber_manual_record</md-icon>
                                <md-icon class="dropdown" v-show="showByIndex === item.attributes.facilityId">arrow_drop_down</md-icon>
                            </template>
                        </div>
                       
                    </div>


                    <!-- CAMPAIGN STATE -->
                    <md-menu md-size="small" v-else>
                        <div md-menu-trigger :ref="'enable-menu-' + item.id" class="menu-trigger" @mouseover="showByIndex = item.id" @mouseout="showByIndex = null">
                            <template v-if="item.status">
                                <div class="green">
                                    <md-icon>fiber_manual_record</md-icon>
                                    <md-icon class="dropdown" v-show="showByIndex === item.id">arrow_drop_down</md-icon></div></template>
                            <template v-else>
                                <md-icon class="pause" v-if="item.status == false && item.startDate">pause_circle_filled</md-icon>
                                <md-icon class="dropdown" v-show="showByIndex === item.id">arrow_drop_down</md-icon>
                                <md-icon v-if="item.status == false && item.startDate == null">fiber_manual_record</md-icon>
                                <md-icon class="dropdown" v-show="showByIndex === item.id">arrow_drop_down</md-icon>
                            </template>
                        </div>
                        <md-menu-content>
                            <md-menu-item @click="changeStatus(true, item)" class="green"><md-icon>fiber_manual_record</md-icon><div class="option">Enable</div></md-menu-item>
                            <md-menu-item @click="changeStatus(false, item)"><md-icon class="pause">pause_circle_filled</md-icon><div class="option">Pause&nbsp;</div></md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </md-table-cell>

                <!-- FACILITY/PRODUCT NAME -->
                <md-table-cell class="facility" :id="tableFacilityIdGenerator(item)">
                    <!-- FACILITY NAME -->
                    <div class="nameContainer" v-if="item.attributes && facilities.length>1">
                        <span class="blue name" :ref="'span'+item.counter" v-if="item.attributes">{{item.attributes.name}} </span>
                        <span class="blue address">{{item.attributes.city + ', ' + item.attributes.state }}</span>
                    </div>
                    <div class="nameContainer" v-else-if="item.type && item.type === 'bedRequests'">
                        {{item.product}}
                    </div>
                    <template v-else>
                        <span class="blue single-facility" :ref="'span'+item.counter" v-if="item.attributes">{{item.attributes.name}} </span>
                    </template>
                    <template v-if="!item.attributes && !item.type">
                        <span
                                class="product"
                                v-show="showProductName(item)"
                                @click="toggle(item)"
                        >{{ item.product|capitalize }} <md-icon class="action">edit</md-icon></span>

                        <!-- VERIFY PHONE INPUT -->
                        <template v-if="productEdit == item.counter && defaultPaymentMethod && item.productId==1">
                            <div class="phoneInput">
                                <div class="usFlag"><img src="/images/gopremium/us_flag.png" />US +1</div>
                                <div class="phone">
                                    <input
                                            @blur="confirmReceivingNumberOrUrl(item.facilityId, item.productId, item.counter, item.id)"
                                            :ref="'productInput'+item.counter"
                                            type="text" placeholder="(___) ___-____"
                                            v-mask="['(###) ###-####']"
                                            :value="getNumber(item.facilityId)"
                                            @keyup.enter="confirmReceivingNumberOrUrl(item.facilityId, item.productId, item.counter, item.id)"
                                    />
                                </div>
                            </div>
                        </template>

                        <!-- VERIFY URL INPUT -->
                        <template v-if="productEdit == item.counter && defaultPaymentMethod && item.productId==2">
                            <div class="phoneInput">
                                <div class="url">
                                    <input
                                            @blur="confirmReceivingNumberOrUrl(item.facilityId, item.productId, item.counter, item.id)"
                                            :ref="'productInput'+item.counter"
                                            type="text" placeholder="https://"
                                            @click="productUpdateSnack = false"
                                            :value="getUrl(item.facilityId)"
                                            @keyup.enter="confirmReceivingNumberOrUrl(item.facilityId, item.productId, item.counter, item.id)"
                                    />
                                </div>
                            </div>
                        </template>

                        <!-- RED BOX - PREMIUM CALLS -->
                        <template v-if="defaultPaymentMethod && !singleVerifiedPhoneOrUrl(item) && item.productId==1 && goPremiumFunnelCompleteThisLogin && item.status === true">
                            <div @click="addVerifiedPhoneOrUrl(item); toggle(item)"  class="premiumCheck">Receiving number setup</div>
                        </template>
                        <!-- RED BOX - PREMIUM CLICKS -->
                        <template v-if="defaultPaymentMethod && !singleVerifiedPhoneOrUrl(item) && item.productId==2 && goPremiumFunnelCompleteThisLogin && item.status === true">
                            <div @click="addVerifiedPhoneOrUrl(item); toggle(item)" class="premiumCheck">Landing page setup</div>
                        </template>

                    </template>

                </md-table-cell>

                <!-- BUDGET -->
                    <md-table-cell class="budgetCell" v-if="status1 || !mobile">
                    <!-- FACILITY BUDGET -->
                    <span v-if="item.attributes">{{ getTotalBudget(item.attributes.facilityId) | currency}}</span>
                    <!-- LEAD/BED REQUEST 'BUDGET' BEFORE AT LEAST ONE SUBSCRIPTION PURCHASED -->
                    <span
                            v-else-if="item.type && item.type === 'bedRequests' && !subscriptions.length"
                            class="table-link"
                            @click="bedRequestBudgetClick(item)"
                    ><span class="table-link blue">
                                    Setup
                                </span></span>
                    <!-- LEAD/BED REQUEST 'BUDGET' AFTER AT LEAST ONE SUBSCRIPTION HAS BEEN PURCHASED -->
                    <span
                            v-else-if="item.type && item.type === 'bedRequests' && subscriptions.length"
                            class="table-link"
                            @click="bedRequestBudgetClick(item)"
                    >{{item.status ? 'Included' : 'Off' }}</span>

                    <!-- CAMPAIGN BUDGET -->
                    <template v-else>
                        <!-- If the company has never been Premium then the “$0” under Budget should say “Setup”, which prompts “Admission Matter / See Pricing” card. -->
                        <template v-if="!subscriptions.length">
                            <div
                                    @mouseover="showBudget = item.id"
                                    @mouseout="showBudget = null"
                                    class="menu-trigger"
                                    @click="$emit('startGoPremiumFunnel', item.facilityId)"
                            >
                                <span class="table-link blue">
                                    Setup
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <md-menu md-size="small" :mdCloseOnClick="closeOnClick" :ref="'menu'+item.id">
                                <!-- OTHERWISE SHOW BUTTON TO OPEN BUDGET MENU -->
                                <div
                                        md-menu-trigger
                                        class="menu-trigger"
                                        @mouseover="showByIndex = item.id"
                                        @mouseout="showByIndex = null"
                                >
                                    <div
                                            class="budget"
                                            @click="openBudgetDialogClick(item)"
                                            @mouseout="showBudget = null"
                                            @mouseover="showBudget = item.id"
                                            :ref="'test'+item.id"
                                            id="menu-test"
                                    >
<!--                                        <span v-if="item.budget">-->
                                        <span>
                                            {{ item.budget | currency }} <md-icon class="dropdown">arrow_drop_down</md-icon>
                                        </span>
                                    </div>
                                </div>

                                <!-- BUDGET SLIDER DIALOG -->
                                <md-menu-content class="dialogBudget">
                                    <template v-if="selectedFacilityItem">
                                        <span class="title">Set your budget</span>
                                    </template>
                                    <span class="budget">{{ '$'+formatPrice(Math.ceil(amount)) }}</span>
                                    <div class="slidecontainer">
                                       <template v-if="selectedProductId==1">
                                        <input
                                                class="slider"
                                                type="range"
                                                v-model.number="amount"
                                                :min="100"
                                                :max="10000"
                                                :step="1"
                                                @click="closeOnClick = false"
                                        >
                                        </template>
                                        <template v-else-if="selectedProductId==2">
                                        <input 
                                                class="slider"
                                                type="range"
                                                v-model.number="amount"
                                                :min="50"
                                                :max="10000"
                                                :step="1"
                                                @click="closeOnClick = false"
                                        >
                                        </template>
                                        <div class="typical"></div>
                                        <div class="competitor">Typical budget range</div>
                                    </div>
                                    <!-- CALLS TEXT -->
                                    <div v-if="
                                            selectedProductId==1 &&
                                            amount < 10000 &&
                                            amount >= 100
                                        "
                                         class="reach"
                                    >
                                        Receive up to {{ amount/selectedInteractionCost | callClickRange }} calls per month
                                    </div>

                                    <!-- CLICKS TEXT -->
                                    <div
                                            v-if="
                                                selectedProductId === 2 &&
                                                amount < 10000 &&
                                                amount >= 50
                                            "
                                            class="reach"
                                    >
                                        Receive up to {{ amount/selectedInteractionCost | callClickRange }} clicks per month
                                    </div>

                                    <!-- REQUEST HIGHER BUDGET TEXT -->
                                    <div v-if="amount === 10000" class="reach">
                                        Need a larger budget? Select contact and we will be in touch to discuss your goals.
                                    </div>
                                    <!-- BUDGET 0 TEXT -->
                                    <div v-if="amount === 0 && selectedProductId == 1" class="reach">Set your call budget to activate</div>
                                    <div v-if="amount === 0 && selectedProductId == 2" class="reach">Set your click budget to activate</div>

                                    <md-dialog-actions>
                                        <!-- CLOSE BUTTON -->
                                        <md-button class="md-primary" @click="closeOnClick = true">Close</md-button>
                                        <!-- SAVE BUTTON -->
                                        <md-button
                                                class="md-primary md-raised"
                                                v-if="amount < 10000 && amount >= 50"
                                                @click="closeBudgetMenu(selectedFacilityId, selectedProductId, amount)"
                                        >Save</md-button>
                                        <!-- CONTACT ME BUTTON -->
                                        <md-button
                                                class="md-primary md-raised"
                                                v-if="amount === 10000"
                                                @click="contactMe(selectedFacilityId, selectedProductId, amount); closeOnClick = true"
                                        >Contact</md-button>
                                    </md-dialog-actions>
                                </md-menu-content>
                            </md-menu>
                        </template>
                    </template>
                </md-table-cell>

                <!-- STATUS -->
                <md-table-cell v-if="status2 || !mobile">
                    <span v-if="item.attributes">{{ getFacilityStatusText(item.attributes.facilityId) }}</span>
                    <span v-else-if="item.type && item.type === 'bedRequests'">{{ item.status ? 'Active' : 'Inactive' }}</span>
                    <span v-else>{{ item | status }}</span>
                </md-table-cell>

                <!-- CURRENT CPC/MARKET PRICING -->
                <md-table-cell :class="{ 'alignRight' : !mobile}" v-if="status3 || !mobile">
                    <span v-if="item.attributes"></span>
                    <span v-else-if="item.type && item.type === 'bedRequests'">n/a</span>
                    <span
                            v-else-if="!showMarketPricing"
                            @click="$emit('startGoPremiumFunnel', item.facilityId)"
                            class="table-link"
                    >Learn more</span>
                    <span v-else>{{item.currentCpc | currencyOriginal}}</span>
                </md-table-cell>

                <!-- INTERACTIONS -->
                <md-table-cell :class="{ 'alignRight' : !mobile}" v-if="status4 || !mobile">
                    {{item.interactions}}
                </md-table-cell>

                <!-- CPC -->
                <md-table-cell :class="{ 'alignRight' : !mobile}" v-if="status5 || !mobile">
                    <span v-if="item.type && item.type === 'bedRequests'">n/a</span>
                    <span v-else-if="(!item.attributes && defaultPaymentMethod) && item.cpc > 0">
                        {{ item.cpc | currencyOriginal}}
                    </span>
                    <span v-else-if="!item.attributes">n/a</span>
                </md-table-cell>

                <!-- SPEND -->
                <md-table-cell :class="{ 'alignRight' : !mobile}" v-if="status6 || !mobile">
                    <span v-if="item.type && item.type === 'bedRequests'">n/a</span>
                    <span v-else>{{item.spend | currencyOriginal}}</span>
                </md-table-cell>

            </md-table-row>
        </md-table>

        <div class="selection-arrows" :class="{ 'addtopmarginarrows' : subscriptions.length == 0 && !subscriptionBannerDismissed && !welcomeTooltip && !GoPremiumTooltip}" v-if="mobile">
            <div @click="selectColumn('left')" :class="{'disablearrow': !leftarrow}"><md-icon class="left" :class="{'disablearrow': !leftarrow}">navigate_before</md-icon></div>
            <div @click="selectColumn('right')" ><md-icon class="right" :class="{'disablearrow': !rightarrow}">navigate_next</md-icon></div>
        </div>
        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="productUpdateSnack"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="productUpdateSnack = false">Close</md-button>
        </md-snackbar>

        <!-- CONFIRM YOU WANT TO ENABLE BED REQUESTS AND DISABLE PREMIUM CAMPAIGNS FOR FACILITY -->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmEnableBedRequestsDisableAll"
                md-title="Please confirm"
                md-content="Unlimited forms will replace premium clicks and calls."
                md-confirm-text="Continue"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmEnableBedRequestsDisableAll = false"
                @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO ENABLE BED REQUESTS AND DISABLE PREMIUM CLICKS FOR FACILITY -->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmEnableBedRequestsDisableClicks"
                md-title="Please confirm"
                md-content="Unlimited forms will replace premium clicks."
                md-confirm-text="Continue"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmEnableBedRequestsDisableClicks = false"
                @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO ENABLE BED REQUESTS AND DISABLE PREMIUM CALLS FOR FACILITY -->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmEnableBedRequestsDisableCalls"
                md-title="Please confirm"
                md-content="Unlimited forms will replace premium calls."
                md-confirm-text="Continue"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmEnableBedRequestsDisableCalls = false"
                @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO DISABLE PREMIUM CLICKS FOR FACILITY AND ENABLE BED REQUESTS -->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmTurnOffClicks"
                md-title="Please confirm"
                md-content="Unlimited forms will become the default and replace premium clicks."
                md-confirm-text="Continue"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmTurnOffClicks = false"
                @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO ENABLE BED REQUESTS AND DISABLE PREMIUM CALLS FOR FACILITY -->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmTurnOffCalls"
                md-title="Please confirm"
                md-content="Unlimited forms will become the default and replace premium calls."
                md-confirm-text="Continue"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmTurnOffCalls = false"
                @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO DISABLE BED REQUESTS AND ENABLE PREMIUM CAMPAIGNS FOR FACILITY-->
        <md-dialog-confirm
                class="action-confirm-dialog"
                :md-active.sync="showConfirmTurnOffBedRequests"
                md-title="Please confirm"
                md-content="Disabling unlimited forms will automatically enable your premium clicks and premium calls."
                md-confirm-text="Enable"
                md-cancel-text="Cancel"
                @md-cancel="showConfirmTurnOffBedRequests = false"
                @md-confirm="changeFacilityStatus(true, tempBedRequestHolder, true)"
        />

        <!-- CONFIRM YOU WANT TO ENABLE A CAMPAIGN AND DISABLE BED REQUESTS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmEnableCampaign"
                    md-title="Please confirm"
                    md-content="Enabling premium clicks and premium calls will replace leads."
                    md-confirm-text="Enable"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmEnableCampaign = false"
                    @md-confirm="tempBedRequestHolder.attributes ? changeFacilityStatus(true, tempBedRequestHolder, true) : changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TO ENABLE A CLICK CAMPAIGN AND DISABLE BED REQUESTS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOnClicks"
                    md-title="Please confirm"
                    md-content="Premium clicks will replace unlimited forms."
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOnClicks = false"
                    @md-confirm="tempBedRequestHolder.attributes ? changeFacilityStatus(true, tempBedRequestHolder, true) : changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TO ENABLE A CLICK CAMPAIGN AND DISABLE BED REQUESTS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOnCalls"
                    md-title="Please confirm"
                    md-content="Premium calls will replace unlimited forms."
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOnCalls = false"
                    @md-confirm="tempBedRequestHolder.attributes ? changeFacilityStatus(true, tempBedRequestHolder, true) : changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TO ENABLE A CLICK CAMPAIGN WHEN THERE IS ALREADY A CALL CAMPAIGN IN PLACE FOR THIS FACILITY -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOnClicksAfterCalls"
                    md-title="Please confirm"
                    md-content='Activating premium clicks will add a “Visit Website” link to your page.'
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOnClicksAfterCalls = false"
                    @md-confirm="changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TO ENABLE A CALL CAMPAIGN WHEN THERE IS ALREADY A CLICK CAMPAIGN IN PLACE FOR THIS FACILITY -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOnCallsAfterClicks"
                    md-title="Please confirm"
                    md-content='Activating premium calls will add a tracking number to your page.'
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOnCallsAfterClicks = false"
                    @md-confirm="changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TO DISABLE PREMIUM CLICKS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOffClicksOnly"
                    md-title="Please confirm"
                    md-content='Pausing premium clicks will remove the “Visit Website” link from your listing.'
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOffClicksOnly = false"
                    @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
            />
        </div>

         <!-- CONFIRM YOU WANT TO DISABLE PREMIUM CALLS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmTurnOffCallsOnly"
                    md-title="Please confirm"
                    md-content=' Pausing premium calls will remove your tracking number from your listing.'
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmTurnOffCallsOnly = false"
                    @md-confirm="changeStatus(false, tempBedRequestHolder, true)"
            />
        </div>

        <!-- CONFIRM YOU WANT TURN OFF ALL CAMPAIGNS AND ENABLE BED REQUESTS -->
        <div v-if="tempBedRequestHolder">
            <md-dialog-confirm
                    class="action-confirm-dialog"
                    :md-active.sync="showConfirmDisableAllCampaigns"
                    md-title="Please confirm"
                    md-content="Unlimited forms will become the default and replace premium calls and premium clicks."
                    md-confirm-text="Continue"
                    md-cancel-text="Cancel"
                    @md-cancel="showConfirmDisableAllCampaigns = false"
                    @md-confirm="tempBedRequestHolder.attributes ? changeFacilityStatus(false, tempBedRequestHolder, true) : changeStatus(true, tempBedRequestHolder, true)"
            />
        </div>

        <subscribe-educator-modal
            :item="subscribeItem"
            @close="subscribeItem = null"
            @confirm="subscribeItem = null"
            @changeStatus="changeStatus(true, $event, true)"
        ></subscribe-educator-modal>
        
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import goPremiumMixin from '../../mixins/goPremium'
import {endOfDay, isAfter, isBefore, parse, startOfToday} from 'date-fns'
import SubscribeEducatorModal from "./SubscribeEducatorModal";

export default {
        name: "GoPremiumTable",
        mixins: [goPremiumMixin],
        props: {
            startDate: Date,
            endDate: Date,
            selectedFacility: Array,
            calls: Array,
            callsGA: Array,
            clicks: Array,
            clicksGA: Array,
            firstRequestedDate: Date,
            lastRequestedDate: Date,
            showDialogPremium: Boolean,
            bedRequests: Array
        },
        components: {
            SubscribeEducatorModal
        },
        data() {
            return {
                showByIndex: null,
                showBudget: null,
                closeOnClick: false,
                amount: 5000,
                selectedProductId: null,
                selectedFacilityItem: null,
                selectedCampaigns: [],
                selectedInteractionCost: null,
                alternateheader: false,
                isActive: true,
                dialogBudget: true,
                productEdit: null,
                productSet: null,
                productUpdateSnack: false,
                urls: [],
                snackMsg: null,
                showCPCTooltip: false,
                showMKPTooltip: false,
                showUserActionsTooltip: false,
                showBudgetTooltip: false,
                noBudgetCampaigns: [],
                showRows: [],
                showConfirmEnableBedRequestsDisableAll: false,
                showConfirmEnableBedRequestsDisableClicks: false,
                showConfirmEnableBedRequestsDisableCalls: false,
                showConfirmTurnOffBedRequests: false,
                showConfirmEnableCampaign: false,
                showConfirmTurnOffClicks: false,
                showConfirmTurnOffCalls: false,
                showConfirmTurnOnClicks: false,
                showConfirmTurnOnCalls: false,
                showConfirmTurnOffCallsOnly: false,
                showConfirmTurnOffClicksOnly: false,
                showConfirmTurnOnCallsAfterClicks: false,
                showConfirmTurnOnClicksAfterCalls: false, // SHOWS A CONFIRM WHEN THERE IS ALREADY A CALL CAMPAIGN, AND WE ENABLE CLICKS
                showConfirmDisableAllCampaigns: false,
                tempBedRequestHolder: null,
                subscribeItem: null,
                mobile:false,
                budgetLabel: "Monthly budget",
                status1: true,
                status2: false,
                status3: false,
                status4: false,
                status5: false,
                status6: false,
                columnCounter: 1,
                leftarrow: false,
                rightarrow: true,
                budgetTooltipText: '',
                marketPriceTooltipText: '',
                userActionsTooltipText: '',
                CPCTooltipText: '',
                minSlider: 50
            }
        },
        computed: {
            ...mapGetters([
                'verifiedPhoneOrUrls',
                'singleVerifiedPhoneOrUrl',
                'goPremiumFunnelCompleteThisLogin',
                'goPremiumFunnelTooltip',
                'products',
                'calculateBudget',
                'getFacilityInteractionCostsByDate',
                'subscriptions',
                'subscriptionBannerDismissed',
                'welcomeTooltip',
                'GoPremiumTooltip',
                'campaignsWithStartDates',
                'enabledCampaigns',
                'callProduct',
                'clickProduct',
                'goPremiumFunnelInfo',
                'getFacilitySubscriptionStartDate',
                'getFacilityPeriodStartDate',
                'failedPayments',
                'hubspotContact'
            ]),

            showMarketPricing() {
                if(this.subscriptions.length) {
                    return true;
                }
                if(this.$store.getters.statusLogByName('ViewedFunnelPricing')){
                    return true;
                }
                return false
            },

            tableData(){
                let data = []
                var counter = 0
                // console.log('PRODUCTS', JSON.parse(JSON.stringify(this.products)));
                const callProduct = this.products.find(i => i.attributes.slug === "premium-calls")
                const clickProduct = this.products.find(i => i.attributes.slug === "premium-clicks")
                for(const facility of this.displayFacility){
                    if(this.displayFacility.find(item => item.attributes.facilityId === facility.attributes.facilityId)){
                        const facilityCopy = JSON.parse(JSON.stringify(facility));
                        facilityCopy.id = 'facility-' + facilityCopy.id
                        facilityCopy.counter = 'facility-'+facilityCopy.attributes.counter;
                        data.push(facilityCopy);
                        //INTERACTIONS
                        const callInteractions = this.getCampaignInteractions(callProduct.id, facility.attributes.facilityId)
                        // console.log('CALL INTERACTIONS', callInteractions);
                        const clickInteractions = this.getCampaignInteractions(clickProduct.id, facility.attributes.facilityId)
                        let bedRequestInteractions = [];
                        if(this.bedRequests && this.bedRequests.length){
                            bedRequestInteractions = this.bedRequests.filter(bedRequest => {
                                const requestDate = parse(bedRequest.date)
                                if(
                                    isAfter(requestDate, this.startDate) &&
                                    isBefore(requestDate, endOfDay(this.endDate)) &&
                                    parseInt(facility.attributes.facilityId) === parseInt(bedRequest.facilitiesSelected)
                                ){
                                    return bedRequest
                                }
                                return false;
                            })
                        }

                        // console.log('CALL INTERACTIONS', facility.attributes.facilityId,  callInteractions);
                        // console.log('CLICK INTERACTIONS',facility.attributes.facilityId, clickInteractions, "\n\n\n");
                        facility.spend = callInteractions.spend + clickInteractions.spend
                        facility.interactions = callInteractions.count + clickInteractions.count + bedRequestInteractions.length;

                        const campaigns = JSON.parse(JSON.stringify(this.campaigns)).filter(campaign => campaign.facilityId === facility.attributes.facilityId)

                        // console.log('CAMPAIGNS', JSON.parse(JSON.stringify(campaigns)))
                        if(this.showRows.includes(facility.attributes.facilityId)){
                            for(const campaign of campaigns){
                                // CALLS
                                if(parseInt(campaign.productId) === parseInt(callProduct.id)){
                                    campaign.spend = callInteractions.spend;
                                    campaign.interactions = callInteractions.count;
                                    // CLICKS
                                }else if(parseInt(campaign.productId) === parseInt(clickProduct.id)){
                                    campaign.spend = clickInteractions.spend;
                                    campaign.interactions = clickInteractions.count
                                }

                                // CALCULATE CPC
                                if(campaign.interactions > 0 && campaign.spend > 0){
                                    campaign.cpc = campaign.spend / campaign.interactions
                                }else {
                                    campaign.cpc = 0
                                }
                                campaign.currentCpc = this.getInteractionCostsValue(campaign.productId, campaign.facilityId)
                            }

                            // console.log('SUBSCRIPTIONS', JSON.parse(JSON.stringify(this.subscriptions)));
                            // console.log('\n\nFACILITY ID', facility.attributes.facilityId)
                            const subscription = this.subscriptions.find(subscription => subscription.facilityId === facility.attributes.facilityId);
                            const facilityEnabledCampaigns = this.campaigns.filter(campaign => campaign.facilityId === facility.attributes.facilityId && campaign.status)
                            // console.log('FACILITY ENABLED CAMPAIGNS', JSON.parse(JSON.stringify(facilityEnabledCampaigns)))
                            // console.log('SUBSCRIPTION: ', subscription);
                            const subscriptionData = {
                                counter:  facilityCopy.counter = 'subscription-'+facilityCopy.attributes.counter,
                                id:  facilityCopy.counter = 'subscription-'+facilityCopy.id.match(/(\d+)/)[0],
                                status: !!(subscription && subscription.active && facilityEnabledCampaigns.length === 0),
                                budget: 89,
                                facilityId: facility.attributes.facilityId,
                                product: 'Unlimited Forms',
                                productId: 'subscription',
                                interactions: bedRequestInteractions.length,
                                cost: '',
                                cpc: 'n/a',
                                type: 'bedRequests'
                            }
                            // console.log('SUBSCRIPTION DATA FOR TABLE', JSON.parse(JSON.stringify(subscriptionData)));
                            // console.log('CAMPAIGNS', JSON.parse(JSON.stringify(campaigns)))


                            //console.log('TABLE CAMPAIGNS', JSON.parse(JSON.stringify(campaigns)))

                            // DISPLAY FACILITY DATA, BED REQUESTS, CLICK CAMPAIGN, CALL CAMPAIGN IN THAT ORDER

                            data = [...data, subscriptionData, ...campaigns.reverse() ]
                        }

                    }
                }
                // console.log('TABLE DATA 1', JSON.parse(JSON.stringify(data)));
                return data;
            },
        },
        watch: {
            productEdit(newdata, old){
                if(newdata){
                    setTimeout(() => this.$refs['productInput'+newdata][0].focus(), 250);
                }

            },

            calls(newdata, old){
                //console.log('WATCH CALLS', this.calls);
            },
            selectedCampaigns(value, old) {
                if(this.selectedCampaigns.length === 0){
                    this.alternateheader = false
                }
                if(value === old){

                    return
                }

                // console.log('selectedCampaign Change', JSON.parse(JSON.stringify(value)), JSON.parse(JSON.stringify(old)))

                // FIND ADDED ITEMS
                let newItems = []
                for(const newItem of value){
                    let found = null
                    for(const oldItem of old){
                        if(oldItem === newItem){
                            found = newItem
                        }
                    }
                    if(!found){ // THESE ARE THE ITEMS ADDED
                        newItems.push(newItem)
                        if(newItem.attributes){ // IF IT HAS ATTRIBUTES IT'S A FACILITY -- ADD ALL CAMPAIGNS IN FACILITY
                            const campaigns = this.campaigns.filter(campaign => campaign.facilityId === newItem.attributes.facilityId)
                            for (const campaign of campaigns){
                                if(!this.selectedCampaigns.find(item => item === campaign)){
                                    this.selectedCampaigns.push(campaign)
                                }
                            }
                        } else { //IF BOTH CAMPAIGNS ARE ADDED FOR FACILITY, CHECK FACILITY
                            const campaigns = value.filter(campaign => campaign.facilityId === newItem.facilityId);
                            // console.log('CAMPAIGNS FOUND', JSON.parse(JSON.stringify(campaigns)));
                            if(campaigns && campaigns.length === 2){
                                const facility = this.tableData.find(facility => {
                                    if(facility.attributes){
                                        if(facility.attributes.facilityId === newItem.facilityId){
                                            return facility
                                        }
                                    }
                                    return false
                                })
                                //  console.log('FOUND FACILITY', facility)
                                const facilityAlreadyThere = this.selectedCampaigns.find(fac => fac === facility);
                                if(!facilityAlreadyThere)
                                    this.selectedCampaigns.push(facility)
                            }
                        }
                    }
                    this.alternateheader = true
                }

                // FIND REMOVED ITEMS
                let removedItems = []
                for(const oldItem of old){
                    let found = null;
                    for(const newItem of value){
                        if(oldItem === newItem){
                            found = oldItem
                            continue
                        }
                    }
                    if(!found){ // THESE ITEMS WERE REMOVED
                        removedItems.push(oldItem)
                        this.selectedCampaigns = this.selectedCampaigns.filter(campaign => {
                            if(oldItem.attributes){ // IF IT HAS ATTRIBUTES IT'S A FACILITY. -- REMOVE ALL CAMPAIGNS FOR THIS FACILITY.
                                if(oldItem.attributes.facilityId === campaign.facilityId) return false;
                            }
                            return campaign !== oldItem
                        })

                        // IF ALL CAMPAIGNS ARE REMOVED FOR A FACILITY, REMOVE FACILITY
                        if(!oldItem.attributes){
                            const campaignsFound = this.selectedCampaigns.find(item => item.facilityId === oldItem.facilityId);
                            if(!campaignsFound){
                                this.selectedCampaigns = this.selectedCampaigns.filter(item => {
                                    if(item.attributes && item.attributes.facilityId === oldItem.facilityId)
                                        return false
                                    return item;
                                })
                            }
                        }
                    }
                }
            }
        },
        filters: {
            status(campaign){
                if(!campaign.startDate){
                    // console.log('CAMPAIGN START?', campaign);
                    return 'Inactive'
                }
                else if(campaign.startDate && (campaign.status === true || campaign.status == 1)){
                    return 'Active'
                }
                else if(campaign.startDate && !campaign.status){
                    return 'Paused'
                }else {
                    return 'Inactive'
                }
            },
            formatPrice: (value) => {
                //let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            currency: (value) => {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                })

                if(value % 1 !== 0){
                    value = Math.ceil(value)
                }
                return formatter.format(value)
            },
             currencyOriginal: (value) => {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                })
                return formatter.format(value)
            },
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); } );
            },
            callClickRange: (value) => {
                //const low = Math.ceil(value / 2);
                //const high = parseInt(value * 1.5);
                return `${parseInt(value)}`
            }
        },
        async created(){
            this.showRows.push(this.facilities[0].attributes.facilityId)

            // WHEN FUNNEL COMPLETES, EXPAND THE UPDATED FACILITY
            if(this.$route.query['premium-campaigns']){
                this.showRows = [parseInt(this.goPremiumFunnelInfo.funnelFacility)]
            }
            //console.log('CALLS DATA', this.calls);

            // this.update();

            if( this.windowWidth <= 760 ) {
                this.mobile = true;
                this.budgetLabel = "Budget"
            }
        },
        mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                    this.budgetLabel = "Budget"
                }else{
                    this.mobile = false;
                }

                this.setToolTipTexts();
            })

            this.setToolTipTexts();
        },
        methods: {
            ...mapActions([
                'updateCampaignStatus',
                'createCampaignLogs',
                'updateCampaignBudget',
                'addVerifiedPhoneOrUrl',
                'fetchSubscriptions',
                'setSubscriptionBannerDismissed',
                'sendFeedback'
            ]),
            tableFacilityIdGenerator(facility){
                if(facility.attributes && facility.attributes.facilityId){
                  return `facility-${facility.attributes.facilityId}`
                }
                return ''
            },
            selectColumn(option){
                if(option == "left" && this.columnCounter > 1){
                    this.columnCounter--;
                }else if(option == "right" && this.columnCounter < 6){
                    this.columnCounter++;
                }
                // console.log(this.columnCounter)

                if(this.columnCounter == 1){
                    this.budgetLabel = 'Budget';
                    this.status1 = true;
                    this.status2 = false;
                    this.status3 = false;
                    this.status4 = false;
                    this.status5 = false;
                    this.status6 = false;
                    this.leftarrow = false;
                    this.rightarrow = true;
                }else if(this.columnCounter == 2){
                    this.budgetLabel = 'Status';
                    this.status1 = false;
                    this.status2 = true;
                    this.status3 = false;
                    this.status4 = false;
                    this.status5 = false;
                    this.status6 = false;
                    this.leftarrow = true;
                    this.rightarrow = true;
                }else if(this.columnCounter == 3){
                    this.budgetLabel = 'Market Price';
                    this.status1 = false;
                    this.status2 = false;
                    this.status3 = true;
                    this.status4 = false;
                    this.status5 = false;
                    this.status6 = false;
                    this.leftarrow = true;
                    this.rightarrow = true;
                }else if(this.columnCounter == 4){
                    this.budgetLabel = 'User Actions';
                    this.status1 = false;
                    this.status2 = false;
                    this.status3 = false;
                    this.status4 = true;
                    this.status5 = false;
                    this.status6 = false;
                    this.leftarrow = true;
                    this.rightarrow = true;
                }else if(this.columnCounter == 5){
                    this.budgetLabel = 'Average CPC';
                    this.status1 = false;
                    this.status2 = false;
                    this.status3 = false;
                    this.status4 = false;
                    this.status5 = true;
                    this.status6 = false;
                    this.leftarrow = true;
                    this.rightarrow = true;
                }else if(this.columnCounter == 6){
                    this.budgetLabel = 'Spend';
                    this.status1 = false;
                    this.status2 = false;
                    this.status3 = false;
                    this.status4 = false;
                    this.status5 = false;
                    this.status6 = true;
                    this.leftarrow = true;
                    this.rightarrow = false;
                }
                
            },
            bedRequestBudgetClick(item){
                if(!this.subscriptions.length){
                    this.$emit('startGoPremiumFunnel', item.facilityId)
                }else {
                    // CHECK FOR SUBSCRIPTION FOR THIS FACILITY
                    const facilitySubscription = this.subscriptions.find(subscription => subscription.facilityId === item.facilityId);
                    if(!facilitySubscription){
                        this.subscribeItem = item;
                        return
                    }

                    // OPEN ENABLE/DISABLE MENU FOR THIS BED REQUESTS LINE.
                    const menuRef = this.$refs['enable-menu-' + item.id];
                    if(menuRef){
                        setTimeout(() => {menuRef[0].click()}, 1)
                    }
                }
            },

            async confirmReceivingNumberOrUrl(facilityId, productId, counter, campaignId){
                // PHONE NUMBER FIELD
                if(parseInt(productId) === parseInt(this.callProduct.id)){
                    // console.log('CLICKED OUTSIDE PHONE FIELD', facilityId)
                    // console.log('TRACKING NUMBERS', JSON.parse(JSON.stringify(this.trackingNumbers)));
                    let receivingNumberObj = this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(facilityId))
                    let receivingNumber = null

                    // RECEIVING NUMBER PREVIOUSLY SET, UPDATE IT.
                    if(receivingNumberObj) {
                        receivingNumber = receivingNumberObj.receivingNumber
                        if(this.$refs['productInput'+counter][0].value.length == 14 && '+1'+this.$refs['productInput'+counter][0].value.replace(/\D/g,'') != receivingNumber){
                            try{
                                let result = await this.$store.dispatch('ctmUpdateNumber', {number: '+1'+this.$refs['productInput'+counter][0].value.replace(/\D/g,''), facilityId, campaignId})
                                this.snackMsg = "Your receiving number has been successfully updated."
                                // console.log('UPDATED RECEIVING NUMBER', result);

                                let facility = this.facilities.find(i => parseInt(i.attributes.facilityId) === parseInt(facilityId))

                                let data = {
                                    receivingNumber: '+1'+this.$refs['productInput'+counter][0].value.replace(/\D/g,''),
                                    facilityName: facility.attributes.name
                                }

                                let status = 0
                                this.$store.dispatch('createNotifications', {description: 'Your receiving number was updated.', status: status, data, datatype: "Profile"})

                                this.productEdit = null
                               // this.productUpdateSnack = true

                            }catch (e) {
                                this.snackMsg = "An error has occurred, please contact our support team."
                                this.productUpdateSnack = true
                            }
                        }else if(this.$refs['productInput'+counter][0].value.length != 14){
                            this.snackMsg = "Please enter a valid 10 digit phone number."
                            this.productUpdateSnack = true
                            return;
                        }else{
                            this.snackMsg = "Your receiving number has been confirmed."
                            //this.productUpdateSnack = true
                        }
                    }
                    // NO RECEIVING NUMBER SET PREVIOUSLY, CREATE NEW NUMBER
                    else{
                        const newReceivingNumber = this.$refs[`productInput${counter}`].value.replace(/\D/g,'')
                        // console.log('THE NEW RECEIVING NUMBER', newReceivingNumber)
                        if(newReceivingNumber.length !== 10){
                            this.snackMsg = "Please enter a valid 10 digit phone number."
                            this.productUpdateSnack = true
                            return
                        }
                        const newNumber = await this.$store.dispatch('ctmBuyNumber', {number: newReceivingNumber, facilityId, campaignId})

                        this.snackMsg = "Your receiving number has been succesfully updated."

                        let facility = this.facilities.find(i => parseInt(i.attributes.facilityId) === parseInt(facilityId))
                        var data = {
                            receivingNumber: '+1'+this.$refs['productInput'+counter][0].value.replace(/\D/g,''),
                            facilityName: facility.attributes.name
                        }

                        var status = 0
                        this.$store.dispatch('createNotifications', {description: 'Your receiving number was updated.', status: status, data, datatype: "Profile"})

                        this.productEdit = null
                        this.productUpdateSnack = true
                    }

                    // URL FIELD
                }else{



                    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
                    if(!regex .test(this.$refs['productInput'+counter][0].value )) {
                        this.snackMsg = "Please add a valid landing page."
                        this.productUpdateSnack = true
                        this.productEdit = counter
                        return
                    }
                    const receivingUrlObj = this.trackingUrls.find(trackingUrls => trackingUrls.facilityId === facilityId)
                    let receivingUrl = null

                    // URL SET PREVIOUSLY IN SYMFONY
                    if(receivingUrlObj){
                        receivingUrl = receivingUrlObj.url

                        if(this.$refs['productInput'+counter][0].value != receivingUrl){
                            let result = await this.$store.dispatch('updateUrl', {url: this.$refs['productInput'+counter][0].value, facilityId, campaignId})
                            this.trackingUrls.forEach((item) => {
                                if(item.facilityId == facilityId){
                                    item.url = this.$refs['productInput'+counter][0].value
                                }
                            })

                            let facility = this.facilities.find(i => i.attributes.facilityId === facilityId)

                            const data = {
                                url:   this.$refs['productInput'+counter][0].value,
                                facilityId: facilityId,
                                facilityName: facility.attributes.name
                            }

                            const status = 0
                            this.$store.dispatch('createNotifications', {description: 'Your landing page was updated.', status: status, data, datatype: "Profile"})

                            this.$store.commit('setTrackingUrl', this.trackingUrls)
                            this.snackMsg = "Your landing page has been successfully updated."
                            //this.productUpdateSnack = true
                        }else{
                            this.snackMsg = "Your landing page was added."
                            //this.productUpdateSnack = true
                        }
                    }
                    // NO URL FOUND, LET'S CREATE ONE
                    else {
                        // console.log('NEED TO CREATE A URL, NOT ONE CREATED PREVIOUSLY', 'facilityId', facilityId);
                        const dbUrl = await this.$store.dispatch('createUrl', {
                            url: this.$refs['productInput'+counter][0].value,
                            facilityId,
                            status: 1,
                            productId: 2
                        })

                        await this.$store.dispatch('getFacilityTrackingUrl', {facilityId})

                        // console.log('TRACKING URLS', this.trackingUrls)

                        let facility = this.facilities.find(i => i.attributes.facilityId === facilityId)
                        const data = {
                            url:   this.$refs['productInput'+counter][0].value,
                            facilityId: facilityId,
                            facilityName: facility.attributes.name
                        }

                        const status = 0
                        this.$store.dispatch('createNotifications', {description: 'Your landing page was updated.', status: status, data, datatype: "Profile"})

                        this.snackMsg = "Your landing page has been successfully updated."
                        this.productUpdateSnack = true
                    }
                }

                // console.log('VERIFIED PHONE/URLS LENGTH', this.verifiedPhoneOrUrls.length, 'ENABLED CAMPAIGNS LENGTH', this.enabledCampaigns.length);
                if(this.verifiedPhoneOrUrls.length === this.enabledCampaigns.length && !this.goPremiumFunnelTooltip){
                    this.$root.$emit('GoPremiumTooltipFunnel')
                }


                this.productEdit = null
            },
            toggle(item){

                if(!this.defaultPaymentMethod){ // IF THEY DON'T HAVE A PAYMENT METHOD, THEY HAVEN'T GONE PREMIUM - SEND THEM TO THE FUNNEL
                    this.$emit('startGoPremiumFunnel', item.facilityId)
                    return
                }else if(!item.startDate){ // THEY ARE PREMIUM, BUT NO CAMPAIGN BUDGETS SELECTED -- OPEN THE BUDGET DIALOG
                    this.changeStatus(true, item)
                    // this.openBudgetDialog(item);
                    return;
                }
                this.productEdit = item.counter
            },
            getNumber(facilityId){
                const receivingNumberObj =  this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(facilityId))
                let receivingNumber = null
                if(receivingNumberObj) receivingNumber = receivingNumberObj.receivingNumber
                if(receivingNumber && receivingNumber.length === 12){
                    receivingNumber = receivingNumber.replace('+1', '')
                }else if(receivingNumber && receivingNumber.length === 11 && receivingNumber[0] === '1'){
                    receivingNumber = receivingNumber.slice(1);
                }
                return receivingNumber
            },
            getUrl(facilityId){
                // console.log('TRACKING URLS', this.trackingUrls);
                const urlObj = this.trackingUrls.find(trackingUrls => parseInt(trackingUrls.facilityId) === parseInt(facilityId))
                let url = null
                if(urlObj) url = urlObj.url
                return url
            },

            toggleRow(facility) { // SHOWS OR HIDES CAMPAIGN BED REQUEST ROWS FOR A FACILITY
                if(this.facilities.length === 0 || !facility.attributes) {
                    return;
                }
                if(this.facilities.length === 1){
                    return;
                }
                if(this.showRows.includes(facility.attributes.facilityId)){
                    this.showRows.splice(this.showRows.indexOf(facility.attributes.facilityId), 1)
                } else {
                    this.showRows.push(facility.attributes.facilityId)
                }
            },

            getTotalBudget(facilityId, total = 0) {
                this.campaigns.forEach((item) => {
                    if (facilityId == item.facilityId) {
                        total += Number(item.budget)
                    }
                })
                return total
            },

            /** CHECKS FOR ACTIVE SUBSCRIPTION OR CAMPAIGN FOR FACILITYID THAT IS PASSED IN. USED TO SHOW GREY/GREEN STATUS FOR FACILITY ROW */
            getFacilityStatus(facilityId) {

                const subscription = this.subscriptions.find(subscription => subscription.facilityId === facilityId);
                if(subscription && subscription.active) return true;
                for(const campaign of this.campaigns){
                    if(campaign.facilityId === facilityId && campaign.status === true){
                        return true;
                    }
                }
                return false
            },

            getFacilityStatusText(facilityId) {
                let response = 'Inactive'
                const subscription = this.subscriptions.find(subscription => subscription.facilityId === facilityId);
                if(subscription && subscription.active) return 'Active';
                for (const campaign of this.campaigns.filter(i => i.facilityId === facilityId)){
                    if(campaign.status === true){
                        return 'Active'
                    }else if (campaign.status === false && campaign.startDate !== null){
                        response = 'Paused'
                    }
                }
                return response;
            },

            // This function checks which product type and loops through the stored data to count the number of interactions
            getCampaignInteractions(productId, facilityId) {
                // console.log('GET CAMPAIGN INTERACTIONS', productId, facilityId);

                const subscriptionStartDate = this.getFacilitySubscriptionStartDate(facilityId);
                // console.log('SUBSCRIPTION START DATE', subscriptionStartDate);
                const campaign = this.campaigns.find(campaign => campaign.facilityId == facilityId && campaign.productId == productId);
                // console.log('CAMPAIGN', campaign);
                let campaignStartDate = startOfToday();
                if(campaign.startDate){
                    campaignStartDate = parse(campaign.startDate);
                }
                let spend = 0;
                let count = 0;
                const calls = JSON.parse(JSON.stringify(this.calls))
                switch (parseInt(productId)) {
                    case 1: // CALLS
                        // console.log('LOOKING AT CALLS')
                        for(const callData of calls){
                            // console.log("CALL DATA", callData)
                            if(parseInt(facilityId) === parseInt(callData.facilityId)){
                                for(const call of callData.data.calls){
                                    if(call.sale && call.sale.conversion){
                                        // console.log('CALL', count, call);
                                        // ADD TO TOTAL CALLS
                                        const calledAt = parse(call.called_at);
                                        // console.log('CALLED AT', calledAt);
                                        // console.log('AFTER FILTER START DATE', isAfter(calledAt, this.startDate), this.startDate)
                                        // console.log('AFTER CAMPAIGN START DATE', isAfter(calledAt, campaignStartDate), campaignStartDate)
                                        // console.log('Before end date', isBefore(calledAt, endOfDay(this.endDate)), this.endDate);
                                        if(
                                            isAfter(calledAt, this.startDate) &&
                                            isBefore(calledAt, endOfDay(this.endDate))
                                        ){
                                            count += 1;
                                        }
                                        // // ADD TO SPEND
                                        // console.log('SUBSCRIPTION START DATE:', subscriptionStartDate)
                                        // console.log('CALLED AT:', calledAt);
                                        // console.log('first campaign start test', isAfter(calledAt, subscriptionStartDate))
                                        // console.log('after selected start date test', isAfter(calledAt, this.startDate))
                                        // console.log('after campaign start date test', isAfter(calledAt, campaignStartDate), campaignStartDate)
                                        // console.log('before selected end date test', isBefore(calledAt, endOfDay(this.endDate)))

                                        if(
                                            isAfter(calledAt, subscriptionStartDate) &&
                                            isAfter(calledAt, this.startDate) &&
                                            isAfter(calledAt, campaignStartDate) &&
                                            isBefore(calledAt, endOfDay(this.endDate))
                                        ){
                                            const interactionPrices = this.getFacilityInteractionCostsByDate({
                                                facilityId,
                                                date: calledAt
                                            })
                                            spend += interactionPrices.singleCallCost
                                            // console.log('SPEND', spend);
                                        }
                                    }
                                }
                            }
                        }
                        return {count, spend}
                        break;
                    case 2: // CLICKS
                        for(const facilityClicks of this.clicks){
                            if(facilityClicks.facilityId === facilityId){
                                for(const click of facilityClicks.data){
                                    if(
                                        isAfter(click.date.date, this.startDate) &&
                                        isBefore(click.date.date, endOfDay(this.endDate))
                                    ){
                                        count++

                                        if(
                                            isAfter(click.date.date, subscriptionStartDate) &&
                                            isAfter(click.date.date, this.startDate) &&
                                            isAfter(click.date.date, campaignStartDate) &&
                                            isBefore(click.date.date, endOfDay(this.endDate))
                                        ){
                                            const interactionPrices = this.getFacilityInteractionCostsByDate({
                                                facilityId,
                                                date: click.date.date
                                            })
                                            spend += interactionPrices.singleClickCost
                                        }
                                    }
                                }
                            }
                        }

                        return {count, spend}
                        break;
                    default:
                        return {count, spend}
                        break;
                }
            },

            // Function checks which data is populated and returns active value
            getInteractionCostsValue(productId, facilityId, cost = 0) {
                const product = this.products.find(product => parseInt(product.id) === parseInt(productId));
                const pricing = this.calculateBudget({numInteractions: 1});
                // console.log('PRICING', pricing);
                // console.log('PRODUCT', productId, JSON.parse(JSON.stringify(product)))

                const facilityPricing = pricing.facilityBudgets.find(budget => budget.facilityId === facilityId);

                if(product.attributes.slug === "premium-calls"){
                    return facilityPricing.singleCallCost
                }else{
                    return facilityPricing.singleClickCost
                }
            },

            openBudgetDialog(campaign){
                // console.log('SUPPOSED TO OPEN BUDGET DIALOG', JSON.parse(JSON.stringify(campaign)));
                if(campaign.budget === null) campaign.budget = 0
                this.amount = campaign.budget;
                this.selectedFacilityId = campaign.facilityId;
                this.selectedProductId = campaign.productId
                this.selectedFacilityItem = this.facilities.find(facility => facility.attributes.facilityId === campaign.facilityId)

                // console.log('SELECTED FACILITY ITEM', JSON.parse(JSON.stringify(this.selectedFacilityItem)));

                const budget = this.calculateBudget({numInteractions: campaign.budget});
                 //console.log('BUDGET', JSON.parse(JSON.stringify(budget)));
                const facilityBudget = budget.facilityBudgets.find(facilityBudget => parseInt(facilityBudget.facilityId) === parseInt(campaign.facilityId));
                // console.log('THE FACILITY BUDGET', facilityBudget, 'ALL THE FACILITY BUDGETS', budget.facilityBudgets);
                this.selectedInteractionCost = this.selectedProductId === 1 ? facilityBudget.singleCallCost : facilityBudget.singleClickCost;
                this.amount = parseInt(parseFloat(campaign.budget))

                const ref = `menu${campaign.id}`
                const elem = this.$refs[ref]
                setTimeout(function() { elem[0].toggleContent() }, 100);


            },

            openBudgetDialogClick(campaign){
               //  console.log('SUPPOSED TO OPEN BUDGET DIALOG CLICK', JSON.parse(JSON.stringify(campaign)));
                // OPEN SUBSCRIPTION SIGN UP MODAL IF THEY TRY TO ENABLE A CAMPAIGN

                if(campaign.budget === null) campaign.budget = 0
                this.showBudget = campaign.id;
                this.selectedProductId = campaign.productId;
                this.selectedFacilityId = campaign.facilityId;
                this.selectedFacilityItem = this.facilities.find(facility => facility.attributes.facilityId === campaign.facilityId)
                //console.log(campaign.budget);
                const budget = this.calculateBudget({numInteractions: campaign.budget});
               // console.log('BUDGET', budget);
                const facilityBudget = budget.facilityBudgets.find(facilityBudget => facilityBudget.facilityId === campaign.facilityId);
               

                this.selectedInteractionCost = this.selectedProductId === 1 ? facilityBudget.singleCallCost : facilityBudget.singleClickCost;
                // console.log('CAMPAIGN INTERACTION COST', interactionCost);
                // console.log('CURRENT CAMPAIGN BUDGET', parseFloat(campaign.budget));

                const budgetedInteractions = parseInt(parseFloat(campaign.budget))
                //console.log("Budget:"+budgetedInteractions)
                if(budgetedInteractions == 0){
                    if(this.selectedProductId == 2){
                        this.amount = 3400;
                    }else{
                        this.amount = 320;
                    }
                }else{
                    this.amount = budgetedInteractions;
                }
                // console.log('budgetedInteractions', budgetedInteractions);
            },

            async activateCampaignFromExternalRoute(item){
              setTimeout(() => {
                return this.openBudgetDialog(item)
              }, 100)

            },

            async changeStatus(status, item, bedRequestConfirm = null) {
                 // console.log('CHANGE STATUS CALLED', status, JSON.parse(JSON.stringify(item)), bedRequestConfirm);

                // MAKE SURE THEY HAVE COMPLETED FUNNEL, OR SEND THROUGH GO PREMIUM
                if(!this.defaultPaymentMethod){
                    this.$emit('startGoPremiumFunnel', item.facilityId)
                    return
                }

                // MAKE SURE THAT THE CURRENT FACILITY HAS A SUBSCRIPTION
                if(this.subscriptions.length){
                    const facilitySubscription = this.subscriptions.find(subscription => subscription.facilityId === item.facilityId);
                    // console.log('FACILITY SUBSCRIPTION', facilitySubscription);
                    if(!facilitySubscription && status || (facilitySubscription && !facilitySubscription.active && status)){
                        // MAKE SURE THEY'VE SET A BUDGET, OR POP UP THE BUDGET
                        if((item.budget == 0 || item.budget === null) && status === true){
                            // console.log('SHOULD OPEN BUDGET DIALOG')
                            return this.openBudgetDialog(item)
                        }
                        this.subscribeItem = item
                        return
                    }
                }

                // console.log('WE HAVE A SUBSCRIPTION');

                if(item.status == status && !bedRequestConfirm){
                    // console.log('RETURNING HERE');
                    return;
                }

                // IF THIS IS A BED REQUEST
                const enabledFacilityCampaigns = this.campaigns.filter(campaign => campaign.facilityId === item.facilityId && campaign.status)
                if(item.type && item.type === 'bedRequests'){
                    // console.log('ITEM IS BED REQUEST')
                    const changeFacility = JSON.parse(JSON.stringify(this.facilities.find(facility => facility.attributes.facilityId === item.facilityId)))
                    // console.log('WILL CHANGE STATUS FOR THIS FACILITY', JSON.parse(JSON.stringify(changeFacility)));
                    if(bedRequestConfirm){
                        // IF THEY'VE HAD PREMIUM CAMPAIGNS BEFORE, JUST ENABLE THE CAMPAIGNS.
                        this.changeFacilityStatus(status, changeFacility, true);
                        this.tempBedRequestHolder = null;
                        return;
                    }else {
                        // console.log('STATUS COMPARE', status, item.status);
                        if(item.status === status) return;
                        if(status === true){ // SHOW CONFIRM DIALOG FOR TURNING OFF CAMPAIGNS
                            const enabledClickCampaign = enabledFacilityCampaigns.find(campaign => campaign.productId === parseInt(this.clickProduct.id))
                            const enabledCallCampaign = enabledFacilityCampaigns.find(campaign => campaign.productId === parseInt(this.callProduct.id))

                            if(enabledClickCampaign && enabledCallCampaign){
                                this.showConfirmEnableBedRequestsDisableAll = true;
                            }else if(enabledClickCampaign){
                                this.showConfirmEnableBedRequestsDisableClicks = true;
                            }else if(enabledCallCampaign){
                                this.showConfirmEnableBedRequestsDisableCalls = true;
                            }

                            this.tempBedRequestHolder = JSON.parse(JSON.stringify(item))
                            return
                        }else {
                             // console.log('SHOULD LAUNCH showConfirmTurnOffBedRequests', JSON.parse(JSON.stringify(item)))
                            this.showConfirmTurnOffBedRequests = true;
                            this.tempBedRequestHolder = JSON.parse(JSON.stringify(this.facilities.find(facility => facility.attributes.facilityId === item.facilityId)));
                            return
                        }
                    }

                } else { // NOT A SUBSCRIPTION. IF NO BUDGET OPEN THE BUDGET DIALOG.
                    if (!item.budget && status){
                        // console.log('NO BUDGET OR STATUS, OPENING BUDGET DIALOG', JSON.parse(JSON.stringify(item)));
                        return this.openBudgetDialog(item)
                    }
                }

                // SHOW CONFIRM DIALOG IF WE'LL BE TURNING OFF BED REQUESTS
                if(!bedRequestConfirm && !enabledFacilityCampaigns.length) {
                    if(status === true && !this.noBudgetCampaigns.length) {
                        if(item.productId === parseInt(this.clickProduct.id)){
                            this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                            this.showConfirmTurnOnClicks = true;
                            return;
                        }else if(item.productId === parseInt(this.callProduct.id)) {
                            this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                            this.showConfirmTurnOnCalls = true;
                            return;
                        }
                    }
                }else if(!bedRequestConfirm && enabledFacilityCampaigns.length === 1 && status === false){
                    if(item.productId === parseInt(this.clickProduct.id)){
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOffClicks = true;
                        return;
                    }else if(item.productId === parseInt(this.callProduct.id)) {
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOffCalls = true;
                        return;
                    }

                }
                // SHOW CONFIRM IF TURNING ON CLICK CAMPAIGN WHEN THERE IS ALREADY A CALL CAMPAIGN
                else if(status && !bedRequestConfirm && item.productId === parseInt(this.clickProduct.id)){
                    // console.log('CAMPAIGN', item, this.callProduct);
                    const callCampaign = this.campaigns.find(campaign => campaign.facilityId === item.facilityId && campaign.productId === parseInt(this.callProduct.id));
                    // console.log(callCampaign);
                    if(callCampaign && callCampaign.status && !this.noBudgetCampaigns.length){
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOnClicksAfterCalls = true;
                        return;
                    }

                } else if(status && !bedRequestConfirm && item.productId == 1){
                    // console.log('CAMPAIGN CALL', item, this.callProduct);
                    const clickCampaign = this.campaigns.find(campaign => campaign.facilityId === item.facilityId && campaign.productId === parseInt(this.clickProduct.id));
                    // console.log(clickCampaign);
                    if(clickCampaign && clickCampaign.status && !this.noBudgetCampaigns.length){
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOnCallsAfterClicks = true;
                        return;
                    }

                }else if(!bedRequestConfirm && item.productId == 1){
                    // console.log('CAMPAIGN', item, this.callProduct);
                    const clickCampaign = this.campaigns.find(campaign => campaign.facilityId === item.facilityId && campaign.productId === parseInt(this.clickProduct.id));
                    // console.log(clickCampaign);
                    if(clickCampaign && clickCampaign.status){
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOffCallsOnly = true;
                        return;
                    }

                }else if(!bedRequestConfirm && item.productId == 2){
                    // console.log('CAMPAIGN', item, this.callProduct);
                    const callCampaign = this.campaigns.find(campaign => campaign.facilityId === item.facilityId && campaign.productId === parseInt(this.callProduct.id));
                    // console.log(callCampaign);
                    if(callCampaign && callCampaign.status){
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(item));
                        this.showConfirmTurnOffClicksOnly = true;
                        return;
                    }

                }

                this.tempBedRequestHolder = null

                // MAKE SURE THEY'VE SET A BUDGET, OR POP UP THE BUDGET
                if((item.budget == 0 || item.budget === null) && status === true){
                    return this.openBudgetDialog(item)
                }

                // MAKE SURE THEY'VE SET A URL OR PHONE NUMBER FOR THIS CAMPAIGN
                // console.log('CHANGING ITEM STATUS', item);
                if(item.productId === 1 && !this.$store.getters.facilityTrackingNumber(item.facilityId)){
                    console.error('NO PHONE NUMBER')
                    this.productEdit = item.counter
                    this.snackMsg = "Please enter the facility phone number before activating this campaign."
                    this.productUpdateSnack = true
                    return
                }else if(item.productId === 2 && !this.$store.getters.facilityTrackingUrl(item.facilityId)){
                    this.productEdit = item.counter
                    this.snackMsg = "Please add a landing page."
                    this.productUpdateSnack = true
                    return
                }

                if(status === true && !this.notOverBudget(item, item.budget)){
                    this.openBudgetDialog(item)
                    return;
                }

                var data = ""
                var product = ""

                if(item.productId == 1)
                {
                    product = "Premium Calls "
                }else if(item.productId == 2)
                {
                    product = "Premium Clicks "
                }else{
                    product = "Unlimited Forms "
                }
                

                if(status==false)
                {
                    var statusText = product+"is off"
                }else
                {
                    var statusText = product+"is on"

                    if(parseInt(this.callProduct.id) === parseInt(item.productId)){
                        // console.log('THIS.TRACKINGNUMBERS', this.trackingNumbers, item.facilityId);
                        // console.log('FACILITY TRACKING NUMBER', JSON.parse(JSON.stringify(this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(item.facilityId)))))

                        const facilityTrackingNumber = this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(item.facilityId))

                        // console.log('TRACKING NUMBER', trackingNumber);
                        if(!facilityTrackingNumber.number && parseInt(item.productId) === parseInt(this.callProduct.id)){
                            // console.log('MAKING CALL TO ctmBuyNumber');
                            await this.$store.dispatch('ctmBuyNumber', {number: facilityTrackingNumber.receivingNumber, facilityId: item.facilityId, campaignId: item.id})
                            data = this.trackingNumbers.find(trackingNumber => trackingNumber.facilityId == item.facilityId).number
                        }else{
                            data = facilityTrackingNumber.number
                        }
                        if(item.productId == 2){
                            // console.log('TRACKING URLS', this.trackingUrls)
                            data = this.trackingUrls.find(trackingUrls => parseInt(trackingUrls.facilityId) === parseInt(item.facilityId)).url
                        }
                    }

                }
                this.$store.dispatch('createCampaignLogs', {status: status, campaignId: item.id})
                this.$store.dispatch('createNotifications', {description: statusText, status: status, data: item, datatype: "Premium"})

                await this.updateCampaignStatus({
                    campaign: item,
                    status,
                    data
                })

                // CHECK TO SEE IF THERE ARE MORE CAMPAIGNS TO UPDATE
                if(status && this.noBudgetCampaigns && this.noBudgetCampaigns.length){
                    // console.log('ENABLING THE NEXT NO BUDGET CAMPAIGN', this.noBudgetCampaigns);
                    this.changeStatus(true, this.noBudgetCampaigns[0], true);
                    this.noBudgetCampaigns = this.noBudgetCampaigns.slice(1);
                    return
                }

                // TURN ON RED CONFIRM BOXES AND TURN ON RECEIVING NUMBER/WEBSITE TOOLTIP
                // console.log('TURN ON RED BOXES ETC', JSON.parse(JSON.stringify(item)));
                if(!item.startDate){
                    // console.log('MADE IT IN');
                    this.$store.commit('setGoPremiumCompleteFlag', true)
                    this.$store.commit('SetReceivingTooltip', true)
                    // console.log('SHOULD OPEN RECEIVING TOOLTIP(IF THIS IS THE FIRST TIME)');
                    this.$root.$emit('receivingTooltip')

                    // SET RECEIVING TOOLTIP POSITION
                    this.$emit('moveReceivingTooltip', item.facilityId);
                }

                this.$store.commit('updateUserStatus', 1);
            },

            async changeFacilityStatus(status, facility, bedRequestConfirm = false){
                if(!this.subscriptions.length){
                    return this.$emit('startGoPremiumFunnel', facility.attributes.facilityId)
                }
                // console.log('CHANGE FACILITY STATUS CALLED', status, bedRequestConfirm,  JSON.parse(JSON.stringify(facility)));
                const enabledFacilityCampaigns = this.campaigns.filter(campaign => campaign.facilityId === facility.attributes.facilityId && campaign.status)
                // console.log('ENABLED FACILITY CAMPAIGNS', JSON.parse(JSON.stringify(enabledFacilityCampaigns)));

                // SHOW CONFIRM DIALOG
                if(!enabledFacilityCampaigns.length && status === true && !bedRequestConfirm){
                    this.showConfirmEnableCampaign = true;
                    this.tempBedRequestHolder = JSON.parse(JSON.stringify(facility));
                    // console.log('TEMP BED REQUEST HOLDER', JSON.parse(JSON.stringify(this.tempBedRequestHolder)))
                    return;
                }else if(!status && !bedRequestConfirm && enabledFacilityCampaigns.length){
                    // console.log('ENABLED CAMPAIGNS', JSON.parse(JSON.stringify(enabledFacilityCampaigns)));
                    const enabledClickCampaign = enabledFacilityCampaigns.find(campaign => campaign.productId === parseInt(this.clickProduct.id))
                    const enabledCallCampaign = enabledFacilityCampaigns.find(campaign => campaign.productId === parseInt(this.callProduct.id))
                    // console.log('CLICK CAMPAIGN', enabledClickCampaign);
                    // console.log('CALL CAMPAIGN', enabledCallCampaign);

                    if(enabledCallCampaign && enabledClickCampaign){
                        this.showConfirmDisableAllCampaigns = true;
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(facility));
                    } else if(enabledCallCampaign){
                        this.showConfirmTurnOffCalls = true
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(enabledCallCampaign));
                    } else if(enabledClickCampaign){
                        this.showConfirmTurnOffClicks = true
                        this.tempBedRequestHolder = JSON.parse(JSON.stringify(enabledClickCampaign));
                    }
                    return;
                }


                //console.log('changeFacilityStatus', status, facility);
                const noBudgetCampaigns = this.campaigns.filter(campaign => campaign.facilityId === facility.attributes.facilityId && !campaign.budget);
                if(status && noBudgetCampaigns && noBudgetCampaigns.length > 0) {
                    this.noBudgetCampaigns = this.campaigns.filter(campaign => campaign.facilityId === facility.attributes.facilityId).reverse();
                    // console.log('NO BUDGET CAMPAIGNS', JSON.parse(JSON.stringify(this.noBudgetCampaigns)));
                    this.changeStatus(status, this.noBudgetCampaigns[0], true)
                    this.noBudgetCampaigns = this.noBudgetCampaigns.slice(1);
                    return;
                }
                for(const campaign of this.campaigns){
                    // console.log('LOOPING THROUGH CAMPAGINS', campaign);
                    if(campaign.facilityId === facility.attributes.facilityId){
                        if(!status && !campaign.budget) continue; // DON'T DISABLE A CAMPAIGN THAT HAS NEVER BEEN ENABLED
                        this.changeStatus(status, campaign, true)
                    }
                }
            },

            async massChangeStatus(status){
                this.noBudgetCampaigns = this.campaigns.filter(campaign => !campaign.attributes && !campaign.budget);
                if(this.noBudgetCampaigns && this.noBudgetCampaigns.length > 0) {
                    this.changeStatus(status, this.noBudgetCampaigns[0])
                    this.noBudgetCampaigns = this.noBudgetCampaigns.slice(1);
                    return;
                }

                for(const campaign of this.selectedCampaigns){
                    if(!campaign.attributes){
                        this.changeStatus(status, campaign)
                    }
                }
                this.selectedCampaigns = []
            },

            openEnablePauseMenu(item) {
                // console.log('OPEN ENABLE PAUSE MENU', JSON.parse(JSON.stringify(item)));
                const menuRef = this.$refs['enable-menu-' + item.id];
                if(menuRef){
                    setTimeout(() => {menuRef.click()}, 5)
                }
            },

            async closeBudgetMenu(facilityId, productId, amount){
                // console.log('CLOSE BUDGET MENU FUNCTION - SAVING BUDGET');
                const campaign = this.campaigns.find(campaign => campaign.facilityId == facilityId && campaign.productId == productId);
                if(!this.notOverBudget(campaign, amount))
                    return;

                this.productUpdateSnack = false;

                const prevBudgetCampaign = this.campaigns.find(campaign => campaign.facilityId == facilityId && campaign.productId == productId);
                const previousBudget = prevBudgetCampaign.budget
                await this.saveBudget(facilityId, productId, amount);
                this.closeOnClick = true;

                const subscription = this.subscriptions.find(subscription => subscription.facilityId === campaign.facilityId);

                if(!campaign.status){
                    // console.log('CAMPAIGN IS DISABLED', JSON.parse(JSON.stringify(campaign)));
                    // console.log('refs', this.$refs);
                    campaign.budget = amount;


                    if(subscription && subscription.active && !previousBudget){
                        // console.log('calling change status');
                        this.changeStatus(true, campaign)

                    } else if(!previousBudget && !subscription){
                        // console.log('SETTING subscribeItem')
                        this.subscribeItem = campaign
                    } else {
                        // console.log('opening enable/pause menu');
                        this.openEnablePauseMenu(campaign)

                    }
                }

                this.$refs['menu'+campaign.id][0].toggleContent()
            },

            async saveBudget(facilityId, productId, amount){
                // console.log('saveBudget called', facilityId + '|' + productId + '|' + amount)
                let campaign = this.campaigns.find(campaign => campaign.facilityId === facilityId && campaign.productId === productId);
                // console.log('FOUND CAMPAIGN', campaign);
                await this.updateCampaignBudget({campaignId: campaign.id, budget: amount})
            },
            async contactMe(facilityId, productId, amount){
                let facility = this.facilities.find(facility => facility.attributes.facilityId === facilityId)
                const product = productId === parseInt(this.callProduct.id) ? 'Premium calls' :  'Premium clicks'

                const data = {
                    feedback: `Request higher budget for ${product} for Listing ${facility.attributes.name} – ${facilityId}`,
                    requestType: 'Request higher budget',
                    hubspotContact: this.hubspotContact
                }
                await this.sendFeedback(data);
            },
            onCampaignsSelect(items){
                //console.log('onCampainSelect', items)
            },
            getAlternateLabel () {
                const facilities = this.selectedCampaigns.filter(item => item.attributes)
                //console.log('facilities selected', JSON.parse(JSON.stringify(facilities)));
                let label = 'facility'
                if(facilities.length > 1) label = 'facilities'
                return `${facilities.length} ${label} selected`;
            },

            // RETURNS FALSE IF OVER BUDGET, RETURNS TRUE IF UNDER BUDGET FOR CAMPAIGN
            notOverBudget(campaign, budget){
                let canEnable = false;
                const periodStart = this.getFacilityPeriodStartDate(campaign.facilityId);
                // console.log('PERIOD START DATE', periodStart);

                const pricing = this.calculateBudget({numInteractions: 1})

                const facilityPricing = pricing.facilityBudgets.find(budget => budget.facilityId === campaign.facilityId);

                // console.log('CAMPAIGN', campaign);

                // IF CALLS CAMPAIGN
                if(parseInt(campaign.productId) === parseInt(this.callProduct.id)){
                    const campaignCalls = this.calls.find(facilityCalls => facilityCalls.facilityId === campaign.facilityId)
                    if(!campaignCalls){
                        canEnable = true // there are no calls, should be able to enable campaign
                    }else {
                        const filteredCalls = campaignCalls.data.calls.filter(call => isAfter(parse(call.called_at), periodStart));
                        const spend = filteredCalls.length * facilityPricing.singleCallCost
                        // console.log('FILTERED CALLS', JSON.parse(JSON.stringify(filteredCalls)))
                        // console.log('CALLS SPEND', spend, 'BUDGET', budget)
                        if(spend < budget)
                            canEnable = true;
                    }



                // IF CLICKS CAMPAIGN
                }else if(parseInt(campaign.productId) === parseInt(this.clickProduct.id)) {

                    const campaignClicks = this.clicks.find(facilityClicks => facilityClicks.facilityId === campaign.facilityId)
                    if(!campaignClicks){
                        canEnable = true // there are no calls, should be able to enable campaign
                    }else {
                        // console.log('CAMPAIGN CLICKS', campaignClicks);
                        const filteredClicks = campaignClicks.data.filter(click => isAfter(click.date.date, periodStart));
                        const spend = filteredClicks.length * facilityPricing.singleClickCost
                        // console.log('FILTERED CLICKS', JSON.parse(JSON.stringify(filteredClicks)))
                        // console.log('CLICKS SPEND', spend, 'CAMPAIGN BUDGET', budget);
                        if(spend < budget)
                            canEnable = true
                    }
                }

                if(canEnable === false){
                    this.snackMsg = "Budget has been consumed for this period, please adjust your budget."
                    this.productUpdateSnack = true
                }
                return canEnable
            },

            showProductName(item){
                if(this.productEdit === item.counter){
                    return false;
                }
                if(this.productEdit !== item.counter){
                    if(!this.singleVerifiedPhoneOrUrl(item) && this.goPremiumFunnelCompleteThisLogin && item.status)
                        return false
                }
                return true
            },

            setToolTipTexts() {
                // Sets tooltip texts
                if ((this.subscriptions.length == 0 && this.campaignsWithStartDates && this.campaignsWithStartDates.length > 0 && !this.mobile) || (this.showDialogPremium && !this.mobile)) {
                    this.budgetTooltipText = 'You are in control of your budget. No overspending. Adjust your individual budgets below whenever you need and only pay when users click-through or call.';
                    this.marketPriceTooltipText = 'Market pricing matters which is why we searched public databases to gather as much data as possible. Using facility name, search data and pricing data, we are able to offer you the best possible price per click.';
                    this.userActionsTooltipText = 'A user action is counted each time a user clicks-through to your website or calls directly.';
                    this.CPCTooltipText = 'CPC stands for cost-per-click or cost-per-call. CPC is the amount you have paid divided by its total number of clicks or calls for the selected period';
                } else if ((this.subscriptions.length == 0 && !this.subscriptionBannerDismissed && !this.welcomeTooltip && !this.GoPremiumTooltip && !this.mobile) || (this.failedPayments && this.failedPayments.length)) {
                    this.budgetTooltipText = 'You are in control of your budget. No overspending. Adjust your individual budgets below whenever you need and only pay when users click-through or call.';
                    this.marketPriceTooltipText = 'Market pricing matters which is why we searched public databases to gather as much data as possible. Using facility name, search data and pricing data, we are able to offer you the best possible price per click.';
                    this.userActionsTooltipText = 'A user action is counted each time a user clicks-through to your website or calls directly.';
                    this.CPCTooltipText = 'CPC stands for cost-per-click or cost-per-call. CPC is the amount you have paid divided by its total number of clicks or calls.';
                } else if (!this.mobile) {
                    this.budgetTooltipText = 'You are in control of your budget. No overspending. Adjust your individual budgets below whenever you need and only pay when users click-through or call.';
                    this.marketPriceTooltipText = 'Market pricing matters which is why we searched public databases to gather as much data as possible. Using facility name, search data and pricing data, we are able to offer you the best possible price per click.';
                    this.userActionsTooltipText = 'A user action is counted each time a user clicks-through to your website or calls directly.';
                    this.CPCTooltipText = 'CPC stands for cost-per-click or cost-per-call. CPC is the amount you have paid divided by its total number of clicks or calls.';
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    .md-dialog{
        top: 460px;
    }
    .blue:hover{
        cursor: pointer;
    }
    .single-facility:hover {
        cursor: unset;
    }
    .nameContainer{
        display: block;
        position: relative;
        overflow: visible;
        white-space: nowrap;
    }
    .name{
        margin-top: -8px;
        width: 100%;
        float: left;
    }
    .address{
        position: absolute;
        font-size: 10px;
        font-style: italic;
        top: 4px;
        left: 0px;
    }

    .tooltipbox{
        position: absolute;
        top: 0rem;
        left: 0rem;
        z-index: 60;
        background-color: #fff;
        width: 220px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    .tooltipbudget{
        top: -394px;
        // right: 59.4%;
        height: 122px;
        width: 320px;
    }
    .user{
        top: 394px;
        right: 23%;
    }
    .cpc{
        top: 394px;
        right: 10%;
        height: 122px;
        width: 320px;
    }

    .mkp{
        top: 394px;
        right: 35%;
        height: 122px;
        width: 320px;
    }
    .contextualToolBudget{
        position: absolute;
        top: 372px;
        right: 58%;
        z-index: 6;
        .md-icon{
            color: #c6c6c6!important;
        }
        cursor: pointer;
    }
    .contextualTooltipUser{
        position: absolute;
        top: 372px;
        right: 21.5%;
        z-index: 6;
        .md-icon{
            color: #c6c6c6!important;
        }
        cursor: pointer;
    }
    .contextualTooltipCPC{
        position: absolute;
        top: 372px;
        right: 8.5%;
        z-index: 6;
        .md-icon{
            color: #c6c6c6!important;
        }
        cursor: pointer;
    }

     .contextualTooltipMKP{
        position: absolute;
        top: 372px;
        right: 34%;
        z-index: 6;
        .md-icon{
            color: #c6c6c6!important;
        }
        cursor: pointer;
    }
    @media only screen and (max-width: 1700px) and (min-width: 1600px)  {
        .contextualTooltipUser{
            right: 21.5%;
        }
        .contextualToolBudget{
            right: 57.8%;
        }
        .tooltipbudget{
            right:59%;
            left: unset;;
        }
    }
    @media only screen and (max-width: 1600px) and (min-width: 1550px)  {
        .contextualTooltipUser{
            right: 21%;
        }
        .contextualToolBudget{
            right: 57.5%;
        }
    }

    @media only screen and (max-width: 1550px) and (min-width: 1450px)  {
        .contextualToolBudget{
            right: 56.5%;
        }
        .contextualTooltipUser{
            right: 21%;
        }
        .tooltipbudget{
            right: 57.4%;
        }

    }
    @media only screen and (max-width: 1500px) and (min-width: 1450px)  {
        .contextualToolBudget{
            right: 56%;
        }
    }
    @media only screen and (max-width: 1450px) and (min-width: 1400px)  {
        .contextualTooltipUser{
            right: 21%;
        }
        .contextualToolBudget{
            right: 54%;
        }

        .tooltipbudget{
            right: 56%;
        }
        .contextualTooltipMKP{
            right: 33%;
        }
    }

    @media only screen and (max-width: 1400px) and (min-width: 1375px)  {
        .contextualToolBudget{
            right: 53.9%;
        }
        .contextualTooltipUser{
            right: 20%;
        }
        
        .contextualTooltipMKP{
            right: 32%;
        }
        .contextualTooltipCPC{
            right: 6.5%;
        }

        .tooltipbudget{
            right: 55.4%;
        }
    }

    @media only screen and (max-width: 1375px) and (min-width: 1320px){
        .contextualTooltipUser{
            right: 16.5%;
        }
        .contextualToolBudget{
            right: 52.5%;
        }
        .contextualTooltipMKP{
            right: 30%;
        }
        .contextualTooltipCPC{
            right: 3.5%;
        }
    }

    @media only screen and (max-width: 1320px) and (min-width: 750px){

        .contextualTooltipUser{
            right: 13%;
        }
        .contextualToolBudget{
            right: 51%;
        }
         .contextualTooltipMKP{
           right: 27.5%;
        }
        .contextualTooltipCPC{
            right: 0%;
        }

         .tooltipbudget{
            right: 53%;
        }
        .user{

            right: 15%;
        }
        .cpc{

            right: 10%;

        }

        .mkp{
   
            right: 29%;

        }



    }
    .addtopmargin{
        top: 480px;
        transition: top 1s;
    }
    .addtopmarginlow{
        top: 520px!important;
        transition: top 1s;
    }

    .alternateheadercirclelow{
        top: 496px!important;
        transition: top 1s;
    }
    .premiumCheck{
        border: 2px solid red;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 4px;
        padding-bottom: 4px;
        color: var(--md-theme-default-primary);
        cursor: pointer;
        width: 240px;
    }
    .budget-select{
        height: 200px;
        width: 400px;

        z-index: 6;
        background-color: #fff;
    }
    .facility{
        width: 25%;
        min-width: 270px;
        max-width: 270px;
        overflow: hidden;
    }
    .cta{
        cursor: pointer;
    }
    .md-table-row{
        background-color: #fff;
    }
    .md-title .icon:hover{
        cursor: pointer;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-app-content .md-card{
        margin-bottom: 20px;
    }
    .md-content{
        height: auto;
    }
    .md-table + .md-table[data-v-14d4afe3]{
        margin-top: 0px;
    }
    .green .md-icon{
        color: #189702!important;
    }
    .grey{
        background-color: #f8f9fb;
    }
    .hide{
        display: none;
    }
    .md-table.md-theme-default .md-table-row td{
        border-top: none;
    }
    .checkbox div{
        padding: 6px 20px 6px 20px;
    }
    .checkbox, .state{
        width: 1%;
    }
    tr{
        border-top: 1px solid #eee;
    }
    .grey td{
        border:  1px solid #eee;
    }
    .md-icon{
        font-size: 18px!important;
    }
    .menu-trigger{
        width: 100%;
        display: block;
    }
    .md-menu{
        width: 100%
    }
    .md-list-item-content .md-icon{
        padding-left: 6px;
    }
    .md-list-item-content .option
    {
        margin-left:-14px;
        text-align: left;
        display: flex;
        padding-right: 8px;
    }
    .menu-trigger .dropdown{
        position: absolute;

    }
    .menu-trigger {
        cursor: pointer;
    }
    .budget .dropdown{
        position: absolute;
        top:12px;
    }
    .budgetCell .menu-trigger .dropdown{
        top: 0px!important;
        position: relative!important;
    }
    .budget{
        cursor: pointer;
    }
    .dialogBudget{
        width: 400px;
        height: 240px;
        background-color: #fff;
        text-align: center;
        max-width: unset;
        overflow: hidden;
        border-radius: 10px;

        .md-dialog-actions{
            position: absolute;
            top: 180px;
            right: 10px;
        }
        .md-menu-content-container{
            overflow: hidden;
        }
    }
    .dialogBudget .title{
        font-weight: 500;
        padding-top: 12px;
    }
    .dialogBudget .budget{
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin-top: 14px;
        color: var(--md-theme-default-primary);
    }
    .dialogBudget .slidecontainer {
        width: 100%;
    }
    .dialogBudget .slider {
        margin-top: 16px;
        -webkit-appearance: none;
        width: 90%;
        height: 6px;
        border-radius: 5px;
        background: var(--md-theme-default-primary);
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        box-shadow: 0px 0px 2px 2px #ccc;
    }
    .dialogBudget .reach{
        margin-top: 10px;
        white-space: normal;
        width: 90%;
        text-align: left;
        margin-left:auto;
        margin-right: auto;
    }

    .dialogBudget{

        .md-menu-item{
            float: right;
            padding: 0;
        }
    }

    .overlay{
        height: 210px;
        width: 100%;
        right: 0px;
        top: 590px;
        position: absolute;
        z-index: 2;
    }


    .dialogBudget .typical, .dialogPremium .typical{
        border-bottom: 1px solid #9c9c9c;
        width: 35%;
        margin-left: 25%;
        margin-right: auto;
        height: 8px;
        border-left: 1px solid #9c9c9c;
        border-right: 1px solid #9c9c9c;
        margin-top: 4px;
    }
    .dialogBudget .competitor, .dialogPremium .competitor{
        margin-top: 2px;
        margin-left: 20%;
        margin-right: auto;
        width: 40%;
        color:#9c9c9c;
        font-size: 12px;
    }
    .graph .md-icon{
        margin-top: -2px;
        color: var(--md-theme-default-primary);
    }
    .graph .md-button{
        position: absolute;
        right:0;
        top:39px;
    }
    .dialogPremium{

        border-radius: 10px;

        .step1{
            width: 420px;
            height: 380px;
            text-align: center;
        }
        .step2{
            width: 560px;
            height: 460px;
            text-align: center;

        }
        .step3{
            width: 860px;
            height: 520px;
            text-align: center;


            .right-side{
                width: 30%;
                float: left;
                text-align: left;
                margin-top: 20px;
                color: #545454;
                padding-right: 30px;
                span{
                    display: block;
                    margin-bottom: 20px;
                }
                .customize{
                    padding-top: 80px;
                    cursor: pointer;
                }
                .customize:hover{
                    text-decoration: underline;
                }
            }
            .left-side{
                width: 70%;
                float: left;
                text-align: left;
                padding-left: 30px;
                margin-top: 20px;
            }
            .md-dialog-actions{
                top:420px;
                position: absolute;
                left:8px;
            }
            .box{
                border: 1px solid #eee;
                border-radius: 5px;
                padding: 15px;
                margin-bottom: 14px;
                margin-right: 20px;
                strong{
                    font-size: 24px;
                    .raised-text{
                        font-size: 12px;
                        top: -10px;
                        left:4px;
                        position: relative;
                        display: inline;
                        font-weight: normal;
                    }
                }
                span{
                    display: block;
                    color: #545454;
                    font-size: 14px;
                    padding-top:2px;
                }

                .recommended{
                    background-color: var(--md-theme-default-primary);
                    color: white;
                    padding:2px;
                    text-align: center;
                    border-radius: 8px;
                    width: 164px;
                    font-size: 14px;
                    margin-bottom: 8px;
                }
            }
            .box:hover{
                border: 1px solid var(--md-theme-default-primary);
                cursor: pointer;
            }

        }
        .step4{
            width: 680px;
            height: 400px;
            border-radius: 10px;
            text-align: center;

            .md-dialog-actions{
                margin-top: 0px;
                float: left;
                margin-left: 20px;
            }
        }
        .step6{
            width: 480px;
            height: 200px;
            border-radius: 10px;
            text-align: center;

            .md-dialog-actions{
                margin-top: 30px;
            }
        }
        img{
            height: 127px;
            width: 155px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
        .md-dialog-title{
            font-size: 28px;
            font-weight: bold;
            margin-top: 10px;
            display: block;
        }
        .md-dialog-actions{
            margin-top: 20px;
            display: block;
            width: unset;
        }
        .accordion {
            background-color: #eee;
            color: #444;
            cursor: pointer;
            padding: 18px;
            width: 100%;
            text-align: left;
            border: none;
            outline: none;
            transition: 0.4s;
        }
        .active, .accordion:hover {
            background-color: #ccc;
        }
        .panel {
            padding: 0 18px;
            background-color: white;
            display: none;
            overflow: hidden;
        }
        .full-control{
            margin-top: 20px;
            .list{
                width: 90%;
                margin-left: auto;
                margin-right: 6%;

                .md-list-item-text{
                    font-weight: 500;
                }
                .md-list-item-content .md-icon{
                    margin-top: -1px;
                }
                .md-button{
                    float: right;
                    margin-top: 4px;
                    margin-bottom: 10px;
                }
            }
            ul .md-list-item{
                margin-top: -2px;
            }
            .md-list-expand ul{
                padding-bottom: 18px;
            }
            .md-list-expand ul li{
                margin-left:-48px;
                margin-top: -10px;
                font-size: 14px;
                height: 40px;

                .md-icon{
                    color: green;
                    margin-left: -7px;
                }
            }
            .md-list-item-content{
                font-size: 14px;
            }

        }


    }
    .md-table-cell-selection+td .md-table-cell-container{
        width: 100%;
    }

    .md-table-row .md-table-cell{
        border: 1px solid #eee;
    }


    .alternateheader{
        padding-top: 63px;
        transition: padding-top 1s;
    }
    .budgetCell{
        text-align: left;
    }
    .alignRight{
        text-align: right;
    }
    .fakeCircle{
        position: absolute;
        top: 0rem;
        left: 1.6rem;
        // margin: 0% 80% 0% 0%;
        z-index: 6;
    }
    .alternateheadercircle{
        top: 435px;
        transition:top 1s;
    }

    .product .action{
        cursor: pointer;
        margin-top: -4px;
        font-size: 14px!important;
    }
    .phoneInput{
        border: 1px solid #ccc;
        height: 30px;
        width: 100%;
        min-width: 240px;
        .usFlag{
            width: 80px;
            float: left;
            border-right: 1px solid #ccc;
            height: 100%;;
            padding-left: 8px;
            padding-top: 6px;
        }
        .phone{
            width: 60%;
            padding-left: 8px;
            display: inline-block;
            position: relative;
            height: 30px;
            input{
                border: none;
                background: none;
                font-size: 13px;
                color: #43484f;
                width: 100%;
                position: absolute;
                top: -1px;
                height: 30px;
            }
        }
        .url{
            width:97%;
            padding-left: 8px;
            display: inline-block;
            height: 30px;
            position: relative;;
            input{
                border: none;
                background: none;
                font-size: 13px;
                color: #43484f;
                width: 100%;
                position: absolute;
                top: -1px;
                height: 30px;
            }

        }
        img{
            margin-top: -3px;
            margin-right: 8px
        }
    }
    .product{
        top: 1px;
        position: relative;
        display: block;
    }
    .pause{
        font-size: 16px!important;
    }
    .sublow{
        top: 472px;
    }
    .sublowtooltip{
        top: 496px;
    }


    .header-closed {
        .blue {
            color: var(--md-theme-default-primary-on-background, #0078d3)!important;
        }

    }

    .action-confirm-dialog{
        border-radius: 10px;
    }

    .table-link:hover {
        text-decoration: underline;
        cursor: pointer;
       
    }
    .table-link{
         color: var(--md-theme-default-primary-on-background, #0078d3)!important;
    }

    .budgetCell .table-link{
        color: #43484f!important;
    }

    .budgetCell .blue{
         color: var(--md-theme-default-primary-on-background, #0078d3)!important;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .nameContainer{
            overflow-x: hidden;
            overflow-y: visible;
            white-space:nowrap;
            width: 170px;
            height: 20px;
        }

        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            box-shadow: 0px 0px 2px 2px #ccc;
        }
        .facility{
            width: 13.5%;
            min-width: 165px;
        }
        .selection-arrows{
            position: absolute;
            top:434px;
            right: 16px;
            z-index: 2;
            font-size: 20px!important;
            display: inline-flex;
            .right{
                font-size: 30px!important;
                margin-left: 10px;
                color: #c6c6c6;
            }
            .left{
                font-size: 30px!important;
                color: #c6c6c6;
            }
        }
        .dialogBudget{
            width: 95%;
            margin: unset;
            margin-left: -47.5%;
            left: 50%!important;
            padding-left: 5px;
            padding-right: 5px;
            max-height: unset!important;
        }
        .md-dialog{
            height: unset;
            width: 95%!important;
            margin: unset;
            margin-left: -47.5%;
            left: 50%!important;
            top: 18%!important;
            padding-right: 10px;
        }    
        .disablearrow{
            color: #eee!important;
        }   
        .addtopmarginarrows{
            top: 538px;
        }

        .budgetCell .menu-trigger .dropdown{
            top: 14px;
        }

        .headerToolTipIcon {
            display: none;
        }

        .fakeCircle{
            position: absolute;
            top: 0rem;
            left: initial;
            margin: 0% auto;
            z-index: 6;
        }
    }

    .add-facility {
        float: right;
        border-radius: 50%;
        margin-bottom: 16px;
        margin-right: 18px;
    }

    .headerToolTipIcon {
        position: absolute;
        top: 0rem;
        right: 0rem;
        
        i.md-icon {
            color: #c6c6c6;

            &:hover {
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;
            }
        }
    }


</style>