import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    hubspotContact: null,
    hubspotCompanyOwner: null
  }),
  actions: {

    async fetchHubspotContactByEmail(context){
      const user = context.getters.user;
      const url = `${apiUrl}/hubspot-contacts/${user.email}`
      const result = await axios.get(url);

      context.commit('setHubspotContact', result.data)

      return result.data
    },

    async hubspotLoginTracking(context){
      const user = context.getters.user;
      const url = `${apiUrl}/hubspot-contacts/${user.email}`

      const result = await axios.patch(url, {
        type: 'login',
        last_connect_login_device: screen.width > 760 ? "Desktop" : "Mobile",
        hubspotutk: getCookie('hubspotutk')
      })
    },

    async hubspotStartGoPremiumFunnel(context){
      const user = context.getters.user;
      // update hubspot contact
      const contactUrl = `${apiUrl}/hubspot-contacts/${user.email}`
      const contactUpdateResult = await axios.patch(contactUrl, {
        type: 'startGoPremiumFunnel'
      })
    },

    async hubspotGoPremiumSelectGoal(context, {goal}){
      const user = context.getters.user;
      const contactUrl = `${apiUrl}/hubspot-contacts/${user.email}`
      let apiGoal = ''
      if(goal === 'clicks') apiGoal = 'Generate more clicks'
      else if(goal === 'calls') apiGoal = 'Generate more calls'
      else if(goal === 'bedRequests') apiGoal = 'Generate bed requests'
      else apiGoal = 'Generate clicks and calls'

      const contactResult = await axios.patch(contactUrl, {
        type: 'goPremiumGoalSelected',
        goal: apiGoal
      })
    },

    async hubspotGoPremiumBudgetAdd(context, budget){
      //update hubspot contact
      const user = context.getters.user;
      const contactUrl = `${apiUrl}/hubspot-contacts/${user.email}`
      const result = await axios.patch(contactUrl, {
        type: 'goPremiumBudgetAdded',
        budget
      })

      // update hubspot company
      const hubspotUser = context.getters.hubspotContact
      if(hubspotUser && hubspotUser['associated-company']){
        const companyUrl = `${apiUrl}/hubspot-companies/${hubspotUser['associated-company']['company-id']}`
        const companyUpdateResult = await axios.patch(companyUrl, {
          type: 'goPremiumBudgetAdded',
          budget
        })
      }
    },

    async hubspotPaymentTypeAdded(context){
      const user = context.getters.user;
      const contactUrl = `${apiUrl}/hubspot-contacts/${user.email}`
      const result = await axios.patch(contactUrl, {
        type: 'addedPaymentInfo',
      })

      // update hubspot company
      const hubspotUser = context.getters.hubspotContact
      if(hubspotUser && hubspotUser['associated-company']){
        const companyUrl = `${apiUrl}/hubspot-companies/${hubspotUser['associated-company']['company-id']}`
        const companyUpdateResult = await axios.patch(companyUrl, {
          type: 'addedPaymentInfo',
          pay_now_or_later: 'Pay Later'
        })
      }
    },

    async setHubspotPlanIntent(context, plan){
      const hubspotUser = context.getters.hubspotContact
      // console.log('SETTING PLAN INTENT', plan, hubspotUser['associated-company']);

      if(hubspotUser && hubspotUser['associated-company']){
        const companyUrl = `${apiUrl}/hubspot-companies/${hubspotUser['associated-company']['company-id']}`
        const result = await axios.patch(companyUrl, {
          type: 'planIntent',
          plan
        })
      }
    },

    async setLearnMoreClicked(context){
      const hubspotUser = context.getters.hubspotContact
      if(hubspotUser && hubspotUser['associated-company']){
        const companyUrl = `${apiUrl}/hubspot-companies/${hubspotUser['associated-company']['company-id']}`
        const result = await axios.patch(companyUrl, {
          type: 'learnMoreButtonClick'
        })
      }
    },

    async sendFeedback(context, data) {
      const url = `${apiUrl}/feedbacks`
      const sent = await axios.post(url, data)

      return sent.data;
    },

    async requestDemoForm(context, data) {
      data.hubspotutk = getCookie(('hubspotutk'));
      const url = `${apiUrl}/hubspot/request-demo-form`
      const sent = await axios.post(url, data)

      return sent.data;
    },

    async fetchHubspotOwner(context) {
      const url = `${apiUrl}/hubspot-owner`
      const response = await axios.get(url)
      if(response && response.data){
        context.commit('setHubspotCompanyOwner', response.data)
      }
    }
  },
  mutations: {
    setHubspotContact(state, hubspotContact){
      state.hubspotContact = hubspotContact
    },
    setHubspotCompanyOwner(state, hubspotCompanyOwner){
      state.hubspotCompanyOwner = hubspotCompanyOwner
    }
  },
  getters: {
    hubspotContact: state => state.hubspotContact,
    hubspotCompanyOwner: state => state.hubspotCompanyOwner
  }
}

const getCookie = function(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}