import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    facilities: [],
    facilityOperationTypes: [],
    goV2Facility: null
  }),
  actions: {
    async fetchFacilities(context) {
      const url = `${apiUrl}/facilities`
      const result = await axios.get(url)

      // console.log('facilities result', JSON.parse(JSON.stringify(result)));

      await context.commit('change', result.data.data)
      // console.log('UPDATED FACILITIES', JSON.parse(JSON.stringify(result.data.data)));
    },

    async fetchFacilityOperationType(context){
      const facilities = context.getters.facilities
      // console.log('FACILITIES', facilities);
      const facilityIds = facilities.map(facility => facility.attributes.facilityId)
      const facilityIdString = facilityIds.join();
      const url = `${apiUrl}/facility-operation-type?facilityIds=${facilityIdString}`
      const result = await axios.get(url);

      context.commit('setFacilityOperationTypes', result.data);
      return result.data;
    },

    async fetchFacilitiesLastUpdatedAt(context) {
      const facilities = context.getters.facilities
      const facilityIds = facilities.map(facility => facility.attributes.facilityId);
      const facilityIdString = facilityIds.join();
      const url = `${apiUrl}/facility-updated-at?facilityIds=${facilityIdString}`
      const result = await axios.get(url);

      return result.data;
    },

    async updateFacility(context, {facilityId, data}){
      const url = `${apiUrl}/facilities/${facilityId}`
      const result = await axios.patch(url, data)
    }
  },
  mutations: {
    change(state, facilities) {
      state.facilities = facilities
    },
    setFacilityOperationTypes(state, facilityOperationTypes){
      state.facilityOperationTypes = facilityOperationTypes
    },
    setGoV2Facility(state, facilityId){
      console.log('setting goV2Facility', facilityId)
      state.goV2Facility = facilityId;
    },
    changeFacilityPremiumDashboard(state, facilityId) {
      const facility = state.facilities.find(facility => parseInt(facility.attributes.facilityId) === parseInt(facilityId));
      if(facility) {
        const facilityCopy = JSON.parse(JSON.stringify(facility))
        facilityCopy.attributes.premiumDashboard = !facilityCopy.attributes.premiumDashboard
        Object.assign(facility, facilityCopy)
      }
    }
  },
  getters: {
    facilities: state => state.facilities,
    facilityIds: state => state.facilities.map(facility => facility.attributes.facilityId),
    dashboardFacilities: state => state.facilities.filter(facility => facility.attributes.premiumDashboard),
    facilityOperationTypes: state => state.facilityOperationTypes,
    goV2Facility: state => state.goV2Facility,
    getFacilityType: state => (facilityId) => {
      const facility = state.facilities.find(facility => parseInt(facility.attributes.facilityId) === parseInt(facilityId))
      return facility.attributes.facilityType
    }
  }
}