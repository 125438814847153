<template>
    <md-snackbar
            md-position="center"
            :md-duration="Infinity"
            :md-active.sync="showSnackbar"
            md-persistent
    >
        <span>Your credit card has expired. Please <router-link :to="{name: 'settings.payment-methods'}">click here</router-link> to update your default payment method.</span>
        <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
    </md-snackbar>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {isAfter} from 'date-fns'
    export default {
        name: "ExpiredCardNotification",
        data() {
            return {
                showSnackbar: false
            }
        },
        computed: {
            ...mapGetters(['defaultPaymentMethod'])
        },
        mounted() {
            // console.log('DEFAULT PAYMENT METHOD', this.defaultPaymentMethod);
            if(this.defaultPaymentMethod && isAfter(new Date(), new Date(this.defaultPaymentMethod.expireDate))){
                this.showSnackbar = true;
            }
        }
    }
</script>

<style scoped>
    a {
        color: #fff !important;
        text-decoration: underline;
    }
</style>