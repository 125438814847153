<template>
    <div>
        <slot>
        </slot>
    </div>
</template>

<script lang="javascript">
    import Config from '../../config.js'

    export default {
        props: {
            type: {
                type: String,
                required: true
            },
            id: {
                type: Number,
                required: false
            },
            filter: {
                type: Object,
                required: false
            },
            callback: {
                type: Function,
                required: false
            }
        },

        data() {
            return {
                data: {}
            }
        },

        mounted() {
            var self = this
            var url = `${Config.apiUrl}/${this.type}`

            // Single resource?
            if (this.id) {
                url += `/${this.id}`
            }

            // Any filters?
            if (this.filter) {
                url += '?'
                Object.keys(this.filter).forEach((key) => {
                    url += `filter[${key}]=${this.filter[key]}&`
                })
            }

            this.$http.get(url)
                .then(response => {
                    if (response.data) {
                        self.callback(response.data)
                    }
                })
                .catch(error => {
                    var response = error.response
                    // TODO: Do something with error
                    //console.log(response)
                })
        },
    }
</script>