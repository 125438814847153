<template>
    <div class="md-layout md-alignment-top-center">
        <div class="md-layout-item md-size-90 ">
            <h1>Request a demo</h1>
            <form novalidate @submit.prevent="validateForm">
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item  md-xlarge-size-70 md-large-size-50 md-medium-size-75">

                        <md-field :class="getValidationClass('jobTitle')">
                            <label for="job-title">Job Title *</label>
                            <md-input type="text" name="job-title" id="job-title" v-model="form.jobTitle" :disabled="sending" />
                            <span class="md-error" v-if="!$v.form.jobTitle.required">Job Title is required</span>
                            <span class="md-error" v-else-if="!$v.form.jobTitle.email">Invalid Job Title</span>
                        </md-field>

                        <md-field :class="getValidationClass('subject')">
                            <label for="subject">I would like to learn more about...*</label>
                            <md-select v-model="form.subject" name="subject" id="subject" :disabled="sending">
                                <md-option value="Premium Clicks and Calls">Premium Clicks and Calls</md-option>
                                <md-option value="Request a Bed">Request a Bed</md-option>
                                <md-option value="How to increase brand visibility">How to increase brand visibility</md-option>
                                <md-option value="How to establish new patient connections">How to establish new patient connections</md-option>
                                <md-option value="Other">Other</md-option>
                            </md-select>
                            <span class="md-error">This field is required</span>
                        </md-field>


                        <md-button
                                type="submit"
                                class="md-primary md-raised"
                                :disabled="sending"
                                v-if="!sent"
                        >Request Demo</md-button>
                        <md-button
                                @click="$emit('close')"
                                class="md-primary md-raised"
                                v-else
                        >Close</md-button>
                        <md-button @click="$emit('back')" class="md-raised">Back</md-button>


                    </div>
                </div>



                <md-snackbar :md-active.sync="showSnackbar">{{snackMsg}}</md-snackbar>
            </form>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import { validationMixin } from 'vuelidate'
    import customValidationMixin from "../../../mixins/validationMixin"
    import {
        required,
        minLength,
    } from 'vuelidate/lib/validators'
    export default {
        name: "RequestDemoForm",
        data: () => ({
            form: {
                jobTitle: null,
                subject: null
            },
            snackMsg: '',
            showSnackbar: false,
            sending: false,
            sent: false,
            lastUser: null
        }),
        computed: {
            ...mapGetters([
                'user'
            ])
        },
        mixins: [validationMixin, customValidationMixin],
        validations: {
            form: {
                jobTitle: {
                    required,
                    minLength: minLength(3)
                },
                subject: {
                    required,
                },

            }
        },
        methods: {
            ...mapActions([
                'requestDemoForm'
            ]),
            getValidationClass (fieldName) {
                const field = this.$v.form[fieldName]

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            clearForm () {
                this.$v.$reset()
                this.form.firstName = null
                this.form.lastName = null
                this.form.age = null
                this.form.gender = null
                this.form.email = null
            },
            async submitForm () {
                try {
                    this.sending = true
                    //('USER', this.user);

                    const data = {
                        firstname: this.user.first_name,
                        lastname: this.user.last_name,
                        email: this.user.email,
                        jobtitle: this.form.jobTitle,
                        demo_question: this.form.subject,
                    }

                    await this.requestDemoForm(data);


                    // Instead of this timeout, here you can call your API
                    // TODO - SAVE THE FORM DATA
                    this.snackMsg = "Demo requested! We will reach out to you to schedule a demo soon."
                    this.showSnackbar = true;
                    this.sent = true;
                }catch(e){
                    this.snackMsg = "Error submitting your request. Please contact support!"
                    this.sending = false;
                }

            },
            validateForm () {
                this.$v.$touch()

                if (!this.$v.$invalid) {
                    this.submitForm()
                }
            }
        }

    }
</script>

<style scoped>
    h1{
        margin-top: 65px;
        font-size: 36px;
        font-weight: 300;
        display: block;
        text-align: center;
        padding-top: 10px
    }

    .md-button {
        height: 44px;
        padding-left: 25px;
        padding-right: 25px;
        box-shadow: none;
        color: #0078D3;
        border: 1px solid #0078D3;
        text-transform: none;
        /*width: 130px;*/
        float: right;
    }

    .md-primary {
        color: #fff;
        border: none;
        background-color: #0078D3;
        margin-right: 0;
        float: right;
    }

</style>