import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios';
import {startOfToday, endOfToday, getDaysInMonth, parse} from 'date-fns'

export default {
  state: () => ({
    subscriptions: [],
    subscriptionBannerDismissed: false,
    subscriptioncosts:[]
  }),
  actions: {
    async fetchSubscriptions(context){
      const url = `${apiUrl}/subscriptions`
      const result = await axios.get(url);

      context.commit('setSubscriptions', result.data);
      return result.data;

    },
    async fetchSubscriptionCosts(context){
      const url = `${apiUrl}/getsubscriptioncosts`
      const result = await axios.get(url);
      context.commit('setSubscriptionCosts', result.data[0]);
      return result.data;

    },
    async createSubscriptions(context, facilityIds){
      const url = `${apiUrl}/subscriptions`
      const result = await axios.post(url, {facilityIds})

      // console.log('CREATED SUBSCRIPTIONs', JSON.parse(JSON.stringify(result.data)))

      context.commit('setSubscriptions', result.data);
      return result.data
    },

    async cancelSubscription(context, subscriptionId){
      const url = `${apiUrl}/subscriptions/${subscriptionId}/cancel`
      const result = await axios.put(url);
      await context.dispatch('fetchSubscriptions');
      return result.data;
    }
  },
  mutations: {
    setSubscriptions(state, subscriptions){
      for(const subscription of subscriptions){
        const stateSubscription = state.subscriptions.find(item => item.id === subscription.id);
        if(stateSubscription){
          Object.assign(stateSubscription, subscription)
        }else {
          state.subscriptions.push(subscription);
        }
      }

    },
    setSubscriptionCosts(state, subscriptioncosts){
     
      if(subscriptioncosts)
      {
        state.subscriptioncosts = (subscriptioncosts);
      }
      
    },
    setSubscriptionBannerDismissed(state, value){
      state.subscriptionBannerDismissed = value;
    }
  },
  getters: {
    subscriptions: state => state.subscriptions,

    subscriptioncosts: state => state.subscriptioncosts,

    activeSubscriptions: state => state.subscriptions.filter(subscription => subscription.active),

    subscriptionBannerDismissed: state => state.subscriptionBannerDismissed,

    facilitySubscriptionCost: (state, getters) => facilityId => {
      // console.log('FACILITY SUBSCRIPTION COST FACILITY ID', facilityId);
      // console.log('FACILITY OPERATION TYPES', getters.facilityOperationTypes);

      const subscriptioncosts = getters.subscriptioncosts;
     
      return subscriptioncosts;
    },
    facilitySubscriptionCostDefaults: (state, getters) => {
      // console.log('FACILITY SUBSCRIPTION COST FACILITY ID', facilityId);
      // console.log('FACILITY OPERATION TYPES', getters.facilityOperationTypes);

      const subscriptioncosts = getters.subscriptioncosts;
      //console.log("FACILITY subscriptioncosts", subscriptioncosts.attributes.defaults);

      return subscriptioncosts
    
    },

    totalSubscriptionCost: (state, getters )=> {
      let total = 0;
      for(const facility of getters.facilities){
        total += getters.facilitySubscriptionCost(facility.attributes.facilityId);
      }
      return total;
    },

    getFacilitySubscriptionStartDate: (state) => facilityId => {
      const subscription = state.subscriptions.find(subscription => subscription.facilityId == facilityId);
      if(subscription){
        return subscription.startDate;
      }
      return startOfToday();
    },

    getFacilityPeriodStartDate: (state) => facilityId => {
      const subscription = state.subscriptions.find(subscription => subscription.facilityId == facilityId);
      if(!subscription) {
        return null;
      }

      const today = endOfToday();
      const numDaysThisMonth = getDaysInMonth(today);
      const subscriptionStart = parse(subscription.startDate);
      // console.log('SUBSCRIPTION START DATE', subscription.startDate, 'PARSED STARTDATE', subscriptionStart);
      let subscriptionDayOfMonth = subscriptionStart.getUTCDate();

      // console.log('SUBSCRIPTION DAY OF MONTH', subscriptionDayOfMonth, 'NUM DAYS THIS MONTH', numDaysThisMonth);

      if (subscriptionDayOfMonth > numDaysThisMonth) {
        subscriptionDayOfMonth = numDaysThisMonth;
      }

      // console.log('FINALIZED SUBSCRIPTION DAY OF MONTH', subscriptionDayOfMonth);

      let periodStartDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), subscriptionDayOfMonth);

      // console.log('FIRST PERIOD START DATE', periodStartDate);

      // IF THE API START DATE IS IN THE FUTURE, PULL THE DATE BACK A MONTH
      if (periodStartDate >= today) {
        const lastMonth = today.getUTCMonth() > 0 ? today.getUTCMonth() - 1 : 11;
        const lastMonthYear = today.getUTCMonth() > 0 ? today.getUTCFullYear() : today.getUTCFullYear() - 1;
        const lastMonthDate = new Date(lastMonthYear, lastMonth, 1);
        const numDaysLastMonth = getDaysInMonth(lastMonthDate);
        subscriptionDayOfMonth = subscriptionStart.getUTCDate();
        if (subscriptionDayOfMonth > numDaysLastMonth) {
          subscriptionDayOfMonth = numDaysLastMonth
        }

        periodStartDate = new Date(lastMonthYear, lastMonth, subscriptionDayOfMonth);

        // console.log('API START DATE', periodStartDate, new Date(lastMonthYear, lastMonth))
      }

      // console.log('FINALIZED START OF PERIOD', periodStartDate);
      return periodStartDate;
    }

  }
}