<template>
    <div class="menu main-menu">
        <md-button class="md-icon-button burgerMenu" @click="updateMenu()" v-if="$parent.$parent.$parent.$parent.mobile">
                <md-icon>menu</md-icon>
            </md-button>
        <div class="md-title nav-title left" v-if="['dashboard.premium', 'calls.index', 'clicks.index', 'leads.index'].includes($route.name)">
            <template v-if="$route.path == '/premium/' || $route.path == '/premium'">
                Premium
            </template>
            <template v-else>
                <router-link to="/premium" v-if="!$parent.$parent.$parent.$parent.mobile && (user.role && user.role.slug === 'admin')">Premium</router-link><span v-if="!mobile" v-html="divider" />
            </template>
            <span v-if="$route.name.includes('clicks')">Clicks</span>
            <span v-if="$route.name.includes('calls')">Calls</span>
            <span v-if="$route.name.includes('forms')">Forms</span>
        </div>
        <div
            class="md-title nav-title left"
            v-else-if="$route.name.includes('settings')"
        >
            <router-link
                to="/settings/summary"
            >Settings</router-link>
            <span
                v-if="$route.name.includes('new') || $route.name.includes('payment-methods')"
            >
                <template
                    v-if="!mobile">
                    <span v-html="divider" />
                    <router-link
                        :to="{name: 'settings.payment-methods'}"
                    >Payment method</router-link>
                    <span v-if="$route.name === 'settings.new-payment-method'"><span v-html="divider" />Credit card</span>
                </template>
            </span>

            <span v-else-if="$route.name.includes('transactionslist') && !mobile"><span v-html="divider" />Transactions</span>
            <span v-else-if="$route.name.includes('subscriptionsList')&& !mobile"><span v-html="divider" />Subscriptions</span>
            <span v-else-if="$route.name.includes('payment-receipt') && !mobile"><span v-html="divider" /><router-link to="/settings/transactions">Transactions</router-link><span v-html="divider" />Invoice</span>
            <span v-else-if="$route.name === 'settings.index' && !mobile"><span v-html="divider" />Summary</span>
            
        </div>
        
        <div class="md-title nav-title left" v-else-if="['match.index'].includes($route.name)">Match</div>
        <div class="md-title nav-title left" v-else-if="['reviews.index'].includes($route.name)">Reviews</div>
        <div class="md-title nav-title left" v-else-if="['account.index'].includes($route.name)"> <router-link
                to="/account/summary"
            >Account</router-link>
            <span v-if="$route.name.includes('account.index') && !mobile"><span v-html="divider" />Summary</span>
        </div>
        <div class="md-title nav-title left" v-else-if="'/feedback' ==$route.path || '/feedback/' ==$route.path">Contact us</div>
        <div class="md-title nav-title left" v-else-if="$route.path.includes('listing/')">
            <div class="name mobile" v-if="!$parent.$parent.$parent.$parent.mobile">
                <template
                    v-if="facilities.length > 1"
                >
                  <router-link
                      :to="{name: 'bulk.edit'}"
                  >My Listings</router-link><span v-html="divider" />
                </template>{{ facilityName }}<span v-if="facilities.length > 1" class="address">{{ selectedFacilityAddress }}</span>
            </div>
            <div  class="name" v-else >Admin</div>
            <span v-if="$route.name.includes('contact') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Contact</span>
            <span v-if="$route.name.includes('general') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />General</span>
            <span v-if="$route.name.includes('listing.summary') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Summary</span>
            <span v-if="$route.name.includes('overview') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Overview</span>
            <span v-if="$route.name.includes('accreditation') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Accreditation</span>
            <span v-if="$route.name.includes('admissions') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Admissions</span>
            <span v-if="$route.name.includes('financial') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Financial</span>
            <span v-if="$route.name.includes('detoxservice') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Detoxservice</span>
            <span v-if="$route.name.includes('levelofcare') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Level of care</span>
            <span v-if="$route.name.includes('treatmentfocus') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Treatment focus</span>
            <span v-if="$route.name.includes('therapyprograms') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Therapy programs</span>
            <span v-if="$route.name.includes('amenities') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Amenities</span>
            <span v-if="$route.name.includes('feedback') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Send feedback</span>
            <span v-if="$route.name.includes('media') && !$parent.$parent.$parent.$parent.mobile"><span v-html="divider" />Media</span>
        </div>
        <div class="md-title nav-title left" v-if="$route.path.includes('notifications')">Notifications</div>
        <div class="md-title nav-title left" v-else-if="$route.name === 'bulk.edit'">My Listings</div>

        <div class="menus">
          <template v-if="user.role && user.role.slug === 'admin'">
            <md-menu md-size="auto" md-align-trigger :md-close-on-click="true" v-if="!$parent.$parent.$parent.$parent.mobile">
                <md-button md-menu-trigger class="unbold">Settings</md-button>
                <md-menu-content class="settings">     
                    <md-list-item to="/settings/summary"><span title="Edit your account" class="md-list-item-text">Summary</span></md-list-item>
                    <md-list-item to="/settings/transactions"><span title="Edit your account" class="md-list-item-text">Transactions</span></md-list-item>
                    <md-list-item to="/settings/payment-methods"><span title="Edit your account" class="md-list-item-text">Payment methods</span></md-list-item>
                </md-menu-content>
            </md-menu>
            <md-button md-menu-trigger v-else class="md-icon-button" to="/settings/summary"><md-icon>settings</md-icon></md-button>
          </template>

            <md-button class="md-icon-button" to="/feedback">
                <md-icon>help</md-icon>
            </md-button>

            <template v-if="user.role && user.role.slug === 'admin'">

            <!-- NOTIFICATIONS MENU -->
            <md-menu
                md-size="auto"
                md-align-trigger
                md-direction="bottom-end"
                :md-offset-x="113"
                @md-opened="clearNotifications()"
                v-if="!$parent.$parent.$parent.$parent.mobile"
            >
                <md-button md-menu-trigger class="md-icon-button">
                    <md-icon>notifications</md-icon>
                    <template v-show="notifications.data">
                        <span class="v-badge__badge red" v-if="numNotifications>0"><span>{{ numNotifications }}</span></span>
                    </template>
                </md-button>
                <md-menu-content class="notifications" v-model="notifications.data">
                    <md-menu-item :to="generateNotificationRoute(item)"  v-for="(item, index) in notifications.data" v-bind:key="item.id" v-if="index < 5">
                        <span class="md-list-item-text">{{ item.attributes.description }}</span>
                        <span class="time">{{ item.attributes.createdAt | date("m/d/Y") }}</span>
                    </md-menu-item>
                    <md-menu-item class="viewAll" :to="{name: 'notifications.index'}"><span class="md-list-item-text">View all</span></md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-button md-menu-trigger v-else class="md-icon-button" to="/notifications"><md-icon>notifications</md-icon></md-button>
            </template>

            <md-menu md-size="auto" md-align-trigger md-direction="bottom-end" :md-offset-x="15">
                <md-button md-menu-trigger v-if="!$parent.$parent.$parent.$parent.mobile" class="unbold">Account</md-button>
                <md-button md-menu-trigger v-else class="personMenu"><md-icon>person</md-icon></md-button> 
                <md-menu-content class="account">
                    <md-menu-item to="/account/summary"><span title="Account Summary" class="md-list-item-text"><a class="url">Summary</a></span></md-menu-item>
                    <md-menu-item v-if="facilities.length == 1" :class="{hovering: isHovering}" @mouseover="isHovering = true"  @mouseout="isHovering = false" class="md-caption">
                        
                    <!---  <div class="menuContainer" v-if="facilities.length > 1">
                            <span title="Edit your facility profile" class="md-list-item-text"><a class="url" target="_blank" :href="createMySpecialUrl(item.attributes.facilityId)"><md-icon class="icon">input</md-icon> {{ item.attributes.name.split(' ').slice(0,3).join(' ') }}</a></span>
                            <span class="addressMenu">{{  item.attributes.city + ', ' +  item.attributes.state }}</span>
                        </div>
                        <div class="clear" v-if="facilities.length > 1"></div> -->
                        <template>
                            <span title="Edit your listing" class="md-list-item-text"><a class="url" target="_blank" :href="createMySpecialUrl(facilities[0].attributes.facilityId)"><md-icon class="icon">input</md-icon> {{ dropdownFacilityName }}</a></span>
                        </template>
                    </md-menu-item>
                    <!---<md-menu-item to="/account" class="hr-top"><md-icon>add</md-icon><span title="Add a new facility" class="md-list-item-text">Add new facilitiy</span></md-menu-item> -->
                    <md-menu-item to="/logout"><span title="Log out" class="md-list-item-text">Log out</span></md-menu-item>
                </md-menu-content>
            </md-menu>  
        </div>
    </div>
</template>

<script lang="javascript">

import {mapGetters, mapActions} from 'vuex'
import{startOfDay, subMonths, format} from 'date-fns'

export default {
    name: 'TopNavigation',
    components: {
    },
    data() {
        return {
            facilities: {},
            selectedFacilityName: '',
            showNotifications: false,
            selectedFacilityAddress: '',
            isHovering: false,
            numNotifications: 0,
            closeOnSelect: true,
            mobile: false,
            divider: "&nbsp;&nbsp>&nbsp;&nbsp"
        }
    },
    async created(){
        if( this.windowWidth <= 760 ) {
            this.mobile = true
        }
        
        this.facilities = this.$store.getters.facilities
        const notifications = await this.$store.dispatch('fetchNotifications')
        this.numNotifications = notifications.data.filter(notification => notification.attributes.status === false).length
    },
    mounted(){

        this.$watch('windowWidth', (value, oldValue) => {

            if(value <= 760){
                this.mobile = true;
            }else{
                this.mobile = false;
            }
        
        })

        // CHECK TO SEE IF WE GOT LOGGED OFF ON ANOTHER TAB, OR IN SOME OTHER STRANGE WAY
        // TODO - FIX THIS SO THAT IF ONE TAB IS ACTIVE, IT DOESN'T KILL THE OTHER TAB
        // const userCheck = window.setInterval(() => {
        //     const lsUser = this.$ls.get('user');
        //     // console.log('CHECKING FOR USER', JSON.parse(JSON.stringify(this.user)));
        //     // console.log('ls user: ', lsUser);
        //     if(!this.user || !this.user.token  || !lsUser){
        //         clearTimeout(userCheck);
        //         this.$router.push({name: 'auth.login'})
        //     }
        // }, 5000)
    },
    filters: {
        date(date){
            return format(new Date(date), 'MM/DD/YYYY')
        },
        calculate(date){
            var diff = Math.abs(new Date() - new Date(date));

            var minutes = Math.floor((diff/1000)/60);

            if(minutes > 60){
                var n = minutes%60
                var hours = (minutes - n) / 60

                if(hours>24){
                    var numdays = Math.floor((hours*3600) / 86400)
                    if(Math.floor(((hours*3600) % 86400) / 3600)>0){
                        return numdays + 'd' + Math.floor(((hours*3600) % 86400) / 3600)+ 'h'
                    }else{
                        return numdays + 'd'
                    }
                    
                }else{
                    return hours + "h" + n + 'm'
                }
            }else{
                 return minutes + "m"
            }
        }
    },
    watch: {
        user: {
            handler: (newVal, oldVal) => {
                if(!newVal || !newVal.token){
                    this.$router.push({name: 'auth.login'});
                }
            },
            deep: true
        }
    }, 
    computed: {
        ...mapGetters({
            notifications: 'notifications',
            user: 'user',
            subscriptions:  'subscriptions'
        }),
        facilitiesIDs() {
            return this.facilities.map(facility => facility.attributes.facilityId)
        },
        facilityName() {
          if(this.facilities && this.facilities.length){
            const facility = this.facilities.find(facility => facility.attributes.facilityId === parseInt(this.$route.params.facilityId))

            if(facility.attributes.name.length <= 70){
              return facility.attributes.name
            }
            return facility.attributes.name.substring(0,70) + '...';

          }
          return ''
        },
        dropdownFacilityName() {
          if(this.facilities && this.facilities.length){
            if(this.facilities[0].attributes.name.length <= 22){
              return this.facilities[0].attributes.name
            }
            return this.facilities[0].attributes.name.substring(0, 22) + '...';
          }
          return ''
        }
    },
    methods: {
        ...mapActions(['markNotificationViewed', 'fetchSubscriptions']),
        async clearNotifications(){
            this.showNotifications = !this.showNotifications

            for(const notification of this.notifications.data){
                if(!notification.attributes.status){
                    await this.markNotificationViewed(notification.id)
                }

            }

            window.setTimeout(() => {
                this.numNotifications = 0
            }, 200)
        },
        updateMenu(state){
            this.$parent.$parent.$parent.$parent.showNavigation = !this.$parent.$parent.$parent.$parent.showNavigation;
        },
        createMySpecialUrl(facilityId){
            // console.log(process.env.NODE_ENV)

            if(process.env.NODE_ENV === 'production'){
                if(window.location.origin === "https://connect-staging.rehab.com"){
                    return "https://staging.rehab.com/"+facilityId
                }else if(window.location.origin === "https://connect-dev.rehab.com"){
                    return "https://dev.rehab.com/"+facilityId
                }else if(window.location.origin === "https://connect-demo.rehab.com"){
                    return "https://demo.rehab.com/"+facilityId
                }
                return "https://www.rehab.com/"+facilityId
            }
            else if(process.env.NODE_ENV === 'local'){
                return "http://local.rehab.com/"+facilityId
            }
        },

        generateNotificationRoute(notification){
            if(notification.attributes.datatype === 'bed request'){
                return {name: 'forms.index'}
            }
            else if(notification.attributes.datatype === 'match'){
                return {name: 'match.index'}
            }
            else if(notification.attributes.datatype === 'click'){
                return {name: 'clicks.index'}
            }
            else if(notification.attributes.datatype === 'call'){
                return {name: 'calls.index'}
            }
            else if(notification.attributes.datatype === 'review'){
                return {name: 'reviews.index'}
            }
            else {
                return {name: 'notifications.index'}
            }
        }


    }
}

</script>

<style lang="scss" scoped>
    .menu .nav-title.left span {
      margin: 0 0 0 0 !important;
    }
    .hovering:hover{
        background-color: #E4E4E4;
    }
    .url{
        text-decoration: none!important;
        color: #000!important;
    }
    .icon{
        font-size: 18px!important;
    }
    .menu .nav-title.left{
        font-weight: 300;
        font-size: 18px;
        height: 46px;
        span{
            margin-left: 5px;
        }
    }
    .md-button{
        margin: 6px 8px;
    }
    .address{
        position: absolute;
        font-size: 10px;
        font-style: italic;
        top: 17px;
        right: 5px;
    }
     .addressMenu{
        position: absolute;
        font-size: 10px;
        font-style: italic;
        top: 16px;
        right: 0px;
    
    }
    .name{
        float: left;
        display: block;
        position: relative;
        overflow: visible;
        height: 40px;
    }
    .menuContainer{
        float: left;
        display: block;
        position: relative;
        margin-top: -8px;
        clear: both;

    }

    .clear{
        display: block;
        clear: both;
        width: 100%;
    }
    .iconExit{
        position: absolute;
        top:0px;
        left:0px;
    }
    .md-menu-content{
        max-height: unset;
    }
    .red {
        background-color: #f44336!important;
        border-color: #f44336!important;
    }
    .v-badge__badge {
        color: #fff;
        position: absolute;
        font-size: 11px;
        top: -5px;
        right: -4px;
        border-radius: 50%;
        height: 19px;
        width: 19px;
        display: table;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        transition: .3s cubic-bezier(.25,.8,.5,1);
        position: absolute;
        overflow: visible;
        z-index: 1;
        cursor: pointer;
        span{
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
       
    }
    .notifications{
        position: absolute;
        margin-top: 3px;
        right: 0;
        width: 350px;

        border-radius: 2px;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
        z-index: 3;

        .md-list-item-text{
            font-size: 14px;
        }

        .md-icon{
            color: #000!important;
        }
        .md-list-item{
            height: 50px;
        }
        .time{
            font-size: 14px;
            color: var(--md-theme-default-text-primary-on-background, rgba(0,0,0,0.4));
        }
        li:hover{
            cursor: pointer;
        }
        .viewAll{
            height: 46px;
        }
        .md-icon{
            margin-right: 10px;
            margin-top: -1px;
        }
    }
    .menu{
        width: 100%;
        display: inline-block;
        .md-button{
            margin-left:10px;
        }
       
    }
    .md-menu{
        width: unset;
    }
    .md-list{
        padding-bottom: 0px;
    }
    .account{
        position: absolute;
        right:16px;
        margin-top: 7px;
        width: 240px;
        border-radius: 2px;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
        z-index: 3;

        .md-list-item{
            height: 50px;
        }

        .exit{
            height: 46px;
        }

        .md-list-item-text{
            font-size: 14px;
        }

        .md-icon{
            color: #000!important;
            margin-right: 10px;
            margin-top: -1px;
        }
       
    }
    .settings{
        position: absolute;

        margin-top: 6px; 
        width: 190px;
        border-radius: 2px;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

        .md-list-item{
            height: 50px;
        }

        .exit{
            height: 46px;
        }

        .md-list-item-text{
            font-size: 14px;
        }

        .md-icon{
            color: #000!important;
            margin-right: 10px;
            margin-top: -1px;
        }
       
    }
    .md-icon-button a:hover{
        text-decoration: none;
    }

    .unbold {
      font-weight: normal;
    }


    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

       
        .menu .nav-title.left{
            font-size: 18px;
        }
       

        .menus{
            display: flex;
            float: right;
            margin-top: 2px;
        }
        .menu .md-button{
            margin-left: 4px;
            margin-right: 0px;
            margin-top: 0px;
            min-width: unset;
           
        } 
       
        .burgerMenu{
            display: block;
            float: left;
            padding-top: 3px;
        }  
        .personMenu{
            margin-top: 1px!important;
        }
        .menu .nav-title.left span{
            margin-left: 0;
        }
    }
</style>