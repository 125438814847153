<template>
   <div class="cards">
        <div class="container">
            <div class="left">
                <h1>No premium clicks yet...</h1>
                <div class="copy">Patients are more likely to engage with informative content from listings with helpful reviews.</div>
            </div>
            <div class="right">
                <h2>Only pay for results</h2>
                <img src="/images/cards/connectPremiumPromos-01.svg"/>
                <div class="copy">Control your budget and only pay when a visitor clicks-through to your website.</div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions} from 'vuex'

    export default{
        name: 'CallsCard',
        computed: {
            ...mapGetters([
                'subscriptions'])
        }      
    }
</script>

<style lang="scss" scoped>
    .container{
        border: 1px solid #ddd;
        display: flex;
        height: auto;
        width: 100%;
        max-width: 950px;
        position: relative;
        margin: 40px;
        max-height: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        h1{
            line-height: 36px;
            color: #33475A;
            font-weight: 800;
            letter-spacing: .2px;
        }
        .copy{
            color: #646464;
            line-height: 26px;
            padding-bottom: 26px;
            padding-top: 16px;
            letter-spacing: .2px;
        }

        .left{
            width: 41.66%;
            float: left;
            position: relative;
            background-color: #f5f5f4;
            padding: 30px;
            padding-top: 140px;
            padding-bottom: 80px;
        }
        .right{
            width: 58.333%;
            float: left;
            position: relative;
            padding: 60px;
            padding-top: 94px;
            padding-bottom: 80px;
            background-color: #fff;

            img{
                height: 180px;
                padding-bottom: 20px;
                padding-top: 10px;
            }

            h2{
                color: #33475A;
                font-weight: 500;
                letter-spacing: .2px;
            }
        }
    }
</style>

          