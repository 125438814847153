<template>
    <div>
                <div class="md-layout md-alignment-top-right filters" v-if="!checkCampaignsIsPremium && (displayCalls.length > 0 || firstLoad==false)">
                    <div class="md-layout-item md-size-15"  v-if="facilities.length > 1">
                        <md-field>
                            <md-select placeholder="Listing" v-model="selectedFacility" name="selectedFacility" id="selectedFacility" mdClass="filtersPaddingTopFacility">
                                <md-option :value="0">View all</md-option>
                                <md-option v-for="(item) in facilities" :value="item.attributes.facilityId" :key="item.attributes.facilityId">{{ item.attributes.name }}</md-option>
                            </md-select>
                        </md-field>
                    </div>    
                     <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="startDate" md-immediately>
                        </md-datepicker>
                    </div>
                    <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="endDate" md-immediately>
                        </md-datepicker>
                    </div>
                </div>    
                <div class="md-layout">   
                    <md-card v-if="displayCalls.length>0">
                        <md-table class="md-table-mobile-padding">
                            <md-table-row class="white">
                                <md-table-head>Caller ID</md-table-head>
                                <md-table-head>Caller Number</md-table-head>
                                <md-table-head>Source</md-table-head>
<!--                                <md-table-head v-if="!mobile">Location</md-table-head>-->
                                <md-table-head>Date</md-table-head>
                                <md-table-head v-if="!mobile">Time</md-table-head>
                                <md-table-head v-if="!mobile">Dial Status</md-table-head>
                                 <!-- <md-table-head>Duration</md-table-head>-->
                                 <!-- <md-table-head>Audio</md-table-head>-->
                              <md-table-head v-if="!mobile">Paid</md-table-head>
                            </md-table-row>
                            <md-table-row class="grey" v-for="(item) in paginationCalls" v-bind:key="item.id">
                                <md-table-cell>{{ item.cnam }}</md-table-cell>
                                <md-table-cell>{{ item.contact_number}}</md-table-cell>
                                <md-table-cell>Listing</md-table-cell>
<!--                                <md-table-cell v-if="!mobile">{{ item.city | city}}</md-table-cell>-->
                                <md-table-cell>{{ item.called_at | formatDate }}</md-table-cell>
                                <md-table-cell v-if="!mobile">{{ item.called_at | time }}</md-table-cell>
                                <md-table-cell>{{ item.call_status.charAt(0).toUpperCase() + item.call_status.slice(1)}}</md-table-cell>
                          <!--  <md-table-cell>{{ item | duration}}</md-table-cell> -->
                          <!--  <md-table-cell>
                                     <div :data-id="item.id" v-on:click="play_audio($event, item.audio, item.id)"><md-icon>play_arrow</md-icon>
                                    </div>
                                </md-table-cell> -->
                              <md-table-cell v-if="!mobile">{{ item | paidCall }}</md-table-cell>
                            </md-table-row>
                        </md-table>

                        <!-- PAGINATION BUTTONS -->
                        <div class="md-layout" v-if="displayCalls.length > paginationLimit">
                            <div class="md-layout-item table-nav">
                                <md-button
                                      class="md-raised md-primary"
                                      :disabled="currentPage === 1"
                                      @click="previousPage"
                                >Previous</md-button>
                                <md-button
                                      class="md-raised md-primary"
                                      :disabled="nextDisabled"
                                      @click="nextPage"
                                >Next</md-button>
                            </div>
                        </div>
                        
                    </md-card>
                    <div class="notice" v-show="displayCalls.length == 0 && loading == false && firstLoad==false && user.status == 1 && showPremiumCallsCard">
                        <span class="title">No results have been found.</span>
                        <span class="intro">Please adjust your search filters.</span>
                    </div>

                    <div class="container" v-show="displayCalls.length == 0 && loading == false && firstLoad==true && user.status == 1 && showPremiumCallsCard">
                        <app-callszero></app-callszero>
                    </div>

                    <div class="container" v-show="displayCalls.length == 0 && user.status == 3 && loading == false || !showPremiumCallsCard && displayCalls.length == 0 && loading == false">
                        <app-calls></app-calls>
                    </div> 
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading == true"></md-progress-spinner>
                </div>
                <app-insightstooltip v-show="insightstooltip == true"></app-insightstooltip>
    </div>
</template>

<script lang="javascript">

    import config from '../config.js'
    import {mapGetters, mapActions} from 'vuex'
    import{subMonths, format, startOfMonth, endOfToday, parse} from 'date-fns'

    export default{
        name: 'Calls',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                calls: [],
                startDate: startOfMonth(subMonths( new Date(), 48 )),
                endDate: endOfToday(),
                currentAudio: '',
                audio : new Audio(),
                loading: true,
                firstLoad: true,
                user: [],
                insightstooltip: true,
                mobile: false,
                paginationLimit: 15,
                currentPage: 1
            }
        },
        async created(){ 
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            this.user = this.$store.getters.user
            if(this.user.status == 1){
                await this.update()
            }else{
                this.loading = false
            }
            this.$material.locale.dateFormat = "MM/dd/yyyy"
           
        },
        mounted(){
            this.$watch('startDate', (value, oldValue) => {
                if(value.getTime() != oldValue.getTime()){
                    this.update()
                    this.firstLoad = false
                }
            })
            this.$watch('endDate', (value, oldValue) => {
                
                var valueA = new Date(value)
                valueA = valueA.getDate() + '/' + valueA.getMonth() + '/' + valueA.getFullYear()

                var valueB = new Date(oldValue)
                valueB = valueB.getDate() + '/' + valueB.getMonth() + '/' + valueB.getFullYear()

                if(valueA != valueB){
                   
                    this.update()
                    this.firstLoad = false
                    
                }
            }),
             this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        },
        computed: {
            ...mapGetters({
                facilities: 'facilities',
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                checkCampaignsIsPremium: "checkCampaignsIsPremium",
                callProduct: 'callProduct',
                campaignsWithStartDates: 'campaignsWithStartDates'
            }),
            showPremiumCallsCard(){
                const premiumCallCampaign = this.campaignsWithStartDates.find(campaign => campaign.attributes.productId == this.callProduct.id)
                if(premiumCallCampaign)
                {
                    return true;
                }
                return false
            },
            facilitiesIDs(){
                return this.facilities.map(facility => facility.attributes.facilityId)
            },
            displayCalls(){ // THE CALLS THAT ARE DISPLAYED AFTER FILTER
                if(this.calls.length>0)
                {
                    this.loading = true
                }
               
                let displayCalls = []
                for(let callObj of this.calls){
                    // check for facilityId
                    if(
                        (this.selectedFacility === 0 || this.selectedFacility === callObj.facilityId)
                        && callObj.data.calls
                    ){
                        for(const call of callObj.data.calls){
                            const foundIt = displayCalls.find(displayCall => displayCall.id === call.id);
                            if(!foundIt){
                                displayCalls.push(call);
                            }
                        }
                       
                    }
                    if(displayCalls.length == 0){
                        this.loading = false
                        
                    }
                }
              
                return displayCalls 
            },

            paginationCalls() {
                const start = this.paginationLimit * (this.currentPage - 1);
                const end = start + this.paginationLimit;

                return this.displayCalls.slice(start, end)
            },

            nextDisabled() {
                if(this.currentPage === Math.ceil(this.displayCalls.length / this.paginationLimit)){
                    return true;
                }
                return false;
            }
        },
        filters: {
            formatDate(date){
                const dateString = date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'MM/DD/YYYY')
            },
            time(date){
                const dateString = date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'hh:mm a')
            },
            phone(phoneNumberString){
                var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
                if (match) {
                    var intlCode = (match[1] ? '+1 ' : '')
                    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
                }
                return null
            },
            city(cityString){
                if (cityString && cityString.length > 0){
                    return `${cityString}`
                }
                return ''
            },
            duration(item) {
                const minutes = Math.floor(item.duration / 60) < 10 ? '0'+Math.floor(item.duration / 60): Math.floor(item.duration / 60)
                const seconds = (Number(item.duration) - Math.floor(item.duration / 60) * 60) < 10 ? '0' + (Number(item.duration) - Math.floor(item.duration / 60) * 60) : Number(item.duration) - Math.floor(item.duration / 60) * 60
                return `${minutes}:${seconds}`
            },

          paidCall(item){
              let paid = "No"
              if(item.sale && item.sale.conversion){
                paid = "Yes"
              }

              return paid;
          }
        },
        methods: {
            ...mapActions([
                "fetchCallTrackingMetricsApi"
            ]),
            play_audio:function(el, audioUrl, id){
                if( this.currentAudio != audioUrl)
                {   
                    el.path[0].innerHTML = "pause"

                    this.currentAudio = audioUrl
                    this.audio.src = audioUrl
                    this.audio.play()
                    this.audio.addEventListener('ended', (event) => {
                        el.path[0].innerHTML = "play_arrow"
                    });
                }else{
                    el.path[0].innerHTML = "play_arrow"
                    this.currentAudio = ''
                    this.audio.pause()
                }
            },
            async update(){
                
                if(this.user.status == 1){
                    await this.callTrackingMetricsApi()
                    this.loading = false;
                }else{
                    this.loading = false
                }
            },
            async callTrackingMetricsApi(){
                this.loading = true
                this.calls = [];

                const promises = []
                for(const facilityId of this.facilitiesIDs){
                    try{
                        // console.log(facilityId)
                        promises.push(this.fetchCallTrackingMetricsApi({
                            facilityId,
                            trackingNumber: this.getTrackingNumber([facilityId]),
                            startDate: format(new Date(this.startDate), "YYYY-MM-DD"),
                            endDate: this.formatApiEndDate(this.endDate)
                        }));
                    }
                    catch(error){
                        //console.log("error1")
                        this.loading = false
                    }                    
                }

                const results = await Promise.all(promises)
                for(const [i, result] of results.entries()){
                  if(result){
                    this.calls.push({
                      facilityId: this.facilitiesIDs[i],
                      data: result
                    })
                  }
                }



            },
            getTrackingNumber(facilitiesIDs){
                var trackingNumber = []

                this.$store.getters.trackingNumbers.forEach((item) => {
                
                    var facilitiesID = Number(item.facilityId)
                    if(facilitiesIDs.indexOf(facilitiesID) >= 0){  
                        trackingNumber.push(item.filter_id)
                    }
                })
                return [...new Set(trackingNumber)];
            },

            previousPage() {
                if(this.currentPage > 1){
                  this.currentPage--
                }
            },

            nextPage() {
                this.currentPage++;
            }

        }
    }

</script>

<style lang="scss" scoped>
    span {
      width: 100%;
      height: 100%;
      padding: 8px;
      display: block;
      background: md-get-palette-color(blue, 200);
    }
 
  .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 50%;
        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
           
        }
        span{
            height: unset;
        }
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .white{
        background-color: #fff;
    }
    .center{
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    .container{
        width: 100%;
    }
    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }
</style>
