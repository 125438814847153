<template>
    <div class="campaigns">
        <div>


            <subscription-banner
                @startSubscription="startGoPremiumFunnel()"
                :show-dialog-premium="showDialogPremium"
            ></subscription-banner>
            <expired-card-notification></expired-card-notification>
            <failed-payment-banner></failed-payment-banner>
            <div  :class="{ 'organicshort' : (checkCampaignsIsPremiumCalls && graphType=='calls') || (checkCampaignsIsPremiumClicks && graphType=='clicks')}" class="tooltipbox organic" v-show="showOrganicTooltip">View your organic results right alongside the performance of your Premium Clicks and Calls.</div>
            <div class="md-layout md-alignment-top-right filters">

                  <!-- FACILITY SELECT -->
                <div class="md-layout-item md-xlarge-size-15 md-large-size-15 md-small-size-30 md-xsmall-size-30" v-if="facilities.length > 1">
                    <md-field>
                        <label
                            for="facility-select"
                            @click="clickFilterLabel('facility-select-filter')"
                        >Listing</label>
                        <md-select
                            name="facility-select"
                            id="facility-select"
                            v-model="selectedFacility"
                            md-immediately
                            class="graphfilter"
                            md-dense
                            ref="facility-select-filter"
                            :mdClass="facilityFilterClass"
                            placeholder="Listing"
                            multiple
                        >
                            <md-option
                                v-for="(item) in facilities"
                                :value="item.attributes.facilityId"
                                :key="item.attributes.facilityId"
                                class="calls"
                            ><div class="name">{{ item.attributes.name }}<span class="addressMenu">{{  item.attributes.city + ', ' +  item.attributes.state }}</span></div></md-option>
                        </md-select>
                    </md-field>
                </div>
                <!-- ACTIVITY SELECT -->
                <div class="md-layout-item md-xlarge-size-15 md-large-size-20 md-small-size-30 md-xsmall-size-30 filters_inner">
<!--                    <div class="interaction-line pageViewsLine" v-if="activitySelection == 'pageViews'"></div>-->
<!--                    <div class="interaction-line uniqueUsersLine" v-if="activitySelection == 'uniqueUsers'"></div>-->
                    <md-field>
                        <label
                            for="activity-select"
                            @click="clickFilterLabel('activityFilter')"
                        >Activity</label>
                        <md-select
                            name="activity-select"
                            id="activity-select"
                            v-model="activitySelection"
                            md-immediately
                            class="graphfilter"
                            md-dense
                            ref="activityFilter"
                            :mdClass="filtersClass"
                            placeholder="Activity"
                            multiple
                        >
                            <md-option
                                value="uniqueUsers"
                                selected
                                class="calls"
                            ><div class="callsLine"></div><span>Visitors</span></md-option>
                            <md-option
                                value="pageViews"
                                class="calls"
                            >
                                <div class="uniqueUsersLine"></div><span>Pageviews</span>
                            </md-option>

                        </md-select>
                    </md-field>
                </div>

                <!-- BASIC SELECT -->
                <div class="md-layout-item md-xlarge-size-15 md-large-size-20 md-small-size-30 md-xsmall-size-30 filters_inner">
                    <md-field>
                        <label
                            for="basic-select"
                            @click="clickFilterLabel('basicFilter')"
                        >Free</label>
                        <md-select
                            name="basic-select"
                            id="basic-select"
                            v-model="basicSelection"
                            md-immediately
                            class="graphfilter"
                            md-dense
                            ref="basicFilter"
                            :mdClass="filtersClass"
                            placeholder="Free"
                            multiple
                        >
                            <md-option
                                value="freeReferrals"
                                class="calls"
                            ><div class="freeReferralsLine"></div><span>Matches</span></md-option>
                            <md-option
                                value="organicClicks"
                                class="calls"
                            >
                                <div class="clicksLine"></div><span>Clicks</span>
                            </md-option>
                            <md-option
                                value="organicCalls"
                                class="calls"
                            >
                                <div class="callsLine"></div><span>Calls</span>
                            </md-option>

                        </md-select>
                    </md-field>
                </div>

                <!-- PREMIUM SELECT -->
                <div class="md-layout-item md-xlarge-size-15 md-large-size-20 md-small-size-30 md-xsmall-size-30 filters_inner">
                    <md-field>
                        <label
                            @click="clickFilterLabel('premiumFilter')"
                        >Paid</label>
                        <md-select
                            v-model="premiumSelection"
                            md-immediately
                            class="graphfilter"
                            md-dense
                            ref="premiumFilter"
                            :mdClass="filtersClass"
                            placeholder="Paid"
                            multiple
                        >
                            <md-option
                                value="bedRequests"
                                selected
                                class="calls"
                            >
                                <span>Forms</span></md-option>
                            <md-option
                                value="clicks"
                                class="clicks"
                            >
                                <span>Clicks</span>
                            </md-option>
                            <md-option
                                value="calls"
                                class="calls"
                            >
                                <div class="callsLine"></div><span>Calls</span>
                            </md-option>

                        </md-select>
                    </md-field>
                </div>

                <!-- DATE RANGE SELECTOR -->
                <div
                    class="md-layout-item md-xlarge-size-15 md-large-size-15 calendar"
                    :class="{
                          'calendar-only': facilities.length === 1,
                          'md-xsmall-size-100': facilities.length === 1,
                          'md-small-size-100': facilities.length === 1
                      }"
                >
                    <date-picker
                        v-model="dateRange"
                        is-range
                        :popover="{visibility: 'click', placement: 'bottom-start'}"
                        :max-date="maxDate"
                        :min-date="minDate"
                        transition="none"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="flex justify-center items-center">
                                <md-field>
                                    <md-icon>event</md-icon>
                                    <label>Date range</label>
                                    <md-input
                                        :value="dateDisplay"
                                        v-on="calendarClick(inputEvents.start)"
                                        class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                        :min-date="new Date(2019, 0, 1)"
                                    />
                                </md-field>
                            </div>
                        </template>
                    </date-picker>
              </div>
            </div>

            <!-- GRAPH -->
            <div class="graph">
                <template v-if="showGraphOverlay">
                    <md-button
                        v-if="!subscriptions.length"
                        @click="learnMoreClick()"
                    >Learn more<md-icon>add</md-icon></md-button>
                </template>
                <apexchart
                    type="area"
                    :options="chartOptions"
                    :series="series"
                    :height="240"
                ></apexchart>
                <div class="future" :class="{'future-organic-premium': interactionFilter === 'organicAndPremium', 'no-filters-future': filtersLength === 0}" v-if="showGraphOverlay"></div>
            </div>

            <!-- PREMIUM TABLE -->
            <go-premium-table
                ref="premiumTable"
                :start-date="dateRange.start"
                :end-date="dateRange.end"
                :selected-facility="selectedFacility"
                @startGoPremiumFunnel="startGoPremiumFunnel($event)"
                :calls="calls"
                :callsGa="callsGA"
                :clicks="clicks"
                :clicksGa="clicksGA"
                :last-requested-date="lastRequestedDate"
                :first-requested-date="firstRequestedDate"
                :show-dialog-premium="showDialogPremium"
                :bed-requests="bedRequests"
                @moveReceivingTooltip="moveReceivingTooltip($event)"
            ></go-premium-table>

            <!-- GO PREMIUM FUNNEL -->
            <md-dialog :md-active.sync="showDialogPremium" class="dialogPremium" :class="{'keyword-scan': funnelData.currentStep === 'keyword-scan' || funnelData.currentStep === 'step1'}">

                <!-- STEP 1 MODAL -->
                <template v-if="funnelData.currentStep === 'step1'">
                    <div class="step1">
                        <step1-modal
                            @next="step1Next"
                            @closeFunnel="showDialogPremium = false"
                            @requestDemo="funnelData.currentStep = 'request-demo'"
                        ></step1-modal>
                    </div>
                </template>

                <!-- KEYWORD SCAN MODAL -->
                <template v-if="funnelData.currentStep === 'keyword-scan'">
                    <keyword-template
                        @next="funnelData.currentStep = '3'"
                        @back="funnelData.currentStep = 'step1'"
                        @keywordScreenCompleted="funnelData.keywordScreenCompleted = true"
                    ></keyword-template>
                </template>

                <template v-if="funnelData.currentStep === 'request-demo'">
                    <div class="request-demo">
                        <request-demo-form
                            @back="funnelData.currentStep = 'step1'"
                            @close="showDialogPremium = false;"
                        ></request-demo-form>
                    </div>
                </template>

            </md-dialog>

            <!-- TOOLTIPS -->
            <app-welcometooltip v-if="WelcomeTooltip == true"></app-welcometooltip>

            <app-gopremiumtooltip v-show="GoPremiumTooltip == true"></app-gopremiumtooltip>
            <app-gopremiumtooltipfunnel v-show="GoPremiumTooltipFunnel == true"></app-gopremiumtooltipfunnel>
            <app-receivingtooltip v-show="confirmReceiving == true" ></app-receivingtooltip>
            <app-gopremiumthanks v-show="gopremiumthanks == true" @awesome="confirmReceiving = true" @moveReceivingTooltip="moveReceivingTooltip($event)"></app-gopremiumthanks>
            <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showFunnelSnackbar"
                md-persistent
            >
                <span>{{snackMsg}}</span>
                <md-button class="md-primary" @click="showFunnelSnackbar = false">Close</md-button>
            </md-snackbar>

        </div>
    </div>
</template>

<script lang="javascript">

import {mapActions, mapGetters, mapMutations} from 'vuex'
import config from '../config.js'
import VueApexCharts from 'vue-apexcharts'
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  setDate,
  startOfDay,
  startOfToday,
  subMonths
} from 'date-fns'
import goPremiumMixin from '../mixins/goPremium'

import GoPremiumTable from './GoPremium/GoPremiumTable'
import NewPaymentMethodForm from './GoPremium/Funnel/NewPaymentMethodForm'
import KeywordTemplate from './GoPremium/Funnel/KeywordTemplate'
import PricingResults from './GoPremium/Funnel/PricingResults'
import SubscriptionBanner from "./GoPremium/SubscriptionBanner";
import Step1Modal from "./GoPremium/Funnel/Step1Modal"
import RequestDemoForm from "./GoPremium/Funnel/RequestDemoForm";
import ExpiredCardNotification from "./GoPremium/ExpiredCardNotification";
import FailedPaymentBanner from "./Payments/FailedPaymentBanner"
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
        // store,
        name: 'GoPremium',
        components: {
            SubscriptionBanner,
            'apexchart': VueApexCharts,
            GoPremiumTable,
            NewPaymentMethodForm,
            KeywordTemplate,
            PricingResults,
            Step1Modal,
            RequestDemoForm,
            ExpiredCardNotification,
            FailedPaymentBanner,
            Calendar,
            DatePicker
        },
        mixins: [goPremiumMixin],
        data() {
            return {
                bedRequests: [],
                freeReferrals: [],
                interactionFilter: "Organic",
                doneLoading: false,
                graphType: 'calls',

                activitySelection: ['uniqueUsers'],
                basicSelection: [],
                premiumSelection: [],


                calls: [],
                callsGA: [],
                clicks: [],
                clicksGA: [],
                pageViewsGA: [],
                uniqueUsersGA: [],
                selectedFacility: [],
                config: config,
                chartData: null,
                startDate: null,
                endDate: null,
                dateRange: {start: null, end: null},
                selectedFacilityId:null,
                monthsPeriod: 6,
                counter: 0,
                showDialog: false,
                showDialogPremium: false,
                GoPremiumTooltip: true,
                GoPremiumTooltipFunnel: true,
                WelcomeTooltip: true,
                showFunnelSnackbar: false,
                expandCalls: false,
                expandClicks: false,
                expandCallsClicks: true,
                snackMsg: null,
                showSalesNumber: false,
                step:1,
                blur: false,
                showOrganicTooltip: false,
                gopremiumthanks: true,
                confirmReceiving: true,
                mobile: false,
                graphMaxNum: 0,
                funnelData: {
                    goal: null,
                    budget: 0,
                    paymentCreated: false,
                    currentStep: 1,
                    sliderAmount: 0,
                    keywordScreenCompleted: false
                },
                firstRequestedDate: null,
                lastRequestedDate: null,

                //graph num-months controls
                noActiveSubsSubtractMonths: 6,
            }
        },
        computed: {
             ...mapGetters([
                'verifiedPhoneOrUrls',
                'singleVerifiedPhoneOrUrl',
                'goPremiumFunnelCompleteThisLogin',
                'tooltips',
                'calculateBudget',
                'interactionCostsOverridesByFacility',
                'subscriptions',
                'activeSubscriptions',
                'checkCampaignsIsPremiumClicks',
                'checkCampaignsIsPremiumCalls',
                'facilityOperationTypes',
                'user',
                 'campaignsWithStartDates',
                 'callProduct',
                 'clickProduct',
                 'enabledCampaigns',
                 'goV2Facility',
                 'subscriptionBannerDismissed',
                 'failedPayments'
             ]),

            minDate() {
               return new Date(2019, 0, 1);
            },

            maxDate(){
                return new Date();
            },

            dateDisplay() {
               if(this.dateRange.start && this.dateRange.end){
                  return `${format(this.dateRange.start, 'MM/DD/YYYY')} - ${format(this.dateRange.end, 'MM/DD/YYYY')}`
               }
               return ''
            },

            filtersClass() {
               if(
                   this.activeSubscriptions.length === 0
                   && !this.subscriptionBannerDismissed
               ) {
                 return 'filtersPaddingTopCallsNoSubscriptions'
               } else if(this.failedPayments && this.failedPayments.length){
                 return 'filtersPaddingTopCallsNoSubscriptions'
               }

               return 'filtersPaddingTopCalls'

            },

            filtersLength() {
                const allFilters = [...this.activitySelection, ...this.basicSelection, ...this.premiumSelection]
                return allFilters.length
            },

            facilityFilterClass() {
              if(
                  this.activeSubscriptions.length === 0
                  && !this.subscriptionBannerDismissed
              ) {
                return 'filtersPaddingTopFacilityWBanner'
              } else if(this.failedPayments && this.failedPayments.length){
                return 'filtersPaddingTopFacilityWBanner'
              }
              return 'filtersPaddingTopFacility'
            },

            chartOptions() {
                const periods = []

                // FIGURE OUT PERIOD LENGTH. SPLIT TO WEEKS IF LESS THAN MONTH, DAYS IF IT'S LESS THAN A WEEK.
                const daysBetween = differenceInCalendarDays(this.dateRange.end, this.dateRange.start)
                let period = 'month'
                if(daysBetween <= 7){
                    period = 'day'
                } else if(daysBetween <= 31){
                    period = 'week'
                }

                let endDate = new Date(this.dateRange.end);
                if(this.checkCampaignsIsPremiumCalls || this.checkCampaignsIsPremiumClicks) endDate = addMonths(endDate, 3)


                let iteratorDate = setDate(this.dateRange.start, 1);
                if(period !== 'month') iteratorDate = this.dateRange.start

                // FIGURE OUT PERIOD LABELS
                while(iteratorDate <= endDate ){
                    if(period === 'month') {
                        periods.push(format(iteratorDate, 'MMM'));
                        iteratorDate = addMonths(iteratorDate, 1);
                    } else if (period === "week"){
                        periods.push(format(iteratorDate, 'MM/DD') + " - " + format(endOfWeek(iteratorDate), 'MM/DD'));
                        iteratorDate = addDays(endOfWeek(iteratorDate), 1);
                    } else if(period === "day"){
                        periods.push(format(iteratorDate, 'MM/DD'))
                        iteratorDate = addDays(iteratorDate, 1)
                    }
                }

                // FIGURE OUT GRAPH COLORS
                let graphColors = []

                if(this.activitySelection){
                    if(this.activitySelection.includes('uniqueUsers')){
                        graphColors.push('#39ABB2')
                    }
                    if(this.activitySelection.includes('pageViews')){
                        graphColors.push('#E75F24')
                    }
                }
                if(this.basicSelection){
                    if(this.basicSelection.includes('organicClicks')){
                        graphColors.push('#A5C882')
                    }
                    if(this.basicSelection.includes('organicCalls')){
                        graphColors.push('#008FFB')
                    }
                    if(this.basicSelection.includes('freeReferrals')){
                        graphColors.push('#c7d9ff')
                    }
                }
                if(this.premiumSelection){
                    if(this.premiumSelection.includes('clicks')){
                        graphColors.push('#F7DD72')
                    }
                    if(this.premiumSelection.includes('calls')){
                        graphColors.push('#ff46e6')
                        graphColors.push('#e7b4df')
                    }
                    if(this.premiumSelection.includes('bedRequests')){
                        graphColors.push('#f7c193')
                    }
                }

                // SHOW A LINE WHEN THERE'S NO DATA
                if(!this.filtersLength){
                  graphColors.push('#b6b6b6')
                }

                // FIND THE MAX NUMBER IN THE SERIES TO USE FOR GRAPH HEIGHT
                let maxNum = 0;
                for( const item of this.series){
                    const max = Math.max(...item.data)
                    if(max > maxNum) maxNum = max;
                }
                if(maxNum){
                    this.graphMaxNum =  maxNum * 1.25;
                    if(this.graphMaxNum < 5) this.graphMaxNum = 5;
                } else {
                    this.graphMaxNum = 5;
                }

                // console.log('MAX NUM', maxNum);

                 return {
                     dataLabels: {
                         enabled: false
                     },
                     chart: {
                         id: 'Payments',
                         toolbar: {
                             show: false,
                         },
                         zoom: {
                             enabled: false
                         }
                     },
                     legend: {
                       show: true,
                       showForSingleSeries: this.filtersLength === 1,
                       onItemClick: {
                         toggleDataSeries: false
                       },
                       onItemHover: {
                         highlightDataSeries: false
                       },
                     },
                     tooltip: {
                        enabled: this.filtersLength,
                        shared: true,
                     },
                     stroke: {
                         curve: 'straight'
                     },
                     xaxis: {
                         categories: periods,
                         labels: {
                             formatter: function (value) {
                                 return value;
                             }
                         }
                     },
                     colors: graphColors,
                     yaxis: {
                         tickAmount: 2,
                         forceNiceScale: true,
                         max: () => {
                             // console.log('max num again',this.graphMaxNum);
                             return this.graphMaxNum
                         }
                     }
                 }
            },

            showGraphOverlay() {
                if(this.activeSubscriptions.length === 0 && this.filtersLength === 0){
                  return true
                }
                if(this.graphType === 'pageViews' || this.graphType === 'uniqueUsers'){
                  return false;
                }
                if(!this.checkCampaignsIsPremiumClicks  && this.graphType === "clicks"){
                    return false;
                }
                if(!this.checkCampaignsIsPremiumCalls && this.graphType === "calls"){
                    return false;
                }
                if(this.subscriptions.length === 0){
                    return true;
                }
                if(this.subscriptions.length > 0 && this.graphType === 'bedRequests'){
                    return false;
                }
                if(this.subscriptions.length === 0)
                {
                    return true;
                }else{
                    return false;
                }
            },

            showBedRequestsOptionInGraph() {
                if(this.subscriptions.length && this.campaignsWithStartDates.length && (this.interactionFilter === 'Organic'))
                    return false;

                 return true;
            },

            series(){ // CALCULATE ALL THE GRAPH DATA

                // SHOW DATA WHEN THERE'S NO DATA FOR SOME AWFUL REASON.
                if(!this.filtersLength){
                  if(this.activeSubscriptions && this.activeSubscriptions.length > 0){
                    return [{"name":"nothing","data":[0,0,0,0,0,0,0,0,0,0]}]
                  }
                  return [{"name":"nothing","data":[0,0,0,0,0,0,0,7,9,11]}]
                }

                const clickData = []
                const organicClickData = []
                const callData = []
                const totalCallData = []
                const organicCallData = []
                const periods = []
                const bedRequestData = []
                const freeReferralsData = []
                const pageViewsData = []
                const uniqueUsersData = []


                const startDate = new Date(this.dateRange.start);
                const endDate = new Date(this.dateRange.end);

                const daysBetween = differenceInCalendarDays(endDate, startDate)

                let period = 'month'
                if(daysBetween <= 7){
                    period = 'day'
                } else if(daysBetween <= 31){
                    period = 'week'
                }

                let iteratorDate = setDate(this.dateRange.start, 1);
                if(period === 'day' || period === 'week') iteratorDate = startOfDay(this.dateRange.start)

                const validFacilities = this.selectedFacility

                // LOOP THROUGH PERIODS BETWEEN START AND END DATE
                let iterations = 0;
                while(isBefore(iteratorDate, endDate) || isSameDay(iteratorDate, endDate)){
                    const startOfPeriod = iteratorDate
                    let endOfPeriod = endOfMonth(startOfPeriod);
                    if(period === 'day') endOfPeriod = endOfDay(startOfPeriod)
                    if(period === 'week') endOfPeriod = endOfWeek(startOfPeriod)

                    let formattedPeriod = format(iteratorDate, 'MMM')
                    if(period === 'day') formattedPeriod = format(iteratorDate, 'MM/DD')
                    if(period === 'week') formattedPeriod = format(iteratorDate, 'MM/DD') + " " + format(endOfPeriod, 'MM/DD')
                    periods.push(formattedPeriod);

                    // GET CLICK DATA
                    let thisPeriodsClicks = 0;
                    for(const facilityClicks of this.clicks) {
                        if(validFacilities.includes(facilityClicks.facilityId)){
                            if(facilityClicks && facilityClicks.data){
                                const clicks = facilityClicks.data.filter(click => {
                                    const clickDate =  parse(click.date.date)
                                    if(clickDate > startDate && clickDate > startOfPeriod && clickDate < endOfPeriod)
                                        return click;
                                    return false;
                                })
                                thisPeriodsClicks += clicks.length;
                            }

                        }
                    }

                    let thisMonthsClicksGA = 0;
                    //console.log(this.clicksGA)
                    for(const facilityClicks of this.clicksGA){
                        if(validFacilities.includes(facilityClicks.facilityId)){
                            if(facilityClicks && facilityClicks.data){
                                const clicks = facilityClicks.data.filter(click => {
                                    const clickDate = new Date(click.date)
                                    if(clickDate > startDate && clickDate > startOfPeriod && clickDate < endOfPeriod)
                                        return click;
                                    return false
                                })
                                thisMonthsClicksGA += clicks.length;
                            }
                        }
                    }

                    // GET CALL DATA
                    let thisMonthsConvertedCalls = 0;
                    let thisMonthsTotalCalls = 0;
                    for(const facilityCalls of this.calls){
                        if(validFacilities.includes(facilityCalls.facilityId)){
                            const callsList = facilityCalls.data.calls;
                            if(callsList){
                                const filteredCalls = callsList.filter(call => {
                                    const callDate = parse(call.called_at)
                                    // console.log('CALL DATE', callDate);
                                    // console.log('CALL', JSON.parse(JSON.stringify(call)))
                                    if(callDate > startDate && callDate > startOfPeriod && callDate < endOfPeriod && call.sale && call.sale.conversion)
                                        return call;
                                    return false;
                                })
                                const monthAllCalls = callsList.filter(call => {
                                    const callDate = parse(call.called_at)
                                    if(callDate > startDate && callDate > startOfPeriod && callDate < endOfPeriod){
                                      return call;
                                    }
                                    return false
                                })
                                thisMonthsConvertedCalls += filteredCalls.length;
                                thisMonthsTotalCalls += monthAllCalls.length;
                            }
                        }
                    }

                    let thisMonthsGACalls = 0
                    for(const gaCalls of this.callsGA) {
                        // console.log('FACILITY GA CALLS:', JSON.parse(JSON.stringify(gaCalls)))
                        // console.log('VALID FACILITIES', JSON.parse(JSON.stringify(validFacilities)))
                        if(validFacilities.includes(gaCalls.facilityId)){
                            // console.log('in valid facilities list');
                            if(gaCalls && gaCalls.data){
                                const filteredCalls = gaCalls.data.filter(call => {
                                     // console.log('CALL FOR GRAPH', call);
                                    const callDate = parse(call.date);
                                    if(callDate > startDate && callDate > startOfPeriod && callDate < endOfPeriod)
                                        return call
                                    return false;
                                })
                                 //console.log('ADDING TO MONTH CALLS: ', filteredCalls.length)
                                    thisMonthsGACalls += filteredCalls.length;
                                //console.log(thisMonthsGACalls)
                            }

                        }
                    }

                    // GET BED REQUEST DATA
                    let thisPeriodsBedRequests = 0
                    if(this.bedRequests && this.bedRequests.length){
                        for(const bedRequest of this.bedRequests){
                            // console.log('BED REQUEST', JSON.parse(JSON.stringify(bedRequest)));
                            if(validFacilities.includes(parseInt(bedRequest.facilitiesSelected))){

                                const requestDate = parse(bedRequest.date)
                                if(
                                    isAfter(requestDate, startDate) &&
                                    isAfter(requestDate, startOfPeriod) &&
                                    isBefore(requestDate, endOfPeriod)
                                ){
                                    thisPeriodsBedRequests += 1;
                                }
                            }
                        }
                    }

                    // GET FREE REFERRALS DATA
                    let thisPeriodsFreeReferrals = 0
                    if(this.freeReferrals && this.freeReferrals.length){
                        for(const freeReferral of this.freeReferrals){
                            // console.log('FREE REFERRAL', JSON.parse(JSON.stringify(freeReferral)))
                            const freeReferralFacilityIds = freeReferral.facilitiesSelected.split(',')
                            // console.log('FREE REFERRAL FACILITY IDS', freeReferralFacilityIds)
                            for(const freeReferralFacilityId of freeReferralFacilityIds){
                                if(validFacilities.includes(parseInt(freeReferralFacilityId))){
                                    const freeReferralDate = parse(freeReferral.date)
                                    if(
                                        isAfter(freeReferralDate, startDate) &&
                                        isAfter(freeReferralDate, startOfPeriod) &&
                                        isBefore(freeReferralDate, endOfPeriod)
                                    ){
                                        // console.log('WE ARE USING THIS ONE!')
                                        thisPeriodsFreeReferrals += 1;
                                    }
                                }
                            }
                        }
                    }
                    // console.log('FREE REFERRALS FOR PERIOD', thisPeriodsFreeReferrals);

                    // GET PAGEVIEWS DATA
                    let periodPageViews = 0
                    for(const facilityPageViews of this.pageViewsGA){
                        if(validFacilities.includes(parseInt(facilityPageViews.facilityId))){
                            if(facilityPageViews && facilityPageViews.interactions){
                                const filteredPageViews = facilityPageViews.interactions.filter(view => {
                                    const viewDate = new Date(view.date)
                                    if(viewDate > startDate && viewDate > startOfPeriod && viewDate < endOfPeriod)
                                        return view;
                                    return false
                                })
                                periodPageViews += filteredPageViews.length
                            }
                        }
                    }

                    // GET UNIQUE USERS DATA
                    let periodUniqueUsers = 0
                    for(const facilityUniqueUsers of this.uniqueUsersGA){
                      if(validFacilities.includes(parseInt(facilityUniqueUsers.facilityId))){
                        if(facilityUniqueUsers && facilityUniqueUsers.interactions){
                          const filteredUniqueUsers = facilityUniqueUsers.interactions.filter(view => {
                            const viewDate = new Date(view.date)
                            if(viewDate > startDate && viewDate > startOfPeriod && viewDate < endOfPeriod)
                              return view;
                            return false
                          })
                          periodUniqueUsers += filteredUniqueUsers.length
                        }
                      }
                    }

                    // console.log('OLD ITERATOR DATE', iteratorDate);
                    if(period === 'day') iteratorDate = addDays(iteratorDate, 1)
                    else if(period === 'week') iteratorDate = addWeeks(iteratorDate, 1)
                    else iteratorDate = addMonths(iteratorDate, 1);
                    //console.log('NEW ITERATOR DATE', iteratorDate)

                    // PUSH DATA TO CLICK AND CALLS ARRAYS.
                    clickData.push(parseInt(thisPeriodsClicks));
                    callData.push(parseInt(thisMonthsConvertedCalls));
                 //   totalCallData.push(parseInt(thisMonthsTotalCalls));

                    bedRequestData.push(thisPeriodsBedRequests);
                    freeReferralsData.push(thisPeriodsFreeReferrals);

                    organicCallData.push(parseInt(thisMonthsGACalls));
                    organicClickData.push(parseInt(thisMonthsClicksGA))

                    pageViewsData.push(parseInt(periodPageViews))
                    uniqueUsersData.push(parseInt(periodUniqueUsers))

                    iterations += 1;
                    // console.log('ITERATIONS', iterations);
                    if(iterations > 100) {
                        break
                    }
                }

                // IF THEY ARE NOT PREMIUM, SHOW THEM 3 MONTHS IN THE FUTURE
                if(this.showGraphOverlay){
                    // PREMIUM CALLS
                    let yearHigh = Math.max(...callData);
                    let organicYearHigh = Math.max(...organicCallData);
                    if(organicYearHigh > yearHigh) yearHigh = organicYearHigh
                    if(yearHigh < 6) yearHigh = 6;

                    // callData.push(callData[callData.length - 1])
                    let callInterator = 0
                    while(callInterator < 3){
                        yearHigh = yearHigh + parseInt(yearHigh * .3)
                        callData.push(yearHigh);
                        callInterator++
                    }

                    // ORGANIC CALLS
                    let organicCallHigh = Math.max(...organicCallData);
                    if(organicCallHigh < 6) organicCallHigh = 6
                    // organicCallData.push(0)
                    let organicCallIterator = 0;
                    while(organicCallIterator < 3){
                      organicCallHigh = organicCallHigh + parseInt(organicCallHigh * .3)
                      organicCallData.push(organicCallHigh);
                      organicCallIterator++
                    }

                    // PREMIUM CLICKS
                    let clicksYearHigh = Math.max(...clickData)
                    let organicClicksYearHigh = Math.max(...organicClickData)
                    if(clicksYearHigh < organicClicksYearHigh) clicksYearHigh = organicClicksYearHigh
                    if(clicksYearHigh < 6) clicksYearHigh = 6

                    // clickData.push(clickData[clickData.length -1])
                    let clickInterator = 0
                    while(clickInterator < 3){
                        clicksYearHigh = clicksYearHigh + parseInt(clicksYearHigh * .3)
                        clickData.push(clicksYearHigh);
                        clickInterator++
                    }

                    // ORGANIC CLICKS
                    let organicClickHigh = Math.max(...organicClickData);
                    if(organicClickHigh < 6) organicClickHigh = 6;
                    // organicClickData.push(0)
                    let organicClickIterator = 0;
                    while(organicClickIterator < 3) {
                      organicClickHigh = organicClickHigh + parseInt(organicClickHigh * .3)
                      organicClickData.push(organicClickHigh)
                      organicClickIterator++
                    }

                    // BED REQUESTS
                    let bedsYearHigh = 6;
                    // bedRequestData.push(0)
                    let bedsInterator = 0
                    while(bedsInterator < 3){
                        bedsYearHigh = bedsYearHigh + parseInt(bedsYearHigh * .3)
                        bedRequestData.push(bedsYearHigh);
                        bedsInterator++
                    }

                    // FREE REFERRALS
                    let freeReferralsHigh = 6;
                    // freeReferralsData.push(0)
                    let referralsIterator = 0
                    while(referralsIterator < 3){
                        freeReferralsHigh = freeReferralsHigh + parseInt(freeReferralsHigh * .3)
                        freeReferralsData.push(freeReferralsHigh);
                        referralsIterator++
                    }

                    // VISITORS
                    let visitorsYearHigh = parseInt(uniqueUsersData.reduce((a, b) => (a + b)) / uniqueUsersData.length);
                    if(visitorsYearHigh < 6) visitorsYearHigh = 6
                    // uniqueUsersData.push(0)
                    let visitorsIterator = 0
                    while(visitorsIterator < 3){
                        visitorsYearHigh = visitorsYearHigh + parseInt(visitorsYearHigh * .3)
                        uniqueUsersData.push(visitorsYearHigh)
                        visitorsIterator++;
                    }

                    // PAGE VIEWS
                    let pageViewsYearHigh = parseInt(pageViewsData.reduce((a, b) => (a + b)) / pageViewsData.length);
                    if(pageViewsYearHigh < 6) pageViewsYearHigh = 6
                    // pageViewsData.push(0)
                    let pageViewsIterator = 0
                    while(pageViewsIterator < 3){
                        pageViewsYearHigh = pageViewsYearHigh + parseInt(pageViewsYearHigh * .3)
                        pageViewsData.push(pageViewsYearHigh);
                        pageViewsIterator++;
                    }
                }

                // POPULATE DATA TO RETURN
                const returnData = []
                if(this.activitySelection) {
                    if(this.activitySelection.includes('uniqueUsers')){
                        returnData.push({name: ' Visitors', data: uniqueUsersData})
                    }
                    if(this.activitySelection.includes('pageViews')){
                        returnData.push({name: ' Pageviews', data: pageViewsData})
                    }
                }

                if(this.basicSelection){
                    if(this.basicSelection.includes('organicClicks')){
                        returnData.push({name: ' Free clicks', data: organicClickData})
                    }
                    if(this.basicSelection.includes('organicCalls')){
                        returnData.push({name: ' Free calls', data: organicCallData})
                    }
                    if(this.basicSelection.includes('freeReferrals')){
                        returnData.push({name: ' Free Matches', data: freeReferralsData})
                    }
                }

                if(this.premiumSelection){
                    if(this.premiumSelection.includes('clicks')){
                        returnData.push({name: ' Paid clicks', data: clickData})
                    }
                    if(this.premiumSelection.includes('calls')){
                        returnData.push({name: ' Paid calls', data: callData})
                      //  returnData.push({name: ' Total calls', data: totalCallData})
                    }
                    if(this.premiumSelection.includes('bedRequests')){
                        returnData.push({name: ' Forms', data: bedRequestData})
                    }
                }

                return returnData
            }
        },
        watch: {
            interactionFilter(newVal, oldVal){
                if(newVal === 'Organic' && this.graphType=== 'bedRequests'){
                    this.graphType = 'clicks'
                }
            },
             'showDialogPremium'(newVal, oldVal) {
                 if (newVal == false) {

                    if(this.$route.query && this.$route.query.funnel){ // ROUTE BACK TO NORMAL ROUTE WITH NO QUERY PARAMS.
                        this.$router.push({name: 'dashboard.premium'})
                        this.setBlur(false)
                    }
                 } else {
                    if(!this.mobile){
                        this.setBlur(true);
                    }
                }
            },
            'showGraphOverlay'(newVal, oldVal){
                 if(newVal !== oldVal){
                     if(newVal){
                         this.dateRange.start = startOfDay(setDate(subMonths(new Date(), 5), 1))
                     } else {
                         this.dateRange.start = startOfDay(setDate(subMonths(new Date(), 9), 1))
                     }
                 }
            },

            funnelData: {
                async handler(value, old){
                    // console.log('FUNNEL DATA CHANGE: new:', JSON.parse(JSON.stringify(value)), 'old: ', JSON.parse(JSON.stringify(old)), 'facilityIds', value.funnelFacility)
                    if(value.currentStep === "keyword-scan" && this.showDialogPremium){
                        await this.$store.dispatch('fetchInteractionCostsLookup', {facilityIds: value.funnelFacility, refresh: 1})
                    }

                    this.$store.dispatch('updateGoPremiumFunnelInfo', this.funnelData)
                },
                deep: true
            },

            'dateRange.start'(value, old) {
                if(old && value === null){
                    this.dateRange.start = old;
                    return;
                }
                const now = new Date();
                if(isAfter(value, now)){
                    this.dateRange.start = old;
                    return;
                }
                const twoYearsAgo = subMonths(now, 24);
                if(isBefore(value, twoYearsAgo)){
                    this.dateRange.start = old;
                    return;
                }


                if(value && old && value.toString() !== old.toString()){
                    //console.log('startdate change', value, old);
                    this.update();
                }

            },
            'dateRange.end'(value, old) {
                if(old && value === null){
                  this.dateRange.end = old;
                  return;
                }
                const now = new Date();
                if(isAfter(value, now)){
                    this.dateRange.end = old;
                    return;
                }
                const twoYearsAgo = subMonths(now, 24);
                if(isBefore(value, twoYearsAgo)){
                    this.dateRange.end = old;
                    return;
                }

                if(value && old && value.toString() !== old.toString()){
                    //console.log('endDate change', value, old);
                    this.update();
                }

            },
            '$route.query.funnel': {
                handler: function(newVal, oldVal){
                    const goPremiumData = this.$store.getters.goPremiumFunnelInfo;
                    if(goPremiumData){
                        this.funnelData = JSON.parse(JSON.stringify(goPremiumData))
                    }
                    // console.log('FUNNEL CHANGE', newVal, oldVal);
                    // console.log('ROUTE', this.$route)
                    // console.log('FUNNEL DATA', this.funnelData);
                    if(newVal === 'keyword-scan' && this.funnelData.currentStep !== 'keyword-scan'){
                        this.funnelData.currentStep = 'keyword-scan'
                    }else if(newVal === 'step1' && this.funnelData.currentStep !== 'step1'){
                        this.funnelData.currentStep = 'step1'
                    }else if(newVal === undefined){
                        this.showDialogPremium = false;
                    }
                },
                deep: true,
                immediate: true
            },

            windowWidth(value) {
                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            },

            selectedFacility(value, oldValue) {
              if( (!value && oldValue) || (value && !value.length && oldValue) ){
                this.selectedFacility = oldValue
                // window.setTimeout(() => {
                //   this.selectedFacility = [];
                //   this.selectedFacility = oldValue
                // }, 1)
              }

            }
        },

        async created() {

            if( this.windowWidth <= 760 ) {
                this.mobile = true;
                this.budgetLabel = "Budget"
            }

            if(!this.activeSubscriptions || (this.activeSubscriptions && !this.activeSubscriptions.length)){
                this.dateRange.start = startOfDay(setDate(subMonths(new Date(), this.noActiveSubsSubtractMonths), 1))
            }else {
                this.dateRange.start = startOfDay(setDate(subMonths(new Date(), 9), 1))
            }

            this.$material.locale.dateFormat = "MM/dd/yyyy"
            this.dateRange.end = startOfToday()
            this.firstRequestedDate = startOfDay(this.dateRange.start)
            this.lastRequestedDate = startOfToday()
        },
        async mounted() {

            this.setBlur(false)
            this.fetchFacilityInformation(this.facilitiesIDs.join(','))
            this.update()
            this.$root.$on('gopremiumTooltip', () => {
                this.GoPremiumTooltip = true
            })
             this.$root.$on('showDialogPremium', () => {
                this.startGoPremiumFunnel()
            })

            if(this.$route.query.funnel == 'subscription'){
                if(this.$route.query.facilityId){
                    this.startGoPremiumFunnel(this.$route.query.facilityId)
                }else {
                    this.startGoPremiumFunnel();
                }
            }
            if(this.$route.query.funnel == 'gopremium'){
                this.startGoPremiumFunnel();
            }
            this.tooltipsCheck()
            this.startFunnelForExistingUsers()


            // OPEN THE AWESOME CARD AFTER FINISHING FUNNEL
            if(this.$route.query['premium-campaigns']){
                this.$root.$emit('gopremiumthanks')
                this.confirmReceiving = false;

                this.interactionFilter = 'Premium';
                this.graphType = "bedRequests";
                if(!this.mobile){
                    this.setBlur(true);
                }
            }

            // OPEN FUNNEL TO STEP 1
            if(this.$route.query.funnel && this.$route.query.funnel === 'step1'){
                // console.log('THERE WAS A CHANGE');
                this.showDialogPremium = true
                this.funnelData.currentStep = 'step1'
            }

            // OPEN FUNNEL TO KEYWORD SCAN SLIDE
            if(this.$route.query.funnel && this.$route.query.funnel === 'keyword-scan'){
                this.showDialogPremium = true
                this.funnelData.currentStep = 'keyword-scan'
            }

            // SET GRAPH TO BED REQUESTS BY DEFAULT IF THEY HAVE A SUBSCRIPTION BUT NO CAMPAIGNS.
            if(this.subscriptions.length && !this.campaignsWithStartDates.length){
                this.interactionFilter = 'Premium'
                this.graphType = 'bedRequests'
            }

            // START ENABLING CLICK CAMPAIGN
            if(this.$route.query.activate && this.$route.query.activate === 'clicks') {
                this.graphType = 'clicks'
                this.enableCampaigns()
            }

            // START ENABLING CALL CAMPAIGN
            if(this.$route.query.activate && this.$route.query.activate === 'calls') {
                this.graphType = 'calls'
                this.enableCampaigns()
            }

            this.selectedFacility = this.facilitiesIDs

        },
        methods: {
            ...mapActions([
                'makeInitialPayment',
                'hubspotStartGoPremiumFunnel',
                'fetchCampaigns',
                'hubspotGoPremiumSelectGoal',
                'createSubscriptions',
                'fetchFacilityOperationType',
                'fetchCreditCards',
                'fetchPremiumLeads',
                'setLearnMoreClicked',
                'fetchFacilityUniqueUsersGA',
                'fetchFacilityPageViewsGA',
                'fetchMatches'
            ]),
             ...mapMutations([
                'setBlur'
            ]),

            clickFilterLabel(refName){
              setTimeout(() => {this.$refs[refName].openSelect()}, 1)
            },

            async startFunnelForExistingUsers(){
                const cutoffDate = new Date(2019, 11, 18);
                const userCreated = parse(this.user.createdAt);
                // console.log('USER CREATED AT', userCreated, '\ncutoff', cutoffDate, '\n', isBefore(userCreated, cutoffDate));
                if(isBefore(userCreated, cutoffDate)){
                    // console.log('it is before');
                    const activeCampaigns = this.campaigns.filter(campaign => campaign.status);
                    const activeSubscriptions = this.subscriptions.filter(subscription => subscription.active === true);
                    if(
                        this.$store.getters.statusLogByName('ExistingUserRedirectedToGoV2Funnel')
                        && !this.$store.getters.statusLogByName('ExistingUserViewedFunnel')
                        && activeCampaigns.length === 0
                        && activeSubscriptions.length === 0
                    ){
                        this.GoPremiumTooltip = true;
                        // this.$root.$emit('gopremiumTooltip')
                        this.$store.dispatch('createStatusLog', {
                            type: 'ExistingUserViewedFunnel',
                            action: 'viewed'
                        })
                        // THE GOPREMIUM TOOLTIP TAKES OVER HERE AND OPENS ITSELF TO THE FINAL STEP.
                    }
                }
            },
            budgetBreakdown(budget, type){
                return this.$store.getters.getBudgetBreakdown({facilityId: this.displayFacility[0].attributes.facilityId, type, budget})
            },
            tooltipsCheck(){
                if(!this.$store.getters.statusLogByName('WelcomeTooltip')){
                    this.$root.$emit('welcomeTooltipUp')
                    this.$store.commit('setTooltips', 'WelcomeTooltip')
                    this.$store.dispatch('createStatusLog', {
                        type: 'WelcomeTooltip',
                        action: 'closed'
                    })
                }
            },
            async update() {
                this.matches = []
                this.callTrackingMetricsApi()
                this.getFacilityClicks()

                this.getPageViewsGA();
                this.getUniqueUsersGA();

                const bedRequests = await this.fetchPremiumLeads({
                    facilityIds: this.facilitiesIDs,
                    startDate: this.formatDate(this.firstRequestedDate),
                    endDate: this.formatApiEndDate(this.lastRequestedDate)
                });
                this.bedRequests = bedRequests.data;

                const freeReferrals = await this.fetchMatches({
                    facilityIds: this.facilitiesIDs,
                    startDate: this.formatDate(this.firstRequestedDate),
                    endDate: this.formatApiEndDate(this.lastRequestedDate)
                })
                this.freeReferrals = freeReferrals.data
            },
            async callTrackingMetricsApi(startDate = this.dateRange.start, endDate = this.dateRange.end) {
                if((!this.calls.length && !this.callsGA.length) || (isBefore(startDate, this.firstRequestedDate) || isAfter(endDate, this.lastRequestedDate))){

                    if(isBefore(startDate, this.firstRequestedDate))
                        this.firstRequestedDate = startDate
                    if(isAfter(endDate, this.lastRequestedDate))
                        this.lastRequestedDate = endDate

                    this.calls = []
                    this.callsGA = []

                    this.calls = await this.fetchCallTrackingMetricsApi({
                        facilityId: this.facilitiesIDs,
                        trackingNumber: this.getTrackingNumber(this.facilitiesIDs),
                        startDate: format(this.firstRequestedDate, 'YYYY-MM-DD'),
                        endDate: this.formatApiEndDate(this.lastRequestedDate)
                    });


                    // GET GOOGLE ANALYTICS DATA
                    try {
                        const callsGA = await this.fetchFacilityCallsGA({
                            facilityIds: this.facilitiesIDs,
                            startDate: format(this.firstRequestedDate, 'YYYY-MM-DD'),
                            endDate: this.formatApiEndDate(this.lastRequestedDate)
                        });
                        //console.log('GA CALLS OLD EVENTS', JSON.parse(JSON.stringify(callsGA)));

                        for (const facilityCallData of callsGA.facilityInteractions){
                          this.callsGA.push({
                            facilityId: parseInt(facilityCallData.facilityId),
                            data: facilityCallData.interactions
                          })
                        }

                    }catch(e){
                        console.error(e);
                    }


                    // GET GOOGLE ANALYTICS DATA FOR NEW TRACKING
                    try {
                        const callsGA = await this.fetchFacilityCallsGANewTracking({
                            facilityIds: this.facilitiesIDs,
                            startDate: format(this.firstRequestedDate, 'YYYY-MM-DD'),
                            endDate: this.formatApiEndDate(this.lastRequestedDate)
                        });
                       // console.log('GA CALLS NEW TRACKING ', JSON.parse(JSON.stringify(callsGA)));

                        for (const facilityCallData of callsGA.facilityInteractions){
                          this.callsGA.push({
                            facilityId: parseInt(facilityCallData.facilityId),
                            data: facilityCallData.interactions
                          })
                        }

                    }catch(e){
                        console.error(e);
                    }

                    return this.calls;
                }
            },

            async getFacilityClicks() {
                if((!this.clicks.length && !this.clicksGA.length) || (isBefore(this.dateRange.start, this.firstRequestedDate) || isAfter(this.dateRange.end, this.lastRequestedDate))){

                    if(isBefore(this.dateRange.start, this.firstRequestedDate))
                        this.firstRequestedDate = this.dateRange.start
                    if(isAfter(this.dateRange.end, this.lastRequestedDate))
                        this.lastRequestedDate = this.dateRange.end


                    this.clicks = []
                    this.clicksGA = []

                    // GET & PARSE PREMIUM CLICKS FROM SYMFONY
                    var clicks = await this.fetchFacilityClicks({
                        facilityIds: this.facilitiesIDs,
                        startDate: this.formatDate(this.firstRequestedDate),
                        endDate: this.formatApiEndDate(this.lastRequestedDate)
                    });
                    for(let facilityId of this.facilitiesIDs){
                        const facilityClickData = {
                            facilityId,
                            data: clicks.filter(click => click.facilityId === facilityId)
                        }
                        let existingClicks = this.clicks.find(clickFacility => clickFacility.facilityId === facilityId);
                        if(existingClicks){
                            existingClicks = facilityClickData
                        } else {
                            this.clicks.push(facilityClickData)
                        }
                    }

                    // GET & PARSE ORGANIC CLICKS FROM GA
                    try {
                        const clicksGA = await this.fetchFacilityClicksGA({
                            facilityIds: this.facilitiesIDs,
                            startDate: this.formatDate(this.firstRequestedDate),
                            endDate: this.formatApiEndDate(this.lastRequestedDate)
                        });
                        // console.log("CLICKS RESPONSE:", JSON.parse(JSON.stringify(clicksGA)))

                        for(const click of clicksGA.facilityInteractions){
                            //console.log('GA CLICK', click);
                            const facilityId = this.facilitiesIDs.find(facilityId => parseInt(click.facilityId) === parseInt(facilityId));

                            if(facilityId){
                                // console.log('FACILITY ID CLICK', facilityId);
                                const facilityClicks = this.clicksGA.find(clicks => clicks.facilityId === facilityId)
                                if(facilityClicks){
                                    // console.log('FACILITY CLICKS', JSON.parse(JSON.stringify(facilityClicks)))
                                    facilityClicks.data = click.interactions
                                }else{
                                    this.clicksGA.push({
                                        facilityId,
                                        data: click.interactions
                                    })
                                }
                                //console.log('CLICKS GA', this.clicksGA)
                            }
                        }
                    } catch(e) {
                        console.error(e);
                    }

                    // GET & PARSE ORGANIC CLICKS FROM GA
                    try {
                        const clicksGA = await this.fetchFacilityClicksGANewTracking({
                            facilityIds: this.facilitiesIDs,
                            startDate: this.formatDate(this.firstRequestedDate),
                            endDate: this.formatApiEndDate(this.lastRequestedDate)
                        });
                        // console.log("CLICKS RESPONSE:", JSON.parse(JSON.stringify(clicksGA)))

                        for(const click of clicksGA.facilityInteractions){
                           // console.log('GA CLICK', click);
                            const facilityId = this.facilitiesIDs.find(facilityId => parseInt(click.facilityId) === parseInt(facilityId));

                            if(facilityId){
                              //  console.log('FACILITY ID CLICK', facilityId);
                                const facilityClicks = this.clicksGA.find(clicks => clicks.facilityId === facilityId)
                                if(facilityClicks){
                                 //   console.log('FACILITY CLICKS', JSON.parse(JSON.stringify(facilityClicks)))
                                    facilityClicks.data = click.interactions
                                }else{
                                    this.clicksGA.push({
                                        facilityId,
                                        data: click.interactions
                                    })
                                }
                                //console.log('CLICKS GA', this.clicksGA)
                            }
                        }
                    } catch(e) {
                        console.error(e);
                    }
                }
            },

            async getPageViewsGA() {
                // console.log('GET PAGE VIEWS GA')
                if(!this.pageViewsGA.length || (isBefore(this.dateRange.start, this.firstRequestedDate) || isAfter(this.dateRange.end, this.lastRequestedDate))){
                    const gaPageViews = await this.fetchFacilityPageViewsGA({
                        facilityIds: this.facilitiesIDs,
                        startDate: this.formatDate(this.firstRequestedDate),
                        endDate: this.formatApiEndDate(this.lastRequestedDate)
                    });
                    // console.log('GA PAGE VIEWS', JSON.parse(JSON.stringify(gaPageViews)));

                    if(gaPageViews && gaPageViews.facilityInteractions){
                       this.pageViewsGA = gaPageViews.facilityInteractions
                    }
                }
            },

            async getUniqueUsersGA() {
                // console.log('GET PAGE VIEWS GA')
                if(!this.uniqueUsersGA.length || (isBefore(this.dateRange.start, this.firstRequestedDate) || isAfter(this.dateRange.end, this.lastRequestedDate))){
                    const gaUniqueUsers = await this.fetchFacilityUniqueUsersGA({
                        facilityIds: this.facilitiesIDs,
                        startDate: this.formatDate(this.firstRequestedDate),
                        endDate: this.formatApiEndDate(this.lastRequestedDate)
                    });
                    // console.log('GA UNIQUE USERS', JSON.parse(JSON.stringify(gaUniqueUsers)));

                    if(gaUniqueUsers && gaUniqueUsers.facilityInteractions){
                        this.uniqueUsersGA = gaUniqueUsers.facilityInteractions
                    }
                }
            },


            // Returns the facility name that is associated with the campaign
            getFacilityName(id, name = '') {
                this.facilities.forEach((item) => {
                    if (item.attributes.facilityId == id) {
                        name = item.attributes.name
                    }
                })
                return name
            },

            // Small word tweak depending on the number of selections from the listing
            getAlternateLabel(count, word = '') {
                count > 1 ? word = 'campaigns' : word = 'campaign'
                return `${count} ${word} selected`
            },

            formatDate(date) {
                return format(date, 'YYYY-MM-DD')
            },

            learnMoreClick() {
                this.setLearnMoreClicked()
                this.startGoPremiumFunnel()
            },

            /** cta should be "subscription" or "campaigns" **/
            startGoPremiumFunnel(facilityId = null){
                // console.log('FACILITY ID PASSED IN', facilityId);
                // console.log('GOV2 FACILITY', this.goV2Facility);
                if(!facilityId){
                    if(this.goV2Facility){
                        // console.log('USING GOV2 FACILITY FROM STORE')
                        facilityId = this.goV2Facility
                    }else {
                        // console.log('GRABBING FIRST FACILITY ID');
                        facilityId = this.facilities[0].attributes.facilityId
                    }
                }

                const facilityPricing = this.fiveBudget.facilityBudgets.find(facilityBudget => parseInt(facilityBudget.facilityId) === parseInt(facilityId))

                this.fetchFacilityOperationType();
                this.fetchCreditCards()
                this.showDialogPremium = true
                this.$root.$emit('gopremiumTooltipClose')
                const goPremiumData = this.$store.getters.goPremiumFunnelInfo;
                if(goPremiumData){
                    // console.log('HAVE GO PREMIUM DATA', goPremiumData)
                    this.funnelData = JSON.parse(JSON.stringify(goPremiumData))
                    this.funnelData.currentStep = 'step1'
                    this.funnelData.funnelFacility = parseInt(facilityId);
                    this.funnelData.facilityPricing = facilityPricing
                } else {
                    this.funnelData = {
                        currentStep: 'step1',
                        goal: null,
                        budget: 0,
                        paymentCreated: false,
                        sliderAmount: 0,
                        keywordScreenCompleted: false,
                        funnelFacility: parseInt(facilityId),
                        facilityPricing,
                    }
                }

                // console.log('FUNNEL DATA', JSON.parse(JSON.stringify(this.funnelData)));
                if(!this.mobile){
                    this.setBlur(true);
                }

                // CHANGE OVERLAY STYLE
                const all = document.getElementsByClassName('md-overlay');
                setTimeout(() => {
                    for (const overlay of all) {
                        overlay.style.opacity = '0.8';
                    }
                }, 10);

                // IF CURRENT SUBSCRIPTION ACTIVE AND NO CAMPAIGNS, REDIRECT TO SELECT A BUDGET PAGE
                if(this.activeSubscriptions && this.activeSubscriptions.length){
                    // console.log('FUNNEL DATA IN START FUNNEL FUNCTION', this.funnelData);
                    this.funnelData.goal = null;
                    this.$store.dispatch('updateGoPremiumFunnelInfo', this.funnelData)
                    return this.$router.push({name: 'premium.funnel.select-goal'});
                }

                // START FUNNEL
                const query = {};
                Object.assign(query, this.$route.query);
                query.funnel = 'step1';
                this.$router.push({path: this.$route.path, query})
            },

            step1Next(){
                this.funnelData.currentStep = 'keyword-scan'

                const query = {};
                Object.assign(query, this.$route.query);
                query.funnel = 'keyword-scan';
                this.$router.push({path: this.$route.path, query})
            },



            funnelChooseGoal(type){
                this.funnelData.goal = type;
                this.funnelData.currentStep = "keyword-scan"
                this.hubspotGoPremiumSelectGoal({goal: type})
            },

            funnelPaymentOptionBudget(){
                if(this.funnelData.goal === 'clicks'){
                    return Math.ceil( this.funnelData.budget * this.fiveBudget.singleClickAverage )
                } else if (this.funnelData.goal === 'calls'){
                    return Math.ceil( this.funnelData.budget * this.fiveBudget.singleCallAverage)
                } else if ( this.funnelData.goal === "callsAndClicks"){
                    return Math.ceil( this.funnelData.budget * ( this.fiveBudget.singleClickAverage + this.fiveBudget.singleCallAverage ) )
                }
            },

            /** ENABLES WHATEVER CAMPAIGN MATCHES THE CURRENT this.graphType  */
            enableCampaigns(){
                let campaigns = []
                if(this.graphType === 'clicks'){
                    // console.log('CLICK PRODUCT', this.clickProduct);
                    campaigns = this.campaigns.filter(campaign => campaign.productId === parseInt(this.clickProduct.id))
                }else if(this.graphType === 'calls'){
                    campaigns = this.campaigns.filter(campaign => campaign.productId === parseInt(this.callProduct.id))
                }

                // console.log('CHANGE CAMPAIGNS', this.graphType, campaigns)
                if(campaigns.length){
                    // console.log('SHOULD BE ENABLING THIS CAMPAIGN', campaigns[0])
                    this.$refs.premiumTable.activateCampaignFromExternalRoute(campaigns[0]);
                }

            },
            calendarClick(inputEventsStart) {

                // DON'T OPEN CALENDAR IF NOT PREMIUM, OPEN FUNNEL INSTEAD
                if(!this.activeSubscriptions || (this.activeSubscriptions && !this.activeSubscriptions.length)){
                    inputEventsStart.click = this.learnMoreClick;
                    inputEventsStart.focusin = this.learnMoreClick;

                   
                }
                return inputEventsStart
            },

            moveReceivingTooltip(facilityId) {
                // console.log('MOVE RECEIVING TOOLTIP CALLED', facilityId);
                // console.log('ACTIVE SUBSCRIPTIONS', JSON.parse(JSON.stringify(this.activeSubscriptions)))
                if(!this.activeSubscriptions)
                    return
                if(!facilityId){
                    facilityId = this.activeSubscriptions[0].facilityId;
                }

                const facilityNameTD = document.getElementById(`facility-${facilityId}`)
                const facilityNameTDBox = facilityNameTD.getBoundingClientRect();
                let top = facilityNameTDBox.top;
                // console.log('THE FACILITY TD', facilityNameTD, top, left);
                setTimeout(() => {
                  const receivingTooltip = document.getElementById('receiving-tooltip');
                  // console.log('THE RECEIVING TOOLTIP', receivingTooltip);
                  receivingTooltip.style.top = top - 248 + 'px';
                }, 10)

            }
        },
        filters: {
            formatPrice: (value) => {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            callClickRange: (value) => {
                //const low = Math.ceil(value / 2);
                //const high = parseInt(value * 1.5);
                return `${value}`
            }
        },
    }

</script>

<style lang="scss" scoped>

    .addressMenu{
        font-size: 10px;
        font-style: italic;
        clear: both;
        width: 100%!important;
        display: block;
        text-align: left;
    }
    .add-margin-top{
        top: 184px!important;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-app-content .md-card{
        margin-bottom: 20px;
        flex-flow: unset!important;
    }
    .md-content{
        height: auto;
    }
    .md-table + .md-table[data-v-14d4afe3]{
        margin-top: 0px;
    }
    .green .md-icon{
        color: #189702;
    }
    .blue{
        color: var(--md-theme-default-primary);
    }
    .grey{
        background-color: #f8f9fb;
    }
    .md-table.md-theme-default .md-table-row td{
        border-top: none;
    }
    .checkbox div{
        padding: 6px 20px 6px 20px;
    }
    .checkbox, .state{
        width: 1%;
    }
    tr{
        border-top: 1px solid #eee;
    }
    .grey td{
        border:  1px solid #eee;
    }
    .md-icon{
        font-size: 18px!important;
    }
    .menu-trigger{
        width: 100%;
        display: block;
    }
    .md-menu{
        width: 100%
    }
    .md-list-item-content .md-icon{
        padding-left: 6px;
    }
    .md-list-item-content .option
    {
        margin-left:-14px;
        text-align: left;
        display: flex;
        padding-right: 8px;
    }
    .menu-trigger .dropdown{
         position: absolute;

    }
    .menu-trigger {
        cursor: pointer;
     }
    .budget .dropdown{
        position: absolute;
        top:10px;
    }
    .budget{
        cursor: pointer;
    }
    .dialogBudget{
        width: 680px;
        height: 400px;
        border-radius: 10px;
        text-align: center;
    }
    .dialogBudget .md-dialog-title{
          font-size: 28px;
          font-weight: bold;
          margin-top: 10px;
    }
    .dialogBudget .budget, .dialogPremium .budget{
        font-size: 38px;
        font-weight: bold;
        color: var(--md-theme-default-primary);
        margin-bottom: 10px;
        width:500px;
    }
    .dialogBudget .slidecontainer, .dialogPremium .slidecontainer {
        width: 100%;
    }
    .dialogBudget .slider, .dialogPremium .slider {
        margin-top: 28px;
        -webkit-appearance: none;
        width: 95%;
        height: 10px;
        border-radius: 5px;
        background: var(--md-theme-default-primary);
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        box-shadow: 0px 0px 2px 2px #ccc;
    }
    .dialogBudget .reach, .dialogPremium .reach{
        margin-top: 10px;
    }

    .keyword-scan {
        top: 121px !important;
    }

    .md-dialog-actions{
        width:95%;
        padding-bottom: 24px;
    }
    .overlay{
        height: 210px;
        width: 100%;
        right: 0px;
        top: 590px;
        position: absolute;
        z-index: 2;
    }
    .dialogBudget .typical, .dialogPremium .typical{
        border-bottom: 1px solid #9c9c9c;
        width: 40%;
        margin-left: 20%;
        margin-right: auto;
        height: 10px;
        border-left: 1px solid #9c9c9c;
        border-right: 1px solid #9c9c9c;
        margin-top: 4px;
    }
    .dialogBudget .competitor, .dialogPremium .competitor{
        margin-top: 10px;
        margin-left: 20%;
        margin-right: auto;
        width: 40%;
        color:#9c9c9c;
    }
    .graph .md-icon{
        margin-top: -2px;
        color: #fff!important;
        margin-right: -4px;
    }
    .graph{
        margin-top: -20px;
        margin-bottom: -8px;
        position: relative;
    }
    .graph .md-button{
        position: absolute;
        right: 29px;
        top: 24px;
        background-color: #fff;
        height: 28px;
        padding-top: 2px;
        padding-left: 3px;
        z-index: 2;
    }

    .blurItem{
        filter: blur(2px);
    }

    .dialogPremium{
        background: none;
        background-color: none;
        img{
            height: 127px;
            width: 155px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }
        .step1{
            margin-top: 23px;
            width: 910px;
            height: 480px;
            text-align: center;
            background-color: #D6F3FE;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            background-image: url('https://rehab-connect.s3.amazonaws.com/assets/red_shirt_man.png');
            background-position: -15px -25px;
            background-repeat: no-repeat;
        }

        .request-demo {
            margin-top: 0px;
            width: 700px;
            height: 400px;
            text-align: center;
            background-color: #fff;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            overflow: scroll;
        }

        .subscription{
            width: 940px;
            height: 600px;
        }
        .step4{
                width: 700px;
                height: 366px;
                min-height: 366px;
                border-radius: 10px;
                text-align: center;
                background-color: #fff;
                box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
                border-radius: 20px;
                margin-left: auto;
                margin-right: auto;
                .perfacility{
                    display: block;
                }
                .md-dialog-content{
                    margin-top: 36px;
                    overflow: visible;
                    padding-bottom: 10px;
                }

                .md-dialog-actions{
                    margin-top: 0px;
                    float: left;
                    margin-left: 20px;
                }
            }
        .step5{
            width: 670px;
            overflow:none;
            border-radius: 20px;
            text-align: center;
            background-color: #fff;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 20px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 10px;
            padding-right: 10px;

            .options{
                .md-card-actions{
                    margin-top: 20px;
                }

            }
        }

        .md-dialog-title{
            font-size: 28px;
            font-weight: bold;
            display: block;
        }
        .md-dialog-actions{
            margin-top: 20px;
            display: block;
            width: unset;
        }

    }

     .future{
        width: 33.25%;
        height: 185px;
        position: absolute;
        z-index: 1;
        background-color: #C5CCD9;
        top: 1px;
        right: -6px;
        opacity: 0.65;
    }

    .no-filters-future {
        height: 203px;
    }

    @-moz-document url-prefix() {
      .future {
        height: 189px !important;
      }
    }

    .future-organic-premium {
        height: 189px;
    }

    .border-top{
        border-top: 1px solid var(--md-theme-default-divider-on-background, rgba(0,0,0,0.12));
    }
    .md-table-alternate-header{
        z-index: 6;
    }
    .interaction-line {
        position: absolute;
        width: 20px;
        height: 4px;
        top:21px;
        margin-top: 0px;
        border-radius: 5px;
        margin-left: 0px;
    }
    .organicCallsLine {
        background-color: #008FFB;
    }
    .callsLine{
        background-color: #ff46e6;
    }
    .organicClicksLine {
        background-color: #A5C882;
    }
     .clicksLine{
        background-color: #F7DD72;
    }
    .bedRequestsLine{
        background-color: #f7c193;
    }
    .pageViewsLine {
        background-color: #E75F24;
    }
    .uniqueUsersLine {
        background-color: #39ABB2;
    }
    .calls{
         .callsLine{
            position: absolute;
            width: 20px;
            height: 4px;
            top:20px;
            margin-top: -2px;
            border-radius: 5px;
            margin-left: 0px;
            background-color: #448aff;
        }
        span{
            font-size: 14px;
            //padding-left: 30px;
        }
    }
    .clicks{
        .clicksLine{
            position: absolute;
            width: 20px;
            height: 4px;
            top:20px;
            margin-top: -2px;
            border-radius: 5px;
            margin-left: 0px;
            background-color: rgb(165, 200, 130);
        }
        span{
            font-size: 14px;
            //padding-left: 30px;
        }
    }
    .bedRequests{
        .bedRequestsLine{
            position: absolute;
            width: 20px;
            height: 4px;
            top:20px;
            margin-top: -2px;
            border-radius: 5px;
            margin-left: 0px;
            background-color: rgb(247,193,147);
        }
        span{
            font-size: 14px;
            //padding-left: 30px;
        }
    }
    .md-layout-item.md-size-5{
        margin-right: 10px;
    }
    .month{
        float: right;
        display: block;
        margin-top: 4px;
        
    }
    .filters{
        overflow: visible;
        // .icon{
        //    color: #c6c6c6 !important;
        //    left: 80px;
        //    position: relative;
        //    top: -41px;
        //    margin-left: 4px;
        //    &:hover{
        //        cursor: pointer;
        //    }
        //}
        //.organic-and-premium-icon {
        //    left: 154px!important;
        //}
        //.organicFilter{
        //    padding-right: 17px !important;
        //}
        //.icon {
        //    left: 80%;
        //}
        .md-layout-item {
            margin-left: 10px;
            padding-left: 10px;
            .md-has-value {
                label {
                    color: rgba(0, 0, 0, 0.54);
                    top: -10px;
                }
            }
            .md-field {
              margin-top: 10px;
            }
        }
    }
    .tooltipbox{
        position: absolute;
        z-index: 6;
        background-color: #fff; 
        width: 220px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;

    }

    .organic{
        top: 100px;
        right: 38%;
        height: 62px;
        width: 320px;
        z-index: 100;
    }

   
     .fox{
         img{
            height: 40px;
        }
        position: absolute;
        bottom: 1%;
        left: 26px;
        margin-left: -15px;
        cursor: pointer;
    }

    @media (max-width: 1550px){
        .organic{
            top: 100px;
            right: 42%;

        }
    }

    .md-dialog {
        box-shadow: none;
        transform: translate(-50%, -130px);
        top:250px;
        max-height: unset;

        .md-dialog-container{
            padding:40px!important;
             flex-flow: unset
        }
    }

 .organicshort{
         right: 15%;
    }

@media (max-height: 800px) {
    .md-dialog {
        top: 200px;
    }
     .organic{
        top: 100px;
        left: 2%;

    }
}

@media (max-height: 780px) {
    .md-dialog {
        top: 160px;
    }
}

@media (max-height: 770px) {
    .md-dialog {
        top: 160px;
    }
}

@media (max-height: 650px) {
    .md-dialog {
        top: 20%;
    }
}


.tooltip{
    transform: initial;
    left: initial;
    margin-left: initial;
    margin-top: initial;
}
 .mobile{
       position:absolute; /*it can be fixed too*/
        left:0; right:0;
        top:0; bottom:0;
        margin:auto;
        text-align: center;

        /*this to solve "the content will not be cut when the window is smaller than the content": */
        max-width:100%;
        max-height:100%;
        overflow:auto;
        width: 300px;
        height: 600px;
        top:5%;
    }

      .chatBox {
        position: absolute;
       bottom: 30px;
        padding: 1em;
        left: 60px;

        width: 200px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 1px -4px rgba(0, 0, 0, 0.9), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
    }

    .chatBox::after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -14px;;
        left: 0;
        box-sizing: border-box;
        border: 1em solid black;
        border-color: transparent transparent #fff #fff;
        margin-top: -15px;
        zoom: 0.5;
        transform: rotate(45deg)!important;
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.12)
    }

    .campaigns .md-button{
        text-transform: unset;
        background: #1860E2;
        color: #fff;
        md-icon{
            color: #fff;
        }
        width: 146px;
        height: 34px;
        margin-right: -6px;
    }

    .back-div{
        margin-top:6px !important;
        width: 90% !important;
        padding: 0 !important;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        padding-left: 10px;
    }

    //.callsfilter {
    //    min-width: 137px !important;
    //}

    .organic-and-premium {
        min-width: 190px !important;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .dialogPremium{
            .step1{
                background-image: none;
                width: 100%;
                min-width: unset;
                max-width: unset;
                height: 100%;
                margin-top: 0px;
                border-radius: unset;
               
            }
        }
        .keyword-scan{
            top: 0px!important;
        }

         .organicshort{
             right: 10%;
         }
         .organic{
             top: 114px;
         }
        .graph{
            margin-right: 10px;
            margin-left: -6px;
        }
        .add-margin-top{
            top: 187px!important;
        }

        .filters {
            height: 108px;
            padding-top: 4px;
            justify-content: flex-start;
        }
    }
</style>