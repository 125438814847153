import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    mediaPageInfo: {}
  }),
  actions: {
    async loadFacilityMediaPageInfo({dispatch, commit}, facilityId) {
      const data = {
        data: null,
        information: {
          image: null
        },
        videoInfo: null,
        pendingUpdates: false
      }
      
      // LOAD FACILITY INFO
      data.data = await dispatch('fetchFacilityInformation', facilityId);
      await dispatch('fetchFacilityDataChildNames');
      
      // VIDEO INFO
      let prefix = '';
      if(data.data.facilityVideo.videoTypeName === "youtube"){
        prefix = "https://youtu.be/"
      }else if(data.data.facilityVideo.videoTypeName === "vimeo"){
        prefix = "https://vimeo.com/"
      }
      data.videoInfo = {
        content: prefix ? prefix + data.data.facilityVideo.video : '',
        videoType: data.data.facilityVideo.videoTypeName,
        childId: data.data.facilityVideo.id,
      }

      // HANDLE PENDING VIDEO UPDATES
      if(data.data.facilityVideo.pendingUpdates && data.data.facilityVideo.pendingUpdates.length > 0){

        let videoId = data.data.facilityVideo.video;

        for(const update of data.data.facilityVideo.pendingUpdates){
          // console.log('VIDEO UPDATE', JSON.parse(JSON.stringify(update)));

          // HANDLE VIDEO ID UPDATE
          if(update.action === 'value_change' && update.changes.attribute === 'video'){
            videoId = update.changes.newValue;
            data.pendingUpdates = true;
          }

          // HANDLE VIDEO SITE CHANGE

          if(update.action === 'value_change' && update.changes.attribute === 'videoType'){
            prefix = update.changes.newValue.video_type_name === 'youtube' ? "https://youtu.be/" : "https://vimeo.com/"
            data.videoInfo.videoType = update.changes.newValue.video_type_name
            data.pendingUpdates = true;
          }

          // HANDLE VIDEO CREATE
          if(update.action === 'created'){
            prefix = update.changes.attributes.video_type.video_type_name === 'youtube' ? "https://youtu.be/" : "https://vimeo.com/"
            videoId = update.changes.attributes.video;
            data.pendingUpdates = true;
          }
        }

        data.videoInfo = {
          content: prefix ? prefix + videoId : '',
          childId: data.data.facilityVideo ? data.data.facilityVideo.id : null,
          videoType: data.videoInfo.videoType
        }
      }

      if(!data.videoInfo.videoType) data.videoInfo.videoType = 'youtube'

      // HANDLE PENDING IMAGE UPDATES
      if(data.data.facility.pendingUpdates) {
        const profileImageChange = data.data.facility.pendingUpdates.find(update => update.changes && update.changes.attribute === 'imagePath');
            // console.log('PENDING IMAGE UPDATES', JSON.parse(JSON.stringify(profileImageChange)))
        if(profileImageChange){
          data.data.facility.pendingImagePath = profileImageChange.changes.newValue
          data.pendingUpdates = true;
        }

      }

      // LOAD GALLERY IMAGES
      const galleryImages = await dispatch('fetchFacilityGalleryImages', facilityId);
      // console.log('THE GALLERY DATA', JSON.parse(JSON.stringify(galleryImages)))
      if(galleryImages){
        if(galleryImages.pendingModeration && galleryImages.pendingModeration.length){
          data.pendingUpdates = true;
        }
        data.gallery = JSON.parse(JSON.stringify(galleryImages));

        if(galleryImages.pendingDeletes && galleryImages.pendingDeletes.length){
          data.pendingUpdates = true;
          for(const toDelete of galleryImages.pendingDeletes){
            data.gallery.galleryImages = data.gallery.galleryImages.filter(image => image.id !== toDelete.object_id)
          }
        }

        if(galleryImages.pendingUpdates && galleryImages.pendingUpdates.length){
          data.pendingUpdates = true;
          for(const toUpdate of galleryImages.pendingUpdates){
            // console.log('toUpdate', toUpdate);
            const approvedImage = data.gallery.galleryImages.find(image => image.id === toUpdate.object_id);
            // console.log('approved image', JSON.parse(JSON.stringify(approvedImage)));
            if(approvedImage){
              if(toUpdate.changes && toUpdate.changes.attribute === 'title'){
                approvedImage.title = toUpdate.changes.newValue
              }else if(toUpdate.changes && toUpdate.changes.attribute === 'description'){
                approvedImage.description = toUpdate.changes.newValue
              }
            }
            // console.log('UPDATED APPROVED IMAGE', JSON.parse(JSON.stringify(approvedImage)));
          }
        }
      }


      commit('setFacilityMediaPageInfo', {facilityId, data});

      return data;
      
    },

    async updateFacilityMediaPage({state, dispatch}, {facilityId, submitObject, submitFields}){
      await dispatch('loadFacilityMediaPageInfo', facilityId);
      const data = state.mediaPageInfo[facilityId];

      if(submitFields.video){
        // UPDATE THE VIDEO
        const videoSubmitObj = JSON.parse(JSON.stringify(submitObject.videoInfo))
        // console.log('VIDEO SUBMIT OBJECT');

        // STRIP THE VIDEO ID FROM THE URL
        if(submitObject.videoInfo && submitObject.videoInfo.content && (submitObject.videoInfo.content.includes('youtube.com') || submitObject.videoInfo.content.includes('youtu.be'))){
          // console.log('IT IS YOUTUBE' )
          const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
          const match = submitObject.videoInfo.content.match(regExp);
          // console.log('STRIPPED', match[7]);
          videoSubmitObj.content = match[7];
          videoSubmitObj.videoType = 'youtube'
        }
        if(submitObject.videoInfo && submitObject.videoInfo.content && submitObject.videoInfo.content.includes('vimeo')){
          // console.log('IT IS VIMEO' )
          const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
          const match = submitObject.videoInfo.content.match(regExp);
          // console.log('vimeo stripped', match);
          videoSubmitObj.content = match[5];
          videoSubmitObj.videoType = 'vimeo'
        }

        // UPDATE THE VIDEO
        if(videoSubmitObj.content){
          if(!data.data.facilityVideo.video){
            const createdVideo = await dispatch('createAdminItem', {
              facilityId,
              type: 'video',
              content: {
                content: videoSubmitObj.content,
                videoType: videoSubmitObj.videoType
              },
              childId: null
            })
          }
          else if(videoSubmitObj.content !== data.data.facilityVideo.video || videoSubmitObj.videoType !== data.data.facilityVideo.videoTypeName){
            // console.log('UPDATING VIDEO', data.data.facilityVideo);
            await dispatch('updateFacilityInformation', {
              facilityId,
              type: 'video',
              data: {...videoSubmitObj, ...{childId: data.data.facilityVideo.id}}
            })
          }

        }

        // DELETE PENDING UPDATES
        if(data.data.facilityVideo.pendingUpdates) {
          for(const update of data.data.facilityVideo.pendingUpdates){
            await dispatch('deleteContentUpdate', update.id);
          }
        }
      }
    },

    async fetchFacilityGalleryImages(context, facilityId){
      const url = `${apiUrl}/facility-gallery-image/${facilityId}`
      const response = await axios.get(url);
      return response.data;
    },

    async createFacilityGalleryImage(context, {fileName, title, description, file, facilityId}){
      const url = `${apiUrl}/facility-gallery-image/${facilityId}`
      const apiObj = {
        fileName,
        file,
        title,
        description
      }
      await axios.post(url, apiObj)
    },

    async updateFacilityGalleryImage(context, {facilityId, id, description, title}){
      const url = `${apiUrl}/facility-gallery-image/${facilityId}`;
      const data = {
        id,
        title,
        description
      }
      const result = await axios.put(url, data)
      return result.data
    }

  },
  mutations: {
    setFacilityMediaPageInfo(state, {facilityId, data}){
      state.mediaPageInfo[facilityId] = data;
    }
  },
  getters: {
    getMediaPageInfo: (state) => (facilityId) => {
      return state.mediaPageInfo[facilityId]
    }
  }
}