import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    loginLogs: [],
    tooltips: []
  }),
  actions: {

    // LOGIN LOGS 
    async fetchLoginLogs(context) {
      const user = context.getters.user;
      const url = `${apiUrl}/loginlogs`
      const result = await axios.get(url);      
      context.commit('setLoginLogs', result.data)
      return result.data;
    },
    async createLoginLogs(context, {action}) {
      const url = `${apiUrl}/loginlogs`
      const result = await axios.post(url, {
        data: {
          attributes: {
            action,
          }
        }
      }) 
      const data = result.data
      data.data = [result.data.data]
      context.commit('setLoginLogs', data)      
      return result.data
    },

    // STATUS LOGS
    async fetchStatusLogs(context) {
      const url = `${apiUrl}/status-logs`
      const result = await axios.get(url);

      if(result && result.data && result.data.data){
        // console.log('FETCH STATUS LOGS RESULT', result.data.data);
        context.commit('setStatusLogs', result.data.data)
        return result.data.data
      }else{
        context.commit('setStatusLogs', [])
        return []
      }
    },
    async createStatusLog(context, {type, action}){
      const url = `${apiUrl}/status-logs`
      const result = await axios.post(url, {
        data: {
          attributes: {
            statusLogType: type,
            action
          }
        }
      })

      // console.log('CREATED STATUS LOG', result.data.data);

      context.commit('addStatusLog', result.data.data);

    },

    // CAMPAIGN LOGS
    async createCampaignLogs(context, {status, campaignId}) {
      const url = `${apiUrl}/campaignlogs`
      const result = await axios.post(url, {
        data: {
          attributes: {
            status,
            campaignId
          }
        }
      })
      return result.data
    },
  },
  mutations: {
    setLoginLogs(state, loginLogs){
      loginLogs.data.sort(function(a, b) { 
        return b.id - a.id;
      });
      state.loginLogs = loginLogs
    },
    setTooltips(state, tooltips){
        state.tooltips.push(tooltips)
    },
    setStatusLogs(state, statusLogs){
      state.statusLogs = statusLogs
    },
    addStatusLog(state, statusLog){
      state.statusLogs.push(statusLog);
    }
  },
  getters: {
    loginLogs: state => state.loginLogs,
    tooltips: state => state.tooltips,
    statusLogByName: state => name => state.statusLogs.find(statusLog => {
      if(statusLog.attributes && statusLog.attributes && statusLog.attributes.statusLogType && statusLog.attributes.statusLogType.name === name){
        return statusLog
      }
      return false
    })
  }
}