import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {format} from "date-fns";

export default {
  state: () => ({
    admissionsAgents: []
  }),
  actions: {
    async fetchAdmissionsAgent(context, facilityId){
      try{
        const url = `${apiUrl}/admissions-agent/${facilityId}`
        const result = await axios.get(url);
        return result.data;
      }catch(e){
        if(e.response.status === 404){
          return null;
        }
        console.error(e)
      }

    },
    async createAdmissionsAgent(context, {formData, facilityId}) {
      const url = `${apiUrl}/admissions-agent/${facilityId}`
      const result = await axios.post(url, formData)
     // console.log('the big result', result);
    },

    async submitAdmissionsAgent(context, {facilityId, formData}){
      // let phone = formData.phone.replace(/[^0-9.]/g, "").toString()
      // phone = `+1${phone}`

      const fd = new FormData();
      fd.append('firstName', formData.firstName);
      fd.append('lastName', formData.lastName);
      fd.append('email', formData.email);
      fd.append('presetPhoto', formData.presetPhoto);
      // fd.append('phone', phone );
      if(formData.selectedFile){
        fd.append('photo', formData.selectedFile, formData.selectedFile.name)
        fd.append('fileName', formData.selectedFile.name);
      }

      // console.log('FORM DATA', fd);

      await context.dispatch('createAdmissionsAgent', {formData: fd, facilityId})
    }



  },
  mutations: {

  },
  getters: {

  }
}