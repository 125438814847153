<template>
    <div v-if="firstTimeEditAdmin" class="md-dialog tooltip">
        <template v-if="step==1">
            <div class="container step2">
                <div class="close" @click="closeTooltip"><md-icon>close</md-icon></div>
                <md-dialog-title v-if="!mobile">Customize your budget anytime</md-dialog-title>
                <md-dialog-title v-if="mobile">Customize your budget</md-dialog-title>
                <md-dialog-content>
                    Easily tailor your monthly budget to meet your specific goals. Customize the amount you want to allocate to your campaign.
                </md-dialog-content>
                <div class="arrow-down"></div>
                
                <span v-if="!checkCampaignsIsPremium" class="steps">{{ step }}/2</span>
                <span v-else class="steps">{{ step }}/2</span>

                <md-dialog-actions>
                    <md-button class="active" v-if="step<stepsTotal" @click="step=2">Next</md-button>
                    <md-button class="active" v-if="step==stepsTotal" @click="closeTooltip">End Tour</md-button>
                </md-dialog-actions>
            </div>
        </template>
        <template v-if="step==2">
            <div class="container step4">
                <div class="close" @click="closeTooltip"><md-icon>close</md-icon></div>
                <md-dialog-title>Detailed insights</md-dialog-title>
                <md-dialog-content>
                    View up-to-date campaign data and easily monitor performance. Here you can see a complete log of all interactions with your listing.                
                </md-dialog-content>
                <div class="arrow-left"></div>
                <div class="arrow-up"></div>
                <span class="steps">{{ step }}/{{ stepsTotal }}</span>
                <md-dialog-actions>
                    <md-button class="secondary" v-if="step<=stepsTotal && step>1" @click="step = 1">Back</md-button>
                    <md-button class="active" @click="closeTooltip">End Tour</md-button>
                </md-dialog-actions>
            </div>
        </template>       
    </div>
</template>
<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions} from 'vuex'

    export default{
        name: 'GoPremiumTooltipFunnel',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
                step:1,
                stepsTotal: 2,
                mobile: false
            }
        }, 
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        mounted() {
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })

            this.$root.$on('GoPremiumTooltipFunnel', () => {
                window.scrollTo(0, 0);
                if(this.receivingTooltip){
                    this.firstTimeEditAdmin = false
                }else{
                    this.firstTimeEditAdmin = true
                }
                
            })
        },
        computed: {
             ...mapGetters([
                'receivingTooltip',
                 'checkCampaignsIsPremium'
            ]),
        },
        watch:{
            firstTimeEditAdmin(newVal, oldVal){
                if(this.receivingTooltip){
                    this.firstTimeEditAdmin = false
                }
            }
        },
        methods: {
            closeTooltip() {
                this.firstTimeEditAdmin = false;
                this.$store.commit('setGoPremiumFunnelTooltip', true);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-dialog {
        overflow: visible;
        top: 0px;
        width: 100%;
        max-width: 100%;
        min-width: unset;

          .container{
            width: 440px;
            height: 220px; 
            background-color:var(--md-theme-default-primary);
            color: #fff;
            padding:4px;
            padding-bottom: 10px;
            text-align: left;
            overflow: visibe;
            padding-top: 22px;
            overflow: visible;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

            .md-dialog-actions{
                position: absolute;
                right: 0px;
                top: 158px;
            }
            .md-dialog-content{
                padding-top: 10px;
            }
        }
         .close{
            color:#fff;
            position: absolute;
            right: 20px;
            top:18px;
            font-size: 24px;
            .md-icon{
                color: #fff;
            }
            :hover{
                cursor: pointer;
            }
        }
        .md-dialog-content a{
            color: #fff;
        }
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color:#fff;
        }
        .secondary{
            color: #9fc1f9;
        }

        .md-dialog-actions{
            padding-right: 14px;
        }
        .arrow-up {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-bottom: 5px solid var(--md-theme-default-primary);
        }
        .arrow-down {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            
            border-top: 10px solid var(--md-theme-default-primary);
            position: absolute;
            top:220px;
            right: 20px
        }
        .arrow-right {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            
            border-left: 10px solid var(--md-theme-default-primary);
            position: absolute;
            top:58px;
            right: -10px
        }
        .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            
            border-right: 10px solid var(--md-theme-default-primary);
            margin-left: -14px;
            position: absolute;
            top: 31px;
        }
        .step1{
            top:78px;
             left: 10px;
            position: absolute;
            z-index: 6;
            .arrow-left{
                top:0px;
            }
        }
        .step2{
            top:128px;
            right: 59.5%;
            position: absolute;
            z-index: 6;
        }
        .step3{
            top:97px;
            right: 16%;
            position: absolute;
            z-index: 6;
        }

         @media only screen and (max-width: 1500px) {
            .step2{
                top:128px;
                right: 52%;
            }
        }
         .step4{
            top:77px;
            left: 10px;
            position: absolute;
            z-index: 6;
            .arrow-left{
                top: 68px;
            }
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-dialog .arrow-left, .md-dialog .arrow-right{
            display: none;
        }
        .md-dialog .container{
            top:76px;
            left: unset;
            margin: 0;
            width: unset;
            right: unset;
            margin-left: 14px;
            margin-right: 14px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
            
        }

        .md-dialog .step1{
             border-top-left-radius: 0;
        }
        .md-dialog .step4{
       
            border-top-left-radius: 0px;
        }
        .md-dialog .step2{
            top: 188px;
            .arrow-down{
                left: 240px;
            }
        }
        .md-dialog .close{
            top: 7px;
            right: 6px;
        }
        .md-dialog .arrow-up {
            width: 0;
            height: 0;
            border-right: 10px solid transparent!important;
            border-left: 10px solid transparent!important;
            border-bottom: 10px solid var(--md-theme-default-primary)!important;
            position: absolute;
            top: -10px;
            left: 0px;
        }
  
    }
</style>