<template>
    <form novalidate @submit.prevent="attemptSubmit" v-show="information">
        <md-card>
            <md-card-content>
                <div class="md-layout md-gutter md-size-100">
                    <div class="md-layout-item md-size-100">

                        <table>
                            <!-- SELECT ALL -->
                            <tr v-if="bulk" class="select-all-row">
                                <td>
                                    <md-checkbox v-model="selectAll" ></md-checkbox>
                                </td>
                                <td>
                                    <div class="selectAll">
                                        Select all properties
                                    </div>
                                </td>
                            </tr>
                          <tr v-if="bulk">
                            <td></td>
                          </tr>
<!---->
                            <!-- PHONE -->
                            <tr>
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.phone" ></md-checkbox>
                                </td>
                                <td>
                                    <div :class="{'md-invalid': showMdInvalidClass($v.information.phone, submitted)}" class="form-group">
                                        <label for="facility-phone">Phone</label>
                                        <input
                                                type="tel"
                                                name="facility-phone"
                                                id="facility-phone"
                                                v-model="information.phone"
                                                v-mask="'(###) ###-####'"
                                                placeholder="Enter phone number"
                                                :disabled="!submitFields.phone"
                                        />
                                        <span class="md-error" v-if="!$v.information.phone.required && showMdInvalidClass($v.information.phone, submitted)"><md-icon class="error-icon">error</md-icon>Phone is required</span>
                                        <span class="md-error" v-else-if="!$v.information.phone.min && showMdInvalidClass($v.information.phone, submitted)"><md-icon class="error-icon">error</md-icon>Phone is too short {{ submitted}}</span>
                                    </div>
                                </td>
                            </tr>

                            <!-- WEBSITE -->
                            <tr>
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.website"></md-checkbox>
                                </td>
                                <td>
                                    <div :class="{'md-invalid': showMdInvalidClass($v.information.website, submitted)}" class="form-group">
                                        <label for="facility-website">Website</label>
                                        <input
                                                name="facility-website"
                                                id="facility-website"
                                                v-model="information.website"
                                                placeholder="https://"
                                                @input="formChanged=true"
                                                :disabled="!submitFields.website"
                                        />
                                        <span
                                                class="md-error"
                                                v-if="!$v.information.website.required && showMdInvalidClass($v.information.website, submitted)">
                                                                    <md-icon class="error-icon">error</md-icon>Website is required
                                                                </span>
                                        <span
                                                class="md-error"
                                                v-else-if="!$v.information.website.min && showMdInvalidClass($v.information.website, submitted)"
                                        >
                                                                    <md-icon class="error-icon">error</md-icon>Website is too short
                                                                </span>
                                        <span
                                                class="md-error"
                                                v-else-if="!$v.information.website.url && showMdInvalidClass($v.information.website, submitted)"
                                        >
                                                                    <md-icon class="error-icon">error</md-icon>Website must be a valid url
                                                                </span>
                                    </div>
                                </td>
                            </tr>

                            <!--RECEIVING PHONE NUMBER -->
                            <template v-if="showReceivingPhone">
                                <tr>
                                    <td v-if="bulk">
                                        <md-checkbox v-model="submitFields.receivingNumber" ></md-checkbox>
                                    </td>
                                    <td>
                                        <div :class="{'md-invalid': showMdInvalidClass($v.information.receivingNumber, submitted)}" class="form-group receiving">
                                            <label for="facility-website">Receiving number</label>
                                            <input
                                                    type="tel"
                                                    name="facility-receiving-number"
                                                    id="facility-receiving-number"
                                                    v-model="information.receivingNumber"
                                                    v-mask="'(###) ###-####'"
                                                    placeholder="Enter phone number"
                                                    :disabled="!submitFields.receivingNumber"
                                            />

                                            <!---       <span class="md-error" v-if="!$v.information.receivingNumber.required"><md-icon class="error-icon">error</md-icon>Receiving number is required</span>
                                                   <span class="md-error" v-else-if="!$v.information.receivingNumber.min"><md-icon class="error-icon">error</md-icon>Receiving number is too short</span> -->
                                            <div @mouseover="showNumberTooltip = true" @mouseout="showNumberTooltip = false" class="iconContainer"><md-icon class="icon">help_outline</md-icon></div>
                                            <div class="tooltipbox number" v-show="showNumberTooltip">A receiving number is a phone number that Rehab.com will forward calls to when a user calls a tracking number. It can be a mobile number, a landline, or the number for your call center. If you have a phone number that people are calling today, it can be a receiving number.</div>
                                        </div>
                                    </td>
                                </tr>
                            </template>


                            <!--RECEIVING URL -->
                            <template v-if="showReceivingUrl">
                                <tr>
                                    <td v-if="bulk">
                                        <md-checkbox v-model="submitFields.receivingUrl" ></md-checkbox>
                                    </td>
                                    <td>
                                        <div :class="{'md-invalid': showMdInvalidClass($v.information.receivingUrl, submitted)}" class="form-group receiving">
                                            <label for="facility-receiving-url">Receiving URL</label>
                                            <input
                                                    name="facility-receiving-url"
                                                    id="facility-receiving-url"
                                                    v-model="information.receivingUrl"
                                                    placeholder="https://"
                                                    @input="formChanged=true"
                                                    :disabled="!submitFields.receivingUrl"
                                            />
                                            <!---       <span class="md-error" v-if="!$v.information.receivingUrl.required"><md-icon class="error-icon">error</md-icon>Receiving URL is required</span>
                                                <span class="md-error" v-else-if="!$v.information.receivingUrl.min"><md-icon class="error-icon">error</md-icon>Receiving URL is too short</span>-->
                                            <div @mouseover="showUrlTooltip = true" @mouseout="showUrlTooltip = false" class="iconContainerURL"><md-icon class="icon">help_outline</md-icon></div>
                                            <div class="tooltipbox url" :class="{'noNumberPadding': !showReceivingPhone}" v-show="showUrlTooltip">The landing page is the page on your website where users land after clicking-through to your website.</div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </table>

                        <template v-if="!bulk">
                            <!-- ADDRESS LINE 1 -->
                            <div class="form-group" :class="{'md-invalid': showMdInvalidClass($v.information.addressLineFirst, submitted)}">
                                <label for="facility-addressLineFirst">Address</label>
                                <input
                                        name="facility-addressLineFirst"
                                        id="facility-addressLineFirst"
                                        v-model="information.addressLineFirst"
                                        placeholder="Address line 1"
                                        @input="formChanged=true"
                                />
                                <span
                                        class="md-error"
                                        v-if="!$v.information.addressLineFirst.required && showMdInvalidClass($v.information.addressLineFirst, submitted)"
                                ><md-icon class="error-icon">error</md-icon>Address line 1 is required</span>
                                <span
                                        class="md-error"
                                        v-else-if="!$v.information.addressLineFirst.min && showMdInvalidClass($v.information.addressLineFirst, submitted)"
                                ><md-icon class="error-icon">error</md-icon>Address line 1 is too short</span>
                            </div>

                            <!-- ADDRESS LINE 2 -->
                            <div class="form-group">
                                <label for="facility-addressLineSecond"></label>
                                <input
                                        name="facility-addressLineSecond"
                                        id="facility-addressLineSecond"
                                        v-model="information.addressLineSecond"
                                        placeholder="Address line 2"
                                        @input="formChanged=true"
                                />
                            </div>

                            <div class="md-layout location">

                                <!-- CITY -->
                                <div class="md-layout-item city">
                                    <multiselect
                                            :class="{ 'multi-select-error': !information.city && submitted }"
                                            id="city"
                                            name="city"
                                            v-model="information.city"
                                            :options="cityOptions"
                                            label="label"
                                            :multiple="false"
                                            :searchable="true"
                                            :internal-search="false"
                                            track-by="id"
                                            :close-on-select="true"
                                            :limit="1"
                                            @input="formChanged = true"
                                            @search-change="getCities"
                                            @select="selectCity"
                                            placeholder="City"
                                    >
                                      <template slot="noOptions" slot-scope="props">Search for a city.</template>
                                      <template slot="noResult" slot-scope="props">No cities found.</template>
                                    </multiselect>

                                    <!--                                                                    <span class="custom-error" v-if="!$v.information.cityId.required && showMdInvalidClass($v.information.cityId, submitted)"><md-icon class="error-icon">error</md-icon>City is required</span>-->
                                    <span class="custom-error" v-if="!information.city && submitted"><md-icon class="error-icon">error</md-icon>City is required</span>
                                </div>
                                <!-- ZIP -->
                                <div class="md-layout-item zip">
                                    <div :class="{'md-invalid': showMdInvalidClass($v.information.postalCode, submitted)}">

                                        <input
                                                name="facility-postalCode"
                                                id="facility-postalCode"
                                                v-model="information.postalCode"
                                                placeholder="ZIP"
                                                :maxlength="5"
                                                @input="formChanged = true"
                                        />
                                        <span class="zip-error" v-if="!$v.information.postalCode.required && submitted"><md-icon class="error-icon">error</md-icon>Zip code is required</span>
                                        <span class="zip-error" v-else-if="!$v.information.postalCode.min && submitted"><md-icon class="error-icon">error</md-icon>Zip Code too short</span>
                                    </div>
                                </div>
                            </div>
                        </template>


                    </div>
                </div>
            </md-card-content>
           <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}">
                <md-button
                        v-if="bulk"
                        class="customGrey"
                        @click.prevent="$emit('cancel')"
                >Cancel</md-button>
                <md-button
                        class="md-raised md-primary"
                        type="submit"
                        :disabled="loading || !formChanged"
                >Save</md-button>
            </md-card-actions>
        </md-card>
    </form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import {
        required,
        minLength,
        maxLength,
        requiredIf,
        url
    } from 'vuelidate/lib/validators'
    import customValidationMixin from '../../../mixins/validationMixin'
    import Multiselect from 'vue-multiselect'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "ContactForm",

        components: {Multiselect},

        mixins: [validationMixin, customValidationMixin],

        data() {
            return {
                cityOptions: [],
                formChanged: false,
                initialLoad: false,
                information: {
                    externalId: null,
                    name: null,
                    route: null,
                    addressLineFirst: null,
                    addressLineSecond: null,
                    city: null,
                    cityId: null,
                    state: null,
                    postalCode: null,
                    phone: null,
                    receivingNumber: null,
                    website: null,
                    receivingUrl: null
                },
                showNumberTooltip: false,
                showUrlTooltip: false,
                submitted: false,
                stateOptions: [],
                selectAll: false,
                submitFields: {
                    phone: true,
                    website: true,
                    receivingNumber: true,
                    receivingUrl: true
                }

            }
        },

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            bulk: {
                type: Boolean,
                default: false
            },
            bulkFacilities: {
                type: Array,
                default: () => []
            },
            originalInformation: {
                type: Object,
                default: function() {
                    return {
                        externalId: null,
                        name: null,
                        route: null,
                        addressLineFirst: null,
                        addressLineSecond: null,
                        city: null,
                        cityId: null,
                        state: null,
                        postalCode: null,
                        phone: null,
                        receivingNumber: null,
                        website: null,
                        receivingUrl: null
                    }
                }
            }
        },

        computed: {
            ...mapGetters({
                checkCampaignsIsPremium: "checkCampaignsIsPremium",
                campaigns: "campaigns",
                callProduct: 'callProduct',
                clickProduct: 'clickProduct'
            }),
            showReceivingPhone() { // ONLY SHOW RECEIVING URL/PHONE IF ONE OF THE SELECTED FACILITIES HAS AN ACTIVE SUBSCRIPTION
                if(this.bulk) {
                    for(const facilityId of this.bulkFacilities){
                        // console.log('FACILITY CAMPAIGNS', JSON.parse(JSON.stringify(facilityCampaigns)));
                        if(this.campaigns.find(campaign =>
                            parseInt(campaign.attributes.facilityId) === parseInt(facilityId) &&
                            campaign.attributes.status &&
                            parseInt(campaign.attributes.productId) === parseInt(this.callProduct.id)
                        )){
                            this.$emit('showReceivingPhone', true)
                            return true;
                        }
                    }
                }else {
                    if(this.campaigns.find(campaign =>
                        parseInt(campaign.attributes.facilityId) === parseInt(this.$route.params.facilityId) &&
                        campaign.attributes.status &&
                        parseInt(campaign.attributes.productId) === parseInt(this.callProduct.id)
                    )) {
                        return true;
                    }
                }

                this.$emit('showReceivingPhone', false)
                return false
            },

            showReceivingUrl() { // ONLY SHOW RECEIVING URL/PHONE IF ONE OF THE SELECTED FACILITIES HAS AN ACTIVE SUBSCRIPTION
                if(this.bulk) {
                    for(const facilityId of this.bulkFacilities){
                        if(this.campaigns.find(campaign =>
                            parseInt(campaign.attributes.facilityId) === parseInt(facilityId) &&
                            campaign.attributes.status &&
                            parseInt(campaign.attributes.productId) === parseInt(this.clickProduct.id)
                        )){
                            this.$emit('showReceivingUrl', true)
                            return true;
                        }
                    }
                }else {
                    if(this.campaigns.find(campaign =>
                        parseInt(campaign.attributes.facilityId) === parseInt(this.$route.params.facilityId) &&
                        campaign.attributes.status &&
                        parseInt(campaign.attributes.productId) === parseInt(this.clickProduct.id)
                    )) {
                        return true;
                    }
                }

                this.$emit('showReceivingUrl', false)

                return false
            }
        },

        validations() {
            if(this.bulk){
                return {
                    information: {
                          phone: {
                              min: minLength(14)
                          },
                          website: {
                              min: minLength(3),
                              url
                          },
                          receivingNumber: {
                              min: minLength(14)
                          },
                          receivingUrl: {
                              url
                          },
}
                }
            }

            else{
                return {information: {
                        phone: {
                            required,
                            min: minLength(14)
                        },
                        website: {
                            required,
                            min: minLength(3),
                            url
                        },
                        receivingNumber: {
                            requiredIf: requiredIf(function() {
                                return this.showReceivingPhone
                            }),
                        },
                        receivingUrl: {
                            requiredIf: requiredIf(function() {
                                return this.showReceivingUrl
                            }),
                            url
                        },
                        addressLineFirst: {required, min: minLength(3)},
                        cityId: {required},
                        state: {required},
                        postalCode: {required, min: minLength(5), max: maxLength(5)},
                    }}
            }
        },

        mounted() {
            this.information = Object.assign(this.information, JSON.parse(JSON.stringify(this.originalInformation)))
            this.formChanged = false;

            if(this.bulk){
                for(const submitField in this.submitFields){
                    this.submitFields[submitField] = false;
                }
            }

            setTimeout(() => {
              this.initialLoad = true;
            }, 10)

        },

        watch: {
            selectAll(newVal, oldVal) {
                if( newVal === oldVal) return;

                for(let submitField in this.submitFields){
                    this.submitFields[submitField] = newVal
                }

            },
            "information.receivingNumber"(newVal, oldVal) {
                if(this.initialLoad){
                    this.formChanged = true
                }
            },
            "information.phone"(newVal, oldVal) {
                if(this.initialLoad){
                    this.formChanged = true
                }
            }
        },

        methods: {
            ...mapActions([
                'searchCities',
                'nameWithState',
                'getCities'
            ]),
            async getCities(search){
                if(search && search.length >= 3){
                    // console.log('CITY SEARCH QUERY', search);
                    const result = await this.searchCities(search);
                    for(const city of result){
                        city.label = await this.nameWithState(city)
                    }
                    this.cityOptions = result;
                    // console.log('CITY OPTIONS:', JSON.parse(JSON.stringify(this.cityOptions)))
                    return this.cityOptions
                }
            },

            selectCity(city){
                // console.log('CITY SELECTED', city);
                this.information.city = city.name
                this.information.cityId = city.id

                const stateRoute = city.route.split('/')[0];
                const foundState = this.stateOptions.find(state => state.route === stateRoute);
                //console.log('found state', foundState, stateRoute)
                if(foundState){
                    this.information.state = foundState.id
                }
                this.formChanged = true;
            },

            validateForm(){
                this.$v.information.$touch()
                if(this.$v.information.$error){
                    console.error('ERROR VALIDATION FORM', JSON.parse(JSON.stringify(this.$v.information)));
                    return false
                }
                return true;
            },

            attemptSubmit(formData) {
                this.submitted = true
                if(this.validateForm()){
                    this.$emit('submit', {
                        information: this.information,
                        submitFields: this.submitFields
                    })
                }
            }
        }



    }
</script>

<style lang="scss" scoped>
    .actions{
        width: 640px;
        padding-bottom: 20px;
        padding-top: 0px;
        
    }

    .md-layout-item img{
        height: 80px;
    }

    .delete {
        height: 34px;
        width: 35px;
        position: absolute;
        right:0;
        top: 0;
    }

    .icon{
        display: inline;
        .md-icon{
            color: #000!important;
            font-size: 20px!important;
        }
        :hover{
            cursor: pointer;
        }
    }

    .md-card{
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        box-shadow: none;
    }

    .md-menu-content.md-select-menu{
        top: 413px!important;
        width: 14.5%!important;
    }

    select, input{
        margin-left: 160px;
        cursor: pointer;
        outline: none;
        border: 1px solid #eee;
        height: 46px;
        border-radius: 5px;
        padding-left: 16px;
        -webkit-text-fill-color: unset !important;
        max-width: 456px;
        width: 100%;
        widows: 100%;
        /*margin-bottom: 26px;*/
        font-size: 15px;
        color: #43484f;
        padding-right: 26px;
        z-index: 999;
    }

    input{
        cursor: auto;
        -webkit-appearance: none;
    }

    .multiselect__tags {
        min-height: 46px;

    }

    .state{
        display: none;
    }

    .multiselect {
        //width: 349px !important;
        padding-bottom: 0;
        // padding-right: 20px;
        margin-left: 160px;
        .input{
            height: 48px;
        }
    }

    .multiselect__tags{
        min-height: 48px;
    }

    .iconContainer, .iconContainerURL{
        left: 125px;
        position: relative;
        top: -73px;
        margin-left: -2px;
        width: 20px;

        .icon{
            color: #c6c6c6 !important;

            &:hover{
                cursor: pointer;
            }
            font-size: 18px !important;
        }

    }

    .tooltipbox{
        position: absolute;
        z-index: 6;
        background-color: #fff;
        width: 220px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;

    }

    .url{
        top: 276px;
        left: 12%;
        height: 86px;
        width: 320px;
        z-index: 100;
    }

    .zip-error, .custom-error{
        color: #d93025!important;
        display: block !important;
        position: relative !important;
        left: -5px;
        padding-top: 0 !important;
        padding-top: 46px;
        font-size: 12px;
        .error-icon{
            font-size: 16px!important;
            color: #d93025!important;
        }
    }
    .custom-error {
        margin-left: 160px;
    }

    .md-card-actions {
        padding-top: 0px;
    }



    .multiselect__tags {
        min-height: 46px;

    }

    .state{
        display: none;
    }

    .admin .multiselect {
        width: 349px;
        padding-bottom: 0;
        margin-right: 20px;
        margin-left: 160px;
        .input{
            height: 48px;
        }
    }

    .multiselect__tags{
        min-height: 48px;
    }

    .iconContainer{
        left: 125px;
        position: relative;
        top: -47px;
        margin-left: -2px;
        width: 20px;

        .icon{
            color: #c6c6c6 !important;

            &:hover{
                cursor: pointer;
            }
            font-size: 18px !important;
        }

    }

    label{
        display: block;
    }

    .iconContainerURL{
        left: 102px;
        position: relative;
        top: -47px;
        margin-left: -2px;
        width: 20px;

        .icon{
            color: #c6c6c6 !important;

            &:hover{
                cursor: pointer;
            }
            font-size: 18px !important;
        }
    }


    .organicFilter{
        padding-right: 22px !important;
    }

    .tooltipbox{
        position: absolute;
        z-index: 6;
        background-color: #fff;
        width: 220px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;

    }

    .number{
        top: 195px;
        left: 19%;
        height: 132px;
        width: 420px;
        z-index: 100;
    }

    .url{
        top: 260px;
        left: 16%;
        height: 86px;
        width: 320px;
        z-index: 100;
    }

    .zip-error, .custom-error{
        color: #d93025!important;
        display: block !important;
        position: relative !important;
        left: -5px;
        padding-top: 0 !important;
        padding-top: 46px;
        font-size: 12px;
        .error-icon{
            font-size: 16px!important;
            color: #d93025!important;
        }
    }
    .custom-error {
        margin-left: 160px;
    }

    .md-card-actions {
        padding-top: 28px;
    }

    /*.receiving:last-of-type {*/
    /*    margin-bottom: 0*/
    /*}*/

    .actionsBulk{
        padding-bottom: 0px;
        padding-top: 0;
        margin-right: 2px;
      padding-right: 18px;
    }

    .noNumberPadding{
        top: 175px;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .admin .receiving{
            height: 71px;
        }
        .iconContainer, .iconContainerURL{
            top: -85px;
        }
        .number{
            top: 241px;
            left: 2.5%;
            height: unset;
            width: 95%;
            z-index: 100;
        }
        .url{
            top: 337px;
            left: 8%;
            height: 86px;
            width: 320px;
            z-index: 100;
        }

        .noNumberPadding{
            top: 245px;
        }
    }
</style>
