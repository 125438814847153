<template>
    <div>
      <md-table md-card class="md-table-mobile-padding">
        <md-table-row>
          <md-table-head md-numeric :class="{'align-left': !mobile}" v-if="!mobile">ID</md-table-head>
          <md-table-head :class="{'align-left': !mobile}">Email</md-table-head>
          <md-table-head :class="{'align-left': !mobile}">Registration date</md-table-head>
          <md-table-head :class="{'align-left': !mobile}" v-if="!mobile">Last login date </md-table-head>
          <md-table-head :class="{'align-left': !mobile}" v-else>Last login </md-table-head>
        </md-table-row>
        <md-table-row class="body-row">
          <md-table-cell :class="{'align-left': !mobile}" v-if="!mobile" md-numeric>#{{ user | maskId }}</md-table-cell>
          <md-table-cell :class="{'align-left': !mobile}">{{ user.email || 'None' }}</md-table-cell>
          <md-table-cell :class="{'align-left': !mobile}">{{ user.createdAt | date }}</md-table-cell>
          <md-table-cell :class="{'align-left': !mobile}">{{ user.lastLoginAt |date }}</md-table-cell>
        </md-table-row>
      </md-table>

    </div>
</template>

<script lang="javascript">
    import Fetch from './api/Fetch.vue'
    import{format} from 'date-fns'
    import {mapGetters} from "vuex";
    export default {
        components: {
            'api-fetch': Fetch
        },

        data() {
            return {
                models: {},
                mobile: false
            }
        },

        computed: {
          ...mapGetters([
            'user'
          ]),
        },

        filters: {
            date(date){
                return format(new Date(date), 'MM/DD/YYYY')
            },
            maskId(user){ // ADD A PORTION OF THE REVERSED UNIX DATESTAMP TO THE ID SO THAT IT IS 6 CHARACTERS LONG.
              const unixDate = Math.round((new Date(user.createdAt)).getTime() / 1000);
              const idLength = `${user.externalId}`.length;
              const additionalCharactersNeeded = 6 - idLength;
              const stringUnix = ("" + unixDate).split("").reverse().join("");
              return `${stringUnix.substring(1, additionalCharactersNeeded + 1)}${user.externalId}`
            },
        },
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },

        watch: {
          windowWidth(value, oldVal) {
            this.mobile = value <= 760;
          }
        },
    }
</script>

<style scoped>
   .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .align-right {
        text-align: right;
    }
    .align-left {
        text-align: left;
    }
</style>