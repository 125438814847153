<template>
    <md-dialog :md-active.sync="firstTimeEditAdmin" class="welcome">
        <template>
            <div class="step1">
                <img src="/images/gopremium/unite.svg">
                <md-dialog-title class="title">Congratulations!<br>Your campaign is live.</md-dialog-title>

   
                <md-dialog-actions>
                    <md-button class="active center" @click="start()">AWESOME</md-button>
                </md-dialog-actions>
            </div>
        </template>
    
    </md-dialog>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default{
        name: 'GoPremiumThanks',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
            }
        },
        computed: {
            ...mapGetters([
                'enabledCampaigns',
                'activeSubscriptions'
            ])
        },
        methods :{
             ...mapMutations([
                'setBlur'
            ]),
            start(){
                this.firstTimeEditAdmin = false
                this.setBlur(true)
                this.$emit('awesome');
                this.$emit('moveReceivingTooltip', null);
                if(!this.enabledCampaigns.length){
                    this.$root.$emit('GoPremiumTooltipFunnel')
                }
            }
        },
        mounted() {
            this.$root.$on('gopremiumthanks', () => {
                this.firstTimeEditAdmin = true
                this.setBlur(true)
            })
        },
        watch: {
            'firstTimeEditAdmin'(newVal, oldVal) {
                if(newVal == false){
                    this.setBlur(false)
                    this.$root.$emit('receivingTooltip')
                    this.$router.push({ name: 'dashboard.premium' })
                }else{
                    //this.setBlur(false)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .md-dialog.md-theme-default{
        background-color:unset;
    }
    .buttons{
        position: absolute;
        top:159px;
    }
    .title{
        padding-top: 30px!important;
        font-size: 24px!important;
    }
    .md-dialog {
       
        img{
            height: 300px;
            margin-top: 30px;
        }

        .close{
            color: white;
            position: absolute;
            right: 34px;
            top:18px;
            font-size: 24px;
        }
        .md-dialog-content a{
            color: white;
        }
        .md-dialog-content{
            padding-left: 50px;
            padding-right: 50px;
        }
        .step1{
            background-color: #6955FF;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            margin-top: 40px;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step2{
            background-color: #50C878;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

             img{
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
         .step3{
            background-color: #FFBD44;
            color: #000;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step4{
            background-color: #6955FF;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .md-dialog-title{
            display: block;
            padding-top: 10px;
            line-height: 30px;
        }
    
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color: white;
        }
        .secondary{
            color: #9fc1f9;
        }
        .md-dialog-actions{
            padding-right: 14px;
            position: absolute;
        }
        .center{
            top: 15px;
            left:260px;
            position: absolute;
            font-size: 16px;
            color: #fff;
            .md-button-content{
                text-transform: capitalize;
            }
            
        }
        .back{
            position: absolute;
            left: -78px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .forward{
            position: absolute;
            right: -679px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .circle
        {
            border-radius: 50%;
            width: 40px;
            height: 40px; 
            background-color: rgba(0,0,0,0.4);
            min-width: unset; 
            .md-icon{
                color: #fff;
            }
        }
        .md-icon{
            font-size: 20px!important;
        }

        .steps{
            position: absolute;
            top: 537px;
            left: 50%;
            text-align: center;
            overflow: visible;
            margin-left: -58px;
   
            .circle{
                border-radius: 50%;
                width: 10px;
                height: 10px; 
                background-color: rgba(0,0,0,0.4);
                min-width: unset;
                display: inline-block;
                margin-right: 8px;
                opacity: 0.4;
            }
            .active{
                 background-color: #fff;
                  opacity: 1;
            }
        }

    }

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-dialog{
           
            .close{
                margin-right: -28px;
                margin-top: -11px;
                display: block;
                color: #fff;
                i{
                    color: #fff;
                }
            }
            .step1, .step2, .step3, .step4{
                height: 100%!important;
                margin: 0;
                padding: 0;
                border-radius: 0;
                width: 100%;
                padding-top: 20px;
            }
            img{
                height: 225px;
            }
            .step4{
                .md-dialog-actions{
                    width: 100%;
                }
                .center{
                    left: 50%;
                    margin-left: -41px;
                }
            }
        }

        .steps, .md-dialog .md-dialog-actions{
            position: relative!important;
            top:0!important;
            margin-left: auto!important;
            margin-right: auto!important;
            width: 100%;
            left: unset!important;
            padding: 0px!important;
        }

         .md-dialog-actions{
             margin-top: 22px;
         }

         .md-dialog .center{
            top: 30px;
            left:50%;
            margin-left: -50px;
            position: absolute;
            font-size: 16px;
            .md-button-content{
                text-transform: capitalize;
            }
            
        }
    
    }
</style>