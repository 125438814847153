const stripeProdKey =
  "pk_live_51EOShkAzE12DEVAHykaWh02LX00sbaJngWYz1oa78XwFtLdQI16o9djIDY1CIphVCwp2nynd7ZRm57muwRMPtb2R00jt95utDG";
const stripeTestKey =
  "pk_test_51EOShkAzE12DEVAHI8tjKCIVV3piJpEq1O6AQi62XxZdzWQmB73zbAP9ityetGz53YZZ03uumHfKG69xDj3SwTHM00ZiuIVHM6";

// DEV DEFAULTS
let apiUrl = "http://localhost:3000/api/v1";
let stripeKey = stripeTestKey;
if (process.env.NODE_ENV === "production") {
  // LIVE ENVIRONMENT
  apiUrl = "https://connect-api.rehab.com/api/v1";
  stripeKey = stripeProdKey;

  // STAGING SERVER
  if (window.location.origin === "https://connect-staging.rehab.com") {
    apiUrl = "https://connect-staging-api.rehab.com/api/v1";
    stripeKey = stripeTestKey;
  } else if (window.location.origin === "http://localhost:5000") {
    apiUrl = "https://connect-staging-api.rehab.com/api/v1";
    stripeKey = stripeTestKey;
  } else if (window.location.origin === "https://connect-dev.rehab.com") {
    apiUrl = "https://connect-dev-api.rehab.com/api/v1";
    stripeKey = stripeTestKey;
  }
}

module.exports = {
  apiUrl,
  stripe: {
    publicKey: stripeKey,
  },
};
