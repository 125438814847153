<template>
    <div>
        <div class="md-layout admin">
                <div class="md-layout-item md-size-100" v-if="!loading">
                <div class="md-layout">
                    <div class="md-layout-item" :class="{ 'md-size-85':!mobile, 'md-size-100':mobile}">
                        
                                <md-card>
                                    <md-card-content>
                                        <div class="md-layout md-gutter md-size-100">
                                            <div class="md-layout-item">
                                                <div class="select-container">
                                                    <select
                                                            name="request-type"
                                                            id="request-type"
                                                            v-model="requestType"
                                                    >
                                                        <option disabled value="...Choose a request type" selected>...Choose a request type</option>
                                                        <option value="Add a location">Add a location</option>
                                                        <option value="General feedback">General feedback</option>
                                                        <option value="General inquiry">General inquiry</option>
                                                        <option value="Regarding a review">Regarding a review</option>
                                                        <option value="Report a bug">Report a bug</option>
                                                        <option value="Update account">Update account</option>
                                                        <option value="Update listing">Update listing</option>
                                                    </select>
                                                </div>

                                              <div
                                                  class="select-container"
                                                  v-if="requestType === 'Update account'"
                                              >
                                                <select
                                                    name="upgrade-downgrade"
                                                    id="upgrade-downgrade"
                                                    v-model="updateAccountReason"
                                                >
                                                  <option disabled value="...Choose an update" selected>...Choose an update</option>
                                                  <option value="Upgrade">Upgrade</option>
                                                  <option value="Downgrade">Downgrade</option>
                                                </select>
                                              </div>

                                                <md-field>
                                                    <md-textarea  v-if="requestType != 'Add a facility'" v-model="feedbackcopy" id="facility-philosophy" name="facility-philosophy" placeholder="Let us know how we can help. We are always available."></md-textarea>
                                                    <md-textarea  v-else v-model="feedbackcopy" id="facility-philosophy" name="facility-philosophy" placeholder="Please provide the name and website of the facility you would like to add."></md-textarea>
                                                    <span class="md-error"> Feedback copy is required</span>
                                                </md-field>
                                            </div>
                                        </div>
                                    </md-card-content>
                                    <md-card-actions class="actions">
                                        <md-button
                                                class="md-raised md-primary"
                                                type="submit"
                                                @click="submitFeedback"
                                                :disabled="feedbackcopy.length<3"
                                        >Submit</md-button>
                                    </md-card-actions>    
                                </md-card>
                                <md-snackbar
                                        md-position="center"
                                        :md-duration="Infinity"
                                        :md-active.sync="feedback"
                                        md-persistent
                                >
                                    <span>Thank you for the feedback. We will be in touch within 24-hours.</span>
                                    <md-button class="md-primary" @click="feedback = false">Close</md-button>
                                </md-snackbar>
                                <md-snackbar
                                        md-position="center"
                                        :md-duration="Infinity"
                                        :md-active.sync="errorSnack"
                                        md-persistent
                                >
                                    <span>There was an error submitting your feedback. Please email <a href="mailto:support@rehab.com" style="color: white">support@rehab.com</a></span>
                                    <md-button class="md-primary" @click="errorSnack = false">Close</md-button>
                                </md-snackbar>

                    </div>
                </div>
            </div>
            <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading"></md-progress-spinner>
        </div>
    </div>
</template>

<script lang="javascript">
    import config from '../../config.js'
    import { validationMixin } from 'vuelidate'
    import { mapGetters, mapActions } from 'vuex'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'

    export default{
        mixins: [validationMixin],
        name: 'SendFeedback',
        data(){
            return {
                facilityId: null,
                config: config,
                errors: null,
                users: [],
                loading: true,
                feedback: false,
                feedbackcopy: '',
                requestType: '...Choose a request type',
                updateAccountReason: '...Choose an update',
                facility: {},
                mobile: false,
                errorSnack: false,
            }
        },
        computed: {
            ...mapGetters([
                'hubspotContact'
            ])
        },
        async created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            this.users = this.$store.getters.user
            this.facilityId = this.$route.params.facilityId
            this.facilities = this.$store.getters.facilities

            if(this.$route.params.facilityId){
                this.facilities.forEach((item) => {
                    if(item.attributes.facilityId == this.$route.params.facilityId){
                        this.facility = item.attributes
                    }
                })
            }else{
                this.facilities.forEach((item) => {
                    this.facility = item.attributes
                })
            }
            this.loading = false
        },
        mounted(){
        },
        methods: {
            ...mapActions([
                'sendFeedback'
            ]),

            getRequestType() {
              if(this.requestType === 'Update account'){
                return this.updateAccountReason
              }
              return this.requestType
            },

            async submitFeedback()
            {
                try {
                    this.loading = true;
                    const data = {
                        feedback: this.feedbackcopy,
                        requestType: this.getRequestType(),
                        hubspotContact: this.hubspotContact
                    }
                    await this.sendFeedback(data);
                    this.loading = false;
                    this.feedback = true;
                }catch(e){
                    console.error(e);
                    this.loading = false;
                    this.errorSnack = true;
                }
            },
        }
    }

</script>

<style lang="scss" scoped>
    .error-message{
        color: red;
    }
       .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .md-tab{
        padding: 0;

    }
    .actions {
        width: 596px;
        padding-top: 10px;
        .md-primary{
            margin-left: 10px;
        }
        padding-bottom: 20px;
    }
   .md-card{
       box-shadow: none;
   }
    .actions{
        width: 604px
    }

    select{
        cursor: pointer;
        outline: none;
        border: 1px solid #eee;
        height: 46px;
        border-radius: 5px;
        padding-left: 16px;
        -webkit-text-fill-color: unset !important;
        width: 579px;
        margin-bottom: 26px;
        font-size: 15px;
        color: #43484f;
        padding-right: 26px;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98.5%;
        background-position-y: 10px;
    }

    #facility-philosophy{
        width: 300px;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-gutter.md-size-100 .md-layout-item{
            padding-right: 10px!important;
        }

        .md-field .md-textarea{
            
            display: block;
      
            
        }
        #facility-philosophy{
            margin-left: 0;
            width: 100%;
            display: block;
        }
        select{
            width: 100%;
            margin: 0;
            min-width: unset;
            max-width: unset;
            margin-bottom: 20px;
        }
        .md-theme-default{
            height: unset;
        }
        .md-tabs-content{
            min-width: unset;
        }
        .actions{
            width: 100%;
            padding-right: 20px;
            button{
                height: 40px; 
            }
        }
    }
</style>