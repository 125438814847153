<template>
  <transition name="expand">
    <div
      class="subscription-banner"
      v-if="
        activeSubscriptions.length === 0 &&
        !subscriptionBannerDismissed &&
        !welcomeTooltip &&
        !GoPremiumTooltip &&
        !showDialogPremium &&
        (!failedPayments || (failedPayments && !failedPayments.length))
      "
    >
      <div class="image">
        <img :alt="accountOwner.imageAlt" :src="accountOwner.image" />
      </div>
      <div class="flex-content">
        <div class="title" v-if="!mobile">
          Expert Support is Available to You
        </div>

        <div class="message" v-if="!mobile">
          Talk with a Rehab.com Marketing Expert to help advertise your program
          during uncertain times.
        </div>
        <div class="message" v-else>
          Talk with a Rehab Marketing Expert to help advertise your program.
        </div>

        <div class="buttons">
          <!---<a  href="https://meetings.hubspot.com/erin-bigelow/schedule-a-demo-with-erin-bigelow" target="_blank"><md-button class="md-raised md-primary">Schedule Call Now</md-button></a>-->
          <a :href="accountOwner.scheduleLink" target="_blank"
            ><md-button class="md-raised md-primary"
              >Schedule Call Now</md-button
            ></a
          >
          <div class="close" @click="setSubscriptionBannerDismissed(true)">
            <md-icon>close</md-icon>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      mobile: false,
    };
  },
  name: "SubscriptionBanner",
  computed: {
    ...mapGetters([
      "subscriptions",
      "subscriptionBannerDismissed",
      "welcomeTooltip",
      "GoPremiumTooltip",
      "user",
      "failedPayments",
      "hubspotCompanyOwner",
    ]),
    activeSubscriptions() {
      return this.subscriptions.filter(
        (subscription) => subscription.active === true
      );
    },
    accountOwner() {
      let data = {
        image: "/images/people/colby.png",
        imageAlt: "Colby Braun",
        scheduleLink: "mailto:appointments@rehab.com",
      };

      // IF IT'S ERIN, SET ALL VARS TO ERIN APPLICABLE INFO
      if (
        this.hubspotCompanyOwner &&
        this.hubspotCompanyOwner.firstName === "Erin"
      ) {
        data = {
          image: "/images/people/colby.png",
          imageAlt: `${this.hubspotCompanyOwner.firstName} ${this.hubspotCompanyOwner.lastName}`,
          scheduleLink: "mailto:appointments@rehab.com",
        };
      }

      return data;
    },
  },
  props: ["showDialogPremium"],
  methods: {
    ...mapMutations(["setSubscriptionBannerDismissed"]),
    ...mapActions(["fetchHubspotOwner"]),
  },
  created() {
    if (this.windowWidth <= 760) {
      this.mobile = true;
    }
  },
  mounted() {
    this.$watch("windowWidth", (value) => {
      if (value <= 760) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.subscription-banner {
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  min-height: 100px;
  display: inline-flex;
  flex-wrap: nowrap;

  .flex-content {
    width: 100%;
  }

  .title {
    font-size: 15px;
    font-weight: 600;
    height: 24px;
    margin-top: 6px;
    color: #43484f;
  }

  .image {
    width: 5%;
    min-width: 90px;
    margin-top: 0;
    padding-top: 15px;
    margin-left: 20px;
    height: 100%;
    img {
      width: 70px;
    }
  }

  .message {
    color: #43484f;
    display: block;
    font-weight: 400;
    width: 100%;
    text-align: left;
    display: flex;
    height: 21px;
  }

  .buttons {
    display: flex;
    width: 100%;

    .md-button {
      text-transform: none;
      color: #fff;
      height: 34px;
      margin-left: 0;
      min-width: 155px;
      box-shadow: none;
      background: #1860e2 !important;
    }
  }

  .buttons div:last-child {
    margin-left: auto;
    padding-right: 8px;
    margin-top: -44px;
    cursor: pointer;
    .md-icon {
      font-size: 16px !important;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.expand-enter-active {
  transition: height 0.5s ease-in-out;
}

.expand-enter {
  height: 0;
}

@media only screen and (max-width: 760px),
  screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
  screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
  screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .subscription-banner .message {
    height: 40px;
    margin-top: 10px;
    width: 98%;
    padding-right: 13px;
  }
  .subscription-banner {
    height: 104px;
    .image {
      min-width: 78px;
    }
  }
  .subscription-banner .buttons div:last-child {
    padding-right: 2px;
    margin-top: -47px;
  }
  .subscription-banner .image {
    margin-top: 0px;
    padding-top: 10px;
    margin-left: 12px;
    img {
      width: 64px;
    }
  }
}
</style>
