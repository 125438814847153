<template>
    <md-dialog :md-active.sync="firstTimeEditAdmin" class="welcome">
        <template v-if="step==1">
            <div class="step1" v-touch:swipe="swipeHandler" v-touch:tap="swipeHandler">
                <img src="/images/gopremium/connect.svg"/>
                <md-dialog-title>Welcome to Rehab.com</md-dialog-title>
                <md-dialog-content>
                    Make new patient connections through a smarter platform. Rehab.com goes beyond program listing services by helping you establish new patient connections.
                </md-dialog-content>
                <md-dialog-actions v-if="step==4" >
                    <md-button class="active center" @click="start()">Get started</md-button>
                </md-dialog-actions>
                <div class="buttons">
                    <md-button class="back circle" v-if="step<=4 && step>1" @click="step--"><md-icon class="md-size-1x">arrow_back</md-icon></md-button>
                    <md-button class="forward circle" v-if="step<4" @click="step++"><md-icon>arrow_forward</md-icon></md-button>
                </div>
                <div class="steps" v-if="mobile">
                    <div class="circle" :class="{'active': step == 1}"></div>
                    <div class="circle" :class="{'active': step == 2}"></div>
                    <div class="circle" :class="{'active': step == 3}"></div>
                    <div class="circle" :class="{'active': step == 4}"></div>
                </div>
            </div>
        </template>
        <template v-if="step==2">
            <div class="step2" v-touch:swipe="swipeHandler" v-touch:tap="swipeHandler">
                <img src="/images/gopremium/clicks.svg"/>
                <md-dialog-title>New patient connections</md-dialog-title>
                <md-dialog-content>
                    Premium does the work so you don’t have to. With a few  clicks, you can increase the visibility of your program and make new patient connections.
                </md-dialog-content>
                 <md-dialog-actions v-if="step==4">
                    <md-button class="active center" @click="start()">Get started</md-button>
                </md-dialog-actions>
                <div class="buttons">
                    <md-button class="back circle" v-if="step<=4 && step>1" @click="step--"><md-icon class="md-size-1x">arrow_back</md-icon></md-button>
                    <md-button class="forward circle" v-if="step<4" @click="step++"><md-icon>arrow_forward</md-icon></md-button>
                </div>
                <div class="steps" v-if="mobile">
                    <div class="circle" :class="{'active': step == 1}"></div>
                    <div class="circle" :class="{'active': step == 2}"></div>
                    <div class="circle" :class="{'active': step == 3}"></div>
                    <div class="circle" :class="{'active': step == 4}"></div>
                </div>
            </div>
        </template>
        <template v-if="step==3">
            <div class="step3" v-touch:swipe="swipeHandler" v-touch:tap="swipeHandler">
                <img src="/images/gopremium/payment.svg"/>
                <md-dialog-title>You are in control</md-dialog-title>
                <md-dialog-content>
                    We are committed to the visibility of all providers, no matter their size or budget. Generate more clicks and calls on the industry’s fastest-growing and most transparent platform.
                </md-dialog-content>
                 <md-dialog-actions v-if="step==4" >
                    <md-button class="active center" @click="start()">Get started</md-button>
                </md-dialog-actions>
                <div class="buttons">
                    <md-button class="back circle" v-if="step<=4 && step>1" @click="step--"><md-icon class="md-size-1x">arrow_back</md-icon></md-button>
                    <md-button class="forward circle" v-if="step<4" @click="step++"><md-icon>arrow_forward</md-icon></md-button>
                </div>
                <div class="steps" v-if="mobile">
                    <div class="circle" :class="{'active': step == 1}"></div>
                    <div class="circle" :class="{'active': step == 2}"></div>
                    <div class="circle" :class="{'active': step == 3}"></div>
                    <div class="circle" :class="{'active': step == 4}"></div>
                </div>
            </div>
        </template>
        <template v-if="step==4">

            <div class="step4" v-touch:swipe="swipeHandler" v-touch:tap="swipeHandler">
                <img src="/images/gopremium/unite.svg"/>
                <md-dialog-title>A platform designed to unite</md-dialog-title>
                <md-dialog-content>
                    Rehab.com is more than a search engine. We are here to help you grow. Together we will discover what works best for you and the patients you care for.
                </md-dialog-content>
                <md-dialog-actions v-if="!mobile">
                    <md-button class="active center" v-if="step==4" @click="start()">LET'S GO</md-button>
                </md-dialog-actions>
                <div class="buttons">
                    <md-button class="back circle" v-if="step<=4 && step>1" @click="step--"><md-icon class="md-size-1x">arrow_back</md-icon></md-button>
                    <md-button class="forward circle" v-if="step<4" @click="step++"><md-icon>arrow_forward</md-icon></md-button>
                 </div>
                 <div class="steps" v-if="mobile">
                    <div class="circle" :class="{'active': step == 1}"></div>
                    <div class="circle" :class="{'active': step == 2}"></div>
                    <div class="circle" :class="{'active': step == 3}"></div>
                    <div class="circle" :class="{'active': step == 4}"></div>
                </div>
                 <md-dialog-actions v-if="mobile">
                    <md-button class="active center" v-if="step==4" @click="start()">LET'S GO</md-button>
                </md-dialog-actions>
            </div>
        </template>
        <template v-if="mobile">
          <div id="tap-left" @click="swipeHandler('left')"></div>
          <div id="tap-right" @click="swipeHandler('right')"></div>
        </template>
        <div class="steps" v-if="!mobile">
            <div class="circle" :class="{'active': step == 1}"></div>
            <div class="circle" :class="{'active': step == 2}"></div>
            <div class="circle" :class="{'active': step == 3}"></div>
            <div class="circle" :class="{'active': step == 4}"></div>
        </div>
    </md-dialog>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapMutations} from 'vuex'

    export default{
        name: 'WelcomeTooltip',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
                step:1,
                mobile: false
            }
        },
        
        methods: {
             swipeHandler (direction) {

                if(this.mobile)
                {
                    if(direction == 'right' && this.step > 1){
                        this.step--;
                    }else if(this.step < 4 ){
                        this.step++;
                    }
                }
            },
            ...mapMutations([
                'setWelcomeTooltip',
                'setBlur'
            ]),
            start(){ 
                this.firstTimeEditAdmin = false
                if(this.$route.query.plan && this.$route.query.plan === 'Premium'){
                    return this.$root.$emit('showDialogPremium')
                }
                this.$root.$emit('gopremiumTooltip')    
            }
        },
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        mounted() {

             this.$root.$on('welcomeTooltipUp', () => {
                this.firstTimeEditAdmin = true
                this.setWelcomeTooltip(true);  
                this.setBlur(true);
                document.body.style.overflow = 'hidden'
            }),

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
          
        },
        watch: {
            'firstTimeEditAdmin'(newVal, oldVal) {
                if(newVal == false){
                
                    if(this.$route.query.plan && this.$route.query.plan === 'Premium'){
                        this.$root.$emit('showDialogPremium')
                    }
                    this.setWelcomeTooltip(false);
                    this.setBlur(false);
                    this.$root.$emit('gopremiumTooltip');
                    document.body.style.overflow = 'visible'

                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .md-dialog.md-theme-default{
        background-color:unset;
    }
    .buttons{
        position: absolute;
        top:200px;
    }
    .md-dialog {
       
        .close{
            display: none;
        }
        img{
            height: 300px;
            margin-top: 30px;
        }

        .close{
            color: white;
            position: absolute;
            right: 34px;
            top:18px;
            font-size: 24px;
        }
        .md-dialog-content a{
            color: white;
        }
        .md-dialog-content{
            padding-left: 40px;
            padding-right: 40px;
        }
        .step1{
            background-color: var(--md-theme-default-primary);
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step2{
            background-color: #479cad;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            margin-top: 40px;
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

             img{
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
         .step3{
            background-color: #FFBD44;
            color: #000;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            margin-top: 40px;
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step4{
            background-color: #6955FF;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            margin-top: 40px;
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .md-dialog-title{
            display: block;
            padding-top: 30px;
        }
    
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color: white;
        }
        .secondary{
            color: #9fc1f9;
        }
        .md-dialog-actions{
            padding-right: 14px;
            position: absolute;
        }
        .center{
            top: -4px;
            left:260px;
            position: absolute;
            .md-button-content{
                text-transform: capitalize;
            }
            
        }
        .back{
            position: absolute;
            left: -78px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .forward{
            position: absolute;
            right: -679px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .circle
        {
            border-radius: 50%;
            width: 40px;
            height: 40px; 
            background-color: rgba(0,0,0,0.4);
            min-width: unset; 
            .md-icon{
                color: #fff;
            }
        }
       

        .steps{
            position: absolute;
            top: 580px;
            left: 50%;
            text-align: center;
            overflow: visible;
            margin-left: -58px;
   
            .circle{
                border-radius: 50%;
                width: 10px;
                height: 10px; 
                background-color: rgba(0,0,0,0.4);
                min-width: unset;
                display: inline-block;
                margin-right: 8px;
                opacity: 0.4;
            }
            .active{
                 background-color: #fff;
                  opacity: 1;
            }
        }
    }

    #tap-right{
      position: fixed;
      width: 50%;
      height: 100%;
      display: block;
    }

    #tap-left{
      position: fixed;
      right: 0;
      width: 50%;
      height: 100%;
      display: block;
      float:right;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-dialog{
           
            .close{
                margin-right: -28px;
                margin-top: -11px;
                display: block;
                color: #fff;
                i{
                    color: #fff;
                }
            }
            .step1, .step2, .step3, .step4{
                height: 100%!important;
                margin: 0;
                padding: 0;
                border-radius: 0;
                width: 100%;
                padding-top: 20px;
            }
            img{
                height: 225px;
            }
            .step4{
                .md-dialog-actions{
                    width: 100%;
                }
                .center{
                    left: 50%;
                    margin-left: -41px;
                }
            }
        }

        .steps, .md-dialog .md-dialog-actions{
            position: relative!important;
            top:0!important;
            margin-left: auto!important;
            margin-right: auto!important;
            width: 100%;
            left: unset!important;
            padding: 0px!important;
        }

         .md-dialog-actions{
             margin-top: 22px;
         }
    
    }

    
</style>