import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { apiUrl } from "../config";
import axios from "axios";
import VueTour from "vue-tour";

// MODULE DEFINITIONS
import Payments from "./Modules/Payments";
import Interactions from "./Modules/Interactions";
import Admin from "./Modules/Admin/Admin";
import Logs from "./Modules/Logs";
import Notifications from "./Modules/Notifications";
import Emails from "./Modules/Emails";
import Users from "./Modules/Users";
import Hubspot from "./Modules/Hubspot";
import Subscriptions from "./Modules/Subscriptions";
import Campaigns from "./Modules/Campaigns";
import Facilities from "./Modules/Facilities";
import Tooltips from "./Modules/Tooltips";
import AdmissionsAgents from "./Modules/Admin/AdmissionsAgents";
import Match from "./Modules/Match";
import General from "./Modules/Admin/General";
import Contact from "./Modules/Admin/Contact";
import Description from "./Modules/Admin/Description";
import Media from "./Modules/Admin/Media";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);
Vue.use(Vuex);

const config = {
  modules: {
    Payments,
    Interactions,
    Admin,
    General,
    Logs,
    Notifications,
    Emails,
    Users,
    Hubspot,
    Subscriptions,
    Campaigns,
    Facilities,
    Tooltips,
    AdmissionsAgents,
    Match,
    Contact,
    Description,
    Media,
  },
  state: () => ({
    products: [],
    trackingNumbers: [],
    user: {
      account: null,
      accountId: null,
      companyId: null,
      createdAt: null,
      email: null,
      externalId: null,
      first_name: null,
      id: null,
      lastLoginAt: null,
      last_name: null,
      status: null,
      token: null,
      updatedAt: null,
      verified_token: null,
    },
    receivingTooltip: false,
    receivingTooltipViewed: false,
    goPremiumFunnelTooltip: false, // denotes whether or not this tooltip has been fired before
  }),
  plugins: [createPersistedState()],
  actions: {
    async fetchProducts(context) {
      try {
        const url = `${apiUrl}/products`;
        const response = await axios.get(url);
        // console.log('PRODUCTS RESPONSE', response.data.data);
        context.commit("changeProducts", response.data.data);
        return response.data.data;
      } catch (e) {
        console.error("error loading products", e);
        console.error(e.response);
      }
    },
  },
  mutations: {
    changeProducts(state, products) {
      state.products = products;
    },
    changeTrackingNumbersPush(state, trackingNumbers) {
      state.trackingNumbers.push(trackingNumbers);
    },
    changeTrackingNumbersSet(state, trackingNumbers) {
      state.trackingNumbers = trackingNumbers;
    },
    clear(state, value) {
      state.products = value;
      state.trackingNumbers = value;
      state.trackingUrls = value;
      state.user = {
        account: null,
        accountId: null,
        companyId: null,
        createdAt: null,
        email: null,
        externalId: null,
        first_name: null,
        id: null,
        lastLoginAt: null,
        last_name: null,
        status: null,
        token: null,
        updatedAt: null,
        verified_token: null,
      };
      state.receivingTooltip = false;
      state.receivingTooltipViewed = false;
      state.goPremiumFunnelTooltip = false;
    },
    setUser(state, user) {
      user.loggedInAt = new Date();
      state.user = user;
    },
    updateUserStatus(state, status) {
      state.user.status = status;
    },

    SetReceivingTooltip(state, status) {
      state.receivingTooltip = status;
    },
    setReceivingTooltipViewed(state) {
      state.receivingTooltipViewed = true;
    },
    setGoPremiumFunnelTooltip(state, status) {
      state.goPremiumFunnelTooltip = status;
    },
  },
  getters: {
    receivingTooltip: (state) => state.receivingTooltip,
    receivingTooltipViewed: (state) => state.receivingTooltipViewed,
    goPremiumFunnelTooltip: (state) => state.goPremiumFunnelTooltip,
    trackingNumbers: (state) => state.trackingNumbers,
    products: (state) => state.products,
    clickProduct: (state) =>
      state.products.find(
        (product) => product.attributes.slug === "premium-clicks"
      ),
    callProduct: (state) =>
      state.products.find(
        (product) => product.attributes.slug === "premium-calls"
      ),
    user: (state) => state.user,
    facilityTrackingNumber: (state) => (facilityId) => {
      const trackingNumber = state.trackingNumbers.find(
        (tracking) => tracking.facilityId == facilityId
      );
      if (trackingNumber) {
        return trackingNumber;
      }
      return null;
    },
  },
};

/*
  Dynamically add mutation & action to root and each module for resetting state
  to initial state only if:
  - if there's state property set for module
  - state property is a function that return the initial state object
  ** state must be a function in order to cache initial state
*/
export function createResetHandler(store) {
  const RESET_MODS = [];

  Object.keys(store.modules).forEach((modName) => {
    const mod = store.modules[modName];

    if (!mod.hasOwnProperty("state")) return;
    if (typeof mod.state !== "function") return;

    if (!mod.hasOwnProperty("mutations")) mod.mutations = {};
    if (!mod.hasOwnProperty("actions")) mod.actions = {};

    RESET_MODS.push(modName);

    if (!mod.mutations.hasOwnProperty(modName + "_RESET_STATE")) {
      mod.mutations[modName + "_RESET_STATE"] = function RESET_STATE(state) {
        const s = mod.state();
        Object.keys(s).forEach((key) => (state[key] = s[key]));
      };
    }

    if (!mod.actions.hasOwnProperty(modName + "resetState")) {
      mod.actions[modName + "_resetState"] = function resetState({ commit }) {
        commit(modName + "_RESET_STATE");
      };
    }
  });

  store.actions.resetAllState = function RESET_ALL_STATE({ commit }) {
    RESET_MODS.forEach((m) => commit(`${m}_RESET_STATE`));
    commit("clear", []);
  };
}

createResetHandler(config);

export default new Vuex.Store(config);
