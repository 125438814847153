<template>
    <div>
        <form ref="bankform" id="bankform" @submit.prevent="createBank" v-show="!bankAdded">
            <div class="container">
                <p>Bank account must be located in the United States and in US Dollar currency.</p>
                <md-field :class="getValidationClass('account_holder_name')">
                    <label for="account-holder-name">Name on bank account</label>
                    <md-input
                            v-model="bank.account_holder_name"
                            name="account-holder-name"
                            id="account-holder-name"
                    />
                    <span class="md-error" v-if="!$v.bank.account_holder_name.required">Account holder's name is required</span>
                </md-field>

                <md-field :class="getValidationClass('routing_number')">
                    <label for="routing-number">Routing number</label>
                    <md-input
                            v-model="bank.routing_number"
                            name="routing-number"
                            id="routing-number"
                    />
                    <span class="md-error" v-if="!$v.bank.routing_number.required">Routing number is required</span>
                </md-field>

                <md-field :class="getValidationClass('account_number')">
                    <label for="account-number">Account number</label>
                    <md-input
                            v-model="bank.account_number"
                            name="account-number"
                            id="account-number"
                    />
                    <span class="md-error" v-if="!$v.bank.account_number.required">Account number is required</span>
                </md-field>

                <md-field :class="getValidationClass('account_holder_type')">
                    <label for="account-type">Account type</label>
                    <md-select name="account-type" id="account-type" v-model="bank.account_holder_type">
                        <md-option v-for="type in account_holder_types" :key="type" :value="type">{{type}}</md-option>
                    </md-select>
                    <span class="md-error" v-if="!$v.bank.account_holder_type.required">Account type is required</span>
                </md-field>

                <p class="md-caption">
                    By continuing, you authorize Rehab.com to credit and debit your bank account electronically, including debits to fund any negative balance that may arise.
                </p>

                <p class="md-error">
                    {{bankError}}
                </p>
            </div>
            <md-card-actions md-alignment="left">
                <md-button @click="$emit('goBack')" class="md-raised">Back</md-button>
                <md-button type="submit" class="md-raised md-primary">Submit</md-button>
            </md-card-actions>

        </form>

        <div v-if="bankAdded">
            <p>
                Your Bank Account has been added!
            </p>
            <p>
                Your bank account now needs to be verified. Our payment provider will make two small deposits into your bank account in the next 24-48 hours. The statement description for these deposits will be <strong>AMTS</strong>. After you see these items on your bank statement, please log back in and verify your bank account on the "Settings" page.
            </p>
            <md-card-actions md-alignment="left">
                <md-button @click="$emit('accountCreated')">Continue</md-button>
            </md-card-actions>
        </div>
    </div>
</template>

<script>
    import config from '../../../config.js'
    import { validationMixin } from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import {mapGetters} from 'vuex'
    export default {
        name: "BankAccountForm",
        props: {
            showSubmitButton: {type: Boolean, default: true}, // SHOW OR HIDE THE SUBMIT BUTTON
            cancelRoute: {type: String, default: 'settings.payment-methods'}, // ROUTE TO CALL WHEN CANCEL BUTTON IS CLICKED
        },
        data() {
            return {
                bankAdded: false,
                bankError: null,
                stripe: null,
                bank: {
                    country: 'US',
                    currency: 'usd',
                    routing_number: null,
                    account_number: null,
                    account_holder_name: null,
                    account_holder_type: null
                },
                account_holder_types: [
                    'Individual',
                    'Company'
                ]
            }
        },
        computed: {
            ...mapGetters([
                'user',
            ])
        },
        mixins: [validationMixin],
        validations: {
            bank: {
                routing_number: {required},
                account_number: {required},
                account_holder_name: {required},
                account_holder_type: {required}
            }
        },
        mounted() {
            // eslint-disable-next-line no-undef
            this.stripe = Stripe(config.stripe.publicKey)

        },
        methods: {
            getValidationClass(fieldName) {
                const field = this.$v.bank[fieldName]

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            async createBank() {
                try{
                    this.bankError = null;
                    this.$v.$touch();
                    if (this.$v.$anyError || this.$v.$invalid) {
                        console.error(this.$v.anyError, this.$v.$invalid);
                        return
                    }

                    //VALIDATION COMPLETE, SUBMIT TO STRIPE
                    const {token, error} = await this.stripe.createToken('bank_account', this.bank);
                    //console.log('TOKEN', token);

                    if(error) {
                        throw(e);
                    }

                    const bankAccount = await this.$store.dispatch('createBankAccount', {stripeToken: token})
                    this.bankAdded = true;
                }catch (e) {
                    console.error(e);
                    this.bankError = e.toString();
                }
            }
        }
    }
</script>

<style scoped>
    .container{
        margin-left: 7px;
    }
</style>