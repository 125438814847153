var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"expand"}},[(
      _vm.activeSubscriptions.length === 0 &&
      !_vm.subscriptionBannerDismissed &&
      !_vm.welcomeTooltip &&
      !_vm.GoPremiumTooltip &&
      !_vm.showDialogPremium &&
      (!_vm.failedPayments || (_vm.failedPayments && !_vm.failedPayments.length))
    )?_c('div',{staticClass:"subscription-banner"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"alt":_vm.accountOwner.imageAlt,"src":_vm.accountOwner.image}})]),_c('div',{staticClass:"flex-content"},[(!_vm.mobile)?_c('div',{staticClass:"title"},[_vm._v(" Expert Support is Available to You ")]):_vm._e(),(!_vm.mobile)?_c('div',{staticClass:"message"},[_vm._v(" Talk with a Rehab.com Marketing Expert to help advertise your program during uncertain times. ")]):_c('div',{staticClass:"message"},[_vm._v(" Talk with a Rehab Marketing Expert to help advertise your program. ")]),_c('div',{staticClass:"buttons"},[_c('a',{attrs:{"href":_vm.accountOwner.scheduleLink,"target":"_blank"}},[_c('md-button',{staticClass:"md-raised md-primary"},[_vm._v("Schedule Call Now")])],1),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.setSubscriptionBannerDismissed(true)}}},[_c('md-icon',[_vm._v("close")])],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }