<template>
    <div style="min-height:100px;">
        <form
                ref="paymentForm"
                id="paymentForm"
                @submit.prevent="createCard"
                v-show="!createCardButton"
        >


            <div id="invoice">
                <div class="invoice-header">
                    <span class="section-header">Invoice Details</span>
                </div>
                <div class="md-layout invoice-subscriptions">
                    <div class="md-layout-item left">
                        <span class="section-header">Premium Listings (1)</span><br>
                        <ul data-v-a97a8662="" class="go section-info">
                             <li>Replace third-party ads</li>
                            <li data-v-a97a8662="">Unlimited forms</li>
                            <li data-v-a97a8662="">Billed monthly on the {{ dayOfMonth }}</li>
                        </ul>
                    </div>
                    <div class="md-layout-item md-size-15 subtotal">
                        <template  v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                            <span>{{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides| usd }}/month</span><br>
                        </template>
                        <template v-else>
                        <span>{{ subscriptioncosts.overrides | usd }}/month</span><br>
                        <div class="monthly">billed monthly</div>
                        </template>
                    </div>

                </div>
                    <div class="md-layout invoice-campaigns" v-if="!goPremiumFunnelInfo.isSubscriptionOnly">
                    <div class="md-layout-item left">
                        <span class="section-header">{{ campaignTitle }} ({{ funnelData.budget | usd}} budget) </span><br>
                        <ul data-v-a97a8662="" class="go section-info">
                            <li v-if="funnelData.goal=='calls'">Receive {{ Math.ceil(funnelData.budget / funnelData.facilityPricing.singleCallCost) | callClickRange) }} Premium Calls per listing</li>
                            <li v-if="funnelData.goal=='clicks'">Receive {{ Math.ceil(funnelData.budget / funnelData.facilityPricing.singleClickCost)  | callClickRange }} Premium Clicks per listing</li>
                            <li v-if="funnelData.goal=='callsAndClicks'">Receive {{ calculateFacilityFunnelBudget(funnelData.budget).clicks | callClickRange}} clicks and {{  calculateFacilityFunnelBudget(funnelData.budget).calls | callClickRange}} calls per month</li>
                            <li data-v-a97a8662="">You will be charged every month or when you reach your billing threshold, whichever comes first</li>
                        </ul>
                    </div>
                    <div class="md-layout-item md-size-15 subtotal">
                        <span>{{campaignTotalToday * facilities.length | usd}}</span>
                    </div>

                </div>
                <div class="md-layout  invoice-totals">
                    <div class="md-layout-item left">
                        <span class="total-title">Total Due Today</span><br>
<!--                        <p class="section-header">Due by {{dueDate}}</p>-->
                    </div>
                    <div class="md-layout-item total">
                        <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                        <div class="total_price"> {{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides| usd }}/month</div>
                        </template>
                        <template v-else>
                        <div class="total_price"> {{ subscriptioncosts.overrides | usd }}</div>
                        </template>
                    </div>
                </div>


            </div>

            <div class="container" :class="{'blur': createCardButton}" v-show="showForm">
              <p class="card-error" v-if="cardError"><md-icon class="error-icon">error</md-icon> <span v-html="cardError"></span></p>
                <div class="details">
                    <div class="cardNumber">
                        <label for="">Card number</label>
                        <div id="card-number">
                            <!-- Stripe element will be mounted here -->
                        </div>
                    </div>
                    
                    <div class="cardExpiry">
                        <label for="">&nbsp;</label>
                        <div id="card-expiry">
                            <!-- Stripe element will be mounted here -->
                        </div>
                    </div>
                    <div class="cardCVC">
                        <label for="">&nbsp;</label>
                        <div id="card-cvc">
                            <!-- Stripe element will be mounted here -->
                        </div>
                    </div>
                </div>

                <div class="card-strip"><img src="/images/gopremium/card_strip.png" /></div>
                <div class="details-container">
                    <!-- CARDHOLDER NAME -->
                    <md-field :class="getValidationClass('name')" class="name">
                        <label class="label">Cardholder name</label>
                        <md-input v-model="payment.name" type="text"></md-input>
                        <span class="md-error" v-if="!$v.payment.name.required"><md-icon class="error-icon">error</md-icon> Name is required</span>
                        <span class="md-error" v-else-if="!$v.payment.name.noNumbers"><md-icon class="error-icon">error</md-icon> Name cannot contain numbers</span>
                    </md-field>

                    <!-- ZIPCODE -->
                    <md-field :class="getValidationClass('address_zip')" class="zip">
                        <label class="label">ZIP code</label>
                        <md-input v-model="payment.address_zip" @keypress="isNumber($event)" type="text" maxlength="5"></md-input>
                        <span class="md-error" v-if="!$v.payment.address_zip.required"><md-icon class="error-icon">error</md-icon> ZIP is required</span>
                        <span class="md-error" v-else-if="!$v.payment.address_zip.numeric"><md-icon class="error-icon">error</md-icon> ZIP must be all numbers, no letters</span>
                        <span class="md-error" v-else-if="!$v.payment.address_zip.min || !$v.payment.address_zip.max"><md-icon class="error-icon">error</md-icon> ZIP must be 5 characters long</span>
                    </md-field>
                </div>
            </div>

            <md-card-actions md-alignment="right" v-if="showSubmitButton"  :class="{'blur': createCardButton}">
<!--                <md-button v-if="!this.goPremiumFunnelInfo.isSubscriptionOnly" @click="$emit('goBack', 3)" class="md-raised">Back</md-button>-->
<!--                <md-button v-else @click="$emit('goBack', 'subscriptionOnly')" class="md-raised">Back</md-button>-->
                <md-button class="md-raised md-primary" type="submit" :disabled="formSubmitting">Submit</md-button>
            </md-card-actions>

        </form>

        <div class="center" v-if="createCardButton">
          <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="createCardButton"></md-progress-spinner>
          <div class="login">Processing payment...</div>
        </div>


        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{snackMsg}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import { validationMixin } from 'vuelidate'
    import config from '../../../config.js'
    import {
        required,
        minLength,
        maxLength,
        numeric,
        alpha
    } from 'vuelidate/lib/validators'

    import {format, addMonths} from 'date-fns'

    const noNumbers = (value) => {
        const hasNumber = /\d/.test(value);
        return !hasNumber
    }

    export default {
        name: "NewCreditCardForm",
        mixins: [validationMixin],
        props: {
            showSubmitButton: {type: Boolean, default: true}, // SHOW OR HIDE THE SUBMIT BUTTON
            cancelRoute: {type: String, default: 'settings.payment-methods'}, // ROUTE TO CALL WHEN CANCEL BUTTON IS CLICKED
        },
        data() {
            return {
                useFacilityAddress: false,
                cardError: false,
                creditCard: null,
                createCardButton: false,
                payment: {
                    name: '',
                    address_zip: '',
                    address_country: 'US'
                },
                step: 1,
                formSubmitting: false,
                funnelData: {},
                showSnackbar: false,
                snackMsg: null,
                mobile: false
            }
        },
        computed: {
            ...mapGetters([
                'allFacilityInfo',
                'goPremiumFunnelInfo',
                'facilities',
                'calculateBudget',
                'subscriptions',
                'facilityOperationTypes',
                'facilitySubscriptionCost',
                'totalSubscriptionCost',
                'creditCards',
                'facilitySubscriptionCost',
                'calculateFacilityFunnelBudget',
                'subscriptioncosts',
                'subscriptionCostsOverridesByFacility'
            ]),

            dayOfMonth: function(){
                return format(new Date(), 'Do');
            },

            campaignTotalToday: function(){let total = 0;
                return 0
            },

            campaignTotal: function(){

                return this.goPremiumFunnelInfo.budget;
                
            },

            calculateTotal: function() {
                let total = 0;
                const atLeastOneActiveSubscription = this.subscriptions.find(subscription => subscription.active);
                if(!atLeastOneActiveSubscription){
                    total += this.totalSubscriptionCost;
                }

                total += (this.campaignTotalToday * this.facilities.length)

                return total;

            },

            campaignTitle: function(){
                //console.log('FUNNEL DATA', this.goPremiumFunnelInfo)
                if(this.funnelData.goal === "calls"){
                    return "Premium Calls"
                }else if(this.funnelData.goal === "clicks"){
                    return "Premium Clicks"
                } else {
                    return "Premium Clicks and Calls"
                }
            },

            funnelFacility() {
                // console.log('FUNNEL FACILITY ', this.facilities.find(facility => parseInt(facility.attributes.facilityId) === parseInt(this.goPremiumFunnelInfo.funnelFacility)));
                return this.facilities.find(facility => parseInt(facility.attributes.facilityId) === parseInt(this.goPremiumFunnelInfo.funnelFacility))
            },

            showForm() {
              return this.creditCards.length === 0 || this.cardError
            }
        },

        validations: {
            payment: {
                name: { required, noNumbers },
                // address_line1: { required },
                // address_city: { required },
                // address_state: { required },
                address_zip: {
                    required,
                    min: minLength(5),
                    max: maxLength(5),
                    numeric
                }
            }
        },
        async mounted() {
            await this.fetchSubscriptionCosts();

            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
                this.stripe = Stripe(config.stripe.publicKey)
                this.elements = this.stripe.elements({
                    fonts: [
                        {cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'}
                    ],
                })
                this.stripeElements = {}
                this.mountStripe()
        },
        filters: {
            formatPrice: (value) => {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            usd(value) {
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                })
                if(value % 1 === 0){
                    formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                    })
                }
                return formatter.format(value)
            },
            callClickRange: (value) => {
                const low = Math.ceil(value / 2);
                const high = parseInt(value * 1.5);
                return `${value}`
            }
        },
        created() { 
             if( this.windowWidth <= 760 ) {
                this.mobile = true
            }  
        },
        methods: {
             ...mapActions([
                'fetchSubscriptionCosts'
            ]),
            isNumber(evt){
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            budgetBreakdown(budget){
                return this.$store.getters.getBudgetBreakdown({facilityId: this.facilities[0].attributes.facilityId, type: this.funnelData.goal, budget})
            }, 
            
            getValidationClass (fieldName) {
                const field = this.$v.payment[fieldName]

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            onCardCreated(result) {
                //console.log('onCardCreated', result)

                // API created credit card, save id for when campaign is created
                this.creditCard = {
                    id: result.data.id
                }
            },
            nextPage(){
                this.$v.$touch()
                if (this.$v.$anyError || this.$v.$invalid) {
                    console.error(this.$v.anyError, this.$v.$invalid);
                    return
                }
                this.step = 2;
            },
            async createCard(){
                try{
                    window.scrollTo(0,0);
                    if(this.creditCards.length === 0){
                        //console.log('CREATE CARD FUNCTION CALLED');
                        this.$v.$touch()
                        if (this.$v.$anyError || this.$v.$invalid) {
                            console.error(this.$v.anyError, this.$v.$invalid);
                            return
                        }

                        this.createCardButton = true
                        this.$emit('showStatusBar')
                        // VALIDATION COMPLETE, SUBMIT THE DATA
                        this.formSubmitting = true;
                        let customerInfo = JSON.parse(JSON.stringify(this.payment))

                        // console.log('customerInfo', customerInfo);
                        //
                        // console.log('STRIPE', this.stripe);

                        if(customerInfo.address_line2 === null) customerInfo.address_line2 = ''
                        const result = await this.stripe.createToken(this.stripeElements.card, customerInfo);

                        if(result.error){
                            console.error('ERROR SUBMITTING CREDIT CARD', result.error);
                            this.createCardButton = false
                            this.formSubmitting = false
                            this.cardError = result.error.message
                            return;
                        }

                        // console.log('STRIPE TOKEN RESULT', result);

                        self.cardError = false
                        // console.log("Stripe createToken result - ", result);
                        // Fire the cardCreated event which calls the API create

                        const expireDate = new Date(result.token.card.exp_year, result.token.card.exp_month, 0);
                        const formattedExpireDate = expireDate.getUTCFullYear() + '-' + (parseInt(expireDate.getUTCMonth()) + 1) + '-' + expireDate.getUTCDate();

                        const goPremiumData = this.$store.getters.goPremiumFunnelInfo;
                        // console.log('GO PREMIUM DATA', goPremiumData);

                        const createdCard = await this.$store.dispatch('createCreditCard', {
                            type: result.token.card.brand,
                            token: result.token.id,
                            endNumbers: result.token.card.last4,
                            expireDate: formattedExpireDate,
                            zipCode: result.token.card.address_zip,
                            nameOnCard: result.token.card.name
                        })

                        this.$store.dispatch('hubspotPaymentTypeAdded')

                        await this.$store.dispatch('fetchCreditCards');

                        // FIRE EVENT IN PARENT
                        this.$emit('cardCreated', createdCard)
                        this.formSubmitting = false
                    } else {
                        this.createCardButton = true;
                        this.formSubmitting = true;
                        this.$emit('cardCreated')
                        // this.formSubmitting = false
                    }


                }catch(e) {
                    this.$emit('hideStatusBar')
                    this.formSubmitting = false
                    console.error('ERROR SUBMITTING CREDIT CARD', e);
                    console.error('ERROR.RESPONSE', e.response);
                    this.cardError = e.response.data.message
                    this.createCardButton = false;
                }
            },
            mountStripe() {
                var elementStyles = {
                    base: {
                        color: '#000000',
                        fontWeight: 400,
                        fontFamily: 'Roboto, Consolas, Menlo, monospace',
                        fontSize: '17px',
                        lineHeight: '32px',
                        borderBottom: '1px solid #fff',
                        fontSmoothing: 'antialiased',
                        '::placeholder': {
                            color: '#0000008a',
                            fontWeight: 300,
                        },
                        ':-webkit-autofill': {
                            color: '#e39f48',
                        },
                        ':focus': {
                            borderBottom: '2px'
                        }
                    },
                }

                var elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                }

                var cardNumber = this.elements.create('cardNumber', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardNumber.mount('#card-number')

                var cardExpiry = this.elements.create('cardExpiry', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardExpiry.mount('#card-expiry')

                var cardCvc = this.elements.create('cardCvc', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardCvc.mount('#card-cvc')

                this.stripeElements.card = cardNumber
            },
            stopLoadingError(error){
                this.cardError = error
                this.createCardButton = false;
                this.formSubmitting = false;
                // this.showSnackbar = true;
                // this.snackMsg = error;
            }
        }

    }
</script>

<style lang="scss" scoped>
   
    .go{
        li {
            color: #666;
        }
    }
    .card-error{
        color: #d93025;
        margin-top: 5px !important;
        margin-bottom: 5px;
        margin-left: -6px;

    }
    .error-icon{
        font-size: 16px!important;
        color: #d93025!important;
    }
    .md-error{
        margin-left: -6px;
    }
    .md-field.md-theme-default.md-focused > label, .md-field.md-theme-default.md-has-value > label{
        color: #000;
        font-size: 14px;
    }
    /*.md-field{*/
    /*    width: 48%;*/
    /*    float: left;*/
    /*    margin-right: 2%;*/
    /*}*/
    .container{
        margin-left: 7px;
        padding-top: 20px;
    }
    label{
        color: #666;
    }
    .card-strip{
        margin-left: -5px;
        margin-top: 0px;
    }
    .details{
        margin-bottom: 8px;
        /*margin-top: 30px;*/
        .cardNumber{
            border-bottom: 1px solid #dddddd;
            float: left;
            width: 68%;
            margin-bottom: 4px;

        }
        .cardExpiry{
            border-bottom: 1px solid #dddddd;
            float: left;
            width: 13%;
            margin-left: 24px;
            margin-bottom: 4px;
        }
        .cardCVC{
            border-bottom: 1px solid #dddddd;
            float: left;
            width: 10%;
            margin-left: 24px;
            margin-bottom: 4px;
        }
        label {
            color: #000;
        }

    }
    .zip{
        margin-top: -8px;
    }
    .md-card-actions{
        margin-top: 14px;
        margin-right: 0px;
        padding-right: 0px;
        .md-button{
            height: 44px;
            width: 130px;
            margin-right: 0px;
            padding-right: 0px;
        }
    }
    .spinner{
        top:40%;
        margin-top: -40px;
        left: 50%;
        margin-left: -30px;
        position: absolute;
    }
    .blur{
        filter: blur(1px);
    }
    .center{
        text-align: center;
        padding-bottom: 18px;
    }

    .details-container{

        .name{
            margin-top: 0px;
            width: 48%;
            float: left;
            margin-right: 4%;
        }
        .zip{
            margin-top: 0px;
            width: 48%;
            float: left;
        }
    }
    p{
        color: #666;
    }

    .details-container{
        .name, .zip{
            label{
                color: #7B7B7B;
                font-size: 17px;
            }
        }
    }
    .md-input{
        font-family: Roboto, Consolas, Menlo, monospace;
        font-size: 17px!important;
        letter-spacing: 0.4px;
        -webkit-font-smoothing: antialiased;
    }

    .confirmation{
        border: 3px solid var(--md-theme-default-primary);
        display: table;
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        padding-top: 10px;
        padding-bottom: 0px;
        ul{
            width: 430px;
            float: left;
            li{
                margin-left: -20px;
                padding-left: 0px;
            }
        }
        .rightInfo{
            text-align: right;
            display: table-cell;
            vertical-align: middle;
            padding-top: 4px;
            padding-right: 4px;
            color: #000!important;
        }

        .price {
            font-weight: 500 !important;
            font-size: 34px !important;
            float: right;
            margin-right: 10px;
        }
        .month{
            text-align: right;
            float: right;
            padding-top: 4px;
        }

        .title[data-v-a97a8662] {
            font-size: 18px;
            font-weight: bold;

        }

    }

    #invoice{
        border: 2px solid #D5D8E1;
        border-radius: 5px;
        div.invoice-header, div.invoice-subscriptions, div.invoice-campaigns, div.invoice-totals{
            padding: 12px;
        }

        div.invoice-subscriptions, div.invoice-campaigns, div.invoice-totals {
            border-top: 1px solid #D5D8E1;
        }
        .section-header{
            font-weight: 500;
            font-size: 14px;
        }

        .invoice-header {
            background-color: #F1F6FA;
        }

        .invoice-totals{
            padding-bottom: 8px !important;
            .section-header {
                color: rgba(0, 0, 0, 0.87);
                margin-top:3px;
            }
        }

        .section-info{
            font-size: 12px;
            color: #666;
            margin-top: 0;
            margin-bottom: 0;
        }

        .total-title{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .subtotal{
            color: #000;
            text-align: right;
            /*padding-top: 15px;*/
        }
        .total {
            text-align: right;
            font-size: 24px;
            font-weight: bold;
            padding-left: 0px;
            /*padding-top: 20px;*/
        }
        .monthly{
                font-size: 10px;
                text-align: right;
                font-weight: 400;
                color: #666;
                margin-top: -4px;
            }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .spinner {
            margin-top: unset;
            top: 50px;
        }
        #invoice{
            .total-title{
                font-size: 18px!important;
            }
            .total {
                text-align: right;
                font-size: 18px!important;
            }
            .section-info{
                margin-top: 6px;
            }
        }

        .container{
            padding-top: 10px;
        }

        .details{
            width: 100%;
            display: inline-block;
            margin-bottom: 0px;
            .cardNumber{
                width: 50%;
                display: inline-block;
                 min-width: 170px;
            }
            .cardExpiry{
   
                min-width: 70px;
                width: 23%;
                margin-left: 6%;
                display: inline-block;

            }
            .cardCVC{
                width: 14%;
                margin-left: 5px;
                margin-left : 3%;
                min-width: 40px;
                display: inline-block;

            }
           
        } 
        .card-strip{
            width: unset;
            display: inline-block;
        }
        .details-container{
            width: unset;
            display: inline-block;
        }
       .md-card-actions{
           margin-top: 0px;
       }

        .details-container{

            .name{
                margin-bottom: 10px;
                margin-top: 0px;
            }
            .zip{
                margin-bottom: 10px;
                margin-top: 0px;
            }
        }
        .card-error{
            margin-top: 0px!important;
            margin-bottom: 0px!important;
        }
        .md-size-15 {
            position: absolute;
            right: 10px;
        }

        #invoice .left{
            width: 76%;
            flex: unset;
        }
        form .md-card-actions, .md-card-actions .md-button{
            margin: 0;
            padding: 0;
            margin-top: 4px;
        }
        .md-layout-item{
            overflow: hidden;
            width: 100%;
        }
    }

    .center{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-top: 140px;
    }


</style>