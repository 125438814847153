import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios';
import {isBefore, isAfter, isSameDay} from 'date-fns'

export default {
    state: () => ({
        interactionCosts: {},
        interactionCostOverrides: {},
        facilityCalls: [],
        goPremiumFunnelInfo: null,
        goPremiumFunnelCompleteThisLogin: false,
        trackingUrls: [],
        verifiedPhoneOrUrls: [],
        subscriptionCostOverrides: {}
    }),
    actions: {

        // GET THE COST FOR ALL THE INTERACTIONS
        async fetchInteractionCostsLookup(context, {facilityIds, refresh}){
            if(!refresh) refresh = 0;
            const url = `${apiUrl}/interactioncosts?facilityId=${facilityIds}&refresh=${refresh}`
            const response = await axios.get(url);
            // console.log('fetchInteractionCostsLookup', facilityIds, response.data);

            if(response.data){

                if(response.data.interactionCosts && response.data.interactionCosts.length){
                    if(!facilityIds){
                        // console.log('GOT THE DEFAULTS committing', response.data.interactionCosts)
                        context.commit('setFacilityInteractionCosts', {facilityId: 'default', interactionCosts: response.data.interactionCosts})
                    }
                    else {
                        const facilityIdsArray = facilityIds.toString().split(',');
                        for(const facilityId of facilityIdsArray){
                            context.commit('setFacilityInteractionCosts', {facilityId, interactionCosts: response.data.interactionCosts.filter(ic => parseInt(ic.facilityId) === parseInt(facilityId))})
                        }
                    }


                }

                if(response.data.interactionCostOverrides && response.data.interactionCostOverrides.length){
                    if(!facilityIds){
                        context.commit('setFacilityInteractionCostOverrides', {
                            facilityId: "default",
                            interactionCostOverrides: response.data.interactionCostOverrides
                        })
                    }else {
                        const facilityIdsArray = facilityIds.toString().split(',');
                        for(const facilityId of facilityIdsArray) {
                            context.commit('setFacilityInteractionCostOverrides', {
                                facilityId,
                                interactionCostOverrides: response.data.interactionCostOverrides
                            })
                        }
                    }

                }

            }

            return response.data;
        },

        // GET THE CLICKS FOR THE FACILITIES ARRAY THAT'S PASSED IN
        async fetchFacilityClicks(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/getfacilityclicksapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&action=1&type=1&location=1`
            const response = await axios.get(url);

            if(response.data.data){
                const clicks = response.data.data.map(click => click);

                return clicks
            }

            // console.log('no clicks!');
            return []
        },

        // GET THE CLICKS FOR THE FACILITIES ARRAY THAT'S PASSED IN FROM GOOGLE ANALYTICS
        async fetchFacilityClicksGA(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=External`
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },
        // GET THE CLICKS FOR THE FACILITIES ARRAY THAT'S PASSED IN FROM GOOGLE ANALYTICS
        async fetchFacilityClicksGANewTracking(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=Free click`
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },
        // GET THE CALLS FOR THE FACILITIES ARRAY THAT'S PASSED IN FROM GOOGLE ANALYTICS
        async fetchFacilityCallsGA(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=Call from contact`
            //console.log(url);
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },
        // GET THE CALLS FOR THE FACILITIES ARRAY THAT'S PASSED IN FROM GOOGLE ANALYTICS
        async fetchFacilityCallsGANewTracking(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=Free call click`
            //console.log(url);
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },
        // GET PAGE VIEWS FOR THE FACILITY ARRAY THAT'S PASSED IN
        async fetchFacilityPageViewsGA(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=pageViews`
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },
        // GET UNIQUE USERS FROM GA FOR THE FACILITY ARRAY THAT'S PASSED IN
        async fetchFacilityUniqueUsersGA(context, {facilityIds, startDate, endDate}) {
            const url = `${apiUrl}/googleapis?facilityId=${facilityIds.join(',')}&startDate=${startDate}&endDate=${endDate}&filter=users`
            const response = await axios.get(url);
            if(response && response.data){
                return JSON.parse(JSON.stringify(response.data));
            }
            return [];
        },

        // GET CALL TRACKING INFO/NUMBERS
        async fetchCallTrackingMetricsApi(context, {facilityId, trackingNumber, startDate, endDate}){
            // console.log('TRACKING NUMBER', trackingNumber);
            if(!trackingNumber || trackingNumber.length === 0 || trackingNumber[0]==null){
                return []
            }
            const url = `${apiUrl}/calltrackingmetricsapis?trackingNumber=${trackingNumber}&startDate=${startDate}&endDate=${endDate}`
            const response = await axios.get(url)

            if(response.data && response.data.calls.length > 0){
                if(Array.isArray(facilityId)){
                    const callData = []

                    for(const id of facilityId){
                        const trackingNumber = context.getters.facilityTrackingNumber(id)
                        if(!trackingNumber){
                            // console.log('NO TRACKING NUMBER FOR FACILITY', id);
                            continue
                        }else if(trackingNumber && !trackingNumber.filter_id){
                            continue;
                        }
                        const facilityCalls = {
                            facilityId: id,
                            data: {calls: []}
                        }
                        facilityCalls.data.calls = response.data.calls.filter(call => {
                            if(call && trackingNumber){
                                // console.log('CALL', call, trackingNumber);
                                // console.log('CALL TRACKING NUMBER', call.tracking_number_id, 'FACILITY TRACKING#',trackingNumber, trackingNumber === call.tracking_number_id, id)
                                return parseInt(call.tracking_number_id) === parseInt(trackingNumber.filter_id)
                            }
                        })

                        // context.commit('setFacilityCalls', {data: facilityCalls, id})
                        callData.push(facilityCalls);
                    }
                    return callData;
                }else {
                    // context.commit('setFacilityCalls', {data: response.data, facilityId})
                    return response.data
                }



            }

            context.commit('setFacilityCalls', {data: [], facilityId});
            return []
        },
        // BUY CALL TRACKING NUMBER
        async ctmBuyNumber(context, {number, facilityId, campaignId}){
            facilityId = parseInt(facilityId);
            
            const url = `${apiUrl}/calltrackingmetricsbuynumbers?number=${number}&facilityId=${facilityId}&campaignId=${campaignId}`
            // console.log('URL', url);
            const response = await axios.post(url)

            // console.log('BUY NUMBER RESPONSE', JSON.parse(JSON.stringify(response)));

            var trackingNumbers = context.getters.trackingNumbers

            let trackingNumber = trackingNumbers.find(item => parseInt(item.facilityId) === parseInt(facilityId))

            // console.log('TRACKING NUMBER', JSON.parse(JSON.stringify(trackingNumber)));

            if(trackingNumber){
               trackingNumber.number = response.data.data.attributes.trackingNumber
            }else {
                trackingNumbers.push({
                    status: true,
                    facilityId: facilityId,
                    number: response.data.data.attributes.trackingNumber,
                    receivingNumber: response.data.data.attributes.receivingNumber,
                    filter_id: response.data.data.attributes.filter_id
                })
            }

            context.commit('changeTrackingNumbersSet', trackingNumbers)
        
            const facilities = context.getters.facilities
            const users =  context.getters.user
            
            let facility = facilities.find(facility => parseInt(facility.attributes.facilityId) === facilityId)
            var data = {
                facilityId: facilityId,
                facilityName: facility.attributes.name,
                campaignId: campaignId,
                contactEmail: users.email,
                contactName: users.first_name + ' ' +users.last_name,
                trackingNumber: response.data.data.attributes.trackingNumber,
                receivingNumber: response.data.data.attributes.receivingNumber
            }

            return []
        },
        // BUY CALL TRACKING NUMBER
        async ctmUpdateNumber(context, {number, facilityId, campaignId}){

            const url = `${apiUrl}/calltrackingmetricsbuynumbers/${facilityId}`
            const response = await axios.patch(url, {campaignId, number})

            if(response){
                if(response.data[0]){
                    // console.log('have th;e 0', response.data[0])
                    const trackingNumbers = context.getters.trackingNumbers

                    for(const item of trackingNumbers){
                        if(item.facilityId == facilityId){
                            item.receivingNumber = number
                        }
                    }
                    context.commit('changeTrackingNumbersSet', trackingNumbers)
                }
                return response.data
            }

            return []
        },
        async getCTMNumber(context, {facilityIds}){
            const url = `${apiUrl}/calltrackingmetricsbuynumbers?facilityId=${facilityIds.join(',')}`
            const response = await axios.get(url)
            
            if(response.data){
                return response.data
            }
            return []
        },
        async getFacilityTrackingNumber(context, {facilityIds}){
            // console.log('FACILITYIDS', facilityIds);

            const CTMNumbers = await context.dispatch('getCTMNumber', {facilityIds})

            // console.log('CTM NUMBERS', CTMNumbers);

            let symfonyLookupFacilityIds = []

            if(CTMNumbers && CTMNumbers.data && CTMNumbers.data.length){
                for(const facilityId of facilityIds){
                    // console.log('LOOPING FACILITY IDS', facilityId);
                    const CTMNumber = CTMNumbers.data.find(num => parseInt(num.attributes.facilityId) === parseInt(facilityId));

                    if(CTMNumber){ // CTM NUMBER IS IN THE CONNECT DATABASE, USE IT!
                        // console.log('found a matching number!', CTMNumber);
                        let receivingNumber = CTMNumber.attributes.receivingNumber
                        if(receivingNumber.length === 10){
                            receivingNumber = '+1'+receivingNumber
                        }
                        var trackingCode = {
                            status : CTMNumber.attributes.status,
                            facilityId : facilityId,
                            number : CTMNumber.attributes.trackingNumber,
                            receivingNumber,
                            filter_id: CTMNumber.attributes.filter_id
                        }
                        if(trackingCode.number && trackingCode.receivingNumber){
                            context.commit('changeTrackingNumbersPush', trackingCode)
                        }

                    } else {// LOOK UP CTM NUMBER IN SYMFONY
                        symfonyLookupFacilityIds.push(facilityId);
                    }

                }
            } else {
                symfonyLookupFacilityIds = facilityIds
            }

            if(symfonyLookupFacilityIds.length){
                // console.log('SYMFONY LOOKUP FACILITY IDS', symfonyLookupFacilityIds);
                let CTMNumbers = await context.dispatch('getChildDataApi', {facilityId: symfonyLookupFacilityIds.join(','), childId: 42})
                // console.log('NUMBER FROM SYMFONY', CTMNumbers)

                if(! Array.isArray(CTMNumbers)){
                    CTMNumbers = [CTMNumbers]
                }
                // console.log('NUMBER FROM SYMFONY AS ARRAY', CTMNumbers)
                for(const CTMNumber of CTMNumbers) {
                    const productId = 1

                    if(CTMNumber.results && CTMNumber.results.length && CTMNumber.results[0] && CTMNumber.results[0].content){
                        let number = CTMNumber.results[0].content.replace(/\D/g,'')
                        if(number.length === 10){
                            number = '+1'+number
                        }
                        const trackingNumber = await context.dispatch('getFacilityTrackingNumberDetails', {facilityId: CTMNumber.facility_id, number})

                        if(trackingNumber){
                            trackingCode = {
                                filter_id: trackingNumber.data.numbers[0].filter_id,
                                status : trackingNumber.data.numbers[0].status,
                                active : trackingNumber.data.numbers[0].active,
                                facilityId : CTMNumber.facility_id,
                                source : trackingNumber.data.numbers[0].source,
                                number: trackingNumber.data.numbers[0].number,
                                receivingNumber: trackingNumber.data.numbers[0].route_to.dial[0].number,
                                tpn: trackingNumber.data.numbers[0].id,
                                rpn:trackingNumber.data.numbers[0].route_to.dial[0].id
                            }
                            context.dispatch('createNumber', {data: trackingCode, facilityId: CTMNumber.facility_id, productId})

                        }else{
                            trackingCode = {
                                status : 0,
                                facilityId : CTMNumber.facility_id,
                                receivingNumber: number,
                            }
                        }
                        context.commit('changeTrackingNumbersPush', trackingCode)
                    }
                }
            }
            return []
        },
        async getFacilityTrackingUrl(context, {facilityId}){
            facilityId = `${facilityId}`;
            const facilityIds = facilityId.split(',');
            const trackedUrl =  await axios.get(`${apiUrl}/trackingurls?facilityId=${facilityId}`)
            // console.log('TRACKED URL', JSON.parse(JSON.stringify(trackedUrl)))
            let symfonyLookupFacilityIds = []
            if(trackedUrl.data.data){

                for(const facilityId of facilityIds){
                    const facilityTrackingUrl = trackedUrl.data.data.find(url => url.attributes.facilityId == facilityId)
                    // console.log('FACILITY TRACKING URL', facilityId, facilityTrackingUrl);
                    if(facilityTrackingUrl){
                        let trackingCode = {
                            status : facilityTrackingUrl.attributes.status,
                            facilityId : parseInt(facilityId),
                            url: facilityTrackingUrl.attributes.url,
                            campaignId: facilityTrackingUrl.attributes.campaignId
                        }
                        // console.log('TRACKING CODE', trackingCode);
                        context.commit('pushTrackingUrl', trackingCode)
                    }else {// LOOK UP TRACKING URL IN SYMFONY
                        symfonyLookupFacilityIds.push(facilityId);
                    }

                }
            }else{
                symfonyLookupFacilityIds = facilityIds
            }

            // GET URLS FOR FACILITIES NOT AVAILABLE IN CONNECT DATABASE.
            if(symfonyLookupFacilityIds && symfonyLookupFacilityIds.length){
                const url = `${apiUrl}/getfacilitychilddataapis?external_ID=${symfonyLookupFacilityIds.join(',')}&child_ID=43`
                const trackingUrls = await axios.get(url)

                // console.log('TRACKING URLS FROM SYMFONY', trackingUrls);
                if(! Array.isArray(trackingUrls.data)){
                    trackingUrls.data = [trackingUrls.data]
                }

                if(trackingUrls.data && trackingUrls.data.length){

                    // console.log('TRACKING URLS', trackingUrls);

                    for(const trackingUrl of trackingUrls.data){
                        if(trackingUrl.results && trackingUrl.results.length){
                            let trackingCode = {
                                status : 0,
                                facilityId : parseInt(trackingUrl.facility_id),
                                url: trackingUrl.results[0].content,
                            }
                            context.dispatch('createUrl', {
                                url: trackingUrl.results[0].content,
                                facilityId: parseInt(trackingUrl.facility_id),
                                status: 1,
                                productId: 2
                            })
                            context.commit('pushTrackingUrl', trackingCode)
                        }
                    }
                }
            }
        },
         // UPDATE DESTINATION URL
         async updateUrl(context, {url, facilityId, campaignId}){

            const response = await axios.patch(`${apiUrl}/trackingurls/${facilityId}`, {campaignId, url})

            // console.log('UPDATE NUMBER RESPONSE', response);

            if(response){

                let trackingCode = {
                    status : true,
                    facilityId : parseInt(facilityId),
                    url,
                    campaignId
                }

                context.commit('pushTrackingUrl', trackingCode)
                return response.data
            }

            return []
        },
         // CREATE DESTINATION URL
         async createUrl(context, {url, facilityId, status, productId}){
            // console.log('CREATE URL FUNCTION')
            const response = await axios.post(`${apiUrl}/trackingurls`, {facilityId, url, status, productId})

            // console.log('UPDATE URL RESPONSE', response);

            if(response){
                context.commit('pushTrackingUrl', {
                    status : 1,
                    facilityId : facilityId,
                    url: url,
                    campaignId: context.getters.campaigns.find(campaign => campaign.productId === 1 && campaign.facilityId == facilityId)
                })
                return response.data
            }

            return []
        },
         // UPDATE DESTINATION PHONE NUMBER
         async createNumber(context, {data, facilityId, productId}){

            const response = await axios.post(`${apiUrl}/trackingnumbers`, {data, facilityId, productId})

            //console.log('UPDATE NUMBER RESPONSE', response);

            if(response){
                return response.data
            }

            return []
        },
        async getChildDataApi(context, {facilityId, childId}){
            var url = `${apiUrl}/getfacilitychilddataapis?external_ID=${facilityId}&child_ID=${childId}`
            const response = await axios.get(url)

            if(response.data){
                return response.data
            }
            return []
        },
        async getFacilityTrackingNumberDetails(context, {facilityId, number}){

            const apiUrl = config.apiUrl;

            try{
            
                const url = `${apiUrl}/calltrackingmetricsnumberdetailsapis?number=${number}`
                const response = await axios.get(url)

                if(response.data.numbers.length>0){
                    return response
                }else{
                    return 
                }            
            }catch(e){
                console.error('ERROR VERIFYING', e);
                this.verifyError = e.message
                return
            }
    
        },

        async updateGoPremiumFunnelInfo(context, funnelData){
            context.commit('setGoPremiumFunnelInfo', funnelData);
        },

        addVerifiedPhoneOrUrl(context, item){
            context.commit('addVerifiedPhoneOrUrl', item)
        },

        setGoPremiumCompleteFlag(context){
            context.commit('setGoPremiumCompleteFlag')
        },
    },
    mutations: {
        setGoPremiumCompleteFlag(state, value){
            if(value === undefined){
                state.goPremiumFunnelCompleteThisLogin = true;
            }else {
                state.goPremiumFunnelCompleteThisLogin = value
            }

        },
        setGoPremiumFunnelInfo(state, funnelData){
            state.goPremiumFunnelInfo = funnelData;
        },
        setFacilityInteractionCosts(state, {interactionCosts, facilityId}){
            state.interactionCosts[facilityId] = interactionCosts
        },

        setFacilityInteractionCostOverrides(state, {interactionCostOverrides, facilityId}){
            if(interactionCostOverrides.length > 0){
                state.interactionCostOverrides[facilityId] = interactionCostOverrides
            }
        },

        setFacilitySubscriptionCostOverrides(state, {subscriptionCostOverrides, facilityId}){
            if(subscriptionCostOverrides.length > 0){
                state.subscriptionCostOverrides[facilityId] = subscriptionCostOverrides
            }
        },

        setFacilityCalls(state, {data, facilityId}){
            let facilityCalls = state.facilityCalls.find(i => i.facilityId === facilityId)
            if(facilityCalls) {
                facilityCalls.data = data;
                return;
            }
            const facilityCallsObject = {
                facilityId,
                data: data
            }
            state.facilityCalls.push(facilityCallsObject);
        },
        pushTrackingUrl(state, trackingUrl){
            state.trackingUrls = state.trackingUrls.filter(url => parseInt(url.facilityId) !== parseInt(trackingUrl.facilityId))
            state.trackingUrls.push(trackingUrl)
        },
        setTrackingUrl(state, trackingUrls){
            state.trackingUrls = trackingUrls
        },
        addVerifiedPhoneOrUrl(state, item){
            if(!state.verifiedPhoneOrUrls.includes(item)){
                state.verifiedPhoneOrUrls.push(item)
            }
        },
        clearVerifiedPhoneOrUrl(state){
            state.verifiedPhoneOrUrls = []
        }
    },
    getters: {
        interactionCostsByFacility: (state) => id => {
            if(state.interactionCosts[id]){
                return state.interactionCosts[id]
            }
            return state.interactionCosts.default
        },
        interactionCostsOverridesByFacility: (state) => id => {
            if(state.interactionCostOverrides[parseInt(id)]){
                return state.interactionCostOverrides[parseInt(id)]
            }
            return null
        },

        subscriptionCostsOverridesByFacility: (state) => id => {
            if(state.subscriptionCostOverrides[parseInt(id)]){
                return state.subscriptionCostOverrides[parseInt(id)]
            }
            return null
        },

        facilityCalls: (state) => id => {
            return state.facilityCalls.find(i => i.facilityId === id)
        },
        allFacilityCalls: (state) => {
            return state.facilityCalls
        },
        goPremiumFunnelInfo: state => {
            return state.goPremiumFunnelInfo
        },
        trackingUrls:  state => {
            return state.trackingUrls
        },
        facilityTrackingUrl: state => facilityId => {
            const trackingUrl = state.trackingUrls.find(url => url.facilityId == facilityId)
            if(trackingUrl) return trackingUrl
            return null
        },
        verifiedPhoneOrUrls: state => {
            return state.verifiedPhoneOrUrls
        },
        singleVerifiedPhoneOrUrl: state => item => {
            // console.log('FINDING VERIFIED PHONE OR URL', JSON.parse(JSON.stringify(item)), JSON.parse(JSON.stringify(state.verifiedPhoneOrUrls)))
            // console.log('WAS IT FOUND?', state.verifiedPhoneOrUrls.find(obj => obj.facilityId == item.facilityId && obj.productId == item.productId))
            return state.verifiedPhoneOrUrls.find(obj => obj.facilityId == item.facilityId && obj.productId == item.productId)
        },
        goPremiumFunnelCompleteThisLogin: state => {
            return state.goPremiumFunnelCompleteThisLogin
        },

        getFacilityInteractionCostsByDate: (state, getters) => ({facilityId, date}) => {
            let callCost = 0;
            let clickCost = 0;
            // GET DEFAULT INTERACTION COSTS
            const defaults = JSON.parse(JSON.stringify(getters.interactionCostsByFacility()))
            const defaultCallCost = defaults.find(i=> i.product.slug === 'premium-calls').defaults
            const defaultClickCost  = defaults.find(i=> i.product.slug === 'premium-clicks').defaults

            // GET THE INTERACTION COSTS FOR THIS FACILITY THAT MONTH
            let facilityInteractionCosts =  JSON.parse(JSON.stringify(getters.interactionCostsByFacility(facilityId)));
            const callCostItem = facilityInteractionCosts.find(i =>
                i.product.slug === "premium-calls" &&
                i.overrides != null &&
                i.startDate &&
                i.expireDate &&
                (isAfter(date, i.startDate) || date === i.startDate) &&
                isBefore(date, i.expireDate)
            )
            const clickCostItem = facilityInteractionCosts.find(i =>
                i.product.slug === "premium-clicks" && // is premium clicks
                i.overrides != null && // has an overrides value
                i.startDate && // has a start date
                i.expireDate && // has an expire date
                (isAfter(date, i.startDate) || date === i.startDate) && // Date passed to function is same as or after interaction cost start date
                isBefore(date, i.expireDate)// Date passed to function is before expire date
            )

            if(callCostItem){
                callCost = parseFloat(callCostItem.overrides)
            }else{
                callCost = parseFloat(defaultCallCost)
            }

            if(clickCostItem){
                clickCost = parseFloat(clickCostItem.overrides)
            }else {
                clickCost = parseFloat(defaultClickCost)
            }

            // HANDLE OVERRIDES
            let overrideCosts = JSON.parse(JSON.stringify(getters.interactionCostsOverridesByFacility(facilityId)));
            if(overrideCosts && overrideCosts.length){
                const callOverride = overrideCosts.find(item =>
                    item.product.slug === 'premium-calls' &&
                    item.startDate &&
                    item.expireDate &&
                    item.overrides &&
                    (isAfter(date, item.startDate) || date === item.startDate) &&
                    isBefore(date, item.expireDate)
                )
                const clickOverride = overrideCosts.find(item =>
                    item.product.slug === 'premium-clicks' &&
                    item.startDate &&
                    item.expireDate &&
                    item.overrides &&
                    (isAfter(date, item.startDate) || date === item.startDate) &&
                    isBefore(date, item.expireDate)
                )

                if(callOverride){
                    // console.log('CALL OVERRIDE', callOverride)
                    callCost = parseFloat(callOverride.overrides)
                }

                if(clickOverride){
                    // console.log('CLICK OVERRIDE', clickOverride)
                    clickCost = parseFloat(clickOverride.overrides);
                }
            }
            return {
                singleCallCost: callCost,
                singleClickCost: clickCost
            }
        },

        calculateFacilityFunnelBudget: (state, getters) => (budget) => {
      
            const goPremiumFunnelInfo = getters.goPremiumFunnelInfo
           // console.log(goPremiumFunnelInfo.goal)
            const callCost = budget / goPremiumFunnelInfo.facilityPricing.singleCallCost;
            const clickCost =  budget / goPremiumFunnelInfo.facilityPricing.singleClickCost;
            if(goPremiumFunnelInfo.goal === 'calls'){
                return Math.ceil(callCost);
            }else if(goPremiumFunnelInfo.goal === 'clicks'){
                return Math.ceil(clickCost);
            }else {

                const budgetCalls = budget * 0.3;
                const budgetClicks = budget * 0.7;
                const callCost = budgetCalls/goPremiumFunnelInfo.facilityPricing.singleCallCost;
                const clickCost = budgetClicks/goPremiumFunnelInfo.facilityPricing.singleClickCost;
                const price = {
                    calls: Math.ceil(callCost),
                    clicks: Math.ceil(clickCost)
                }
                return price;
            }

        },

        calculateBudget: (state, getters) => ({numInteractions, startDate}) => {
            // console.log('PERIOD START DATE', startDate);
            //if(!numInteractions) return;

            const facilities = getters.facilities;

            const facilityBudgets = [];
            let totalCalls = 0;
            let totalClicks = 0;

            for(const facility of facilities){
                const facilityBudget = getters.calculateFacilityBudget({
                    facilityId: facility.attributes.facilityId,
                    startDate,
                    numInteractions
                })
                 //console.log('FACILITY BUDGET', facilityBudget, '\n\n\n\n');
                totalCalls += facilityBudget.singleCallCost;
                totalClicks += facilityBudget.singleClickCost
                facilityBudgets.push(facilityBudget)
            }

            // console.log('TOTAL CALLS', totalCalls)
            // console.log('TOTAL CLICKS', totalClicks)
            const singleCallAverage = totalCalls / facilityBudgets.length
            const singleClickAverage = totalClicks / facilityBudgets.length

             //console.log('AVERAGES', singleCallAverage, singleClickAverage);


            const returnObj = {
                callBudgetAverage: numInteractions,
                clickBudgetAverage:numInteractions,
                totalBudgetAverage: numInteractions,
                facilityBudgets,
                singleCallAverage,
                singleClickAverage
            }

            //console.log('ACCOUNT BUDGET STUFF', returnObj);

            return returnObj


        },

        calculateFacilityBudget:(state, getters) => ({facilityId, startDate, numInteractions}) => {
            // GET DEFAULT INTERACTION COSTS
            const defaults = JSON.parse(JSON.stringify(getters.interactionCostsByFacility()))
            const defaultCallCost = defaults.find(i=> i.product.slug === 'premium-calls').defaults
            const defaultClickCost  = defaults.find(i=> i.product.slug === 'premium-clicks').defaults

            // console.log('FACILITY', facilityId);
            let callCost = 0;
            let clickCost = 0;
            // CHECK FOR FACILTY SPECIFIC INTERACTION COSTS
            let costs =  JSON.parse(JSON.stringify(getters.interactionCostsByFacility(facilityId)))
            // console.log("byFacility:",costs)

            // DOES THIS FACILITY HAVE COSTS DEFINED?
            // console.log('COSTS', costs);
            const callCostItem = costs.find(i=> i.product.slug === "premium-calls")
            const clickCostItem  = costs.find(i=> i.product.slug === "premium-clicks")
            if(callCostItem && callCostItem.overrides){
                callCost = parseFloat(callCostItem.overrides)
            }else{
                callCost = parseFloat(defaultCallCost)
            }
            if(clickCostItem && clickCostItem.overrides != null){
                clickCost = parseFloat(clickCostItem.overrides)
            }else{
                clickCost = parseFloat(defaultClickCost)
            }

            // DOES THIS FACILITY HAVE ADMIN OVERRIDES ON PRICING?
            let overrideCosts = JSON.parse(JSON.stringify(getters.interactionCostsOverridesByFacility(facilityId)));
            // console.log('override costs', facility.attributes.facilityId,  overrideCosts);

            if(overrideCosts && overrideCosts.length){
                // console.log('THERE ARE OVERRIDES', overrideCosts);
                const callOverride = overrideCosts.find(item => item.product.slug === 'premium-calls')
                const clickOverride = overrideCosts.find(item => item.product.slug === 'premium-clicks')


                if(callOverride){
                    if(startDate){ // START DATE IS START OF PERIOD
                        if(
                            (isAfter(new Date(startDate), new Date(callOverride.startDate)) || isSameDay(new Date(startDate), new Date(callOverride.startDate))) &&
                            isBefore(new Date(startDate), new Date(callOverride.expireDate))
                        ){
                            callCost = parseFloat(callOverride.overrides)
                        }
                    }else {
                        // console.log('CALL OVERRIDE', callOverride)
                        callCost = parseFloat(callOverride.overrides)
                    }

                }
                if(clickOverride){
                    // console.log('THERE IS A CLICK OVERRIDE', clickOverride);
                    if(startDate){
                        // console.log(new Date(startDate), new Date(clickOverride.startDate))
                        // console.log(isAfter(new Date(startDate), new Date(clickOverride.startDate)))
                        // console.log(isBefore(new Date(startDate), new Date(clickOverride.expireDate)))
                        if(
                            isAfter(new Date(startDate), new Date(clickOverride.startDate))  || isSameDay(new Date(startDate), new Date(clickOverride.startDate)) &&
                            isBefore(new Date(startDate), new Date(clickOverride.expireDate))
                        ){
                            clickCost = parseFloat(clickOverride.overrides)
                        }
                    }else {
                        // console.log('CLICK OVERRIDE', clickOverride)
                        clickCost = parseFloat(clickOverride.overrides);
                    }
                }

                // console.log('CLICK COST', clickCost);
            }

            // console.log('CALL COST', callCost)
            // console.log('CLICK COST', clickCost);
            // console.log('NUM INTERACTIONS', numInteractions);
            // console.log('CALL BUDGET', numInteractions)
            // console.log('CLICK BUDGET', numInteractions)

            const returnObj = {
                facilityId: facilityId,
                callBudget: numInteractions,
                clickBudget: numInteractions,
                totalBudget: numInteractions,
                singleClickCost: clickCost,
                singleCallCost: callCost
            }

             //console.log('calculateFacilityBudget RETURN: ', JSON.parse(JSON.stringify(returnObj)))

            return returnObj
        },

        getBudgetBreakdown: (state, getters) => item => {
        //type, budget, facilityId
            var callCost, clickCost = 0

            let defaults = JSON.parse(JSON.stringify(getters.interactionCostsByFacility()))
            // console.log('DEFAULT INTERACTION COSTS', defaults);
            var defaultCallCost = defaults.find(i=> i.productId === 1).defaults
            var defaultClickCost  = defaults.find(i=> i.productId === 2).defaults

            let costs =  JSON.parse(JSON.stringify(getters.interactionCostsByFacility(item.facilityId)))
            // console.log("byFacility:",costs)
        
            var callCostItem = costs.find(i=> i.productId === 1) 
            var clickCostItem  = costs.find(i=> i.productId === 2) 
           
            if(callCostItem.overrides != null){
                callCost = callCostItem.overrides
            }else{
                callCost = defaultCallCost
            }
     
            if(clickCostItem.overrides != null){
                clickCost = clickCostItem.overrides
            }else{
                clickCost = defaultClickCost
            }         
    
            var calls, clicks = 0
            
            // console.log(callCost)
            // console.log(clickCost)
            if(item.type == 'callsAndClicks'){
                if(item.budget == 4800){
                    calls = Math.floor( Number(item.budget)/callCost) -2
                    clicks = Math.round((item.budget-(calls*callCost))/clickCost)
                }else{
                    calls = Math.floor( Number(item.budget)/callCost) 
                    clicks = Math.round((item.budget-(calls*callCost))/clickCost)
                }
                
            }

            if(item.type == 'calls'){
                calls = Math.floor( Number(item.budget)/callCost)   
            }

            if(item.type == 'clicks'){
                clicks = Math.floor( Number(item.budget)/clickCost)   
            }
            // console.log(item.type)
            var data = {
                calls: {
                    budget: calls * callCost,
                    calls
                },
                clicks: {
                    budget: clicks * clickCost,
                    clicks
                }
            }
            // console.log(data)
            return data
        
        }
    }
}