import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
    state: () => ({

    }),
    actions: {
        async fetchPremiumLeads(context, {facilityIds, startDate, endDate}){
            const url = `${apiUrl}/getfacilitymatchesapis?external_IDs=${facilityIds}&startDate=${startDate}&endDate=${endDate}&status=1&type=instabook`
            const result = await axios.get(url);
            //console.log('BED REQUEST RESULT ', JSON.parse(JSON.stringify(result.data)));
            return result.data;
        }, 
        async fetchSingleMatch(context, id){
            const url = `${apiUrl}/getfacilitymatchesapis/${id}`
            const result = await axios.get(url);
            return result.data;
        },
        async fetchSingleLead(context, id){
            const url = `${apiUrl}/getfacilityleadsapis/${id}`
            const result = await axios.get(url);
            return result.data;
        },
        async fetchMatches(context, {facilityIds, startDate, endDate}){
            const url = `${apiUrl}/getfacilitymatchesapis?external_IDs=${facilityIds.join(",")}&startDate=${startDate}&endDate=${endDate}&status=1&type=`
            const response = await axios.get(url);
            return response.data
        }
    },
    mutations: {

    },
    getters: {

    }
}