import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
    actions: {
      async recoverPassword(context, data) {
        const url = `${apiUrl}/auth/recover`
        const result = await axios.post(url, {
          data: {
            attributes: {
              data,
            }
          }
        })  
        return result.data
      },

      async RecoverEmail(context, {email}) {
        const apiurl = `${apiUrl}/emails/recover`
        const result = await axios.post(apiurl, {
          data: {
            attributes: {
              email
            }
          }
        })
        return result
    },

    async loginUser(context, {email, password, token}){
      //console.log("Email:", email)
      //console.log("Password:", password)
      //console.log("Token:", token)
      try{
        const postForm = (path, params, method) => {
          method = method || 'post';

          var form = document.createElement('form');
          form.setAttribute('method', method);
          form.setAttribute('action', path);

          for (var key in params) {
            if (params.hasOwnProperty(key)) {
              var hiddenField = document.createElement('input');
              hiddenField.setAttribute('type', 'hidden');
              hiddenField.setAttribute('name', key);
              hiddenField.setAttribute('value', params[key]);

              form.appendChild(hiddenField);
            }
          }

          document.body.appendChild(form);
          form.submit();
        }

        const loginResponse = await axios.post(config.apiUrl + '/auth/login', {
          email,
          password,
          ...token && {token}
        });

        //console.log('#####loginResponse: ', loginResponse);

        // CHECK TO SEE IF THEY HAVE TOKEN, IF NOT REDIRECT TO GOV2
        if(loginResponse.data.facilityId){
          var data = {
            facilityId: loginResponse.data.facilityId,
            contactEmail: email,
          }

          //console.log('LOGIN RESPONSE', JSON.parse(JSON.stringify(loginResponse.data)));


          // POST TO THE GO FUNNEL AND REDIRECT TO STEP 4
          if(window.location.origin === "https://connect-staging.rehab.com"){
            await postForm('https://staging.rehab.com/signup/'+loginResponse.data.facilityId+'/4', {email, password, session: false});
            return {message: 'GoV2 redirect'}
          }else if(window.location.origin === "https://connect-dev.rehab.com"){
            await postForm('https://dev.rehab.com/signup/'+loginResponse.data.facilityId+'/4', {email, password, session: false});
            return {message: 'GoV2 redirect'}
          }else if(window.location.origin === "https://connect-demo.rehab.com"){
            await postForm('https://demo.rehab.com/signup/'+loginResponse.data.facilityId+'/4', {email, password, session: false});
            return {message: 'GoV2 redirect'}
          }else if(window.location.origin === "https://connect.rehab.com"){
            await postForm('https://www.rehab.com/signup/'+loginResponse.data.facilityId+'/4', {email, password, session: false});
            return {message: 'GoV2 redirect'}
          }else{
            await postForm('http://local.rehab.com/signup/'+loginResponse.data.facilityId+'/4', {email, password, session: false});
            return {message: 'GoV2 redirect'}
          }
          throw new Error('gov2-redirect');

        }

        // SET USER IN LOCAL STORAGE AND VUEX
        this._vm.$ls.set('user', JSON.stringify(loginResponse.data));
        context.commit('setUser', loginResponse.data)
        //console.log('LOGIN RESPONSE', JSON.parse(JSON.stringify(loginResponse.data)));

        // FORMAT STATUS LOGS LIKE API
        const statusLogs = loginResponse.data.statusLogs.map(item => {
          return {
            attributes: {
              action: item.action,
              createdAt: item.createdAt,
              statusLogTypeId: item.statusLogTypeId,
              updatedAt: item.updatedAt,
              userId: item.userId,
              statusLogType: item.statusLogType
            },
            id: item.id,
            type: "statusLogs"
          }
        })
        context.commit('setStatusLogs', statusLogs);

        // FORMAT LOGIN LOGS LIKE API
        const loginLogs = loginResponse.data.loginLogs.map(item => {
          return {
            attributes: {
              accountId: item.accountId,
              action: item.action,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              userId: item.userId
            },
            id: item.id,
            type: "loginLogs"
          }
        })
        context.commit('setLoginLogs', {data: loginLogs})

        // FORMAT THE FACILITIES LIKE THE API DOES
        let counter = 0
        const facilities = loginResponse.data.company.facilities.map(item => {
          // console.log('FACILITY', JSON.parse(JSON.stringify(item)))
          // console.log('FACILITY TYPE', JSON.parse(JSON.stringify(item.facilityType)))
          return {
            attributes: {
              addressline1: item.addressline1,
              addressline2: item.addressline2,
              city: item.city,
              companyId: item.companyId,
              createdAt: item.createdAt,
              facilityId: item.facilityId,
              name: item.name,
              state: item.state,
              updatedAt: item.updatedAt,
              zip: item.zip,
              counter: (counter++) * 2,
              facilityTypeId: item.facilityTypeId,
              facilityType: item.facilityType
            },
            id: item.id,
            type: 'facilities'
          }
        })

        // console.log('the facilities', JSON.parse(JSON.stringify(facilities)))



        // FACILITY TRACKING NUMBERS AND TRACKING URLS
        const facilityIds = facilities.map(facility => facility.attributes.facilityId);
        context.dispatch('getFacilityTrackingUrl', {facilityId: facilityIds.join(',')});
        context.dispatch('getFacilityTrackingNumber', {facilityIds})

        // SET INTERACTION COSTS
        let subscriptions = [];
        for(const facility of loginResponse.data.company.facilities ){
          await context.commit('setFacilityInteractionCosts', {
            interactionCosts: facility.interactionCosts,
            facilityId: parseInt(facility.facilityId)
          })

          await context.commit('setFacilityInteractionCostOverrides', {
            interactionCostOverrides: facility.interactionCostOverrides,
            facilityId: parseInt(facility.facilityId)
          })

          await context.commit('setFacilitySubscriptionCostOverrides', {
            subscriptionCostOverrides: facility.subscriptionCostOverrides,
            facilityId: parseInt(facility.facilityId)
          })

          if(facility.subscriptions && facility.subscriptions.length){
            for(const subscription of facility.subscriptions) subscriptions.push(subscription);
          }
        }

        await context.dispatch('fetchInteractionCostsLookup', {facilityIds: ''});
        context.commit('setSubscriptions', subscriptions);

        context.commit('change', facilities)

        context.dispatch('fetchFacilities').then(() => {
          // console.log('DONE GETTING FACILITIES');
          // console.log('FACILITYIDS', context.getters.facilityIds);
          context.dispatch('fetchInteractionCostsLookup', {facilityIds: context.getters.facilityIds.join(',')})
        })

        // FORMAT THE CAMPAIGNS LIKE THE API DOES
        const campaigns = loginResponse.data.account.campaigns.map(item => {
          if(item.startDate){
            context.commit('addVerifiedPhoneOrUrl', {facilityId: item.facilityId, productId: item.productId})
          }
          return {
            attributes: {
              accountId: item.accountId,
              budget: item.budget,
              createdAt: item.createdAt,
              facilityId: item.facilityId,
              goals: item.goals,
              lastUpdatedBy: item.lastUpdatedBy,
              productId: item.productId,
              startDate: item.startDate,
              status: item.status,
              updatedAt: item.updatedAt
            },
            id: item.id,
            type: 'campaign',
            relationships: {
              account: {id: loginResponse.data.accountId}
            }
          }
        })
        context.commit('changeCampaigns', campaigns);

        // FIGURE OUT DEFAULT PAYMENT METHOD:
        if(loginResponse.data.account.paymentMethodId && (loginResponse.data.account.defaultCreditCard || loginResponse.data.account.defaultBankAccount )){
          if(loginResponse.data.account.paymentMethod.name === 'Credit Card'){
            const card = JSON.parse(JSON.stringify(loginResponse.data.account.defaultCreditCard));
            card.paymentMethod = "Credit Card"
            context.commit('setDefaultPaymentMethod', card);
          }else if(loginResponse.data.account.paymentMethod.name === 'Bank Account'){
            const bank = JSON.parse(JSON.stringify(loginResponse.data.account.defaultBankAccount));
            context.commit('setDefaultPaymentMethod', bank)
          }
        }

        // REDIRECT USERS TO CHOOSE BASIC OR PREMIUM IF THEY HAVE NOT DONE SO YET.
        if(loginResponse && loginResponse.data && loginResponse.data.createdAt){
          const campaigns = context.getters.campaigns;
          const activeCampaigns = campaigns.filter(campaign => campaign.status);
          const subscriptions = context.getters.subscriptions;
          const activeSubscriptions = subscriptions.filter(subscription => subscription.active === true);
          if(
              !context.getters.statusLogByName('ViewedPremiumVsBasicPage') &&
              activeCampaigns.length === 0 &&
              activeSubscriptions.length === 0
          ){
            //console.log('USER IS NOT A PREMIUM USER YET, NEED TO REDIRECT THEM TO CHOOSE PREMIUM OR BASIC')
            loginResponse.data.redirect = 'plan.basic-or-premium'
          }
        }

        context.dispatch('fetchHubspotContactByEmail');
        await context.dispatch('fetchStatusLogs');
        await context.dispatch('fetchProducts');
        
        context.dispatch('hubspotLoginTracking')
        context.dispatch('fetchHubspotOwner')

        if(loginResponse.data && loginResponse.data.failedPayments && loginResponse.data.failedPayments.length){
          context.commit('setFailedPayments', loginResponse.data.failedPayments)
        }

        return loginResponse;
      }catch(e){
        console.error('ERROR LOGGING IN', e);
        throw e;
      }

    }
  }
}