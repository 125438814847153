import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {format} from "date-fns";

export default {
  state: () => ({
    contactPageInfo: {},
    cityOptions: []
  }),
  actions: {
    async fetchContactPageInfo(context, facilityId) {
      // console.log('CALLED ME', facilityId);

      await context.dispatch('fetchFacilityDataChildNames')

      const contactOptions = context.getters.facilityDataChildNames(15)

      // LOAD CAMPAIGNS
      const campaigns = context.getters.campaigns.map(campaign => {
        return {
          id: campaign.id,
          status: campaign.attributes.status,
          budget: campaign.attributes.budget,
          facilityId: campaign.attributes.facilityId,
          startDate: campaign.attributes.startDate,
          productId: campaign.attributes.productId,
          interactions: [],
        }
      })

      // LOAD CITY/STATE INFO
      const states = await context.dispatch('getStates');
      const facilityInfo = await context.dispatch('fetchFacilityInformation', facilityId)
      const foundState = states.find(state => state.name === facilityInfo.facility.state)
      let cityOptions = await context.dispatch('getCities', facilityInfo.facility.city);
      // console.log('CITY OPTIONS', cityOptions )
      const foundCity = cityOptions.find(city => parseInt(city.id) === parseInt(facilityInfo.facility.cityId));

      // PHONE/WEBSITE INFO
      const contactInfo = [...JSON.parse(JSON.stringify(facilityInfo.facilityContactInfo.data))]
      let phone = null;
      let website = null;
      for(const contactItem of contactInfo){
        if(contactItem.sectionLabel === "rehab_center_phone"){
          phone = contactItem.content.replace(/^\+[1]/, '');
        } else if(contactItem.sectionLabel === "rehab_center_website"){
          website = contactItem.content
        }
      }

      // SET INITIAL DATA OBJECT
      const data = {
        information: {
          externalId: facilityId,
          addressLineFirst: facilityInfo.facility.addressLineFirst,
          addressLineSecond: facilityInfo.facility.addressLineSecond,
          city: foundCity,
          cityId: facilityInfo.facility.cityId,
          postalCode: facilityInfo.facility.postalCode,
          state: foundState? foundState.id : null,
          phone,
          website,
          receivingNumber: null,
          receivingUrl: null,
        },
        contactInfo,
        states,
        campaigns,
        pendingUpdate: false,
        cityOptions,
        contactOptions,
        users: context.getters.user,
        facilityInfo
      }

      // HANDLE ADDRESS PENDING UPDATES
      if(facilityInfo.facility && facilityInfo.facility.pendingUpdates && facilityInfo.facility.pendingUpdates.length > 0){
        for(const update of facilityInfo.facility.pendingUpdates){
          if(update.action === 'value_change'){
            // ADDRESS LINE 1 UPDATE
            if(update.changes.attribute === 'addressLineFirst'){
              data.information.addressLineFirst = update.changes.newValue;
              data.pendingUpdate = true;
            }
            // ADDRESS LINE 2 UPDATE
            else if(update.changes.attribute === 'addressLineSecond'){
              data.information.addressLineSecond = update.changes.newValue;
              data.pendingUpdate = true
            }
            // CITY/STATE UPDATE
            else if(update.changes.attribute === 'location'){
              const newLocation = update.changes.newValue.split(" - ");
              // console.log('NEW LOCATION', newLocation, `${newLocation[0]}, ${newLocation[1]}`);
              data.information.city = newLocation[0];
              data.cityOptions = await context.dispatch('getCities', newLocation[0]);
              const cityOption = data.cityOptions.find(city => city.label.trim() === `${newLocation[0]}, ${newLocation[1]}`)
              // console.log('CITY OPTION FOUND', JSON.parse(JSON.stringify(cityOption)));
              if(cityOption){
                data.information.city = JSON.parse(JSON.stringify(cityOption))

              }
              data.pendingUpdate = true;
            }
            // POSTAL CODE UPDATE
            else if (update.changes.attribute === "postalCode"){
              data.information.postalCode = update.changes.newValue;
              data.pendingUpdate = true;
            }
          }
        }
      }

      // HANDLE PHONE/WEBSITE PENDING UPDATES.
      if(facilityInfo.facilityContactInfo && facilityInfo.facilityContactInfo.pendingUpdates){
        for(const update of facilityInfo.facilityContactInfo.pendingUpdates){
          // console.log('CONTACT METHOD UPDATE:', JSON.parse(JSON.stringify(update)));

          // UPDATED VALUE CHANGES
          if(update.action === "value_change"){
            const updateMatch = contactInfo.find(item => item.id === update.object_id);
            if(updateMatch){
              // console.log('UPDATE MATCH', JSON.parse(JSON.stringify(updateMatch)));
              if(updateMatch.sectionLabel === "rehab_center_phone"){
                if(update.changes.newValue.length === 12){
                  data.information.phone = update.changes.newValue.slice(2)
                }else if(update.changes.newValue.length === 11 && update.changes.newValue[0] == 1){
                  data.information.phone = update.changes.newValue.slice(1)
                }else {
                  data.information.phone = update.changes.newValue
                }
                data.pendingUpdate = true;

              }else if(updateMatch.sectionLabel === "rehab_center_website"){
                data.information.website = update.changes.newValue;
                data.pendingUpdate = true;
              }
            }
          }
          // CREATES
          else if(update.action === 'created'){
            if(update.changes.attributes.child_name.section_name === 'Website'){
              data.information.website = update.changes.attributes.content
            }else if(update.changes.attributes.child_name.section_name === 'Phone'){
              data.information.phone = update.changes.attributes.content.slice(2);
            }
          }
        }
      }

      // GET RECEIVING NUMBER FROM STORE
      const receivingNumberObj = context.getters.facilityTrackingNumber(facilityId)
      let receivingNumber = ''
      if(receivingNumberObj)
        receivingNumber = receivingNumberObj.receivingNumber
      if(receivingNumber.length === 12)
        receivingNumber = receivingNumber.slice(2)
      if(receivingNumber.length === 11 && receivingNumber[0] === 1)
        receivingNumber = receivingNumber.slice(1)
      data.information.receivingNumber = receivingNumber

      // GET RECEIVING URL FROM STORE
      const receivingUrlObj = context.getters.facilityTrackingUrl(facilityId)
      if(receivingUrlObj) data.information.receivingUrl = receivingUrlObj.url

      // context.state.contactPageInfo[facilityId] = JSON.parse(JSON.stringify(data));
      context.commit('setFacilityContactInfo', {facilityId, data});
      // console.log('SET THE CONTACT PAGE INFO', context.state.contactPageInfo)
      // console.log('GOT THE DATA, RETURNING', JSON.parse(JSON.stringify(data)))
      return data;
    },

    async getStates(context){
      // console.log('GETTING STATES')
      const url = `${apiUrl}/states`
      const result = await axios.get(url);

      // console.log('STATES RESULT', result);
      return result.data
    },

    async getCities(context, search) {
      if(search && search.length >= 3){
        // console.log('CITY SEARCH QUERY', search);
        const result = await context.dispatch('searchCities', search);
        for(const city of result){
          city.label = await context.dispatch('nameWithState', city)
        }
        context.state.cityOptions = result;
        // console.log('CITY OPTIONS:', JSON.parse(JSON.stringify(this.cityOptions)))
        return context.state.cityOptions
      }
    },

    async nameWithState(context, item){
      // console.log('item', item, typeof item);
      if(typeof item === 'object'){
        const stateSplit = item.route.split('/')
        const stateSplit2 = stateSplit[0].split('-');
        let state = '';
        for(let s of stateSplit2){
          state += s.charAt(0).toUpperCase() + s.slice(1) + ' ';
        }
        // console.log('STATE', state);
        // console.log('this is the final name!', `${item.name}, ${state}`)
        return `${item.name}, ${state}`
      }
    },

    async updateContactPageInfo({state, dispatch}, {facilityId, formData, bulk, submitFields}) {
      // console.log('UPDATE CONTACT PAGE INFO CALLED', facilityId, formData, bulk, submitFields);
      // GET AN UPDATED COPY OF CONTACT PAGE INFO
      await dispatch('fetchContactPageInfo', facilityId);

      const promises = [];

      // UPDATE ADDRESS IF NOT A BULK EDIT
      if(!bulk) {
        promises.push(dispatch('updateFacilityAddressInfo', {facilityId, formData}))
      }

      if(submitFields.phone){
        promises.push(dispatch('updateFacilityPhoneNumber', {facilityId, formData}))
      }

      if(submitFields.website){
        promises.push(dispatch('updateFacilityWebsite', {facilityId, formData}))
      }

      promises.push(dispatch('updateReceivingURLAndPhone', {facilityId, formData, submitFields}))

      await Promise.all(promises);

    },

    async updateFacilityAddressInfo(context, {facilityId, formData}) {
      const contactPageInfo = context.state.contactPageInfo[facilityId]

      // PREP FACILITY INFORMATION UPDATE
      const data = {
        ...contactPageInfo.facilityInfo.facility.addressLineFirst !== formData.addressLineFirst && {addressLineFirst: formData.addressLineFirst},
        ...contactPageInfo.facilityInfo.facility.addressLineSecond !== formData.addressLineSecond && {addressLineSecond: formData.addressLineSecond},
        ...contactPageInfo.facilityInfo.facility.cityId !== formData.cityId && {city: formData.cityId},
        ...contactPageInfo.facilityInfo.facility.postalCode !== formData.postalCode && {postalCode: formData.postalCode},
      }

      // SUBMIT UPDATED FACILTIY INFO
      if(Object.keys(data).length > 0){
        await context.dispatch('updateFacilityInformation', {
          facilityId,
          type: 'information',
          data
        })
      }

      // DELETE PENDING ADDRESS UPDATES
      if(contactPageInfo.facilityInfo.facility && contactPageInfo.facilityInfo.facility.pendingUpdates){
        const addressProperties = ['addressLineFirst', 'addressLineSecond', 'postalCode']
        const addressUpdates = contactPageInfo.facilityInfo.facility.pendingUpdates.filter(update =>
            addressProperties.includes(update.changes.attribute)
        )

        if(addressProperties){
          for(const update of addressUpdates){
            // console.log('DELETING', JSON.parse(JSON.stringify(update)));
            await context.dispatch('deleteContentUpdate', update.id);
          }
        }

        // DELETE CITY UPDATES SEPARATELY
        const locationUpdates = contactPageInfo.facilityInfo.facility.pendingUpdates.filter(update => update.changes.attribute === 'location')
        if(data.city && locationUpdates){
          for(const update of locationUpdates){
            await context.dispatch('deleteContentUpdate', update.id);
          }
        }

      }
    },

    async updateFacilityPhoneNumber(context, {facilityId, formData}) {
      const contactPageInfo = context.state.contactPageInfo[facilityId]

      const formerPhoneObj = contactPageInfo.facilityInfo.facilityContactInfo.data.find(item => item.sectionLabel === 'rehab_center_phone');
      const currentStrippedPhone = '+1' + formData.phone.replace(/\D/g,'')

      // UPDATE EXISTING PHONE NUMBER
      if(formerPhoneObj){
        if(formerPhoneObj.content !== currentStrippedPhone){
          const update = await context.dispatch('updateAdminItem', {
            facilityId,
            childId: formerPhoneObj.id,
            content: currentStrippedPhone,
            type: 'contact-information'
          })
        }
      }
      // NEW PHONE NUMBER
      else {
        const phoneContactType = contactPageInfo.contactOptions.find(item => item.sectionLabel === 'rehab_center_phone')
        const created = await context.dispatch('updateAdminItem', {
          facilityId,
          type: 'contact-information',
          childId:  phoneContactType.id,
          content: currentStrippedPhone
        });
      }

      // DELETE PENDING PHONE UPDATES.
      if(contactPageInfo.facilityInfo.facilityContactInfo && contactPageInfo.facilityInfo.facilityContactInfo.pendingUpdates){
        const pendingPhoneUpdates = contactPageInfo.facilityInfo.facilityContactInfo.pendingUpdates.filter(update =>
            (
                formerPhoneObj &&
                update.action === 'value_change' &&
                update.object_id === formerPhoneObj.id
            ) ||
            (
                update.action === 'created' &&
                update.changes.attributes.child_name.section_name === 'Phone'
            )
        );

        for(const update of pendingPhoneUpdates){
          await context.dispatch('deleteContentUpdate', update.id);
        }
      }

    },
    
    async updateFacilityWebsite(context, {facilityId, formData}){
      const contactPageInfo = context.state.contactPageInfo[facilityId]

      const formerWebsiteObject = contactPageInfo.facilityInfo.facilityContactInfo.data.find(item => item.sectionLabel === 'rehab_center_website')
      // console.log('FORMER WEBSITE OBJECT', formerWebsiteObject)
      if(formerWebsiteObject){ //UPDATE
        if(formerWebsiteObject.content !== formData.website){
          const update = await context.dispatch('updateAdminItem', {
            facilityId,
            childId: formerWebsiteObject.id,
            content: formData.website,
            type: 'contact-information'
          })
        }
      }else { // NEW WEBSITE
        const websiteContactType = contactPageInfo.contactOptions.find(item => item.sectionLabel === "rehab_center_website")
        // console.log('WEBSITE CONTACT TYPE', JSON.parse(JSON.stringify(websiteContactType)));
        await context.dispatch('createAdminItem', {
          facilityId,
          type: 'contact-information',
          childId: websiteContactType.id,
          content: formData.website
        })

      }

      // DELETE PENDING WEBSITE UPDATES.
      if(contactPageInfo.facilityInfo.facilityContactInfo && contactPageInfo.facilityInfo.facilityContactInfo.pendingUpdates){
        const pendingPhoneUpdates = contactPageInfo.facilityInfo.facilityContactInfo.pendingUpdates.filter(update =>
            (
                formerWebsiteObject &&
                update.action === 'value_change' &&
                update.object_id === formerWebsiteObject.id
            ) ||
            (
                update.action === 'created' &&
                update.changes.attributes.child_name.section_label === 'rehab_center_website'
            )
        );

        for(const update of pendingPhoneUpdates){
          await context.dispatch('deleteContentUpdate', update.id);
        }
      }
    },
    
    async updateReceivingURLAndPhone(context, {facilityId, formData, submitFields}){
      // console.log('UPDATE RECEIVING URL AND PHONE', facilityId, formData, submitFields)

      const contactPageInfo = context.state.contactPageInfo[facilityId]

      const clickProduct = context.getters.clickProduct;
      const callProduct = context.getters.callProduct;



        // CREATE OR UPDATE RECEIVING NUMBER
      if(submitFields.receivingNumber){
        if(
            context.getters.campaigns.find(campaign =>
                campaign.attributes.facilityId === facilityId &&
                campaign.attributes.status &&
                campaign.attributes.productId == context.getters.callProduct.id
            )){
          const formerReceivingNumberObj = context.getters.facilityTrackingNumber(facilityId)
          const currentStrippedReceivingPhone = '+1' + formData.receivingNumber.replace(/\D/g,'')
          if(formerReceivingNumberObj){ // UPDATE
            if(formerReceivingNumberObj.receivingNumber !== currentStrippedReceivingPhone){
              // console.log('PHONE UPDATE NEEDED HERE')
              // console.log('CAMPAIGNS', facilityId, JSON.parse(JSON.stringify(contactPageInfo.campaigns)));
              let result = context.dispatch('ctmUpdateNumber', {
                number: currentStrippedReceivingPhone,
                facilityId,
                campaignId: contactPageInfo.campaigns.find(campaign => parseInt(campaign.facilityId) === parseInt(facilityId) && parseInt(campaign.productId) === parseInt(callProduct.id)).id
              })

            }
          }else { // CREATE NEW RECEIVING NUMBER
            const newNumberResult = await context.dispatch('ctmBuyNumber', {
              number: currentStrippedReceivingPhone,
              facilityId,
              campaignId: contactPageInfo.campaigns.find(campaign => parseInt(campaign.facilityId) === parseInt(facilityId) && parseInt(campaign.productId) === parseInt(callProduct.id)).id
            })
          }
        }
      }


      if(submitFields.receivingUrl){
        if(context.getters.campaigns.find(campaign =>
            campaign.attributes.facilityId === facilityId &&
            campaign.attributes.status &&
            campaign.attributes.productId == context.getters.clickProduct.id
        )) {
          // CREATE OR UPDATE RECEIVING URL
          const formerReceivingUrlObj = context.getters.facilityTrackingUrl(facilityId)
          if (formerReceivingUrlObj) { // UPDATE
            if (formerReceivingUrlObj.url !== formData.receivingUrl) {
              const result = await context.dispatch('updateUrl', {
                url: formData.receivingUrl,
                facilityId,
                campaignId: null
              })
            }
          } else { // CREATE
            const result = await context.dispatch('createUrl', {
              url: formData.receivingUrl,
              facilityId,
              status: 1,
              productId: clickProduct.id
            })
          }
        }
      }
      }

  },
  mutations: {
    setFacilityContactInfo(state, {facilityId, data}){
      state.contactPageInfo[facilityId] = JSON.parse(JSON.stringify(data));
    }
  },
  getters: {

  }
}