<template>
    <div>
        <general-form
            v-if="!loading"
            :loading="loading"
            :form-changed="formChanged"
            :original-form-data="formData"
            :pending-updates="pendingUpdates"
            @submit="attemptSubmit($event)"
        ></general-form>

        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script lang="javascript">
    import GeneralForm from './Forms/GeneralForm.vue'
    import { mapGetters, mapActions } from 'vuex'

    export default{
        name: 'General',
        components: {
            GeneralForm
        },
        data(){
            return {
                admissions: [],
                admissionsOptions: [],
                originalAccreditations: [],
                treatmentTypes: [],
                originalTreatmentTypes: [],
                treatmentFocusOptions: [],
                facilityData: {},
                array: [],
                boolean: null,
                loading: false,
                formChanged: false,
                formData: {
                    selectedLevelOfCare: null,
                    selectedLevelOfCares: [],
                    detox: 'no',
                    facilityName: '',
                    bedsAvailable: null,
                    bedsFree: null,
                    accreditations: [],
                    naatp: "no",
                    legitScript: "no",
                    stateLicense: null
                },
                showSnackbar: false,
                snackMsg: '',
                pendingUpdatesSnackMsg: "Your updates are pending review. They will go live once they have been reviewed.",
                // submitted: false,
                pendingUpdates: false,
                mobile:false,
                originalData: {
                    facilityName: null,
                }
            }
        },

        computed: {
            ...mapGetters([
                'facilityDataChildNames',
                'levelOfCareOptions',
                'accreditationsOptions'
            ]),
        },

        watch: {
            loading(newVal, oldVal) {
                this.$emit('loading', newVal);
            },
            '$route.params.facilityId'(newId, oldId) {
                if(newId !== oldId){
                   this.loadData()
                }
            }
        },

        async created(){
            await this.loadData();
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },

        methods: {
            ...mapActions([
                'fetchFacilityTreatmentFocuses',
                'fetchFacilityDataChildNames',
                'fetchFacilityInformation',
                'fetchFacilityAmenities',
                'updateFacilityInformation',
                'fetchFacilityAccreditations',
                'updateFacilityAdmission',
                'createAdminItem',
                'deleteAdminItem',
                'updateAdminItem',
                'deleteContentUpdate',
                'loadFacilityGeneralPageInfo',
                'updateFacilityGeneralInfo'
            ]),

            async loadData() {
                try{
                    this.loading = true;

                    // await this.fetchFacilityDataChildNames();
                    this.submitted = true
                    this.facilityId = parseInt(this.$route.params.facilityId)

                    const {pendingUpdates, facilityData, treatmentTypes, admissions, admissionsOptions, formData, originalTreatmentTypes, originalAccreditations} = await this.loadFacilityGeneralPageInfo(this.facilityId)
                    this.pendingUpdates = pendingUpdates;
                    this.facilityData = facilityData;
                    this.treatmentTypes = treatmentTypes;
                    this.admissions = admissions;
                    this.admissionsOptions = admissionsOptions;
                    this.formData = formData;
                    this.originalTreatmentTypes = originalTreatmentTypes;
                    this.originalAccreditations = originalAccreditations;

                     if(this.pendingUpdates) {
                         this.showPendingSnack();
                     }

                    this.loading = false;
                }catch (e) {
                    console.error(e)
                    this.loading = false;
                    this.snackMsg = "There was an error loading the facility details. Please contact support if this problem continues.";
                    this.showSnackbar = true;
                }
            },




            async attemptSubmit({formData, submitFields}){
                this.formData = formData
                try{
                    this.loading = true

                    await this.updateFacilityGeneralInfo({
                        facilityId: this.$route.params.facilityId,
                        formData,
                        submitFields
                    })

                    await this.loadData()
                    this.loading = false
                    this.showPendingSnack()
                }catch(e){
                    await this.loadData();
                    console.error('ERROR SUBMITTING', e);
                    this.snackMsg = "There was an error submitting the facility details. Please contact support."
                    this.showSnackbar = true;
                }
            },

            showPendingSnack() {
                // this.pendingUpdates = false;
                this.snackMsg = this.pendingUpdatesSnackMsg
                this.showSnackbar = true;
            },
        }
    }

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .admin .md-invalid .md-error{
            left: 6px!important;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
</style>