<template>
    <md-dialog :md-active.sync="firstTimeEditAdmin" class="welcome">
        <template>
            <div class="step1">
                <img src="/images/gopremium/connect_match_card.svg"/>
                <md-dialog-title>Match matters</md-dialog-title>
                <md-dialog-content>
                    Rehab.com lays the foundation for the future - it gives providers an advantage when it comes to discovery. And, most importantly, it empowers patients with viable options.
                </md-dialog-content>
                 <md-dialog-actions>
                    <md-button class="active center" @click="close()">LET'S GO</md-button>
                </md-dialog-actions>
            </div>
        </template>
    
    </md-dialog>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default{
        name: 'MatchCard',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
            }
        },
        watch: {
            'firstTimeEditAdmin'(newVal, oldVal) {
                if(newVal == false){
                     this.setBlur(false);
                }
            }
        },
        mounted() {
           this.$root.$on('matchTooltip', () => {
                this.firstTimeEditAdmin = true
                this.setBlur(true);
            })

            const matchTooltipComplete = this.$store.getters.statusLogByName('matchTooltip');
            if(!matchTooltipComplete) {
                this.firstTimeEditAdmin = true;
                this.setBlur(true);
            }
        },
        methods: {
             ...mapMutations([
                'setBlur'
            ]),
            close(){
                this.firstTimeEditAdmin = false;
                this.setBlur(false);
                this.$store.dispatch('createStatusLog', {
                    type: 'matchTooltip',
                    action: 'closed'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    
    .md-dialog.md-theme-default{
        background-color:unset;
    }
    .buttons{
        position: absolute;
        top:159px;
    }
    .md-dialog {
       
        img{
            height: 275px;
            margin-top: 30px;
        }

        .close{
            color: white;
            position: absolute;
            right: 34px;
            top:18px;
            font-size: 24px;
        }
        .md-dialog-content a{
            color: white;
        }
        .md-dialog-content{
            padding-left: 50px;
            padding-right: 50px;
        }
        .step1{
            background-color: #6955FF;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            margin-top: 40px;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step2{
            background-color: #50C878;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

             img{
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
         .step3{
            background-color: #FFBD44;
            color: #000;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .step4{
            background-color: #6955FF;
            color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            height: 515px;
            min-height: 515px; 
            text-align: center;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        }
        .md-dialog-title{
            display: block;
            padding-top: 30px;
        }
    
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color: white;
        }
        .secondary{
            color: #9fc1f9;
        }
        .md-dialog-actions{
            padding-right: 14px;
            position: absolute;
        }
        .center{
            top: -4px;
            left:260px;
            position: absolute;
            .md-button-content{
                text-transform: capitalize;
            }
            
        }
        .back{
            position: absolute;
            left: -78px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .forward{
            position: absolute;
            right: -679px;
            top: 71px;
            width: 10px;
            height: 10px;
        }
        .circle
        {
            border-radius: 50%;
            width: 40px;
            height: 40px; 
            background-color: rgba(0,0,0,0.4);
            min-width: unset; 
            .md-icon{
                color: #fff;
            }
        }
        .md-icon{
            font-size: 20px!important;
        }

        .steps{
            position: absolute;
            top: 537px;
            left: 50%;
            text-align: center;
            overflow: visible;
            margin-left: -58px;
   
            .circle{
                border-radius: 50%;
                width: 10px;
                height: 10px; 
                background-color: rgba(0,0,0,0.4);
                min-width: unset;
                display: inline-block;
                margin-right: 8px;
                opacity: 0.4;
            }
            .active{
                 background-color: #fff;
                  opacity: 1;
            }
        }

    }
      @media (max-width: 1550px){
        .organic{
            top: 100px;
            right: 45%;

        }
    }

    .md-dialog {
        box-shadow: none;
        transform: translate(-50%, -130px);
        top:250px;
        max-height: unset;

        .md-dialog-container{
            padding:40px!important;
             flex-flow: unset
        }
    }


@media (max-height: 800px) {
    .md-dialog {
        top: 200px;
    }
}

@media (max-height: 780px) {
    .md-dialog {
        top: 180px;
    }
}

@media (max-height: 770px) {
    .md-dialog {
        top: 160px;
    }
}

@media (max-height: 650px) {
    .md-dialog {
        top: 20%;
    }
}

@media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-dialog{
            .step1, .step2, .step3, .step4{
                height: 100%;
                top:0px;
                margin: 0;
                padding: 0;
                border-radius: 0;
                width: 100%;
            }
            img{
                height: 225px;
            }
            .step1{
                .md-dialog-actions{
                    width: 100%;
                }
                .center{
                    left: 50%;
                    margin-left: -36px;
                }
            }
        }
    
    }

</style>

          