<template>
    <form novalidate @submit.prevent="attemptSubmit">
        <md-card>
            <md-card-content>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item md-size-80" :class="{'md-size-100': bulk}">
                        <table >
                            <!-- SELECT ALL -->
                            <tr v-if="bulk" class="select-all-row">
                                <td>
                                    <md-checkbox v-model="selectAll" ></md-checkbox>
                                </td>
                                <td>  
                                    <div class="selectAll">
                                        Select all properties
                                    </div>
                                </td>
                            </tr>

                            <!-- NAME -->
                            <tr>
                                <td>
                                    <div class="form-group" :class="{'md-invalid': showMdInvalidClass($v.formData.facilityName, submitted)}" v-if="!bulk">
                                        <label for="facility-name">Name</label>
                                        <input
                                                name="facility-name"
                                                id="facility-name"
                                                v-model="formData.facilityName"
                                                placeholder="Enter facility name"
                                                @input="formChanged = true"
                                        />
                                        <span
                                                class="md-error"
                                                v-if="!$v.formData.facilityName.required && showMdInvalidClass($v.formData.facilityName, submitted)"
                                        >
                                                                    <md-icon class="error-icon">error</md-icon>Facility name is required</span>
                                        <span class="md-error" v-else-if="!$v.formData.facilityName.min && showMdInvalidClass($v.formData.facilityName, submitted)"><md-icon class="error-icon">error</md-icon>Facility name is too short</span>
                                    </div>
                                </td>
                            </tr>

                            <!-- DETOX -->
                            <tr v-if="facilityType.slug !== 'physical_therapy' && facilityType.slug !== 'mental_health'">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.detox" ></md-checkbox>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <label for="detox">Detox</label>
                                        <div class="select-container" >
                                            <select
                                                    name="detox"
                                                    id="detox"
                                                    md-dense
                                                    v-model="formData.detox"
                                                    class="beds"
                                                    :class="{ 'disabled': bulk && !submitFields.detox}"
                                                    mdClass="treatment-type"
                                                    @change="formChanged = true"
                                                    :disabled="bulk && !submitFields.detox"
                                                   
                                            >
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>

                                            </select>
                                        </div>
                                    </div>

                                </td>
                            </tr>

                            <!--  PHYSICAL THERAPY SPECIALTIES -->
                            <tr v-if="facilityType.slug === 'physical_therapy'" id="pt_specialties">
                                <td v-if="bulk">
                                  <md-checkbox class="checkboxLineup" v-model="submitFields.specialties" ></md-checkbox>
                                </td>

                                <td>
                                    <div>
                                        <label>Specialties</label>
                                        <div :class="{ 'checkbox-container-holder': bulk,  'checkbox-container': !bulk, }">
                                            <md-checkbox
                                                v-model="formData.selectedSpecialties"
                                                v-for="option in ptSpecialties"
                                                :value="option.id"
                                                :key="option.id"
                                                class="checkbox pt-specialties"
                                                @change="formChanged = true"
                                                :disabled="bulk && !submitFields.specialties"
                                            >{{option.sectionName}}</md-checkbox>
                                        </div>
                                    </div>
                                </td>

                            </tr>

                            <!-- LEVEL OF CARE -->
                            <tr v-if="facilityType.slug !== 'mental_health'">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxLineup" v-model="submitFields.levelOfCare" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="levelofcare">Level of care</label>
                                        <div class="select-container">
                                            <select
                                                name="Level of care"
                                                id="levelofcare"
                                                v-model="formData.selectedLevelOfCare"
                                                class="beds"
                                                @change="formChanged = true"
                                                :class="{ 'disabled': bulk && !submitFields.levelOfCare}"
                                                :disabled="bulk && !submitFields.levelOfCare"
                                            >
                                                <option
                                                    v-for="option in levelOfCareOptions"
                                                    :key="option.id"
                                                    :value="option.id"
                                                >{{option.sectionName}}</option>
                                                <option value="both">Both</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- TOTAL BEDS -->
                            <tr v-if="facilityType.slug !== 'physical_therapy'">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.totalBeds" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="numberofbeds">Total beds</label>
                                        <div class="select-container">
                                            <select
                                                    name="Number of beds"
                                                    id="numberofbeds"
                                                    v-model="formData.bedsAvailable"
                                                    class="beds"
                                                    placeholder="Total number of beds"
                                                    @change="formChanged = true"
                                                    :class="{ 'disabled': bulk && !submitFields.totalBeds}"
                                                    :disabled="bulk && !submitFields.totalBeds"
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="40">40</option>
                                                <option value="80">80</option>
                                                <option value="100">100+</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>


                            <tr v-else>
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.totalBeds" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="numberofbeds">Program size</label>
                                        <div class="select-container">
                                          <input
                                              name="Number of beds"
                                              id="numberofbeds"
                                              type="text"
                                              v-model="formData.bedsAvailable"
                                              placeholder="Number of patients"
                                              @input="formChanged = true"
                                          />
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- AVAILABLE BEDS -->
                            <tr v-if="facilityType.slug !== 'physical_therapy' ">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.availableBeds" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="beds-free">Open beds</label>
                                        <div class="select-container">
                                            <select
                                                    name="beds-free"
                                                    id="beds-free"
                                                    v-model="formData.bedsFree"
                                                    class="beds"
                                                    placeholder="No. of available beds"
                                                    @change="formChanged = true"
                                                    :class="{ 'disabled': bulk && !submitFields.availableBeds}"
                                                    :disabled="bulk && !submitFields.availableBeds"
                                            >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="40">40</option>
                                                <option value="80">80</option>
                                                <option value="100">100+</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr v-else>
                                <td v-if="bulk">
                                  <md-checkbox class="checkboxNormal" v-model="submitFields.availableBeds" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="beds-free">Availability</label>

                                        <div class="select-container">
                                            <select
                                                name="beds-free"
                                                id="beds-free"
                                                v-model="formData.bedsFree"
                                                class="beds"
                                                placeholder="Availability"
                                                @change="formChanged = true"
                                                :class="{ 'disabled': bulk && !submitFields.availableBeds}"
                                                :disabled="bulk && !submitFields.availableBeds"
                                            >
                                              <option value="0%">Not accepting new patients</option>
                                              <option value="10%">10</option>
                                              <option value="20%">20</option>
                                              <option value="40%">40</option>
                                              <option value="80%">80</option>
                                              <option value="100%">100+</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- ACCREDITATIONS -->
                            <tr>
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxLineup" v-model="submitFields.accreditations" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="accreditations-select">Accreditations</label>

                                        <div class="select-container">
                                            <select
                                                name="accreditations-select"
                                                id="accreditations-select"
                                                v-model="formData.accreditations"
                                                class=""
                                                @change="formChanged = true"
                                                :class="{ 'disabled': bulk && !submitFields.accreditations}"
                                                :disabled="bulk && !submitFields.accreditations"
                                            >
                                              <option v-for="option in CARFandJCoptions" :value="option.id" :key="option.id">{{option.sectionName}}</option>
                                              <option value="both">Both</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- NAATP -->
                            <tr v-if="facilityType.slug == 'addiction_rehab'">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.naatp" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="naatp">NAATP member</label>
                                        <div class="select-container">
                                            <select
                                                    name="naatp"
                                                    id="naatp"
                                                    md-dense
                                                    v-model="formData.naatp"
                                                    class="beds"
                                                    placeholder="NAATP Member"
                                                    mdClass="treatment-type"
                                                    @change="formChanged = true"
                                                    :class="{ 'disabled': bulk && !submitFields.naatp}"
                                                    :disabled="bulk && !submitFields.naatp"
                                            >
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- LEGITSCRIPT -->
                            <tr v-if="facilityType.slug !== 'physical_therapy' && facilityType.slug !== 'mental_health'">
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.legitScript" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="legitscript">LegitScript certified</label>
                                        <div class="select-container">
                                            <select
                                                    name="legitscript"
                                                    id="legitscript"
                                                    md-dense
                                                    v-model="formData.legitScript"
                                                    class="beds"
                                                    placeholder="LegitScript Certified"
                                                    mdClass="treatment-type"
                                                    @change="formChanged = true"
                                                    :class="{ 'disabled': bulk && !submitFields.legitScript}"
                                                    :disabled="bulk && !submitFields.legitScript"
                                            >
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>

                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <!-- STATE LICENSE # -->
                            <tr>
                                <td v-if="bulk">
                                    <md-checkbox class="checkboxNormal" v-model="submitFields.stateLicense" ></md-checkbox>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <label for="statelicense">State license #</label>
                                        <input
                                                name="statelicense"
                                                id="statelicense"
                                                v-model="formData.stateLicense"
                                                placeholder="Enter license"
                                                @input="formChanged = true"
                                                class="input-margin"
                                                :class="{ 'disabled': bulk && !submitFields.stateLicense}"
                                                :disabled="bulk && !submitFields.stateLicense"
                                        />
                                    </div>
                                </td>
                            </tr>

                        </table>

                    </div>
                </div>
            </md-card-content>
            <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}">
                <md-button
                        v-if="bulk"
                        class="customGrey"
                        @click.prevent="$emit('cancel')"
                >Cancel</md-button>
                <md-button
                        class="md-raised md-primary"
                        type="submit"
                        :disabled="loading || !formChanged"
                >{{saveButtonText}}</md-button>
            </md-card-actions>
        </md-card>
    </form>
</template>

<script>
    import customValidationMixin from '../../../mixins/validationMixin'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import { noUrls, noPhoneNumbers } from '../../../helpers/customValidations'
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: "GeneralForm.vue",

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            originalFormData: {
                type: Object,
                default:function(){
                    return {
                        selectedLevelOfCares: [],
                        detox: 'no',
                        facilityName: '',
                        bedsAvailable: null,
                        bedsFree: null,
                        accreditations: [],
                        naatp: "no",
                        legitScript: "no",
                        stateLicense: null
                    }
                }
            },
            bulk: {
                type: Boolean,
                default: false
            },
            saveButtonText: {
                type: String,
                default: 'Save'
            }
        },
        mixins: [validationMixin, customValidationMixin],
        data(){
            return {
                facility: null,
                formChanged: false,
                formData: {
                    selectedLevelOfCares: [],
                    selectedLevelOfCare: null,
                    detox: 'no',
                    facilityName: '',
                    bedsAvailable: null,
                    bedsFree: null,
                    accreditations: [],
                    naatp: "no",
                    legitScript: "no",
                    stateLicense: null,
                    selectedSpecialties: []
                },
                levelOfCareBoth: null,
                submitted: false,
                selectAll: false,
                submitFields: {
                    detox: true,
                    levelOfCare: true,
                    totalBeds: true,
                    availableBeds: true,
                    accreditations: true,
                    naatp: true,
                    legitScript: true,
                    stateLicense: true,
                    specialties: true,
                }
            }
        },
        
        computed: {
            ...mapGetters([
                'levelOfCareOptions',
                'CARFandJCoptions',
                'accreditationsOptions',
                'facilities',
                'getFacilityType',
                'facilityDataChildNames',
                'ptSpecialtyOptions'
            ]),

            facilityType() {
                if(this.$route && this.$route.params && this.$route.params.facilityId){
                   return this.getFacilityType(this.$route.params.facilityId);
                }
                return {slug: 'addiction_rehab'};
            },

            ptSpecialties() {
                return this.ptSpecialtyOptions
            },

            selectedLevelOfCares(){
                // console.log('THE FORM DATA SELECTED LEVEL OF CARE', this.formData.selectedLevelOfCare);
                if(!this.formData.selectedLevelOfCare){
                    // console.log('returning empty array')
                    return []
                }else if(this.formData.selectedLevelOfCare === 'both'){
                    // console.log('it is both')
                    return this.levelOfCareOptions.map(option => option.id)
                }else {
                    // console.log('IT IS ONE')
                    return [this.formData.selectedLevelOfCare]
                }
            },

            selectedAccreditations() {
               console.log('ACCREDITATIONS FIELD VALUE', this.formData.accreditations)
              if(!this.formData.accreditations){
                // console.log('no accreditations selected')
                return []
              }else if(this.formData.accreditations === 'both'){
                // console.log('both accreditations selected')
                return this.CARFandJCoptions.map(option => option.id)
              }else {
                // console.log('one accreditation selected', this.formData.accreditations);
                return [this.formData.accreditations]
              }
            }
        },

        mounted() {
            // this.$set(this.formData, )
            this.formData = Object.assign(this.formData, JSON.parse(JSON.stringify(this.originalFormData)))
            console.log('FORM DATA GeneralForm', JSON.parse(JSON.stringify(this.formData)))

            if(this.bulk){
                for(const submitField in this.submitFields){
                    this.submitFields[submitField] = false;
                }
            }

            if(this.formData.selectedLevelOfCares.length && this.formData.selectedLevelOfCares.length === 1){
                this.formData.selectedLevelOfCare = this.formData.selectedLevelOfCares[0]
            } else if(this.formData.selectedLevelOfCares.length && this.formData.selectedLevelOfCares.length === 2){
              this.formData.selectedLevelOfCare = 'both'
            }

            if(this.formData.accreditations.length && this.formData.accreditations.length === 1){
                this.formData.accreditations = this.formData.accreditations[0];
            }else if(this.formData.accreditations.length && this.formData.accreditations.length === 2){
                this.formData.accreditations = 'both'
            }
        },

        validations() {
            if(!this.bulk){
                return {
                    formData: {
                        facilityName: {
                            required,
                                min: minLength(3)
                        }
                    }
                }
            }
        },

        watch: {
            originalFormData: {
                deep: true,
                handler: function(val, oldVal){
                    console.log('FORM DATA CHANGE', val);
                    this.formData = val;
                }
            },
            'formData.naatp'(newVal, oldVal){
                const naatpOption = this.accreditationsOptions.find(option => option.sectionLabel === 'treatment_naatp')
                
                if(newVal == 'no'){
                    this.formData.accreditations = this.formData.accreditations.filter(id => id !== naatpOption.id)
                }
                else if(newVal == 'yes'){
                    this.formData.accreditations = Array.from(this.formData.accreditations);
                    this.formData.accreditations.push(naatpOption.id)
                }
            },
            'formData.legitScript'(newVal, oldVal){
                const credOption = this.accreditationsOptions.find(option => option.sectionLabel === 'treatment_legit_script')
                
                if(newVal === 'no'){
                    this.formData.accreditations = this.formData.accreditations.filter(id => id !== credOption.id)
                }
                else if(newVal === 'yes'){
                    this.formData.accreditations = Array.from(this.formData.accreditations);
                    this.formData.accreditations.push(credOption.id);
                }
            },
            'formData.bedsAvailable'(newVal, oldVal){ // PREVENT ALPHA INPUT, ONLY NUMBERS
              if(this.facilityType.slug === 'physical_therapy' && newVal !== oldVal){
                const isNumber = /^[0-9]+$/.test(newVal);
                if(!isNumber && newVal !== null && newVal !== ''){
                  this.formData.bedsAvailable = oldVal
                }

              }
            },
            selectAll(newVal, oldVal) {
                if( newVal === oldVal) return;

                for(let submitField in this.submitFields){
                    this.submitFields[submitField] = newVal
                }
            },
        },

        methods: {
            getValidationClass(fieldName) {
                const field = this.$v.formData[fieldName]

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },

            validateForm() {
                this.$v.formData.$touch();
                if(this.$v.formData.$error){
                    console.error('VALIDATION ERROR', this.$v.formData)
                    return false
                }
                return true;
            },

            attemptSubmit() {
                this.submitted = true;
                this.formData.selectedLevelOfCares = this.selectedLevelOfCares
                // console.log('THE LEVEL OF CARES', this.selectedLevelOfCares, this.formData.selectedLevelOfCares);
                // console.log('SUBMITTING FORM DATA:', JSON.parse(JSON.stringify(this.formData)));
                this.formData.accreditations = JSON.parse(JSON.stringify(this.selectedAccreditations));
                if(!this.bulk){
                   if(!this.validateForm()){
                       return;
                   }
                }
                this.$emit('submit', {formData: this.formData, submitFields: this.submitFields});
            },
        }
    }
</script>

<style lang="scss" scoped>
    .actions{
        width: 640px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

     .actionsBulk{
        padding-bottom: 0px;
        padding-right: 18px;
        margin-right: 2px;
     }

    .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
        box-shadow: none;
    }

    select, input{
        margin-left: 160px;
        cursor: pointer;
        outline: none;
        border: 1px solid #eee;
        height: 46px;
        border-radius: 5px;
        padding-left: 16px;
        -webkit-text-fill-color: unset !important;
        max-width: 456px;
        width: 100%;
        font-size: 15px;
        color: #43484f;
        padding-right: 26px;
    }

    select, input::after {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98.5%;
        background-position-y: 10px;
    }

    input{
        cursor: auto;
        -webkit-appearance: none;
    }

    .treatment, .beds{
        max-width: 456px;
        width: 100%;
    }

    .checkbox-container{
        margin-left: 160px;
        margin-bottom: 7px;
        width: 60%;
        .checkbox{
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .checkbox-container-holder{
        margin-left: 0px;
        margin-bottom: 0px;
        width: 60%;
        .checkbox{
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .md-checkbox{
        width: 120px;
    }

    #pt_specialties{
      .md-checkbox {
        width: auto;
      }
    }

    .bulk-admin .form-group{
        margin-bottom: 8px;
    }
    //.bulk-admin .form-group{
    //    select{
    //        margin-left: 0;
    //    }
    //    input{
    //        margin-left: 0;
    //    }
    //}
    //.bulk-admin label {
    //  display: block;
    //}
    


    //.checkboxLineup{
    //    margin-top: 24px!important;
    //}

    //.checkboxNormal{
    //    margin-top: 19px!important;
    //}
    .disabled{
        color: #bbb;
    }

    .pt-specialties {
      width: 220px !important;
    }

    @media only screen and (max-width: 760px),
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
      #pt_specialties{
        .checkbox-container {
          display: block !important;
          .pt-specialties {
            margin: 5px 0;
          }
        }
      }
    }
</style>