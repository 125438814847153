import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMaterial from "vue-material";
import VueLocalStorage from "vue-localstorage";
import VueApexCharts from "vue-apexcharts";
import VueTheMask from "vue-the-mask";
import VueGtm from "vue-gtm";
import Vue2TouchEvents from "vue2-touch-events";
import VueWindowSize from "vue-window-size";
import { gsap } from "gsap";
import { TweenLite, TimelineLite, Back, Elastic, Expo, Bounce } from "gsap";
gsap.registerPlugin(TweenLite, TimelineLite, Back, Elastic, Expo, Bounce);

import { format, addDays, isSameDay } from "date-fns";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "./scss/app.scss";
require("./scss/mobile.scss");

Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(VueWindowSize);
Vue.use(VueLocalStorage, {
  name: "ls",
});

import store from "./store";

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(Vue2TouchEvents);

import DefaultLayout from "@/layouts/Default.vue";
import Login from "./components/Login.vue";
import Logout from "./components/Logout.vue";
import Profile from "./components/Account.vue";
import GoPremium from "./components/GoPremium.vue";
import Calls from "./components/Calls.vue";
import Clicks from "./components/Clicks.vue";
import Match from "./components/Match.vue";
import Settings from "./components/Settings.vue";
import NotificationsView from "./components/NotificationsView.vue";

//Facility admin
import Contact from "./components/Admin/Contact.vue";
import General from "./components/Admin/General.vue";
import Description from "./components/Admin/Description.vue";

import SendFeedback from "./components/Admin/SendFeedback.vue";
import Reviews from "./components/Admin/Reviews.vue";

//Tooltips
import FacilityTooltip from "./components/Tooltips/Facility.vue";
import WelcomeTooltip from "./components/Tooltips/Welcome.vue";
import GoPremiumTooltip from "./components/Tooltips/GoPremium.vue";
import GoPremiumTooltipFunnel from "./components/Tooltips/GoPremiumFunnel.vue";
import ReviewsTooltip from "./components/Tooltips/Reviews.vue";
import MatchTooltip from "./components/Tooltips/Matches.vue";
import ReceivingTooltip from "./components/Tooltips/Receiving.vue";
import GoPremiumThanks from "./components/Tooltips/GoPremiumThanks.vue";
import InsightsTooltip from "./components/Tooltips/Insights.vue";

//Cards
import RequestABed from "./components/Cards/RequestABed.vue";
import RequestABedZero from "./components/Cards/RequestABedZero.vue";
import ClicksCard from "./components/Cards/Clicks.vue";
import ClicksCardZero from "./components/Cards/ClicksZero.vue";
import CallsCardZero from "./components/Cards/CallsZero.vue";
import CallsCard from "./components/Cards/Calls.vue";
import MatchCard from "./components/Cards/Match.vue";
import MatchCardZero from "./components/Cards/MatchZero.vue";
import ReviewsCard from "./components/Cards/Reviews.vue";
import ActivityZero from "./components/Cards/ActivityZero.vue";

//Funnel
import Subscription from "./components/GoPremium/Funnel/Subscription.vue";
import SubscriptionBasic from "./components/GoPremium/Funnel/SubscriptionBasic.vue";

//Navigation
import AppToolbar from "./components/Navigation/AppToolbar.vue";
import NavUserMenu from "./components/Navigation/NavUserMenu.vue";
import NavSideMenu from "./components/Navigation/NavSideMenu.vue";

import PageNotFound from "./components/PageNotFound.vue";

Vue.component("app-toolbar", AppToolbar);
Vue.component("app-navusermenu", NavUserMenu);
Vue.component("app-navsidemenu", NavSideMenu);

Vue.component("app-facilitytooltip", FacilityTooltip);
Vue.component("app-welcometooltip", WelcomeTooltip);
Vue.component("app-gopremiumtooltip", GoPremiumTooltip);
Vue.component("app-gopremiumtooltipfunnel", GoPremiumTooltipFunnel);
Vue.component("app-reviewstooltip", ReviewsTooltip);
Vue.component("app-matchtooltip", MatchTooltip);
Vue.component("app-receivingtooltip", ReceivingTooltip);
Vue.component("app-gopremiumthanks", GoPremiumThanks);
Vue.component("app-insightstooltip", InsightsTooltip);

Vue.component("app-requestabed", RequestABed);
Vue.component("app-requestabedzero", RequestABedZero);
Vue.component("app-clicks", ClicksCard);
Vue.component("app-calls", CallsCard);
Vue.component("app-match", MatchCard);
Vue.component("app-matchzero", MatchCardZero);
Vue.component("app-reviews", ReviewsCard);
Vue.component("app-callszero", CallsCardZero);
Vue.component("app-clickszero", ClicksCardZero);
Vue.component("app-activityzero", ActivityZero);

Vue.component("app-subscription", Subscription);
Vue.component("app-subscriptionbasic", SubscriptionBasic);

// Axios HTTP interceptors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add token to all requests
axios.interceptors.request.use(
  (config) => {
    var user = store.state.user;
    if (user) {
      config.headers["X-API-Token"] = user.token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios, Vuex);

// Global event bus
import EventBus from "./components/EventBus.js";
Object.defineProperties(Vue.prototype, {
  eventBus: {
    get: function () {
      return EventBus;
    },
  },
});

// Routes
const routes = [
  {
    path: "/",
    component: Login,
    children: [
      {
        name: "auth.login",
        path: "",
        component: () => import("./components/LoginForm"),
        meta: {
          title: "Login",
        },
      },
      {
        path: "/password-reset",
        name: "auth.password-reset",
        component: () => import("./components/PasswordReset"),
        meta: {
          title: "Password Recovery",
        },
      },
    ],
  },
  {
    path: "/logout",
    name: "auth.logout",
    component: Logout,
    meta: {
      title: "Logout",
    },
  },
  {
    path: "/account/summary",
    component: DefaultLayout,
    meta: {
      title: "Summary",
    },
    children: [
      {
        path: "/",
        name: "account.index",
        component: Profile,
      },
    ],
  },
  {
    path: "/premium",
    component: DefaultLayout,
    meta: {
      title: "Premium",
    },
    children: [
      {
        path: "/",
        name: "dashboard.premium",
        component: GoPremium,
      },
    ],
  },

  {
    path: "/premium/funnel/price-locked-in",
    name: "premium.funnel.locked-in-price",
    component: () => import("./components/GoPremium/Funnel/PremiumProducts"),
    meta: {
      title: "Price locked in",
    },
  },
  {
    path: "/premium/funnel/increase-visibility",
    name: "premium.funnel.increase-visibility",
    component: () => import("./components/GoPremium/Funnel/PremiumProducts"),
    meta: {
      title: "Increase Visibility",
    },
  },
  {
    path: "/premium/funnel/select-goal",
    name: "premium.funnel.select-goal",
    component: () => import("./components/GoPremium/Funnel/SelectGoal"),
    meta: {
      title: "Pick a Goal",
    },
  },
  {
    path: "/premium/funnel/budget",
    name: "premium.funnel.budget",
    component: () =>
      import("./components/GoPremium/Funnel/SelectCampaignBudget"),
    meta: {
      title: "Confirm Budget",
    },
  },
  {
    path: "/premium/funnel/checkout",
    name: "premium.funnel.checkout",
    component: () => import("./components/GoPremium/Funnel/Checkout"),
    meta: {
      title: "Premium Checkout",
    },
  },
  {
    path: "*",
    name: "404",
    component: PageNotFound,
    meta: {
      title: "Page Not Found",
    },
  },
  {
    path: "/match",
    component: DefaultLayout,
    meta: {
      title: "Match",
    },
    children: [
      {
        path: "/",
        name: "match.index",
        component: Match,
      },
    ],
  },
  {
    path: "/premium/forms",
    component: DefaultLayout,
    meta: {
      title: "Forms",
    },
    children: [
      {
        path: "/",
        name: "forms.index",
        component: () => import("./components/Leads.vue"),
      },
    ],
  },
  {
    path: "/premium/calls",
    component: DefaultLayout,
    meta: {
      title: "Calls",
    },
    children: [
      {
        path: "/",
        name: "calls.index",
        component: Calls,
      },
    ],
  },
  {
    path: "/premium/clicks",
    component: DefaultLayout,
    meta: {
      title: "Clicks",
    },
    children: [
      {
        path: "/",
        name: "clicks.index",
        component: Clicks,
      },
    ],
  },
  {
    path: "/settings/summary",
    component: DefaultLayout,
    meta: {
      title: "Settings",
    },
    children: [
      {
        path: "/",
        name: "settings.index",
        component: Settings,
      },
    ],
  },
  {
    path: "/settings/payment-methods",
    component: DefaultLayout,
    meta: {
      title: "Payment Methods",
    },
    children: [
      {
        path: "/",
        name: "settings.payment-methods",
        component: () => import("./components/Payments/PaymentMethods"),
      },
    ],
  },
  {
    path: "/settings/payment-methods/add",
    component: DefaultLayout,
    meta: {
      title: "Add Payment Method",
    },
    children: [
      {
        path: "/",
        name: "settings.new-payment-method",
        component: () => import("./components/Payments/NewPaymentMethod"),
      },
    ],
  },
  {
    path: "/settings/transactions/:paymentId",
    component: DefaultLayout,
    meta: {
      title: "View Transaction",
    },
    children: [
      {
        path: "/",
        name: "settings.payment-receipt",
        component: () => import("./components/Payments/Receipt"),
      },
    ],
  },
  {
    path: "/settings/subscriptions",
    component: () => import("./components/Payments/SubscriptionList"),
    meta: {
      title: "Subscriptions",
    },
  },
  {
    path: "/settings/subscriptions/:subscriptionId/cancel",
    component: () => import("./components/Payments/CancelSubscription"),
    meta: {
      title: "Cancel Subscription",
    },
  },
  {
    path: "/settings/transactions",
    component: DefaultLayout,
    meta: {
      title: "Transactions",
    },
    children: [
      {
        path: "/",
        name: "settings.transactionslist",
        component: () => import("./components/Payments/TransactionList"),
      },
    ],
  },

  {
    path: "/listing",
    name: "admin",
    component: DefaultLayout,
    meta: {
      title: "Listing Admin",
    },
    children: [
      {
        path: "/listing",
        props: true,
        component: () => import("./components/Admin/Admin"),
        meta: {
          title: "Listing > General",
        },
        children: [
          {
            path: ":facilityId/general",
            name: "general.index",
            props: true,
            component: General,
            meta: {
              title: "Listing > General",
            },
          },
          {
            path: ":facilityId/admissions-agent",
            name: "listing.admissions-agent",
            props: true,
            component: () => import("./components/Admin/AdmissionsAgent"),
            meta: {
              title: "Listing > Admissions Agent",
            },
          },
          {
            path: ":facilityId/contact",
            name: "contact.index",
            props: true,
            component: Contact,
            meta: {
              title: "Listing > Contact",
            },
          },

          {
            path: ":facilityId/summary",
            name: "listing.summary",
            props: true,
            component: Description,
            meta: {
              title: "Listing > Summary",
            },
          },
          {
            path: ":facilityId/overview",
            name: "listing.overview",
            props: true,
            component: Description,
            meta: {
              title: "Listing > Overview",
            },
          },
          {
            path: ":facilityId/media",
            name: "media.index",
            props: true,
            component: () => import("./components/Admin/Media"),
            meta: {
              title: "Listing > Media",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/listing/:facilityId/feedback",
    props: true,
    component: DefaultLayout,
    meta: {
      title: "Listing > Send Feedback",
    },
    children: [
      {
        path: "/",
        name: "feedback.index",
        component: SendFeedback,
      },
    ],
  },

  {
    path: "/reviews",
    props: true,
    component: DefaultLayout,
    meta: {
      title: "Reviews",
    },
    children: [
      {
        path: "/",
        name: "reviews.index",
        component: Reviews,
      },
    ],
  },
  {
    path: "/notifications/",
    props: true,
    component: DefaultLayout,
    meta: {
      title: "Notifications",
    },
    children: [
      {
        path: "/",
        name: "notifications.index",
        component: NotificationsView,
      },
    ],
  },
  {
    path: "/feedback",
    props: true,
    component: DefaultLayout,
    meta: {
      title: "Send Feedback",
    },
    children: [
      {
        path: "/",
        name: "feedback.general",
        component: SendFeedback,
      },
    ],
  },
  {
    path: "/go/choose-premium",
    name: "plan.basic-or-premium",
    props: true,
    component: () => import("./components/PremiumOrBasic"),
    meta: {
      title: "Premium Or Basic?",
    },
  },
  {
    path: "/listings",
    component: DefaultLayout,
    meta: {
      title: "My Listings",
    },
    children: [
      {
        path: "/",
        name: "bulk.edit",
        component: () => import("./components/Admin/BulkEdit/BulkEdit"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  // TITLE TAG CHANGE
  const baseTitle = "Provider Platform | Rehab.com";
  document.title = baseTitle;

  var _hsq = (window._hsq = window._hsq || []);
  if (to.name == "auth.login") {
    // NO REDIRECT
    _hsq.push([
      "identify",
      {
        email: "",
      },
    ]);
  } else if (to.name === "auth.password-reset") {
    return next();
  }
  // DISABLE BACK BUTTON TO PREMIUM/BASIC SCREEN
  else if (
    to.name === "plan.basic-or-premium" &&
    from.name === "dashboard.premium"
  ) {
    return next(false);
  } else if (
    to.name === "premium.funnel.checkout" &&
    from.name === "dashboard.premium"
  ) {
    return next(false);
  } else {
    if (!store.state.user.token) {
      next({ name: "auth.login" });
    } else {
      // they are logged in, set hubspot vars
      if (window.location.origin === "https://connect.rehab.com") {
        // hubspot route tracking
        _hsq.push(["setPath", to.fullPath]);
        _hsq.push([
          "identify",
          {
            email: store.state.user.email,
          },
        ]);
        _hsq.push(["trackPageView"]);
      }
    }
  }
  next();
});

// GOOGLE TAG MANAGER SETUP
Vue.use(VueGtm, {
  id: "GTM-T3FXWQM",
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false,
});

Vue.mixin({
  methods: {
    formatApiEndDate(date) {
      const now = new Date();
      if (isSameDay(now, date)) {
        return format(addDays(now, 1), "YYYY-MM-DD");
      }
      return format(date, "YYYY-MM-DD");
    },
  },
});

// Initialise app
new Vue({ router, store }).$mount("#app");
