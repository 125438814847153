import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
    state: () => ({
        facilityInformation: [],
        facilityDataChildNames: [],
        facilityDescriptions: [],
        facilityAdmissions: [],
        facilityHighlights: [],
        facilityPhilosophies: [],
        facilityAccreditations: [],
        facilityFinancials: [],
        facilityDetoxServices: [],
        facilityLevelOfCareOptions: [],
        facilityTreatmentFocuses: [],
        facilityTherapyPrograms: [],
        facilityAmenities: [],
        facilityAddresses: [],
        facilitySpecialties: [],
    }),
    actions: {
        async fetchFacilityDataChildNames(context){
            const data = context.getters.facilityDataChildNames();
            if(data.length) return data;
            const url = `${apiUrl}/getfacilitydatachildnames`

            const response = await axios.get(url);
            // console.log('RESPONSE BABY!', response)

            context.commit('setFacilityDataChildNames', response.data)

            return response.data
        },

        // CREATE STANDARD ADMIN ITEM
        async createAdminItem(context, {facilityId, type, childId, content}){
            const url = `${apiUrl}/adminupdateapis`
            const response = await axios.post(url, {type, childId, content, facilityId})

            return response.data
        },

        // UPDATE STANDARD ADMIN ITEM
        async updateAdminItem(context, {facilityId, type, childId, content}){
            const url = `${apiUrl}/adminupdateapis/${facilityId}`

            const response = await axios.patch(url, {
                type,
                childId,
                content
            })

            return response;
        },

        // DELETE STANDARD ADMIN ITEM
        async deleteAdminItem(context, {facilityId, type, childId}){
            const url = `${apiUrl}/adminupdateapis/${facilityId}`

            const response = await axios.delete(url, {data: {
                type,
                childId
            }})

            return response;
        },

        ///////INFORMATION PAGE //////////
        // GET THE FACILITY INFORMATION
        async fetchFacilityInformation(context, facilityId){
            const url = `${apiUrl}/getfacilityinformationapis?facilityId=${facilityId}`;
            const response = await axios.get(url);

            // console.log('FETCH FACILITY INFO', response);

            if(Array.isArray(response.data)){
                for(const facilityData of response.data){
                    context.commit('setFacilityInfo', {facilityId: facilityData.facility.externalId, facilityInfo: facilityData})
                }
            }else {
                context.commit('setFacilityInfo', {facilityId, facilityInfo: response.data})
                return response.data
            }


        },

        async updateFacilityInformation(context, {facilityId, type, data}){
            const url = `${apiUrl}/getfacilityinformationapis/${facilityId}`
            const response = await axios.patch(url, {
                type,
                data
            });

            return response.data
        },

        async searchCities(context, searchString){
            const url = `${apiUrl}/cities?search=${searchString}`
            const result = await axios.get(url);

            // console.log('SEARCH CITIES RESULT', typeof JSON.parse(JSON.stringify(result.data)), JSON.stringify(result.data));
            if(JSON.stringify(result.data).includes('Null result for parameter'))
                return []

            return result.data
        },

        async saveFacilityImage(context, {formData, facilityId}){
            // console.log('SAVE FACILITY IMAGE', data, facilityId);
            const data = context.rootGetters.getMediaPageInfo(facilityId);
            // console.log('THE CURRENT DATA', JSON.parse(JSON.stringify(data)));

            if(data && data.data && data.data.facility && data.data.facility.pendingUpdates){
                const profileImageChanges = data.data.facility.pendingUpdates.filter(update => update.changes && update.changes.attribute === 'imagePath');
                if(profileImageChanges){
                    const deleteUpdates = []
                    for(const update of profileImageChanges){
                        console.log('DELETING UPDATE', JSON.parse(JSON.stringify(update)))
                        deleteUpdates.push(context.dispatch('deleteContentUpdate', update.id));
                    }
                    await Promise.all(deleteUpdates);
                }
            }

            const url = `${apiUrl}/facilityimages/${facilityId}`
            const result = await axios.post(url, formData);
            return result.data
        },

        // GET SPECIALTIES INFO
        async fetchFacilitySpecialties(context, facilityId){
            const url = `${apiUrl}/getfacilityspecialtyapis?facilityId=${facilityId}`
            const response = await axios.get(url)

            context.commit('setFacilitySpecialties', {specialties: response.data, facilityId});
        },

        /////// DESCRIPTION PAGE //////
        // GET A FACILITY'S DESCRIPTION
        async fetchFacilityDescription(context, facilityId){
            const url = `${apiUrl}/getfacilitydescriptionapis?facilityId=${facilityId}`;
            const response = await axios.get(url);

            // console.log('FACILITY DESCRIPTION RESPONSE', response);
            context.commit('setFacilityDescription', {
                facilityId,
                facilityDescription: response.data.facilityDescription,
                pendingUpdates: response.data.pendingUpdates
            });
        },




        /////// OVERVIEW PAGE //////
        // GET OVERVIEW INFORMATION
        async fetchFacilityOverviewInfo(context, facilityId){
            const url = `${apiUrl}/getfacilityoverviewapis?facilityId=${facilityId}`
            const response = await axios.get(url)

            // console.log('overview response', JSON.parse(JSON.stringify(response.data)));

            const highlights = response.data.highlights;
            const philosophy = response.data.philosophy;

            context.commit('setFacilityPhilosophy', {facilityId, philosophy})
            if(highlights.length > 0) context.commit('setFacilityHighlights', {facilityId, highlights})
        },

        //////// ACCREDITATION PAGE
        // GET ACCREDITATION INFO
        async fetchFacilityAccreditations(context, facilityId){
            const url = `${apiUrl}/getfacilityaccreditationapis?facilityId=${facilityId}`
            const response = await axios.get(url)

            context.commit('setFacilityAccreditations', {accreditations: response.data, facilityId});
        },

        /////// ADMISSIONS PAGE //////
        // GET FACILITY ADMISSIONS INFO
        async fetchFacilityAdmissions(context, facilityId){
            const url = `${apiUrl}/getfacilityadmissionsapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityAdmissions', {facilityId, admissions: response.data});
            return response.data;
        },

        // UPDATE A FACILITY ADMISSION
        async updateFacilityAdmission(context, {facilityId, childId, content}){
            const url = `${apiUrl}/getfacilityadmissionsapis/${facilityId}`
            const response = await axios.patch(url, {
                facilityId,
                childId,
                content
            })
            
            return response
        },

        /////// FINANCIALS PAGE //////
        // GET FINANCIAL PAGE INFO
        async fetchFacilityFinancialInfo(context, facilityId){
            const url = `${apiUrl}/getfacilityfinancialapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityFinancials', {facilityId, financials: response.data});
        },

        /////// DETOX SERVICES PAGE ////////////
        async fetchFacilityDetoxInfo(context, facilityId) {
            const url = `${apiUrl}/getfacilitydetoxserviceapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityDetoxServices', {facilityId, detoxServices: response.data});
        },
        
        ////// LEVEL OF CARE PAGE ///////////
        async fetchFacilityLevelOfCare(context, facilityId) {
            const url = `${apiUrl}/getfacilitylevelofcareapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityLevelOfCareOptions', {facilityId, levelOfCareOptions: response.data});
        },

        /////// TREATMENT FOCUS PAGE ////////
        async fetchFacilityTreatmentFocuses(context, facilityId) {
            const url = `${apiUrl}/getfacilitytreatmentfocusapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityTreatmentFocuses', {facilityId, treatmentFocuses: response.data});
        },

        //////// THERAPY PROGRAMS PAGE //////////////
        async fetchFacilityTherapyPrograms(context, facilityId){
            const url = `${apiUrl}/getfacilitytherapyprogramsapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityTherapyPrograms', {facilityId, therapyPrograms: response.data});
            return response.data
        },

        ///////// AMENITIES PAGE ///////////////
        async fetchFacilityAmenities(context, facilityId){
            const url = `${apiUrl}/getfacilityamenitiesapis?facilityId=${facilityId}`
            const response = await axios.get(url);

            context.commit('setFacilityAmenities', {facilityId, amenities: response.data});
            return response.data
        },

        ///////// REVIEWS PAGE /////////////
        async createReviewResponse(context,  {reviewsId, comment, facilityId}) {
            const url = `${apiUrl}/getfacilityreviewsapis`
            const response = await axios.post(url, {reviewsId, comment, facilityId})
        },

        async deleteContentUpdate(context, contentUpdateId) {
            const url = `${apiUrl}/content-updates/${contentUpdateId}`
            try {
                const response = await axios.delete(url);
            }catch(e){
                console.log('ERROR DELETING CONTENT UPDATE', e)
            }

        }
    },

    ///////////////////////////// MUTATIONS //////////////////////////////////////////
    mutations: {
        setFacilityDataChildNames(state, data){
            state.facilityDataChildNames = data;
        },

        setFacilityInfo(state, {facilityId, facilityInfo}){
          const found = state.facilityInformation.find(item => item.facilityInfo.facility.externalId === facilityId);
          if(found){
              found.facilityInfo = facilityInfo
              return
          }
          state.facilityInformation.push({
              facilityId,
              facilityInfo
          })

        },

        setFacilityDescription(state, {facilityId, facilityDescription, pendingUpdates}) {
            const existingDescription = state.facilityDescriptions.find(descrip => descrip.facilityId === facilityId)

            if(existingDescription){
                existingDescription.facilityDescription = facilityDescription;
                existingDescription.pendingUpdates = pendingUpdates;
                return;
            }

            state.facilityDescriptions.push({
                facilityId,
                facilityDescription,
                pendingUpdates
            })
        },
        setFacilityAdmissions(state, {facilityId, admissions}){
            const existingAdmissions = state.facilityAdmissions.find(admission => admission.facilityId === facilityId)

            if(existingAdmissions){
                existingAdmissions.admissions = admissions
                return
            }
            state.facilityAdmissions.push({
                facilityId,
                admissions
            })
        },
        setFacilityHighlights(state, {facilityId, highlights}){
            let existingHighlights = state.facilityHighlights.find(highlight => highlight.facilityId === facilityId)
            
            if(existingHighlights)
                existingHighlights.highlights = highlights
            else 
                state.facilityHighlights.push({
                    facilityId,
                    highlights
                })
            
        },
        setFacilityPhilosophy(state, {facilityId, philosophy}){
            let existing = state.facilityPhilosophies.find(philosophy => philosophy.facilityId === facilityId)
            
            if(existing)
                existing.philosophy = philosophy
            else
                state.facilityPhilosophies.push({
                    facilityId,
                    philosophy
                })
        },
        setFacilityAccreditations(state, {facilityId, accreditations}){
            let existing = state.facilityAccreditations.find(accreditation => accreditation.facilityId === facilityId);
            if(existing)
                existing.accreditations = accreditations
            else
                state.facilityAccreditations.push({
                    facilityId,
                    accreditations
                })
        },
        setFacilitySpecialties(state, {facilityId, specialties}){
            let existing = state.facilitySpecialties.find(specialty => specialty.facilityId === facilityId);
            if(existing)
                existing.specialties = specialties
            else
                state.facilitySpecialties.push({
                    facilityId,
                    specialties
                })
        },
        setFacilityFinancials(state, {facilityId, financials}){
            let existing = state.facilityFinancials.find(item => item.facilityId === facilityId);
            if(existing)
                existing.financials = financials
            else
                state.facilityFinancials.push({
                    facilityId,
                    financials
                })
        },
        setFacilityDetoxServices(state, {facilityId, detoxServices}){
            let existing = state.facilityDetoxServices.find(item => item.facilityId === facilityId);
            if(existing)
                existing.detoxServices = detoxServices
            else
                state.facilityDetoxServices.push({
                    facilityId,
                    detoxServices
                })
        },
        setFacilityLevelOfCareOptions(state, {facilityId, levelOfCareOptions}){
            let existing = state.facilityLevelOfCareOptions.find(item => item.facilityId === facilityId);
            if(existing)
                existing.levelOfCareOptions = levelOfCareOptions
            else
                state.facilityLevelOfCareOptions.push({
                    facilityId,
                    levelOfCareOptions
                })
        },
        setFacilityTreatmentFocuses(state, {facilityId, treatmentFocuses}){
            let existing = state.facilityTreatmentFocuses.find(item => item.facilityId === facilityId);
            if(existing)
                existing.treatmentFocuses = treatmentFocuses
            else
                state.facilityTreatmentFocuses.push({
                    facilityId,
                    treatmentFocuses
                })
        },
        setFacilityTherapyPrograms(state, {facilityId, therapyPrograms}){
            let existing = state.facilityTherapyPrograms.find(item => item.facilityId === facilityId);
            if(existing)
                existing.therapyPrograms = therapyPrograms
            else
                state.facilityTherapyPrograms.push({
                    facilityId,
                    therapyPrograms
                })
        },
        setFacilityAmenities(state, {facilityId, amenities}){
            let existing = state.facilityAmenities.find(item => item.facilityId === facilityId);
            if(existing)
                existing.amenities = amenities
            else
                state.facilityAmenities.push({
                    facilityId,
                    amenities
                })
        },
    },

    ///////////// GETTERS ///////////////
    getters: {
        facilityDataChildNames: state => sectionDataType => {
            if(sectionDataType)
                return state.facilityDataChildNames.filter(data => data.sectionDataType === sectionDataType)
            return state.facilityDataChildNames
        },
        levelOfCareOptions: state => {
            return state.facilityDataChildNames.filter(item => ['rehab_center_outpatient_services', 'rehab_center_residential_treatment'].includes(item.sectionLabel))
        },
        accreditationsOptions: (state, getters) => {
            return getters.facilityDataChildNames(1).filter(option => !option.sectionLabel.includes('effective'))
        },
        ptSpecialtyOptions: (state, getters) => {
            return getters.facilityDataChildNames(17).reverse()
        },
        CARFandJCoptions: (state, getters) => {
            return getters.accreditationsOptions.filter(option => option.sectionLabel === 'treatment_carf' || option.sectionLabel === 'treatment_jcaho')
        },
        allFacilityInfo: state => {
            return state.facilityInformation
        },
        facilityInfo: state => facilityId => {
            return state.facilityInformation.find(item => item.facilityId === facilityId);
        },
        facilityDescription: state => facilityId => {
            return state.facilityDescriptions.find(descr => descr.facilityId === facilityId)
        },
        facilityAdmissions: state => facilityId => {
            const admissionsObj = state.facilityAdmissions.find(admission => admission.facilityId === facilityId)
            return admissionsObj.admissions
        },
        facilityHighlights: state => facilityId => {
            const highlightsObj = state.facilityHighlights.find(highlight => highlight.facilityId === facilityId)
            if(highlightsObj){
                return highlightsObj.highlights.filter(highlight => highlight.status === true)
            }else{
                return []
            }
        },
        facilityPhilosophy: state => facilityId => {
            const philosophyObj = state.facilityPhilosophies.find(philosophy => philosophy.facilityId === facilityId)
            if(philosophyObj){
                return philosophyObj.philosophy
            }
            return null;
        },
        facilityAccreditations: state => facilityId => {
            const accreditationsObj = state.facilityAccreditations.find(accreditation => accreditation.facilityId === facilityId);
            return accreditationsObj.accreditations
        },
        facilitySpecialties: state => facilityId => {
            const specialitiesObj = state.facilitySpecialties.find(specialty => specialty.facilityId === facilityId);
            return specialitiesObj.specialties
        },
        facilityFinancials: state => facilityId => {
            const obj = state.facilityFinancials.find(item => item.facilityId === facilityId);
            return obj.financials
        },
        facilityDetoxServices: state => facilityId => {
            const obj = state.facilityDetoxServices.find(item => item.facilityId === facilityId);
            return obj.detoxServices
        },
        facilityLevelOfCareOptions: state => facilityId => {
            const obj = state.facilityLevelOfCareOptions.find(item => item.facilityId === facilityId);
            return obj.levelOfCareOptions
        },
        facilityTreatmentFocuses: state => facilityId => {
            const obj = state.facilityTreatmentFocuses.find(item => item.facilityId === facilityId);
            return obj.treatmentFocuses
        },
        facilityTherapyPrograms: state => facilityId => {
            const obj = state.facilityTherapyPrograms.find(item => item.facilityId === facilityId);
            return obj.therapyPrograms
        },
        facilityAmenities: state => facilityId => {
            const obj = state.facilityAmenities.find(item => item.facilityId === facilityId);
            return obj.amenities
        }
    }
}