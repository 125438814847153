<template>
    <div>
                <div class="md-layout md-alignment-top-right filters" v-if="!checkCampaignsIsPremium && (clicks.length > 0 || firstLoad===false)">
                    <div class="md-layout-item md-size-15"  v-if="facilities.length > 1">
                         <md-field>
                            <md-select placeholder="Listing" v-model="selectedFacility" name="selectedFacility" id="selectedFacility" @md-selected="onChange(selectedFacility)" mdClass="filtersPaddingTopFacility">
                                <md-option :value="0">View all</md-option>
                                <md-option v-for="(item) in facilities" :value="item.attributes.facilityId" :key="item.attributes.facilityId">{{ item.attributes.name }}</md-option>
                            </md-select>
                        </md-field>
                    </div>    
                    <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="startDate" md-immediately>
                        </md-datepicker>
                    </div>
                    <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="endDate" md-immediately>
                        </md-datepicker>
                    </div>
                </div> 
                <div class="md-layout">
                    <md-card v-if="paginationClicks.length>0 && loading === false">
                        <md-table class="md-table-mobile-padding">
                            <md-table-row class="white">

                                <md-table-head>Listing name</md-table-head>
                                <md-table-head v-if="!mobile">Activity</md-table-head>
                                <md-table-head v-if="!mobile">Source</md-table-head>
<!--                                <md-table-head v-if="!mobile" class="center">Location</md-table-head>-->
                                <md-table-head>Date</md-table-head>
                                <md-table-head>Time</md-table-head>
                                <md-table-head v-if="!mobile">Campaign url</md-table-head>

                            </md-table-row>
                            <md-table-row v-for="(item) in paginationClicks" v-bind:key="item.id" class="grey">
                                <md-table-cell>{{ getFacilityName(item.facilityId)  }}</md-table-cell>
                                <md-table-cell v-if="!mobile" ><span v-if="item.type == 1">Click</span></md-table-cell>
                                <md-table-cell v-if="!mobile" >
                                    <span v-if="item.location === 1">
                                        Listing
                                    </span>
                                    <span v-if="item.location === 2">
                                        Match
                                    </span>
                                    <span v-if="item.location != 1 && item.location != 2">
                                        Website
                                    </span>
                                </md-table-cell>
<!--                                <md-table-cell v-if="!mobile" ><span v-if="item.location == 1">Listing page</span><span v-if="item.location == 2">Search page</span></md-table-cell>-->
                                <md-table-cell>{{ item.date.date | formatDate  }}</md-table-cell>
                                <md-table-cell>{{ item.date.date | time  }}</md-table-cell>
                                <md-table-cell v-if="!mobile">{{ item.destinationUrl.split('/?')[0] }}</md-table-cell>
                            </md-table-row>
                        </md-table>

                        <!-- PAGINATION BUTTONS -->
                        <div class="md-layout" v-if="clicks.length > paginationLimit">
                            <div class="md-layout-item table-nav">
                                <md-button
                                    class="md-raised md-primary"
                                    :disabled="currentPage === 1"
                                    @click="previousPage"
                                >Previous</md-button>
                                <md-button
                                    class="md-raised md-primary"
                                    :disabled="nextDisabled"
                                    @click="nextPage"
                                >Next</md-button>
                            </div>
                        </div>
                        
                    </md-card>
               
                    <div class="container" v-show="clicks.length == 0 && loading == false && firstLoad==false && user.status == 1 && showPremiumClicksCard">
                        <app-activityzero></app-activityzero>
                    </div>

                    <div class="container" v-show="clicks.length == 0 && loading == false && firstLoad==true && user.status == 1 && showPremiumClicksCard">
                        <app-clickszero></app-clickszero>
                    </div>
                    <div class="container" v-show="clicks.length == 0 && loading == false &&  user.status== 3 || loading == false && !showPremiumClicksCard && clicks.length == 0">
                        <app-clicks></app-clicks>                    
                    </div> 
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading && clicks.length == 0"></md-progress-spinner>
                </div> 
                 
    </div>
</template>

<script lang="javascript">

    import config from '../config.js'
    import {mapGetters, mapActions} from 'vuex'
    import{subMonths, format, startOfMonth, endOfToday, parse, isBefore} from 'date-fns'

    export default{
        name: 'Reports',
        data(){
            return {
                selectedFacility: 0,
                // facilities: {},
                facilitiesIDs:[],
                config: config,
                clicks: [],
                startDate: startOfMonth(subMonths( new Date(), 48 )),
                endDate: endOfToday(),
                loading: true,
                firstLoad: true,
                user: [],
                insightstooltip: true,
                mobile: false,

                paginationLimit: 15,
                currentPage: 1
            }
        }, 
        created(){
            //populate facilities drop down from the vuex store
            // this.facilities = this.$store.getters.facilities
            this.user = this.$store.getters.user
            this.facilities.forEach((item) => {
                this.facilitiesIDs.push(item.attributes.facilityId)
            })
            this.$material.locale.dateFormat = "MM/dd/yyyy";
            if( this.windowWidth  <= 760 ) {
                this.mobile = true
            }
        },
        mounted(){
            this.$watch('startDate', (value, oldValue) => {
                if(value.getTime() != oldValue.getTime()){
                    this.update()
                    this.firstLoad = false   
                }
            })
            this.$watch('endDate', (value, oldValue) => {
                var valueA = new Date(value)
                valueA = valueA.getDate() + '/' + valueA.getMonth() + '/' + valueA.getFullYear()

                var valueB = new Date(oldValue)
                valueB = valueB.getDate() + '/' + valueB.getMonth() + '/' + valueB.getFullYear()

                if(valueA != valueB){
                   
                    this.update()
                    this.firstLoad = false
                }
            })
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            this.update()
        },
        filters: {
            formatDate(date){
                const dateString = date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'MM/DD/YYYY')
            },
            time(date){
                const dateString = date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'hh:mm a')
            },
        },
        computed: {
            ...mapGetters({
                facilities: 'facilities',
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                checkCampaignsIsPremium: "checkCampaignsIsPremium",
                clickProduct: 'clickProduct',
                campaignsWithStartDates: 'campaignsWithStartDates'
            }),
            showPremiumClicksCard(){
                const premiumClickCampaign = this.campaignsWithStartDates.find(campaign => campaign.attributes.productId == this.clickProduct.id)
                if(premiumClickCampaign)
                {
                    return true;
                }
                return false
            },
            paginationClicks() {
                const start = this.paginationLimit * (this.currentPage - 1);
                const end = start + this.paginationLimit;

                return this.clicks.slice(start, end)
            },
            nextDisabled() {
                if(this.currentPage === Math.ceil(this.clicks.length / this.paginationLimit)){
                  return true;
                }
                return false;
            }
        },
        methods: {
            ...mapActions([
               'fetchFacilityClicks'
            ]),
            getFacilityName(id, name = ''){  
                this.facilities.forEach((item) => {
                    if(item.attributes.facilityId == id){
                        name = item.attributes.name
                    }
                })
                return name
            },
            getFacilityZip(id, zip = ''){  
                this.facilities.forEach((item) => {
                    if(item.attributes.facilityId == id){
                        zip = item.attributes.zip
                    }
                })
                return zip
            },
            async update(){
                this.loading = true
                if(this.user.status == 1){
                    await this.getFacilityClicks()
                }else{
                    this.loading = false
                }  
            },
            getDate(date){
                let endDate = this.endDate.getFullYear() + '-' + (((this.endDate.getMonth() + 1) < 10) ? '0' : '') + (this.endDate.getMonth() + 1) + '-' + ((this.endDate.getDate() < 10) ? '0' : '') + this.endDate.getDate();
                let startDate = this.startDate.getFullYear() + '-' + (((this.startDate.getMonth() + 1) < 10) ? '0' : '') + (this.startDate.getMonth() + 1) + '-' + ((this.startDate.getDate() < 10) ? '0' : '') + this.startDate.getDate();

                return date == 'startDate' ? startDate : endDate
            },
            onChange(event){
      
                 if(event === 0){
                     this.facilities.forEach((item) => {
                        this.facilitiesIDs.push(item.attributes.facilityId)
                    })
                }else{
                   this.facilitiesIDs = [event]
                }
                this.$children.find(child => { return child.$options.name === "ActivityZero"; }).updateFacility();
                this.firstLoad = false
                this.getFacilityClicks()
            },
            async getFacilityClicks(){
                const startDate = format(this.startDate, 'YYYY-MM-DD');
                const endDate = this.formatApiEndDate(this.endDate);
                const result = await this.fetchFacilityClicks({
                    facilityIds: this.facilitiesIDs,
                    startDate,
                    endDate
                })

              if(result && result.length){
                this.clicks = result.sort((a, b) => isBefore(parse(a.date.date), parse(b.date.date)) ? 1 : -1 );
                this.loading = false
              }else {
                this.loading = false
                this.clicks = []
              }
            },
            previousPage() {
                if(this.currentPage > 1){
                   this.currentPage--
                }
            },

            nextPage() {
                this.currentPage++;
            }
        }
    }

</script>
<style lang="scss" scoped>
    span {
      width: 100%;
      height: 100%;
      display: block;
      background: md-get-palette-color(blue, 200);
    }
 
  .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 50%;
        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
           
        }
        span{
            height: unset;
        }
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .white{
        background-color: #fff;
    }
    .center{
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    .md-field.md-theme-default:after{
        background-color: #DBDBDB;
    }
    .container{
        width: 100%;
    }
    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }
</style>