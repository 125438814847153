<template>
   <div class="cards">
        <div class="container">
            <div class="left">
                <h1>No activity for {{ facilityName }}</h1>
                <div class="copy">Once you have a transaction, you will find it here.</div>
            </div>
            <div class="right">
                <h2>Secure activity</h2>
                <img src="/images/cards/connectPremiumPromos-01.svg"/>
                <div class="copy">We make all of your activity easy to find, whenever you need it.</div>
            </div>
        </div>
    </div>
</template>
<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions} from 'vuex'

    export default{
        name: 'ActivityZero',
            data(){
                return {  
                    facilityName : null
                }
        },
        mounted(){
            this.updateFacility();
        },
         methods: {
             updateFacility(){
                const facilities = this.$store.getters.facilities;
                if(this.$parent.selectedFacility && facilities && facilities.length > 1)
                {
                    this.$parent.facilities.forEach((item) => {
                        // console.log(item.attributes.facilityId )
                        if(item.attributes.facilityId == this.$parent.selectedFacility){
                            this.facilityName = item.attributes.name
                        }
                    });
                }else if(facilities && facilities.length){
                    if(facilities > 1){
                        this.facilityName = "your listings"
                    }else {
                        this.facilityName = facilities[0].attributes.name
                    }
                }
             }

         }
    }
</script>

{{ $parent.facilities[0].attributes.name }}

<style lang="scss" scoped>
    .container{
        border: 1px solid #ddd;
        display: flex;
        height: auto;
        width: 100%;
        max-width: 950px;
        position: relative;
        margin: 40px;
        max-height: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        h1{
            line-height: 36px;
            color: #33475A;
            font-weight: 800;
            letter-spacing: .2px;
        }
        .copy{
            color: #646464;
            line-height: 26px;
            padding-bottom: 26px;
            padding-top: 16px;
            letter-spacing: .2px;
        }

        .left{
            width: 41.66%;
            float: left;
            position: relative;
            background-color: #f5f5f4;
            padding: 30px;
            padding-top: 140px;
            padding-bottom: 80px;
        }
        .right{
            width: 58.333%;
            float: left;
            position: relative;
            padding: 60px;
            padding-top: 94px;
            padding-bottom: 80px;
            background-color: #fff;

            img{
                height: 180px;
                padding-bottom: 20px;
                padding-top: 10px;
            }

            h2{
                color: #33475A;
                font-weight: 500;
                letter-spacing: .2px;
            }
        }
        
    }
</style>

          