<template>
     <div class="form-container">
        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="errorMsgDialog"
                md-persistent
        >
            <span>{{ errorMsgSnack }}</span>
            <md-button class="md-primary" @click="errorMsgDialog = false">Close</md-button>
        </md-snackbar>
         <div class="title"><h1>Log in</h1></div>
         <div class="intro">Get more new patient connections. Diversify your marketing.</div>
         <div class="form">
            <form novalidate class="" @submit.prevent="validateForm">
                <md-card class="login">
                    <md-card-content>
                        <md-field :class="getValidationClass('email')">

                            <div clas="input">
                                <md-progress-bar v-if="sending" class="progress-bar" md-mode="indeterminate" />
                                <input
                                    type="email"
                                    :class="{'border-none': sending}"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                    autocomplete="email"
                                    v-model="form.email"
                                    :disabled="sending"
                                    @input="form.email = $event.target.value.toLowerCase()"
                                />
                            </div>
                            <div class="md-error">
                                <div class="md-error" v-if="!$v.form.email.required">The email address field is required</div>
                                <div class="md-error" v-else-if="!$v.form.email.email">The email address is invalid</div>
                            </div>
                        </md-field>
                        
                        <md-field :class="getValidationClass('password')">
                            <div clas="input"><input placeholder="Password" type="password" name="password" id="password" v-model="form.password" :disabled="sending" /></div>
                            <div class="md-error">
                                <div class="md-error" v-if="!$v.form.password.required && !loginError">The password field is required</div>
                            </div>
                        </md-field>

                        <div class="md-error padding" v-if="loginError">The password you’ve entered is incorrect.</div>
                        <div class="terms">
                        By clicking the button below, you agree to Rehab.com’s <a :href="basePath+'terms-of-use'" title="Terms of Use">Terms of Service</a> and <a :href="basePath+'privacy-policy'" title="Privacy Policy">Privacy Policy</a>.
                        </div>
                        <md-card-actions>
                            <md-button type="submit" class="sign-in" :disabled="sending">Log in</md-button>
                        </md-card-actions>
                    </md-card-content>
                     <div class="forgot"><router-link :to="{name: 'auth.password-reset'}">Forgot password?</router-link></div>
                </md-card>

                <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
            </form>
         </div>
     </div>
</template>


<script>
    import 'url-search-params-polyfill';
    import { validationMixin } from 'vuelidate'
    import { mapActions } from 'vuex'
    import config from '../config.js'
    import axios from 'axios'
    import {
        required,
        email
    } from 'vuelidate/lib/validators'

    export default {
        name: 'FormValidation',
        mixins: [validationMixin],
        data: () => ({
            loginError: false,
            config: config,
            form: {
                email: null,
                password: null,
            },
            userSaved: false,
            sending: false,
            lastUser: null,
            trackingNumbers: [],
            recoverPasswordDialog: false,
            recoverEmail: '',
            recoverPasswordDialogWait: false,
            errorMsgDialog: false,
            errorMsg: null,
            errorMsgSnack: null,
            basePath: 'https://www.rehab.com/'
        }),
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                }
            }
        },
        mounted(){
            let urlParams = new URLSearchParams(window.location.search);
            this.form.email = urlParams.get('email');

            if(this.$route.query.notify) {
                if(this.$route.query.notify === "recovered"){
                    this.errorMsgDialog = true;
                    this.errorMsgSnack = "Your password has been successfully updated, please login"
                }
                if(this.$route.query.notify === "sent"){
                    this.errorMsgDialog = true;
                    this.errorMsgSnack = "Please check your email for recovery instructions"
                }

            }
            if(window.location.origin === "https://connect-staging.rehab.com"){
                this.basePath = `https://staging.rehab.com/`
            }else if(window.location.origin === "https://connect-dev.rehab.com"){
                this.basePath = `https://dev.rehab.com/`
            }else if(window.location.origin === "https://connect-demo.rehab.com"){
                this.basePath = `https://demo.rehab.com/`
            }else if(window.location.origin === "https://connect.rehab.com"){
                this.basePath = `https://www.rehab.com/`
            } else{
                this.basePath = `http://local.rehab.com/`
            }
        },
        methods: {
            ...mapActions([
                "resetAllState",
                "fetchHubspotContactByEmail",
                "hubspotLoginTracking",
                "loginUser"
            ]),
            getValidationClass(fieldName){
                const field = this.$v.form[fieldName] 
                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            clearForm(){
                this.$v.$reset()
                this.form.email = null
                this.form.password = null
            },
            async submitForm(){
                this.sending = true
                this.loginError = false
                this.$ls.remove('user')
                await this.resetAllState()
                try{
                    const response = await this.loginUser({
                        'email': this.form.email,
                        'password': this.form.password
                    });
                    this.errorMsgDialog = false

                    if(response && response.message && response.message === 'GoV2 redirect'){
                        return;
                    }

                    this.loginSuccess(response.data)
                }catch (error) {
                    console.error('ERROR LOGGING IN', error.response, error.message);

                    if(error.message && error.message === 'gov2-redirect'){
                        console.log('redirecting to gov2 funnel');
                        return;
                    }

                    if(error.response && error.response.data && error.response.data.message){
                        // INVALID USERNAME/PASSWORD
                        if(error.response.data.message === "Invalid username / password") {
                            this.sending = false;
                            this.loginError = true;
                            this.errorMsgDialog = false;
                            return;
                        }
                        // FACILITY ALREADY CLAIMED
                        else if(error.response.data.error === 'claimedFacility' || error.response.data.error === 'closedFacility'){
                            this.errorMsgSnack = error.response.data.message
                        }

                        else if(error.response.data.message === 'user not found'){
                            this.errorMsgSnack = "The email you’ve entered doesn’t match any account. Sign up for an account."
                        }
                        else if (error.response.data.error === 'noListingAccess'){
                          this.errorMsgSnack = error.response.data.message
                        }
                        // WE DON'T KNOW
                        else {
                            this.errorMsgSnack = "There was an error setting up your account. Please contact our support team."
                        }
                    }
                    // API IS BROKEN
                    else {
                        this.errorMsgSnack = "Our service API is currently offline, please contact our support team."
                    }
                    this.errorMsgDialog = true
                    this.sending = false
                }
            },
            async loginSuccess(data){
                this.$ls.set('user', JSON.stringify(data))

                if(data.role && data.role.slug === "admissions"){
                    // console.log('routing to match')
                    return this.$router.push({name: 'match.index'})
                }

                if(data.redirect){
                    return this.$router.push({name: data.redirect})
                }

                this.$router.push({ name: 'dashboard.premium' })
            },
            validateForm(){
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.submitForm()
                }
               return []
            },


        }
    }
</script>

<style lang="scss" scoped>

    .form-container{
        overflow: hidden;
        div{
            padding-left: 6px;
            padding-right: 6px;
        }   
    }
    .md-field{
        display: block;
        text-align: center;
        margin-bottom: 0px!important;
        margin-top: 2px!important;
    }
    .progress-bar{
        margin-top: -6px;
    }
    .input{
        width: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        position: relative;
    }
    .md-error{
        position: relative;
        clear: both;
        color: #d93025!important;
        width: 380px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0!important;
        padding-top: 0;
        margin-top: 0;
        text-align: left;
        bottom: 0;
        .error-icon{
            font-size: 16px!important;
             color: #d93025!important;
        }
    }
    .md-error.padding{
        padding-left: 14px!important;
        margin-top: -17px;
        font-size: 12px;
    }
    .md-card{
        box-shadow: none;
    }
    .forgot{
        font-size: 15px;
        font-weight: normal;
        text-align: center;
        margin-top: 21px;
        font-weight: 400;
        color: #0078d3;
        -webkit-font-smoothing: auto;
    }
    .loader{
        position: absolute;
        left:50%;
        margin-left: -36px;
        top: 295px;
        z-index: 10;
    }
    .dialog-actions{
        padding-right: 15px;
        padding-bottom: 10px;
    }
    .blurItem{
        filter: blur(2px);
    }

    .md-progress-bar.md-theme-default.md-indeterminate{
        width: 100%;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
        
    }

    .border-none{
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    }

    .intro{
        width: 95%;
        text-align: center;
        line-height: 1.75;
        font-weight: 300;
        font-size: 15px;
        -webkit-font-smoothing: initial;
        margin-left: auto;
        margin-right: auto;
        color: #595959;
    }
   
    .login{
        height: 288px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        padding-top: 13px;    
        .md-field{
            margin: 4px 0 13px;
            padding-top: 0px;
        }
        .terms {
            font-family: 'Lato',sans-serif;
            font-size: 14px;
            padding-left: 20px; 
            padding-right: 20px;
            padding-bottom: 10px;
            padding-top: 24px;
            font-weight: 500;
            width: 470px;
            margin-left: auto;
            margin-right: auto;
            display: none;
        }
    }

    

    .form{
        display: block;
        position: relative;
        float: left;
        width: 100%;
        background-color: #fff;

        .md-field::before, .md-field:after{
            left: unset!important;
            right:unset!important;
            position: relative!important;
            bottom: unset!important;
            content: unset!important;
        }

        input{
            font-size: 16px;
            line-height: 1.33333;
            font-weight: 400;
            color: rgba(0,0,0,0.9);
            position: relative;
            z-index: 1;
            height: 52px;
            padding-left: 15px;
            box-shadow: none;
            border-radius: 2px;
            border: 1px solid rgba(0,0,0,0.6);
            outline: 0;
            box-sizing: border-box;
            background-color: transparent;
            max-width: 380px;
            width: 380px;
            margin-left: auto;
            margin-right: auto;
            font-family: Lato,sans-serif;
            &::placeholder{
                color: #808080!important;
            }
        }
        label {
            font-family: 'Lato',sans-serif;
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
            font-family: Open Sans,sans-serif;
            color: #444;
            font-size: 14px;
            margin-top: -30px;
            
        }
        .sign-in{
            display: block;
            color: #fff!important;
            margin-top: 2px;
            background: #0078d3;
            border: none;
            font-size: 17px;
            border-radius: 0;
            text-transform: unset;
            font-weight: 700;
            height: 52px;
            font-family: 'Lato',sans-serif;
            padding-top: 0px;
            width: 380px;
            margin-left: auto;
            margin-right: auto;
        }
        .md-card-actions{
            margin-top: 0px;
            padding-top: 0px;
            margin-right: 0px;
        }
    }
     .title{
        background-color: #fff;
        position: relative;
        display: block;
        float: left;
        line-height: 1.2;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 100%;
    }
    .title h1{
        font-family: "Lato", sans-serif;
        font-size: 24px;
        color: #000000;
        font-weight: 600;
        padding-top: 15px;
        -webkit-font-smoothing: auto;
        margin-bottom: 8px;
    }
        
    .forgot{
        text-transform: none;
    }

     @media only screen and (max-width: 499px) {

         .md-error{
             padding-left: 3px!important;
         }
       

        .form input{
            max-width: 100%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            &::placeholder{
                color: #808080!important;
            }
        }


        .md-card{
            width: 100%;
        }
        .terms{
            font-size: 12px!important;
            margin-top: -2px;
            margin-bottom: 6px;
        }
        .login{
            height: 292px;
        }

        .sign-in{
             width: 100%;
        }
        .md-card-actions{
            padding: 0!important;
            margin-left: 12px!important;
            margin-right: 12px!important;
        }
        .form .sign-in{
            width: 100%;
        }
    }
</style>