<template>
    <md-dialog
            class="action-confirm-dialog"
            :md-active.sync="itemActive"
            md-confirm-text="Continue"
            md-cancel-text="Cancel"
            @md-cancel="$emit('close')"
            @md-confirm="confirm()"
            :md-click-outside-to-close="!loading"
    >
        <div class="description-body">
            <div v-if="failedPayments && failedPayments.length" class="failed-payment">
                <h3 class="choose">
                    You have a failed payment!
                </h3>
                <p>Your payment of ${{ failedPayments[0].amount }} failed on {{ failedPayments[0].createdAt | date}}.</p>
                <p>Update your payment method before enabling Unlimited Forms, Premium Clicks and Premium Calls.</p>

                <md-dialog-actions>
                    <md-button
                        :md-ripple="false"
                        class="md-primary md-raised"
                        :to="{ name: 'settings.new-payment-method' }"
                        :disabled="loading"
                    >Update Payment Method</md-button>
                </md-dialog-actions>



            </div>

            <div v-else>
                <h3 class="choose">
                    {{title}}
                </h3>
                <ul>
                    <li>{{result1}}</li>
                    <li>{{result2}}</li>
                </ul>

                <hr>

                    <div class="pricing included-price" v-if="item">
                        <div class="subscription-price">
                            <template v-if="subscriptionCostsOverridesByFacility(item.facilityId) && subscriptionCostsOverridesByFacility(item.facilityId)[0]">
                                <span class="price-text">${{ subscriptionCostsOverridesByFacility(item.facilityId)[0].overrides }}/month +</span>
                            </template>
                            <template v-else>
                            <span class="price-text">${{ subscriptioncosts.overrides }}/month +</span>
                            </template>
                        </div>
                      
                        <div class="click-price ">
                            <span class="price-text">{{cost}}</span>
                        </div>
                        <p class="subtitle">{{costSubtitle}}</p>
                        <div class="error-message" v-if="error" v-html="error"></div>

                        <div class="continue-btn">
                            <md-button
                                :md-ripple="false"
                                class="md-primary md-raised"
                                @click="confirm"
                                :disabled="loading"
                            >Continue</md-button>
                        </div>
                  </div>
            </div>
            <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading"></md-progress-spinner>

        </div>
    </md-dialog>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import {format, parse} from "date-fns";
    export default {
        name: "SubscribeEducatorModal",

        props: ["item"],

        data() {
            return {
                title: '',
                itemActive: false,
                result1: '',
                result2: '',
                cost: null,
                costSubtitle: null,
                error: null,
                loading: false,
            }
        },

        computed: {
            ...mapGetters([
                'callProduct',
                'clickProduct',
                'facilitySubscriptionCost',
                'calculateFacilityBudget',
                'subscriptions',
                'campaigns',
                'trackingNumbers',
                'failedPayments',
                'subscriptionCostsOverridesByFacility',
                'subscriptioncosts'
            ]),
        },

        filters: {
            date(date){
                return format(parse(date), 'MM/DD/YYYY')
            },
        },

        watch: {
            item(newVal, oldVal){
                if (newVal){
                    this.error = false;
                    const subscriptionPrice = this.facilitySubscriptionCost(this.item.facilityId);
                    const facilityBudget = this.calculateFacilityBudget({
                        facilityId: this.item.facilityId,
                        numInteractions: 1,
                        startDate: new Date()
                    })

                    // IF CALL PRODUCT
                    if(newVal.productId == this.callProduct.id){
                        // console.log('CALL PRODUCT')
                        this.title = "Premium Calls"
                        this.result1 = "Replace third-party ads"
                        this.result2 = "Generate more calls to your facility"
                        this.cost = this.formatPrice(facilityBudget.singleCallCost)
                        this.costSubtitle = "per call"
                    }
                    // IF CLICK PRODUCT
                    else if(newVal.productId == this.clickProduct.id){
                        this.title = "Premium Clicks"
                        this.result1 = "Replace third-party ads"
                        this.result2 = "Generate more visitors to your website"
                        this.cost = this.formatPrice(facilityBudget.singleClickCost)
                        this.costSubtitle = "per click"
                    }
                    // IF FACILITY
                    else if (newVal.attributes){
                        //console.log('FACILITY ITEM, NO ACTION NOW')
                    }
                    // MUST BE BED REQUEST LINE
                    else {
                        // console.log('BED REQUEST ITEM')
                        this.title = "Unlimited Forms"
                        this.result1 = "Replace third-party ads"
                        this.result2 = "Generate leads to your facility"
                        this.cost = "Included"
                        this.costSubtitle = "Unlimited Forms"
                    }


                    this.itemActive = true;
                    return
                }
                this.itemActive = false
            },
            itemActive(newVal){
                if (!newVal){
                    this.error = null;
                    this.$emit('close')
                }
            }
        },
        async mounted() {
            await this.fetchSubscriptionCosts();
        },
        methods: {
            ...mapActions([
                'createSubscriptions',
                'updateCampaign',
                'fetchSubscriptionCosts'
            ]),
            formatPrice(value) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                })

                return formatter.format(value)
            },
            async confirm() {
                this.loading = true;
                try {
                  this.error = null;
                  // console.log('CONFIRM CLICKED ON ', this.item);
                  // START THE SUBSCRIPTION
                  await this.createSubscriptions([this.item.facilityId])

                  // CALL PRODUCT
                  if(parseInt(this.item.productId) === parseInt(this.callProduct.id)){
                    this.$emit('changeStatus', this.item)
                  }
                  // CLICK PRODUCT
                  else if(this.item.productId == this.clickProduct.id){
                    this.$emit('changeStatus', this.item)
                  }
                  this.itemActive = false;
                  this.loading = false;
                }catch(e){
                  this.loading = false;
                  console.error('ERROR ENABLING LEADS:', e);
                  if(e.response){
                    console.log('ERROR RESPONSE', e.response);
                    this.error = `Error: ${e.response.data.message} <a href="/settings/payment-methods/add/">Update your payment method</a> and try again.`
                  }else {
                    this.error = 'Error enabling Unlimited Forms. If this error persists, <a href="mailto:support@rehab.com">contact support</a>.'
                  }


                }

            }
        }
    }
</script>

<style lang="scss" scoped>
.description-body {
    min-width: 500px;
    padding: 0 25px 15px;

    h3 {
        font-size: 20px;
        color: #545454;
        margin-bottom: 0;
        margin-top: 25px;
        font-weight: 500;
    }
    .choose {
        margin-top: 30px;
    }
    p, li {
        color: #545454;
        font-size: 16px;
    }
    ul {
        list-style: none;
        padding-inline-start: 0;
        li {
            line-height: 30px;
            margin-left: 0;
        }
    }

    .failed-payment{
        max-width: 450px;
    }

    .error-message{
        color: red;
        margin-top: 25px;
        max-width: 330px;
    }

    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #9F9F9F;
        margin: 25px 0 25px;
        padding: 0;
        width: 95%;
    }

    .pricing {
        text-align: left;
    /*padding-top: 25px;*/
    .subscription-price{
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #545454;
        font-weight: 400;
    }
    .call-price{
        margin-left: 30px;
    }
    .click-price {
        display: inline-block;
        color: #545454;
        font-weight: 500;


    sup {
        font-size: 26px;
    }
    .price-text {
        font-size: 45px;
        padding-top: 10px;
        display: inline-block;
    }
    .per {
        display: block;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 8px;
    }

    .iconContainer{
        /*left: 80px;*/
        position: relative;
        top: -25px;
        display: inline-block;
        margin-left: -2px;
        width: 20px;

        .icon{
            color: #c6c6c6 !important;

        &:hover{
             cursor: pointer;
         }
        font-size: 18px !important;
        }

    }

    .tooltipbox{
        display: inline-block;
        position: absolute;
        margin-left: 15px;
        z-index: 6;
        background-color: #fff;
        width: 286px;
        /*height: 100px;*/
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;

    }
    }

    .plus {
        display: inline-block;
        font-size: 30px;
        padding-left: 16px;
        padding-right: 16px;
        color: #0B61C9;
        position: relative;
        top: -10px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 16px;
        margin-top: 5px;

    }

    }
    .included-price{
        .price-text{
            margin-top: 10px;
        }
        .subtitle {
            margin-top: 2px;
        }
    }

    .continue-btn{
        position: absolute;
        right: 25px;
        bottom: 25px;
    }

    .spinner{
      top:50%;
      margin-top: -50px;
      left: 50%;
      margin-left: -40px;
      position: absolute;
    }
}

@media only screen and (max-width: 760px),
screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
{
    .md-dialog {
        .md-dialog-container {
            .description-body {
                min-width: inherit !important;

                hr {
                    width: 100%;
                }
            }
        }
    }
}
</style>