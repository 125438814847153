<template>
     <div v-if="firstTimeEditAdmin" class="md-dialog tooltip">
        <template v-if="step==1">
                <div class="container step1">
                    <div class="close" @click="closeTooltip"><md-icon>close</md-icon></div>
                    <md-dialog-title>Welcome to your account admin</md-dialog-title>
                    <md-dialog-content>
                        Rehab.com offers free tools for showcasing your program, gaining exposure, and communicating with the patients you care for. By updating your listing information you can customize your message to 2M+ annual visitors. 
                    </md-dialog-content>
                    <div class="arrow-left"></div>
                    <span class="steps">{{ step }}/{{ steps }}</span>
                    <md-dialog-actions>
                        <md-button class="secondary" v-if="step<=steps && step>1" @click="step--">Back</md-button>
                        <md-button class="active" v-if="step<steps" @click="step++">Next</md-button>
                        <md-button class="active" v-if="step==steps" @click="closeTooltip">End Tour</md-button>
                    </md-dialog-actions>
                </div>
        </template>
        <template v-if="step==2">
            <div class="container step2">
                <div class="close" @click="closeTooltip"><md-icon>close</md-icon></div>
                <md-dialog-title>Update listing information</md-dialog-title>
                <md-dialog-content>
                    Customize your listing to ensure it is up-to-date and accurate. Start adding and updating your program information and begin customizing your message.                 
                </md-dialog-content>
                <div class="arrow-left"></div>
                <span class="steps">{{ step }}/{{ steps }}</span>
                <md-dialog-actions>
                    <md-button class="secondary" v-if="step<=steps && step>1" @click="step--">Back</md-button>
                    <md-button class="active" v-if="step<steps" @click="step++">Next</md-button>
                    <md-button class="active" v-if="step==steps" @click="closeTooltip">End Tour</md-button>
                </md-dialog-actions>
            </div>
        </template>
        <template v-if="step==3">
            <div class="container step3">
                <div class="close" @click="closeTooltip"><md-icon>close</md-icon></div>
                <md-dialog-title>Save your updates</md-dialog-title>
                <md-dialog-content>
                    Once you have submitted your updates, click “Save” to ensure your updates are submitted for review. All updates are submitted for review by our moderation team to ensure they align with our Terms and Conditions and Privacy Policy.
                </md-dialog-content>
                <div class="arrow-left"></div>
                <span class="steps">{{ step }}/{{ steps }}</span>
                <md-dialog-actions>
                    <md-button class="secondary" v-if="step<=steps && step>1" @click="step--">Back</md-button>
                    <md-button class="active" v-if="step<steps" @click="step++">Next</md-button>
                    <md-button class="active" v-if="step==steps" @click="closeTooltip">End Tour</md-button>
                </md-dialog-actions>
            </div>
        </template>
       
       
     </div>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions} from 'vuex'

    export default{
        name: 'FacilityTooltip',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
                step:1,
                steps: 3
            }
        },
        computed: {
            ...mapGetters({
                loginLogs: 'loginLogs',
                tooltips: 'tooltips',
                user: 'user',
            }),
        },
        mounted() {
            // console.log('LOGIN LOGS', this.loginLogs);
            const loginsData = this.loginLogs.data.filter((item) => item.attributes.action == 'login')
            const loginsDataFiltered = loginsData.filter((item) => item.attributes.userId == this.user.id)
        
            if(loginsDataFiltered.length == 1 && !this.tooltips.find((item) => item == 'AdminTooltip')){
                this.$store.commit('setTooltips', 'AdminTooltip')
            }
            this.$root.$on('facilityTooltip', () => {
                this.firstTimeEditAdmin = true
            })

            const adminTooltipComplete = this.$store.getters.statusLogByName('adminTooltip');
            if(!adminTooltipComplete) {
                this.firstTimeEditAdmin = true;
            }
        },
        methods: {
            closeTooltip() {
                this.firstTimeEditAdmin = false;
                this.$store.dispatch('createStatusLog', {
                    type: 'adminTooltip',
                    action: 'closed'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
   
    .md-dialog {
        overflow: visible;
        top: 0px;
        left: 0px;

        .container{
            width: 400px;
            height: 220px; 
            background-color:var(--md-theme-default-primary);
            color: #fff;
            padding:4px;
            padding-bottom: 10px;
            text-align: left;
            overflow: visibe;
            padding-top: 22px;
            overflow: visible;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

            .md-dialog-actions{
                position: absolute;
                right: 0px;
                top: 158px;
            }
            .md-dialog-content{
                padding-top: 10px;
            }
        }

        .close{
            color: #1E152A;
            position: absolute;
            right: 20px;
            top:18px;
            font-size: 24px;
            .md-icon{
                color: #fff;
            }
            :hover{
                cursor: pointer;
            }
        }
        .md-dialog-content a{
            color: #fff;
        }
    
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color: #fff;
        }
        .secondary{
            color: #9fc1f9;
        }
        .md-dialog-actions{
            padding-right: 14px;
        }
        .arrow-up {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-bottom: 5px solid var(--md-theme-default-primary);
        }
        .arrow-down {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            
            border-top: 10px solid var(--md-theme-default-primary);
             margin-left: -14px;
            position: absolute;
            top: 31px;
        }
        .arrow-right {
            width: 0; 
            height: 0; 
            border-top: 60px solid transparent;
            border-bottom: 60px solid transparent;
            
            border-left: 60px solid var(--md-theme-default-primary);
            
        }
        .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            
            border-right: 10px solid var(--md-theme-default-primary);
            margin-left: -14px;
            position: absolute;
            top: 31px;
        }
        .step1{
            top:72px;
            left: 10px;
            position: absolute;
            z-index: 6;
        }
        .step2{
            top:72px;
            left: 10px;
            position: absolute;
            z-index: 6;
        }
        .step3{
            top:72px;
            left: 10px;
            position: absolute;
            z-index: 6;
        }
         .step4{
            top: 72px;
            left: 10px;
            position: absolute;
            z-index: 6;
            .arrow-left{
                top: 54px;
            }
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .md-dialog .arrow-left, .md-dialog .arrow-right{
            display: none;
        }
        .md-dialog .tooltip{
             z-index: 100;
        }

        .md-title{
            display: block;
            padding-top: 0;
        }
        .md-dialog .container{
            z-index: 1000;
            top:76px;
            left: unset;
            margin: 0;
            width: unset;
            right: unset;
            margin-left: 14px;
            margin-right: 14px;
            border-radius: 5px;
            height: auto;
            -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
        }
        .md-dialog .step2{
            .arrow-down{
                left: 275px;
            }
        }
        .md-dialog .close{
            top: 7px;
            right: 6px;
        }
        .md-dialog .steps{
            top:unset;
            position: relative;
            float: left;
            padding-bottom: 10px;
            padding-top: 16px;
        }
        .md-dialog .container .md-dialog-actions{
            top: unset;
            position: relative;
        }
        .md-dialog .step2, .md-dialog .step3, .md-dialog .step4{
            top: 76px;
        }
    }
</style>