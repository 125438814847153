<template>
    <div>
                <div class="md-layout">
                    <md-card>
                        <md-table class="md-table-mobile-padding" v-if="notifications.data.length>0">
                            <md-table-row>
                                <md-table-head>Alert</md-table-head>
                                <md-table-head>Description</md-table-head>
                                <md-table-head>Date</md-table-head>
                                <md-table-head>Time</md-table-head>
                            </md-table-row>
                            <md-table-row class="grey" v-for="(item, index) in paginationNotifications" v-bind:key="item.id">
                                <md-table-cell>{{ item.attributes.datatype | capitalize }}</md-table-cell>
                                <md-table-cell>{{ item.attributes.description | capitalize }}</md-table-cell>
                                <md-table-cell>{{ item.attributes.createdAt | date }}</md-table-cell>
                                <md-table-cell>{{ item.attributes.createdAt | time }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <!-- PAGINATION BUTTONS -->
                        <div class="md-layout" v-if="notifications.data.length > paginationLimit">
                            <div class="md-layout-item table-nav">
                                <md-button
                                    class="md-raised md-primary"
                                    :disabled="currentPage === 1"
                                    @click="previousPage"
                                >Previous</md-button>
                                <md-button
                                    class="md-raised md-primary"
                                    :disabled="nextDisabled"
                                    @click="nextPage"
                                >Next</md-button>
                            </div>
                        </div>
                    </md-card>
                </div>
                <div class="notice" v-if="notifications.data.length==0">
                    <span class="title">No results have been found</span>
                </div>
    </div>
</template>

<script lang="javascript">

    import config from '../config.js'
    import {mapGetters, mapActions} from 'vuex'
    import {format} from 'date-fns'

    export default{
        name: 'Notifications',
        data(){
            return {
                facilities: [],
                paginationLimit: 15,
                currentPage: 1,
            }
        },
        async created(){
            await this.$store.dispatch('fetchNotifications')
            this.facilities = this.$store.getters.facilities

            for(const notification of this.notifications.data){
                if(notification.attributes.status === false){
                    await this.$store.dispatch('markNotificationViewed', notification.id)
                }
            }
        },
        watch:{
        },
        computed: {
            ...mapGetters({
                notifications: 'notifications',
            }),
            mobile() {
                return this.windowWidth <= 760;
            },
            paginationNotifications() {
                const start = this.paginationLimit * (this.currentPage - 1);
                const end = start + this.paginationLimit;
                return this.notifications.data.slice(start, end)
            },
            nextDisabled() {
                if(this.currentPage === Math.ceil(this.notifications.data.length / this.paginationLimit)){
                  return true;
                }
                return false;
            }
        },
        filters: {
            date(date){
                const dateString = date;
                const localDate = new Date(`${dateString}`);
                return format(localDate, 'MM/DD/YYYY')
            },
            time(date){
                const dateString = date;
                const localDate = new Date(`${dateString}`);
                return format(localDate, 'hh:mm a')
            },
            calculate(date){
                var diff = Math.abs(new Date() - new Date(date));

                var minutes = Math.floor((diff/1000)/60);
                return minutes
            },
            capitalize(val){
                return val.charAt(0).toUpperCase() + val.slice(1);
            },
            formatPrice: (value) => {
                let val = (value / 1).toFixed(2).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },
        methods: {
            facilityData(val){
                const facility = this.facilities.find((item) => Number(item.attributes.facilityId) == Number(val))
                return facility.attributes.name
            },
            previousPage() {
                if(this.currentPage > 1){
                   this.currentPage--
                }
            },

            nextPage() {
                this.currentPage++;
            }
        }
    }

</script>

<style lang="scss" scoped>
    span {
        width: 100%;
        height: 100%;
        padding: 8px;
        display: block;
        background: md-get-palette-color(blue, 200);
    }
    .md-icon:hover{
        cursor: pointer;
    }
    .md-title{
        margin-bottom: 20px;
    }
    .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 50%;

        .title{
            font-size: 22px;
            font-weight: 500;
            clear: both;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
            clear: both;
        }
        span{
            height: unset;
            width: 100%;
            padding: 8px;
            display: block;
        }
    }
    .md-dialog{
        border-radius: 10px;
        width: 400px;
        padding-bottom: 2px;
        top: 156px;

        .md-dialog-title{
            padding-bottom: 0px;
            margin-bottom: 0px;
            text-align: center;
            padding-top: 20px;

        }
        .md-list-item-text{
            font-size: 14px;
        }
        .dialogList{
            padding: 10px;
            padding-bottom: 0px;
        }
        .md-content{
            padding-left:0px;
            text-align: center;
        }
        li{
            height: 30px;
        }
        .md-button{
            margin-top: 20px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .align-right {
        text-align: right;
    }
    .align-left {
        text-align: left;
    }
    .align-center {
        text-align: center;
    }
    .icon .md-icon{
        font-size: 18px!important;
    }
    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }
</style>
