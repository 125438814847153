<template>
    <div class="page-container">
        <md-app>
            <app-toolbar></app-toolbar>
            <app-drawer></app-drawer>

            <md-app-content>
                <p>Page not found.</p>
                <p>
                    <router-link to="/dashboard">
                        View Dashboard
                    </router-link>
                </p>
            </md-app-content>
        </md-app>
    </div>
</template>

<style lang="scss">
    .md-app {
        border-bottom: 1px solid rgba(#000, .12);
    }
    .md-app-scroller {
        min-height: calc(100vh - 65px) !important;
    }
</style>

<script>
export default {
    name: 'Normal'
}
</script>