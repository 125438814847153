<template>
    <div>
                 <div class="tab-container">
                        <md-button
                                class="tab-nav"
                                :class="{ 'tab-nav-selected': tab === 'new' }"
                                @click="tab = 'new'"
                                v-if="reviews.length > 0 && !loading"
                        >New</md-button>
                        <md-button
                                class="tab-nav"
                                :class="{ 'tab-nav-selected': tab === 'archived' }"
                                @click="tab = 'archived'"
                                v-if="responses.length > 0 && !loading"
                                
                        >Archived</md-button>
                    </div>
                <div class="md-layout md-alignment-top-right filters" v-show="users.status == 1 && (responses.length >0 || reviews.length >0 || firstLoad==false )">
                   

                    <div class="md-layout-item md-size-15"  v-if="facilities.length > 1">
                        <md-field>
                            <md-select placeholder="Listing" v-model="selectedFacility" name="selectedFacility" id="selectedFacility" @md-selected="onChange(selectedFacility)" mdClass="filtersPaddingTopFacility">
                                <md-option :value="0">View all</md-option>
                                <md-option v-for="(item) in facilities" :value="item.attributes.facilityId" :key="item.attributes.facilityId">{{ item.attributes.name }}</md-option>
                            </md-select>
                        </md-field>
                    </div>
<!--                    <div class="md-layout-item md-size-15" v-if="!mobile">-->
<!--                        <md-datepicker v-model="startDate" md-immediately>-->
<!--                        </md-datepicker>-->
<!--                    </div>-->
<!--                    <div class="md-layout-item md-size-15" v-if="!mobile">-->
<!--                        <md-datepicker v-model="endDate" md-immediately>-->
<!--                        </md-datepicker>-->
<!--                    </div>-->
                </div>
                <div class="md-layout">
                    <md-card v-if="showReviews.length>0">
                        <md-table class="table md-table-mobile-padding">
                            <md-table-row>
                                <md-table-head v-if="!mobile">ID</md-table-head>
                                <md-table-head>Date</md-table-head>
                                <md-table-head  v-if="!mobile">Respond</md-table-head>
                                <md-table-head>Review</md-table-head>
                                <md-table-head>Score</md-table-head>
                            </md-table-row>
                            <md-table-row slot="md-table-row"  v-for="(item) in showReviews" v-bind:key="item.id" class="grey">
                                <!-- ID -->
                                <md-table-cell
                                        md-label="Id" md-sort-by="Id" v-if="!mobile" 
                                >{{ item | maskId}}</md-table-cell>

                                <!-- DATE -->
                                <md-table-cell
                                        class="table-date"
                                        md-label="Date" md-sort-by="Date"
                                        v-if="item.createdAt"
                                >
                                    {{ item.createdAt.date | formatDate }}
                                </md-table-cell>
                                <md-table-cell md-label="Date" md-sort-by="Date" v-else></md-table-cell>

                                <!-- RESPONSE -->
                                <md-table-cell
                               
                                        md-label="Respond"
                                        md-sort-by="Response"
                                        v-if="!item.response && !mobile"
                                >
                                    <a @click="formInfo.reviewId = item.id">Respond</a>
                                </md-table-cell>
                                <md-table-cell
                                       
                                        md-label="Respond"
                                        md-sort-by="Response"
                                        v-if="item.response && !mobile"
                                >
                                    Yes
                                </md-table-cell>

                                <!-- REVIEW -->
                                <md-table-cell
                                        md-label="Review"
                                        md-sort-by="Review"
                                >
                                    {{ item.comment }}
                                    <br v-if="mobile">
                                    <a @click="formInfo.reviewId = item.id" v-if="!item.response && mobile">Respond</a>
                                    <!-- REVIEW RESPONSE-->
                                    <template v-if="item.response">
                                        <hr>
                                        <p class="review-response">{{ item.response.comment }} <span v-if="item.response.status === 0"> — Pending review</span></p>
                                    </template>

                                    <!-- RESPOND TO REVIEW FORM -->
                                    <template v-if="!item.response && item.id === formInfo.reviewId" >
                                        <form @submit.prevent="add">
                                            <md-field>
                                                <label>Public response</label>
                                                <md-textarea
                                                        :name="'comment-' + item.id"
                                                        :id="'comment-' + item.id"
                                                        v-model="formInfo.comment"
                                                ></md-textarea>
                                                <span
                                                        class="md-error"
                                                        v-if="formInfo.responseSubmitted && !$v.formInfo.comment.required"
                                                >Description is required</span>
                                            </md-field>
                                            <!-- CANCEL -->
                                            <div class="response-buttons">
                                                <md-button
                                                        class="md-primary"
                                                        @click="formInfo.comment = null; formInfo.reviewId = null"
                                                >Close</md-button>
                                                <!-- SAVE -->
                                                <md-button class="md-primary" @click="add()" :disabled="users.status > 1">Submit</md-button>
                                            </div>

                                        </form>
                                    </template>
                                </md-table-cell>

                                <!-- SCORE -->
                                <md-table-cell
                                        md-label="Score"
                                        :class="{'center': !mobile}"
                                        md-sort-by="Score"
                                >
                                    {{ item.score | formatScore}}
                                </md-table-cell>

                            </md-table-row>
                        </md-table>
                    </md-card>
                    <div class="container" v-show="showReviews.length == 0 && loading == false && firstLoad==false && users.status == 1">
                        <app-activityzero></app-activityzero>
                    </div>

                    <div
                        class="container"
                        v-show="showReviews.length == 0 && loading == false && firstLoad==true && users.status == 1"
                    >
                         <reviews-zero
                             :archivedReviews="responseReviews.length > 0"
                         ></reviews-zero>
                    </div>

                    <div class="container" v-show="reviews.length == 0 && loading == false && users.status == 3">
                        <app-reviews></app-reviews>
                    </div>
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading && showReviews.length == 0"></md-progress-spinner>

                </div>
                <app-reviewstooltip v-if="!loading"></app-reviewstooltip>
        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import { validationMixin } from 'vuelidate'
    import {
        required,
        email,
        minLength,
        maxLength
    } from 'vuelidate/lib/validators'
    import {mapGetters, mapActions} from 'vuex'
    import{subMonths, format, startOfMonth, endOfToday, parse} from 'date-fns'

    import ReviewsZero from "@/components/Cards/ReviewsZero";


    export default{
        mixins: [validationMixin],
        name: 'Reviews',
        components: {ReviewsZero},
        data(){
            return {
                config: config,
                data: '',
                formInfo:{
                    response: null,
                    reviewResponse: null,
                    responseSubmitted: false,
                    reviewId: null,
                    comment: null
                },
                selectedFacility: 0,
                submitted: false,
                reviews: [],
                responses: [],
                facilityId: null,
                facilities: {},
                facilitiesIDs:[],
                startDate: new Date(2017, 0, 1),
                endDate: endOfToday(),
                loading: true,
                users: [],
                firstLoad: true,
                showSnackbar: false,
                snackMsg: '',
                tab: 'new',
                mobile: false
            }
        },
        computed: {
            showReviews() {
                if(this.tab === 'new'){
                    return this.reviews.filter(review => !review.response)
                } else {
                    return this.reviews.filter(review => review.response)
                }
            },
            responseReviews() {
              return this.reviews.filter(review => review.response)
            },
            ...mapGetters({
                loginLogs: 'loginLogs',
                tooltips: 'tooltips',
                user: 'user',
            }),
        },
        created(){

            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }

            this.users = this.$store.getters.user
            //populate facilities drop down from the vuex store
            this.facilities = this.$store.getters.facilities
            this.facilities.forEach((item) => {
                this.facilitiesIDs.push(item.attributes.facilityId)
            })
            this.$material.locale.dateFormat = "MM/dd/yyyy";
        },
        async mounted(){

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })

            const loginsData = this.loginLogs.data.filter((item) => item.attributes.action == 'login')
            const loginsDataFiltered = loginsData.filter((item) => item.attributes.userId == this.user.id)
        
            this.$watch('startDate', (value, oldValue) => {
                if(value.getTime() != oldValue.getTime()){
                    this.update()
                    this.firstLoad = false
                }
            })
            this.$watch('endDate', (value, oldValue) => {
                var valueA = new Date(value)
                valueA = valueA.getDate() + '/' + valueA.getMonth() + '/' + valueA.getFullYear()

                var valueB = new Date(oldValue)
                valueB = valueB.getDate() + '/' + valueB.getMonth() + '/' + valueB.getFullYear()

                if(valueA != valueB){
                   
                    this.update()
                    this.firstLoad = false
                }
            })
          
            if(this.user.status == 1){
                await this.update()
            }else{
                this.loading = false
            }

            this.$watch('data', (value, oldValue) => {
                if(this.data.facilityVideo) this.videotype = this.data.facilityVideo.videoTypeName
                if(this.data.facilityDescription) this.facilityDescription = this.data.facilityDescription
            })

            const newReviews = this.reviews.filter(review => !review.response)
            const archivedReviews = this.reviews.filter(review => review.response)

            if(!newReviews.length && archivedReviews.length){
                // console.log('SWITCHING TO ARCHIVED');
                this.tab = 'archived'
            }
        },
        validations: {
            responses: {
                required,
                min: minLength(3),
                $each: {
                    comment:{
                        required
                    }
                },
            },
            formInfo: {
                comment: {
                    required
                }
            }
        },
        filters: {
            formatScore(score) {
                return parseInt(score) + "/10"
            },
            formatDate(date) {
                return format(parse(date), 'MM/DD/YYYY')
            },
            maskId(review){ // ADD A PORTION OF THE REVERSED UNIX DATESTAMP TO THE ID SO THAT IT IS 6 CHARACTERS LONG.
                const unixDate = Math.round((parse(review.createdAt.date)).getTime() / 1000);
                const idLength = `${review.id}`.length;
                const additionalCharactersNeeded = 6 - idLength;
                const stringUnix = ("" + unixDate).split("").reverse().join("");
                return `${stringUnix.substring(1, additionalCharactersNeeded + 1)}${review.id}`

            }
        },
        methods: {
            ...mapActions([
                'createReviewResponse'
            ]),
            async update(){
                if(this.users.status == 1){
                    await this.getInformation()
                }else{
                    this.loading = false
                }
            },
            getValidationClass (fieldName) {
                const field = this.$v.form[fieldName]
                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            async getInformation(){
                this.loading = true;
                this.responses, this.reviews = []


                try{
                    const reviewsResponse = await this.$http.get(this.config.apiUrl + '/getfacilityreviewsapis?facilityId='+this.facilitiesIDs.join(",")+'&startDate='+this.getDate('startDate')+'&endDate='+this.getDate('endDate')+'&status=1')
                    // console.log('REVIEW RESPONSE DATA', JSON.parse(JSON.stringify(reviewsResponse.data)))
                    if(reviewsResponse.data && reviewsResponse.data.approved){
                        const reviews = reviewsResponse.data.approved.filter(item => !item.parentId);

                        const approvedResponses = reviewsResponse.data.approved.filter(item => item.parentId);
                        const pendingResponses = reviewsResponse.data.inactive.filter(item => item.parentId);

                        const responses = [...approvedResponses, ...pendingResponses];

                        for(const response of responses){
                            const review = reviews.find(review => review.id === response.parentId);
                            if(review){
                                review.response = response
                                this.responses.push(response)
                            }
                        }

                        this.reviews = reviews

                        this.reviews.sort(function(a, b) {
                            return b.id - a.id
                        });
                    }
                    this.loading = false;
                }catch (error) {
                    this.loading = false;
                    var response = error.response
                    console.error(error);
                    console.error(response)
                    this.snackMsg = "There was an error loading the reviews. If this problem persists please contact support."
                    this.showSnackbar = true;
                }
            },
            getResponse(reviewId){
                var index = this.responses.findIndex(x => x.parentId === reviewId);
                return index
            },
            getDate(date){
                let endDate = this.endDate.getFullYear() + '-' + (((this.endDate.getMonth() + 1) < 10) ? '0' : '') + (this.endDate.getMonth() + 1) + '-' + ((this.endDate.getDate() < 10) ? '0' : '') + this.endDate.getDate();
                let startDate = this.startDate.getFullYear() + '-' + (((this.startDate.getMonth() + 1) < 10) ? '0' : '') + (this.startDate.getMonth() + 1) + '-' + ((this.startDate.getDate() < 10) ? '0' : '') + this.startDate.getDate();

                return date == 'startDate' ? startDate : endDate
            },
            onChange(event){
                if(event === 0 ){
                    this.facilities.forEach((item) => {
                        this.facilitiesIDs.push(item.attributes.facilityId)
                    })
                }else{
                    this.facilitiesIDs = [event]
                }
                this.$children.find(child => { return child.$options.name === "ActivityZero"; }).updateFacility();
                this.firstLoad = false
                this.getInformation()
            },
            async add(){
                if(this.users.status>1){
                    return
                }
                try{
                    this.formInfo.responseSubmitted = true
                    this.$v.formInfo.$touch()
                    if(this.$v.formInfo.$error) return


                    // console.log('GOT THROUGH VALIDATION');
                    // to form submit after this
                    const createdReviewResponse = await this.createReviewResponse({
                        reviewsId: this.formInfo.reviewId,
                        comment: this.formInfo.comment,
                        facilityId: this.reviews.find(review => review.id === this.formInfo.reviewId).external_id
                    })
                    //console.log('CREATED REVIEW RESPONSE', createdReviewResponse);

                    var item = {
                        comment: this.formInfo.comment,
                        parentId: this.formInfo.reviewId,
                        status: 0,
                    }

                    const review = this.reviews.find(review => review.id === this.formInfo.reviewId)

                    if(review) review.response = item;

                    this.formInfo.comment = null, this.reviewId = null
                    this.submitted = false;
                    // console.log("Submitted")

                    this.snackMsg = "Your response has been submitted for review and will be published soon."
                    this.showSnackbar = true;

                    this.update();

                }catch(e){
                    console.log('ERROR VERIFYING', e);
                    this.verifyError = e.message
                }
            },
        }
    }

</script>

<style lang="scss" scoped>

    form{
        margin-top: 10px;
    }
    .md-textarea{
        height: auto;
        background-color: #fff;
        &::before{
             background-color: #fff;
        }
    }
    .table-date{
        white-space: nowrap;
    }
    .md-dialog{
        width: 500px;
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 50%;

        .title{
            font-size: 22px;
            font-weight: 500;
            clear: both;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
            clear: both;
        }
        span{
            height: unset;
            width: 100%;
            padding: 8px;
            display: block;
        }
    }
    .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .white{
        background-color: #fff;
    }
    .center{
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    .md-field.md-theme-default:after{
        background-color: #DBDBDB;
    }
    .review-response {
        padding-left: 25px;
        font-style: italic;
    }
    hr {
        border: 0; border-top: 1px solid #d7d7d7;
    }

    textarea {
        font-size: 13px !important;
    }

    .response-buttons {
        text-align: right;
        margin-top: -25px;
        .md-button {
            text-transform: none !important;
        }
    }

    .md-button{
        border-radius: 0px;
    }

    .tab-nav {
        font-size: 13px !important;
        align-items:flex-start;
        background-attachment:scroll;
        background-clip:border-box;
        background-color:rgba(0, 0, 0, 0);
        color:rgb(55, 58, 59);
        height:48px;
        line-height:normal;
        margin: 0 0 0 0;
        position:relative;
        font-weight: 500;
    }

    .tab-nav-selected {
        border-bottom: rgb(0, 120, 211) solid 4px;
        color:rgb(0, 120, 211) !important;
        border-bottom-left-radius: 1px;
        padding-top: 3px;

    }

    .tab-container{
 
     
        background-color:#fff!important;
        position: absolute;
       
        z-index: 12;
       box-shadow: 0 3px 1px -4px rgba(0, 0, 0, 0.9), 0 1px 0px 0 rgba(0, 0, 0, 0);

        .tab-nav{
            height: 47px;
        }
        
    }
    .container{
        width: 100%;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .md-field.md-has-textarea:not(.md-autogrow) label{
            font-size: 13px!important;
        }

    }

</style>