<template>
  <div
    v-if="failedPayments && failedPayments.length"
    class="failed-payment-bar md-accent"
  >
    <div class="payment-alert">
      <md-icon class="md-size-3x">error_outline</md-icon>
    </div>
    <div class="payment-alert-body">
      <div class="title">Payment failure!</div>
      <span>Your payment of ${{ failedPayments[0].amount }} failed on {{ failedPayments[0].createdAt | date}}.</span><br>
      <md-button :to="{name: 'settings.new-payment-method'}" class="md-raised">Update Payment Method</md-button>
    </div>

  </div>
</template>

<script>
import{mapGetters} from 'vuex'
import { format, parse } from 'date-fns'

export default {
  name: "FailedPaymentBanner",

  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
        'failedPayments'
    ])
  },

  filters: {
    date(date){
      return format(parse(date), 'MM/DD/YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
  .failed-payment-bar {
    width: 100%;
    height: 100px;
    background-color: #ff5252;
    color: #fff;
    border-bottom: 1px solid #eee;;
    min-height: 100px;
    display: inline-flex;
    flex-wrap: nowrap;

    .payment-alert {
      padding: 15px;
      .md-icon {
        color: #fff;
      }
    }

    .payment-alert-body {
      margin-left: 10px;
      .title{
        font-size: 15px;
        font-weight: 600;
        height: 24px;
        margin-top: 6px;
      }

      .md-button {
        margin-left: 0;
      }

    }
  }
</style>