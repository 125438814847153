import {mapGetters, mapActions} from 'vuex'
import {format, isBefore, isAfter} from 'date-fns'
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            facilities: 'dashboardFacilities',
            products: 'products',
            models: 'campaigns',
            user: 'user',
            defaultPaymentMethod: 'defaultPaymentMethod',
            loginLogs: 'loginLogs',
            tooltips: 'tooltips',
            trackingNumbers: 'trackingNumbers',
            trackingUrls: 'trackingUrls',
        }),
        displayFacility(){
            if(this.selectedFacility){
                return this.facilities.filter(facility => this.selectedFacility.includes(facility.attributes.facilityId))
            }
            return this.facilities
        },

        campaigns() { // This creates a custom object in which to render the list
            const campaigns = []
            var counter = 1;
            for(let item of this.models){
                var campaignItem = {
                    counter: counter++,
                    id: item.id,
                    status: item.attributes.status,
                    budget: item.attributes.budget,
                    facilityId: item.attributes.facilityId,
                    startDate: item.attributes.startDate,
                    product: this.getProductName(item.attributes.productId),
                    productId: item.attributes.productId,
                    interactions: [],
                    cost: '',
                    total: 0
                }
                var itemDate = new Date(item.attributes.startDate);
                let currentDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()));
                currentDate.setHours(0, 0, 0, 0)
                campaigns.push(campaignItem)
            }
            return campaigns
        },

        facilitiesIDs(){
            const ids = this.facilities.map(facility => facility.attributes.facilityId)
            return ids;
        },

        fiveBudget(){
            return this.calculateBudget({numInteractions: 5})
        },
        tenBudget(){
            return this.calculateBudget({numInteractions: 10})
        },
        fifteenBudget(){
            return this.calculateBudget({numInteractions: 15})
        },
    },
    methods: {
        ...mapActions([
            'fetchCallTrackingMetricsApi',
            "fetchFacilityClicks",
            "fetchFacilityClicksGA",
            "fetchFacilityClicksGANewTracking",
            "fetchFacilityCallsGA",
            "fetchFacilityCallsGANewTracking",
            "fetchDefaultPaymentMethod",
            "updateCampaign",
            "fetchFacilityInformation"
        ]),

        // Returns the product name that is associated with the campaign
        getProductName(id, name = '') {
            this.products.forEach((item) => {
                if (item.id == id) {
                    name = item.attributes.name
                }
            })
            return name
        },

        formatPrice(value) {
            if(isNaN(value.toFixed(0))){
                return 0;
            }
            let val = (value / 1).toFixed(0).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

         //   return "$" + (val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")).split('.')
        },

        getTrackingNumber(facilitiesIDs) {
            var trackingNumber = []
            this.$store.getters.trackingNumbers.forEach((item) => {
                var facilitiesID = Number(item.facilityId)
                if (facilitiesIDs.indexOf(facilitiesID) >= 0) {
                    trackingNumber.push(item.filter_id)
                }
            })
            return [...new Set(trackingNumber)];
        },

        funnelSliderValues(goal){
            if(goal === 'calls'){
                return {
                    min: Math.ceil(this.fiveBudget.singleCallAverage * 3),
                    max: Math.ceil(this.fiveBudget.singleCallAverage * 90 + 1),
                    step: this.fiveBudget.singleCallAverage,
                }
            } else if(goal === 'clicks'){
                return {
                    min: Math.ceil(this.fiveBudget.singleClickAverage * 3),
                    max: Math.ceil(this.fiveBudget.singleClickAverage * 90 + 1),
                    step: this.fiveBudget.singleClickAverage,
                }
            }else if(goal === 'callsAndClicks'){
                return {
                    min: Math.ceil(this.fiveBudget.singleCallAverage * 3 + this.fiveBudget.singleClickAverage * 3),
                    max: Math.ceil(this.fiveBudget.singleCallAverage * 90 + this.fiveBudget.singleClickAverage * 90) + 1,
                    step: this.fiveBudget.singleCallAverage + this.fiveBudget.singleClickAverage
                }
            }
        },
    }

}