import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
  state: () => ({
    notifications: [],
  }),
  actions: {

    // CREDIT CARDS
    async fetchNotifications(context) {
      const user = context.getters.user;
      const url = `${apiUrl}/notifications`
      const result = await axios.get(url);

      // console.log('CC RESULT', JSON.stringify(result.data));
      
      context.commit('setNotifications', result.data)
      return result.data;
    },

    async createNotifications(context, {description, status, data, datatype}) {
      const apiurl = `${apiUrl}/notifications`
      const result = await axios.post(apiurl, {
        data: {
          attributes: {
            description,
            status,
            data,
            datatype 
          }
        }
      })
      return result.data
    },

    async markNotificationViewed(context, notificationId){
      const apiurl = `${apiUrl}/notifications/${notificationId}`
      const result = await axios.patch(apiurl, {
        data: {
          attributes: {
            status: 1
          }
        }
      })

      context.commit('markNotificationViewed', notificationId)

      return result.data;

    }
  },
  mutations: {
    setNotifications(state, notifications){
      notifications.data.sort(function(a, b) { 
        return b.id - a.id;
      });
      state.notifications = notifications
    },
    markNotificationViewed(state, notificationId){
      const notification = state.notifications.data.find(notification => notification.id === notificationId);
      if(notification) notification.status = 1;
    }
  },
  getters: {
    notifications: state => state.notifications
  }
}