<template>
    <div class="default-container" :class="{ 'blurItem' : blur}">
        <md-app v-if="!tooSmall">
            <app-toolbar></app-toolbar>
               <md-app-drawer md-permanent="full" :md-active.sync="showNavigation"  md-swipeable>
                <md-toolbar class="md-transparent" md-elevation="0">
                    <div class="logo">
                        <router-link :to="logoDestination">
                            <img class="image image-mobile" src="/connect_logo_mobile.svg"/>
                        <div class="line"></div>
                        </router-link>
                        <router-link :to="logoDestination">
                            <div class="title bold">Rehab.com</div>
                            <div class="sub-title">Provider Platform</div>
                        </router-link>
                    </div>
                    <md-button
                        class="md-icon-button md-dense"
                        v-if="mobile"
                        @click="showNavigation = false"
                    >
                        <md-icon class="smaller-icon">close</md-icon>
                    </md-button>


                </md-toolbar>
                <app-navsidemenu></app-navsidemenu>
            </md-app-drawer>
            <md-app-content>
                <router-view></router-view>
            </md-app-content>
        </md-app>
        <div class="mobile" v-if="tooSmall">
            <h1>Your screen size is too small</h1>
            <h3>We don't currently support this resolution, please try to access our system on a larger device.</h3>
            <p>If you have an urgent request, please contact support at <a href="tel:(855) 981-8300 ">(855) 981-8300</a></p>
        </div>
    </div>
</template>
<script>
  import{mapActions, mapGetters} from 'vuex'
  import config from '../config.js'

  export default {
    name: 'default', // id of the layout (required)
    data() {
            return {
                showMdDialog:false,
                showNavigation: false,
                mobile: false,
                tooSmall: false
            }
    },
    computed: {
        ...mapGetters({
            blur: 'blur',
            user: 'user'
        }),

        logoDestination(){
            const leadsRoute = { name: 'leads.index' }
            const premiumDashboardRoute = { name: 'dashboard.premium' }
            // console.log('THE USER', this.user, this.user.role.slug === "admissions");
            if(this.user && this.user.role && this.user.role.slug === "admissions"){
                return leadsRoute;
            }
            return premiumDashboardRoute

        }
    },
    created(){
        if( this.windowWidth <= 320 ) {
            this.tooSmall = true
        }else{
            this.tooSmall = false
        }

        if( this.windowWidth  <= 760 ) {
            this.mobile = true
        }else{
            this.mobile = false
        }
    },
    mounted(){
        this.$watch('windowWidth', (value, oldValue) => {
            if(value <= 760){
                this.mobile = true;
            }else{
                this.mobile = false;
            }
        })
    }
  }
</script>
<style scoped lang="scss">
   .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .logo{
        position: relative;
        margin-top: -10px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .align-right {
        text-align: right;
    }
    .align-left {
        text-align: left;
    }

    .default-container{
        max-width: 1600px;
        min-width: 1300px;
    }
    .blurItem{
        filter: blur(2px);
    }
    img{
   
        height: 32px;
    }
    img:hover{
        cursor: pointer;
    }
    .title{
        font-weight: 300;
        font-size: 22px;
        margin-left: -5px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        display: inline;
        vertical-align: sub;
        text-decoration: none;
        :hover{
            text-decoration: none;
        }
    }

    .sub-title{
        font-weight: 300;
        font-size: 13px;
        position: absolute;
        margin-left: 0px;
        left: 38px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        margin-top: -5px;
         letter-spacing: -0.5px;
    }

    .bold{
        font-weight: 400;
        font-family: 'HelveticaNeue';
        letter-spacing: -0.5px;
    }
    .md-transparent{
        background-color: #616161!important;
        padding-left: unset;
        margin-top: -2px;
        height: 66px;
        .image{
            padding-left: 20px;
            margin-top: -4px;
        }
    
    }
    a:hover{
        text-decoration: none;
    }

    .mobile{
       position:absolute; /*it can be fixed too*/
        left:0; right:0;
        top:0; bottom:0;
        margin:auto;
        text-align: center;

        /*this to solve "the content will not be cut when the window is smaller than the content": */
        max-width:100%;
        max-height:100%;
        overflow:auto;
        width: 300px;
        height: 600px;
        top:5%;
        h1{
            line-height: 1.2;
        }
    }


    .image-mobile {
        position: relative;
        top: 3px;
        width: 42px;
        padding-right: 10px;
    }

     @media only screen and (max-width: 760px),
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .default-container{
            max-width: 100%;
            min-width: 100%;
        }
  
  
       .md-button {
         position: relative;
         left: 80px;
         top: 3px;
         .md-icon{
           color: #fff !important;
         }
       }

    }

    .smaller-icon{
        font-size: 20px!important;
    }
</style>