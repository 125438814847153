<template>
    <div ref="maindiv" id="pricing-main">

        <div class="progress-bar-container">
            <md-progress-bar md-mode="determinate" :md-value="progressValue"></md-progress-bar>
        </div>

        <div id="waiting">
            <p>Compiling market data to ensure your best price.</p>
        </div>

        <div class="messages">


            <div class="md-layout md-gutter">
                <div class="md-layout-item google" :class="{'md-size-25': !mobile}">
                    <p>Google</p>
                    <div class="google-wrapper">
                        <md-progress-spinner v-if="second < 3" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner"></md-progress-spinner>
                        <img v-else class="google-logo" src="@/assets/google-g.png"/>
                    </div>

                </div>

                <div class="md-layout-item feature-content" v-if="!mobile">
                    <div class="feature">
                        <template>Search Engine Marketing</template>
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 2"></md-progress-spinner>
                        <md-icon v-else>check</md-icon>
                    </div>
                    <div class="feature">
                        Word Tracking
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 4"></md-progress-spinner>
                        <md-icon v-else>check</md-icon>
                    </div>
                </div>

                <div class="md-layout-item feature-content">
                    <div class="feature" v-if="mobile">
                        Word Tracking
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 4"></md-progress-spinner>
                        <md-icon v-else>check</md-icon>
                    </div>
                    <div class="feature">
                        Data for SEO
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 7"></md-progress-spinner>
                        <md-icon v-else>check</md-icon>
                    </div>
                    <div class="feature">
                        Search Metrics
                        <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 9"></md-progress-spinner>
                        <md-icon v-else>check</md-icon>
                    </div>
                </div>
            </div>

        </div>


        <md-dialog-actions class="next-div">
            <transition name="fade">
                <div class="next-caption" v-if="second > 0" style="transition-delay: 9.5s">Best price available:</div>
            </transition>

            <md-button
                    :disabled="progressValue !== 100"
                    class="md-primary md-raised next-btn"
                    @click="$emit('next')"
            >Continue</md-button>
        </md-dialog-actions>
    </div>
</template>

<script>
    export default {
        name: "PricingLoader",
        data(){
            return {
                title: "Comparing price",
                progressValue: 0,
                // progressValue: 100,
                second: 0,
                // second: 12,
                interval: null,
                mobile: false
            }
        },
        mounted(){
            this.interval = setInterval(() => {
                this.change();
            }, 100)

             if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        methods: {
            change(){
                this.second += .1;
                this.progressValue += (33 / 18 * .38) * 1.5;
                if(this.progressValue > 100){
                    this.progressValue = 100;
                    clearInterval(this.interval);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity .5s;
    }

    #pricing-main{
        .progress-bar-container{
            padding-top: 25px;
            width: 95%;
            margin-right: auto;
            margin-left: auto;
            .md-progress-bar{
                height: 11px;
                border-radius: 3px;
            }
        }
        #waiting {
            margin-left: 2.5%;
            padding-top: 9px;
            text-align: left;
            font-weight: 400;
             p{
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -0.3px;
                padding-bottom: 0px;
                margin-bottom: 6px;
            }
        }
        .messages{

            width: 80%;
            margin-right: auto;
            margin-left: auto;

            .feature-content{
                padding-top: 36px;
                .feature {
                    line-height: 35px;
                    text-align: left;
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.3px;
                }
            }

            .spinner {
                float:right;
                position: relative;
                top: 10px;
                margin-right: 10px;
            }
            .md-icon {
                margin-top:5px;
                color: #3AD612;
                float: right;
            }
        }

        .next-div{
            position: absolute;
            bottom: 39px;
            right: 13%;
            .next-caption{
                margin-right: 5px;
                color: #7D8385;
            }
           
        }
        .md-primary{
            margin-left: 6px;
        }
        .md-primary, .md-raised{
            padding-right: 20px;
            padding-left: 20px;
        }
        .md-button{
            height: 44px;
            width: 130px;
        }
        .md-progress-spinner {
            margin-right: 25px;
        }

        .md-icon {
            margin-right: 6px;
        }

        .google {
            p{
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -0.3px;
            }
            .google-wrapper{
                margin-right:auto;
                margin-left: auto;
                width: 60px;
                height: 60px;
                padding: 10px;
                background-color: #DFDFE0;
            }
        }

    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #pricing-main .title{
            display: none;
        }
        #pricing-main .messages .feature-content .feature{
                font-size: 16px;
                width: 72%;
        }
        #pricing-main .google{
           width: 50%;
           text-align: center;
           margin-right: -30px;
           .google-wrapper{
               width: 100px;
               height: 100px;
           }
        }
        #pricing-main .messages{
            width: 100%;
            min-height: 170px;
            .google-wrapper .spinner {
                float:right;
                position: relative;
                top: 28px;
                margin-right: 30px;
            }
        }
         #pricing-main .messages .feature-content{
             width: 50%;
             margin-top: 4px;
         }

        #pricing-main #waiting p{
            font-size: 18px;
            padding-left: 8px;
            padding-right: 8px;
        }

        #pricing-main .next-div{
            position: relative;
            bottom: unset;
            right: unset; 
            margin-left: auto;
            margin-right: auto;
            display: inline-flex;
            margin-top: 30px;
        }

        .md-dialog-actions{
            width: 100%;
            padding-right: 20px;
        }
        #pricing-main .progress-bar-container{
            padding-left: 10px;
            padding-right: 10px;
        }
        .progress-bar-container{
            width: 100%
        }
   }
</style>

<style lang="scss">
    .messages{
        .md-progress-spinner {
            &.md-indeterminate {
                &.md-progress-spinner-leave-active {
                    transition-duration: 0s;
                    animation: none;
                }
            }
        }
    }



</style>