import config from '../../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'

export default {
    state: () => ({
        generalPageInfo: {}
    }),

    ////////////// ACTIONS ///////////////
    actions: {

        /**
         * Gathers all of the data needed for the General admin page for a facility
         * @param context
         * @param facilityId
         * @returns {Promise<any>}
         */
        async loadFacilityGeneralPageInfo(context, facilityId) {
            await context.dispatch('fetchFacilityDataChildNames');
            const data = {
                pendingUpdates: false,
                facilityData: null,
                treatmentTypes: [],
                admissions: [],
                admissionsOptions: [],
                formData: {
                    selectedLevelOfCares: [],
                    selectedSpecialties: [],
                    detox: 'no',
                    facilityName: '',
                    bedsAvailable: null,
                    bedsFree: null,
                    accreditations: [],
                    naatp: "no",
                    legitScript: "no",
                    stateLicense: null
                },
                originalTreatmentTypes: [],
                originalAccreditations: [],
                originalSpecialties: []
            }

            context.state.generalPageInfo[facilityId] = data;

            // GRAB FACILITY INFO TO GET FACILITY NAME
            await context.dispatch('loadGeneralFacilityInfo', facilityId);
            // console.log('after loadGeneralFacilityInfo');

            // GRAB TREATMENT TYPES / LEVEL OF CARE FROM FACILITY INFO
            await context.dispatch('loadGeneralTreatmentTypes', facilityId)

            // GRAB ADMISSIONS TO FIND NUMBER OF BEDS AVAILABLE
            await context.dispatch('loadFacilityAdmissionsInfo', facilityId)

            // GET ACCREDITATION DATA
            await context.dispatch('loadFacilityAccreditationInfo', facilityId)

            // GET SPECIALTY DATA
            await context.dispatch('loadFacilitySpecialtyInfo', facilityId);

            // console.log('STUFF WE FETCHED', JSON.parse(JSON.stringify(context.state.generalPageInfo)))

            return  JSON.parse(JSON.stringify(context.state.generalPageInfo[facilityId]))
        },

        /**
         * Meant to be called from `loadFacilityGeneralPageInfo()` -- this function grabs facility info for the general page.
         * @returns {Promise<void>}
         */
        async loadGeneralFacilityInfo({state, dispatch}, facilityId) {
            if(!state.generalPageInfo[facilityId]){
                state.generalPageInfo[facilityId] = {}
            }

            const facilityData = await dispatch('fetchFacilityInformation', facilityId);
            state.generalPageInfo[facilityId].facilityData = facilityData;
            state.generalPageInfo[facilityId].formData.facilityName = facilityData.facility.name;

            if(facilityData.facility.pendingUpdates) {
                for (const update of facilityData.facility.pendingUpdates){
                    // console.log('HERE IS AN UPDATE', JSON.parse(JSON.stringify(update)));
                    if(update.changes && update.changes.newValue && update.changes.attribute === 'name'){
                        // console.log('PASSES IFS')
                        state.generalPageInfo[facilityId].formData.facilityName = update.changes.newValue;
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                }
            }
            // // console.log('ending loadGeneralFacilityInfo');
        },

        /**
         * Meant to be called from `loadFacilityGeneralPageInfo()` -- this function grabs treatment type/level of care info to be used in the General.vue page.
         */
        async loadGeneralTreatmentTypes({state, dispatch, getters}, facilityId){
            const facilityData = state.generalPageInfo[facilityId].facilityData;
            const levelOfCareOptions = getters.levelOfCareOptions;

            //GET CURRENTLY SELECTED TREATMENT TYPES
            if(facilityData && facilityData.facilityTreatmentTypes){
                state.generalPageInfo[facilityId].treatmentTypes = JSON.parse(JSON.stringify(facilityData.facilityTreatmentTypes.data))
            }

            // FIGURE OUT TREATMENT TYPES THAT HAVE ALREADY BEEN SELECTED
            for (const prevSelected of state.generalPageInfo[facilityId].treatmentTypes){
                if(prevSelected.sectionLabel === "detox"){
                    state.generalPageInfo[facilityId].formData.detox = "yes"
                }
                for(const option of levelOfCareOptions){
                    if(prevSelected.sectionLabel === option.sectionLabel){
                        state.generalPageInfo[facilityId].formData.selectedLevelOfCares.push(option.id);
                        state.generalPageInfo[facilityId].originalTreatmentTypes.push(prevSelected);
                    }
                }
            }

            // CHECK FOR PENDING UPDATES
            if(facilityData && facilityData.facilityTreatmentTypes && facilityData.facilityTreatmentTypes.pendingUpdates){
                for(const update of facilityData.facilityTreatmentTypes.pendingUpdates){
                    // DETOX SWITCHED TO FALSE
                    if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "detox" &&
                        update.changes.attributes.status === true &&
                        update.action === 'deleted'
                    ){
                        state.generalPageInfo[facilityId].formData.detox = 'no'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    // DETOX SWITCHED TO TRUE
                    else if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "detox" &&
                        update.changes.attributes.status === true
                    ){
                        state.generalPageInfo[facilityId].formData.detox = 'yes'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }

                    // INPATIENT, OUTPATIENT AWAITING CREATES;
                    if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        ["rehab_center_residential_treatment", "rehab_center_outpatient_services"].includes(update.changes.attributes.child_name.section_label) &&
                        update.changes.attributes.status === true &&
                        update.action !== 'deleted'
                    ){
                        const treatmentType = levelOfCareOptions.find(treatmentTypeOption => treatmentTypeOption.sectionLabel === update.changes.attributes.child_name.section_label)
                        state.generalPageInfo[facilityId].formData.selectedLevelOfCares.push(treatmentType.id);
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    // INPATIENT, OUTPATIENT AWAITING DELETES
                    else if(update.action === 'deleted'){
                        // // console.log('IN DELETED', JSON.parse(JSON.stringify(update)))
                        if(
                            update.changes
                            && update.changes.attributes
                            && update.changes.attributes.child_name
                            && ["rehab_center_residential_treatment", "rehab_center_outpatient_services"].includes(update.changes.attributes.child_name.section_label)
                        ){
                            // // console.log('TREATMENT TYPE OPTIONS', this.levelOfCareOptions);
                            const treatmentTypeOption = levelOfCareOptions.find(option => option.sectionLabel === update.changes.attributes.child_name.section_label)
                            // // console.log('TREATMENT TYPE OPTION', treatmentTypeOption);
                            // // console.log('FOUND A DELETE', JSON.parse(JSON.stringify(update)));
                            // // console.log('SELECTED LEVEL OF CARES', this.formData.selectedLevelOfCares)
                            state.generalPageInfo[facilityId].formData.selectedLevelOfCares = state.generalPageInfo[facilityId].formData.selectedLevelOfCares.filter(id => id != treatmentTypeOption.id)
                            state.generalPageInfo[facilityId].pendingUpdates = true;
                        }
                    }
                }
            }
        },

        /**
         * Meant to be called from `loadFacilityGeneralPageInfo()` -- this function grabs admissions info for Total Beds and Available beds for  General.vue page.
         */
        async loadFacilityAdmissionsInfo({state, dispatch, getters}, facilityId){
            const admissions = await dispatch('fetchFacilityAdmissions', facilityId)
            state.generalPageInfo[facilityId].admissions = admissions;

            const bedsAvailableObj = admissions.data.find(option => option.sectionLabel === "beds_available")
            const bedsFreeObj = admissions.data.find(option => option.sectionLabel === "beds_free")
            if(bedsAvailableObj)
                state.generalPageInfo[facilityId].formData.bedsAvailable = bedsAvailableObj.customContent
            if(bedsFreeObj)
                state.generalPageInfo[facilityId].formData.bedsFree = bedsFreeObj.customContent

            const admissionsOptions = getters.facilityDataChildNames(2)
            state.generalPageInfo[facilityId].admissionsOptions = admissionsOptions

            if(admissions.pendingUpdates){
                for(const update of admissions.pendingUpdates){
                    // HANDLE CREATES
                    if(update.action === 'created' &&
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "beds_available")
                    {
                        state.generalPageInfo[facilityId].formData.bedsAvailable = update.changes.attributes.custom_content
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    else if(update.action === 'created' &&
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "beds_free")
                    {
                        state.generalPageInfo[facilityId].formData.bedsFree = update.changes.attributes.custom_content
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    // HANDLE UPDATES
                    else if(
                        update.action === 'value_change' &&
                        update.object_id &&
                        bedsAvailableObj &&
                        update.object_id === bedsAvailableObj.id
                    ){
                        state.generalPageInfo[facilityId].formData.bedsAvailable = update.changes.newValue;
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    else if(
                        update.action === 'value_change' &&
                        update.object_id &&
                        bedsFreeObj &&
                        update.object_id === bedsFreeObj.id
                    ){
                        state.generalPageInfo[facilityId].formData.bedsFree = update.changes.newValue;
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                }
            }

        },

        /**
         * Meant to be called from `loadFacilityGeneralPageInfo()` -- this function grabs Accreditation info for the General.vue page.
         */
        async loadFacilityAccreditationInfo({state, dispatch, getters}, facilityId){
            const accreditationsOptions = getters.accreditationsOptions
            await dispatch('fetchFacilityAccreditations', facilityId);
            const originalAccreditations = JSON.parse(JSON.stringify(getters['facilityAccreditations'](facilityId)));
            // console.log('THE ORGINALS', originalAccreditations)
            state.generalPageInfo[facilityId].originalAccreditations = originalAccreditations;
            for(const cred of originalAccreditations.data){
                for(const option of accreditationsOptions){
                    if(cred.sectionLabel === option.sectionLabel && !cred.sectionLabel.includes('effective')){
                        state.generalPageInfo[facilityId].formData.accreditations.push(option.id)

                        if(cred.sectionLabel === 'treatment_legit_script'){
                            state.generalPageInfo[facilityId].formData.legitScript = 'yes';
                        } else if(cred.sectionLabel === "treatment_naatp"){
                            state.generalPageInfo[facilityId].formData.naatp = 'yes'
                        }else if(cred.sectionLabel === "treatment_adp"){
                            state.generalPageInfo[facilityId].formData.stateLicense = cred.customContent
                        }
                    }
                }
            }

            // DEAL WITH UPDATES
            if(originalAccreditations.pendingUpdates){
                const deleted = originalAccreditations.pendingUpdates.filter(update => update.action === 'deleted');
                const created = originalAccreditations.pendingUpdates.filter(update => update.action === 'created');
                const updated = originalAccreditations.pendingUpdates.filter(update => update.action === 'value_change');

                // HANDLE DELETES
                for(const update of deleted ){
                    // // console.log('DELETED ITEM', JSON.parse(JSON.stringify(update)));
                    if(update.changes.attributes.child_name.section_name === 'CARF'){
                        const removeCarf = accreditationsOptions.find(option => option.sectionLabel === 'treatment_carf');
                        state.generalPageInfo[facilityId].formData.accreditations = state.generalPageInfo[facilityId].formData.accreditations.filter(option => option != removeCarf.id)
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }else if(update.changes.attributes.child_name.section_name === "Joint Commission"){
                        const removeJC = accreditationsOptions.find(option => option.sectionLabel === 'treatment_jcaho');
                        state.generalPageInfo[facilityId].formData.accreditations = state.generalPageInfo[facilityId].formData.accreditations.filter(option => option != removeJC.id)
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                    else if(update.changes.attributes.child_name.section_name === "NAATP") {
                        state.generalPageInfo[facilityId].formData.naatp = 'no'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }else if(update.changes.attributes.child_name.section_name === "LegitScript"){
                        state.generalPageInfo[facilityId].formData.legitScript = 'no'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                }

                // HANDLE CREATES
                for(const update of created) {
                    // // console.log('CREATED ITEM', JSON.parse(JSON.stringify(update)));
                    if(update.changes.attributes.child_name.section_name === "LegitScript" && update.changes.attributes.status === true){
                        state.generalPageInfo[facilityId].formData.legitScript = 'yes'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    } else if(update.changes.attributes.child_name.section_name === "NAATP" && update.changes.attributes.status === true){
                        state.generalPageInfo[facilityId].formData.naatp = 'yes'
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    } else if(update.changes.attributes.child_name.section_name === "CARF" && update.changes.attributes.status === true){
                        const carfOption = accreditationsOptions.find(option => option.sectionLabel === 'treatment_carf');
                        state.generalPageInfo[facilityId].formData.accreditations.push(carfOption.id);
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    } else if(update.changes.attributes.child_name.section_name === "Joint Commission" && update.changes.attributes.status === true){
                        const jcOption = accreditationsOptions.find(option => option.sectionLabel === 'treatment_jcaho')
                        state.generalPageInfo[facilityId].formData.accreditations.push(jcOption.id)
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    } else if(update.changes.attributes.child_name.section_name === "State License" && update.changes.attributes.status === true){
                        state.generalPageInfo[facilityId].formData.stateLicense = update.changes.attributes.custom_content;
                        state.generalPageInfo[facilityId].pendingUpdates = true;
                    }
                }

                // HANDLE UPDATES - (Just 'State License' in this case)
                for(const update of updated){
                    // // console.log('UPDATED ITEM', JSON.parse(JSON.stringify(update)))
                    const objectId = update.object_id;
                    // // console.log('OBJECT ID', objectId);
                    if(objectId){
                        const stateLicense = originalAccreditations.data.find(item => item.id === objectId && item.sectionLabel === 'treatment_adp')
                        // // console.log(' STATE LICENSE', stateLicense)
                        if(stateLicense){
                            state.generalPageInfo[facilityId].formData.stateLicense = update.changes.newValue;
                            state.generalPageInfo[facilityId].pendingUpdates = true;
                        }
                    }
                }
            }
        },

        /**
         * Meant to be called from `loadFacilityGeneralPageInfo()` -- this function grabs Accreditation info for the General.vue page.
         */
        async loadFacilitySpecialtyInfo({state, dispatch, getters}, facilityId){
            const specialtyOptions = getters.ptSpecialtyOptions
            await dispatch('fetchFacilitySpecialties', facilityId);
            const originalSpecialties = JSON.parse(JSON.stringify(getters['facilitySpecialties'](facilityId)));

            state.generalPageInfo[facilityId].originalSpecialties = originalSpecialties;
            for(const cred of originalSpecialties.data){
                for(const option of specialtyOptions){
                    if(cred.sectionLabel === option.sectionLabel && !cred.sectionLabel.includes('effective')){
                        state.generalPageInfo[facilityId].formData.selectedSpecialties.push(option.id)
                    }
                }
            }

            // DEAL WITH UPDATES
            if(originalSpecialties.pendingUpdates){
                const deleted = originalSpecialties.pendingUpdates.filter(update => update.action === 'deleted');
                const created = originalSpecialties.pendingUpdates.filter(update => update.action === 'created');
                const updated = originalSpecialties.pendingUpdates.filter(update => update.action === 'value_change');

                // HANDLE DELETES
                for(const update of deleted ){
                    // console.log('DELETED ITEM', JSON.parse(JSON.stringify(update)));

                    const deleteSpecialty = specialtyOptions.find(option => option.sectionLabel === update.changes.attributes.child_name.section_label);
                    // console.log('deleteSpecialty', deleteSpecialty);
                    // console.log('SELECTED SPECIALTIES', JSON.parse(JSON.stringify(state.generalPageInfo[facilityId].formData.selectedSpecialties)))

                    state.generalPageInfo[facilityId].formData.selectedSpecialties = state.generalPageInfo[facilityId].formData.selectedSpecialties.filter(option => option != deleteSpecialty.id)
                    state.generalPageInfo[facilityId].pendingUpdates = true;
                }

                // HANDLE CREATES
                for(const update of created) {
                    // console.log('CREATED ITEM', JSON.parse(JSON.stringify(update)));
                    const createSpecialty = specialtyOptions.find(option => option.sectionLabel === update.changes.attributes.child_name.section_label);
                    // console.log('CREATE SPECIALTY', createSpecialty)
                    state.generalPageInfo[facilityId].formData.selectedSpecialties.push(createSpecialty.id);
                }

            }
        },


        async bulkUpdateGeneralInfo({dispatch}, {facilityIds, formData, submitFields}){
            const promises = [];
            for(const facilityId of facilityIds){
                promises.push(dispatch('updateFacilityGeneralInfo', {
                    facilityId,
                    formData,
                    bulk: true,
                    submitFields
                }))
            }
            await Promise.all(promises)
        },


        /**
         *
         */
        async updateFacilityGeneralInfo({state, dispatch}, {facilityId, formData, bulk, submitFields}) {
            const promises = []
            //GET AN UPDATED COPY OF ALL THE DATA FROM SYMFONY BEFORE SUBMITTING
            await dispatch('loadFacilityGeneralPageInfo', facilityId)

            // UPDATE ALL THE THINGS
            if(!bulk){
                promises.push(dispatch('updateFacilityName', {facilityId, formData}))
            }
            // console.log('SUBMIT FIELDS', JSON.parse(JSON.stringify(submitFields)));
            promises.push(dispatch('updateFacilityTreatmentTypeInfo', {facilityId, formData, submitFields}))
            if(submitFields.totalBeds){
                promises.push(dispatch('updateFacilityTotalBeds', {facilityId, formData, submitFields}))
            }
            if(submitFields.availableBeds){
                promises.push(dispatch('updateFacilityNumBedsFree', {facilityId, formData, submitFields}))
            }

            promises.push(dispatch('updateFacilityAccreditations', {facilityId, formData, submitFields}));
            
            promises.push(dispatch('updateFacilitySpecialtyInfo', {facilityId, formData, submitFields}))

            await Promise.all(promises);
        },

        async updateFacilityName({state, dispatch}, {facilityId, formData}) {
            const facilityData = state.generalPageInfo[facilityId].facilityData;

            // console.log('facility data', facilityData);

            // DELETE PREVIOUS PENDING UPDATES
            if(facilityData.facility.pendingUpdates){
                const facilityNameUpdate = facilityData.facility.pendingUpdates.find(update => update.changes && update.changes.attribute && update.changes.attribute === 'name');

                if(facilityNameUpdate){
                    await dispatch('deleteContentUpdate', facilityNameUpdate.id);
                }
            }

            // SUBMIT UPDATE FOR NEW FACILITY NAME
            if(formData.facilityName !== facilityData.facility.name){
                const data = {name: formData.facilityName}

                await dispatch('updateFacilityInformation', {
                    facilityId,
                    type: 'information',
                    data
                })
            }
        },

        async updateFacilityTreatmentTypeInfo({state, dispatch, getters}, {facilityId, formData, submitFields}) {
            const data = state.generalPageInfo[facilityId];
            const levelOfCareOptions = getters.levelOfCareOptions;
            // console.log('ALL THE DATA', JSON.parse(JSON.stringify(data)));

            // UPDATE DETOX INFO
            const origDetox = data.treatmentTypes.find(type => type.sectionLabel === "detox");
            if(submitFields.detox) {
                if(origDetox && formData.detox === 'no'){ // DETOX SWITCHED FROM 'YES' TO 'NO', NEED TO DELETE
                    await dispatch('deleteAdminItem', {
                        facilityId,
                        type: 'treatment-type',
                        childId: origDetox.id
                    });
                } else if(formData.detox === 'yes' && !origDetox) { // DETOX SWITCHED FROM 'NO' TO 'YES', NEED TO CREATE
                    const created = await dispatch('createAdminItem', {
                        facilityId,
                        type: 'treatment-type',
                        childId: getters.facilityDataChildNames(16).filter(item => item.sectionLabel === "detox")[0].id,
                        content: ''
                    });
                }
            }


            // LOOP THROUGH CURRENTLY SELECTED "LEVEL OF CARES" TO FIND NEW ONES
            let treatmentTypeUpdated = false;
            if(submitFields.levelOfCare){
                for(const treatmentTypeId of formData.selectedLevelOfCares){
                    const treatmentTypeOption = levelOfCareOptions.find(option => option.id === treatmentTypeId);
                    const origTreatmentType = data.originalTreatmentTypes.find(type => type.sectionLabel === treatmentTypeOption.sectionLabel);

                    if(!origTreatmentType){
                        treatmentTypeUpdated = true;
                        await dispatch('createAdminItem', {
                            facilityId,
                            type: 'levelofcare',
                            childId: treatmentTypeOption.id,
                            content: ''
                        })
                    }
                }
            }

            // // FIGURE OUT OLD SELECTED "LEVEL OF CARES"
            const oldSelections = [];
            for(const prevSelected of data.originalTreatmentTypes){
                for(const option of levelOfCareOptions){
                    if(prevSelected.sectionLabel === option.sectionLabel){
                        oldSelections.push({
                            optionId: option.id,
                            treatmentTypeOptionId: prevSelected.id
                        })
                    }
                }
            }

            // DELETE THE ONES THAT ARE NO LONGER SELECTED
            if(submitFields.levelOfCare){
                for(const oldSelection of oldSelections){
                    const stillSelected = formData.selectedLevelOfCares.find(id => id === oldSelection.optionId)
                    if(!stillSelected){
                        // console.log('DELETING THIS ONE', JSON.parse(JSON.stringify(oldSelection)));
                        treatmentTypeUpdated = true;
                        const deleteParams = {
                            facilityId,
                            type: 'levelofcare',
                            childId: oldSelection.treatmentTypeOptionId
                        }
                        // console.log('DELETE PARAMS', JSON.parse(JSON.stringify(deleteParams)));
                        await dispatch('deleteAdminItem', deleteParams);
                    }
                }
            }


            // CHECK IF PENDING UPDATE DELETES NEED TO BE DONE
            const facilityData = data.facilityData
            if (
                facilityData &&
                facilityData.facilityTreatmentTypes &&
                facilityData.facilityTreatmentTypes.pendingUpdates
            ) {
                // DELETE DETOX PENDING UPDATES
                if(submitFields.detox){
                    if (origDetox && formData.detox === 'yes' || !origDetox && formData.detox === 'no') {
                        const pendingUpdate = facilityData.facilityTreatmentTypes.pendingUpdates.find(update => update.changes && update.changes.attributes && update.changes.attributes.child_name.section_label === 'detox')
                        if (pendingUpdate) {
                            await dispatch('deleteContentUpdate', pendingUpdate.id);
                        }
                    }
                }


                // DELETE TREATMENT TYPE PENDING UPDATES
                // console.log('all the updates', JSON.parse(JSON.stringify(this.facilityData.facilityTreatmentTypes.pendingUpdates)));
                const pendingUpdates = facilityData.facilityTreatmentTypes.pendingUpdates.filter(update =>
                    update.changes && update.changes.attributes &&
                    update.changes.attributes.child_name &&
                    ["rehab_center_residential_treatment", "rehab_center_outpatient_services"].includes(update.changes.attributes.child_name.section_label)
                )
                // console.log('PENDING UPDATES ARE', pendingUpdates);

                if(submitFields.levelOfCare){
                    if (pendingUpdates && pendingUpdates.length) {
                        for (const update of pendingUpdates) {
                            // console.log('DELETING PENDING UPDATES', JSON.parse(JSON.stringify(update)));
                            await dispatch('deleteContentUpdate', update.id);
                        }
                    }
                }

            }
        },

        async updateFacilitySpecialtyInfo({state, dispatch, getters}, {facilityId, formData, submitFields}) {
            const data = state.generalPageInfo[facilityId];
            const specialtyOptions = getters.ptSpecialtyOptions

            // console.log('SUBMIT FORM', JSON.parse(JSON.stringify(formData)));
            // console.log('THE DATA', JSON.parse(JSON.stringify(data)));

            // LOOP THROUGH CURRENTLY SELECTED "LEVEL OF CARES" TO FIND NEW ONES
            let specialtyUpdated = false;
            if(submitFields.specialties){
                for(const specialtyId of formData.selectedSpecialties){
                    const specialty = specialtyOptions.find(option => option.id === specialtyId);
                    const origSpecialty = data.originalSpecialties.data.find(type => type.sectionLabel === specialty.sectionLabel);

                    // console.log('FOUND ORIGINAL, NO NEED TO CREATE', origSpecialty)

                    if(!origSpecialty){
                        // console.log('creating specialty', facilityId, 'specialties', specialty.id, '')
                        specialtyUpdated = true;
                        await dispatch('createAdminItem', {
                            facilityId,
                            type: 'specialties',
                            childId: specialty.id,
                            content: ''
                        })
                    }
                }
            }
            // console.log('through loop 1')

            // // FIGURE OUT OLD SELECTED "LEVEL OF CARES"
            const oldSelections = [];
            for(const prevSelected of data.originalSpecialties.data){
                for(const option of specialtyOptions){
                    if(prevSelected.sectionLabel === option.sectionLabel){
                        oldSelections.push({
                            optionId: option.id,
                            specialtyId: prevSelected.id
                        })
                    }
                }
            }

            // console.log('OLD SPECIALTY SELECTIONS', JSON.parse(JSON.stringify(oldSelections)));


            // DELETE THE ONES THAT ARE NO LONGER SELECTED
            if(submitFields.specialties){
                for(const oldSelection of oldSelections){
                    const stillSelected = formData.selectedSpecialties.find(id => id === oldSelection.optionId)
                    if(!stillSelected){
                        // console.log('DELETING THIS ONE', JSON.parse(JSON.stringify(oldSelection)));
                        specialtyUpdated = true;
                        const deleteParams = {
                            facilityId,
                            type: 'specialties',
                            childId: oldSelection.specialtyId
                        }
                        // console.log('DELETE PARAMS', JSON.parse(JSON.stringify(deleteParams)));
                        await dispatch('deleteAdminItem', deleteParams);
                    }
                }
            }

            // CHECK IF PENDING UPDATE DELETES NEED TO BE DONE
            if (
                data.originalSpecialties &&
                data.originalSpecialties.pendingUpdates &&
                submitFields.specialties
            ) {
                // console.log('TIME TO DELETE PENDING UPDATES')
                // DELETE SPECIALTY PENDING UPDATES
                const pendingUpdatesToDelete = data.originalSpecialties.pendingUpdates
                // console.log('PENDING UPDATES ARE', pendingUpdatesToDelete);
                if (pendingUpdatesToDelete && pendingUpdatesToDelete.length) {
                    for (const update of pendingUpdatesToDelete) {
                        // console.log('DELETING PENDING UPDATES', JSON.parse(JSON.stringify(update)));
                        await dispatch('deleteContentUpdate', update.id);
                    }
                }
            }
        },

        async updateFacilityTotalBeds({state, dispatch}, {facilityId, formData}){
            const data = state.generalPageInfo[facilityId];
            // console.log('ALL THE DATA', JSON.parse(JSON.stringify(data)))

            const origBedsAvailableObj = data.admissions.data.find(option => option.sectionLabel === "beds_available")
            let bedsChanged = false;
            if(origBedsAvailableObj){ // UPDATE PREVIOUS VALUE
                if(origBedsAvailableObj.customContent !== formData.bedsAvailable){
                    // save updated beds value
                    await dispatch('updateFacilityAdmission', {
                        facilityId,
                        childId: origBedsAvailableObj.id,
                        content: formData.bedsAvailable
                    })
                    bedsChanged = true;
                }
            }else { // CREATE NEW BEDS VALUE
                if(formData.bedsAvailable){
                    const facilityDataChildNameObj = data.admissionsOptions.find(option => option.sectionLabel === "beds_available")
                    await dispatch('createAdminItem', {
                        facilityId,
                            type: 'admissions',
                            childId: facilityDataChildNameObj.id,
                            content: formData.bedsAvailable
                    })
                    await dispatch('createAdminItem', {
                        facilityId,
                        type: 'admissions',
                        childId: facilityDataChildNameObj.id,
                        content: formData.bedsAvailable
                    })

                    bedsChanged = true;
                }
            }

            // DELETE PENDING BED UPDATES
            if(data.admissions.pendingUpdates){
                const bedsAvailableObj = data.admissions.data.find(option => option.sectionLabel === "beds_available")
                const bedUpdates = data.admissions.pendingUpdates.filter(update =>
                    (update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "beds_available") ||
                    (update.action === 'value_change' &&
                        update.object_id &&
                        bedsAvailableObj &&
                        update.object_id === bedsAvailableObj.id)
                );
                // console.log('PENDING BED UPDATE')
                if(bedUpdates && bedUpdates.length) {
                    for(const update of bedUpdates){
                        // console.log('DELETING', JSON.parse(JSON.stringify(update)));
                        await dispatch('deleteContentUpdate', update.id);
                    }

                }
            }

        },

        async updateFacilityNumBedsFree({state, dispatch}, {facilityId, formData}){
            const data = state.generalPageInfo[facilityId];

            const origBedsFreeObj = data.admissions.data.find(option => option.sectionLabel === 'beds_free');

            if(origBedsFreeObj){ // UPDATE PREVIOUS VALUE
                if(origBedsFreeObj.customContent !== formData.bedsFree){
                    // save updated beds value
                    await dispatch('updateFacilityAdmission', {
                        facilityId,
                        childId: origBedsFreeObj.id,
                        content: formData.bedsFree
                    })
                }
            }else { // CREATE NEW BEDS VALUE
                if(formData.bedsFree){
                    const facilityDataChildNameObj = data.admissionsOptions.find(option => option.sectionLabel === "beds_free")

                    await dispatch('createAdminItem', {
                        facilityId,
                        type: 'admissions',
                        childId: facilityDataChildNameObj.id,
                        content: formData.bedsFree
                    })
                }
            }

            // DELETE PENDING BED FREE UPDATES
            if(data.admissions.pendingUpdates){
                // console.log('ADMISSIONS UPDATES')
                const bedsFreeObj = data.admissions.data.find(option => option.sectionLabel === "beds_free")
                const bedUpdates = data.admissions.pendingUpdates.filter(update =>
                    (update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label === "beds_free") ||
                    (update.action === 'value_change' &&
                        update.object_id &&
                        bedsFreeObj &&
                        update.object_id === bedsFreeObj.id)
                );

                if(bedUpdates && bedUpdates.length) {
                    for(const update of bedUpdates){
                        // console.log('DELETING', JSON.parse(JSON.stringify(update)));
                        await dispatch('deleteContentUpdate', update.id);
                    }

                }
            }

        },

        async updateFacilityAccreditations({state, dispatch, getters}, {facilityId, formData, submitFields}){
            const data = state.generalPageInfo[facilityId];
            const accreditationsOptions = getters.accreditationsOptions
            
             console.log('ACCREDITATION OPTIONS', JSON.parse(JSON.stringify(accreditationsOptions)));


            console.log('CURRENT ACCREDITATION OPTIONS',formData.accreditations) 
            // loop through current selected treatment focuses to find new ones
            for(var accreditationId of formData.accreditations){

                const isValid = Number.isInteger(accreditationId);

                if(isValid){
                //    console.log('accreditationId', accreditationId)
                }else{
                    accreditationId = accreditationId[0];
                 //   console.log('accreditationId', accreditationId)
                }

                const accreditationOption = accreditationsOptions.find(option => option.id === accreditationId);
                
                
                // skip if option not selected for update.
                if(
                    (accreditationOption.sectionLabel === "treatment_carf" || accreditationOption.sectionLabel === 'treatment_jcaho')
                    && !submitFields.accreditations)
                    continue;
                else if(accreditationOption.sectionLabel === "treatment_naatp" && !submitFields.naatp)
                    continue
                else if(accreditationOption.sectionLabel === "treatment_legit_script" && !submitFields.legitScript)
                    continue
                else if(accreditationOption.sectionLabel === "treatment_adp" && !submitFields.stateLicense)
                    continue

                const origAccreditation = data.originalAccreditations.data.find(cred => cred.sectionLabel === accreditationOption.sectionLabel)

                if(!origAccreditation){
                    await dispatch('createAdminItem', {
                        facilityId,
                        type: 'accreditation',
                        childId: accreditationId,
                        content: ''
                    })
                }
            }

            // FIGURE OUT OLD SELECTED ACCREDITATIONS
            const oldSelections = [];
            for(const prevSelected of data.originalAccreditations.data){
                for(const option of accreditationsOptions){
                    if(prevSelected.sectionLabel === option.sectionLabel){

                        // skip if option not selected for update
                        if(
                            (prevSelected.sectionLabel === "treatment_carf" || prevSelected.sectionLabel === 'treatment_jcaho')
                            && !submitFields.accreditations)
                            continue;
                        else if(prevSelected.sectionLabel === "treatment_naatp" && !submitFields.naatp)
                            continue
                        else if(prevSelected.sectionLabel === "treatment_legit_script" && !submitFields.legitScript)
                            continue
                        else if(prevSelected.sectionLabel === "treatment_adp" && !submitFields.stateLicense)
                            continue

                        oldSelections.push({
                            optionId: option.id,
                            accreditationId: prevSelected.id
                        })
                    }
                }
            }

            // DELETE THE ONES THAT ARE NO LONGER SELECTED
            for(const oldSelection of oldSelections){
                const stillSelected = formData.accreditations.find(id => id === oldSelection.optionId)
                if(!stillSelected){
                    await dispatch('deleteAdminItem', {
                        facilityId,
                        type: 'accreditation',
                        childId: oldSelection.accreditationId
                    })
                }
            }

            // FIGURE OUT STATE LICENSE
            if(formData.stateLicense && submitFields.stateLicense){
                // console.log('STATE LICENSE', this.formData.stateLicense)
                const previousLicense = data.originalAccreditations.data.find(cred => cred.sectionLabel === "treatment_adp")
                const licenseOption = accreditationsOptions.find(option => option.sectionLabel === "treatment_adp");
                if(previousLicense) { // UPDATE ADMIN ITEM
                    // console.log('PREV LICENSE', this.formData.stateLicense, JSON.parse(JSON.stringify(previousLicense)))
                    if(previousLicense.customContent !== formData.stateLicense){
                        await dispatch('updateAdminItem', {
                            facilityId,
                            childId: previousLicense.id,
                            content: formData.stateLicense,
                            type: 'accreditation'
                        })
                    }

                }

                else{ // CREATE NEW ADMIN ITEM
                    await dispatch('createAdminItem', {
                        facilityId,
                        type: 'accreditation',
                        childId: licenseOption.id,
                        content: formData.stateLicense
                    })
                }
            }

            if(data.originalAccreditations.pendingUpdates){
                for(const update of data.originalAccreditations.pendingUpdates){
                    console.log('DELETING ACCRED PENDING UPDATE',  JSON.parse(JSON.stringify(update)));

                    if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label &&
                        (update.changes.attributes.child_name.section_label === "treatment_carf" || update.changes.attributes.child_name.section_label === 'treatment_jcaho')
                        && !submitFields.accreditations)
                        continue;
                    else if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label &&
                        update.changes.attributes.child_name.section_label === "treatment_naatp" &&
                        !submitFields.naatp
                    )
                        continue
                    else if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label &&
                        update.changes.attributes.child_name.section_label === "treatment_legit_script" &&
                        !submitFields.legitScript
                    )
                        continue
                    else if(
                        update.changes &&
                        update.changes.attributes &&
                        update.changes.attributes.child_name &&
                        update.changes.attributes.child_name.section_label &&
                        update.changes.attributes.child_name.section_label === "treatment_adp" &&
                        !submitFields.stateLicense)
                        continue


                    await dispatch('deleteContentUpdate', update.id);
                }
            }
        }

    },

    ///////////// MUTATIONS /////////////
    mutations: {

    },

    ///////////// GETTERS ///////////////
    getters: {
        generalPageInfo: state => facilityId => {
            return state.generalPageInfo[facilityId]
        }
    }
}