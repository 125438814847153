import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {format} from "date-fns";

export default {
  state: () => ({
    campaigns: []
  }),
  actions: {
    async updateCampaignStatus(context, {campaign, status, data}){
      const url = `${apiUrl}/campaigns/${campaign.id}`
      const result = await axios.patch(url, {
        data: {
          attributes: {
            status,
            ...!campaign.startDate && {startDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')},
            data
          }
        }
      })

      context.commit('setCampaignStatus', {
        campaignId: campaign.id,
        status,
        ...!campaign.startDate && {startDate: format(new Date(), 'YYYY-MM-DD HH:mm:ss')}
      })
      return result.data
    },
    async updateCampaignBudget(context, {campaignId, budget}){
      const url = `${apiUrl}/campaigns/${campaignId}`
      const result = await axios.patch(url, {
        data:{
          attributes: {
            budget
          }
        }
      })

      context.commit('setCampaignBudget', {campaignId, budget})

      return result.data;
    },
    async updateCampaign(context, {campaign, budget, status, data}){
      const url = `${apiUrl}/campaigns/${campaign.id}`

      const result = await axios.patch(url, {
        data: {
          attributes: {
            status,
            budget,
            data
          }
        }
      })

      context.commit('updateCampaign', {
        campaignId: campaign.id,
        status,
        budget,
        startDate: result.data.data.attributes.startDate
      })

      return result.data
    },
    async fetchCampaigns(context){
      try{
        const user = context.getters.user;
        var url = `${apiUrl}/campaigns?filter[accountId]=${user.accountId}`

        const result = await axios.get(url);
        context.commit('changeCampaigns', result.data.data)
      }catch (e){
        console.error('ERROR LOADING CAMPAIGNS: ', e)
        context.commit('changeCampaigns', [])
      }
    },
  },
  mutations: {
    changeCampaigns(state, campaigns){
      state.campaigns = campaigns
    },
    setCampaignStatus(state, {campaignId, status, startDate}){
      const found = state.campaigns.find(campaign => campaign.id === campaignId)
      if(found){
        found.attributes.status = status;
        if(startDate)
          found.attributes.startDate = startDate
      }
    },
    setCampaignBudget(state, {campaignId, budget}){
      const campaign = state.campaigns.find(campaign => campaign.id === campaignId)
      if(campaign){
        campaign.attributes.budget = budget
      }
    },
    updateCampaign(state, {campaignId, status, startDate, budget}){
      const campaign = state.campaigns.find(campaign => campaign.id === campaignId)
      if(campaign){
        campaign.attributes.budget = budget
        campaign.attributes.status = status
        if(startDate)
          campaign.attributes.startDate = startDate
      }
    },
  },
  getters: {
    campaigns: state => state.campaigns,
    enabledCampaigns: state => state.campaigns.filter(campaign => campaign.attributes.status),
    campaignsWithStartDates: state => state.campaigns.filter(campaign => campaign.attributes.startDate !== null), // Meant to check if a campaign is active, or has been active
    checkCampaignsIsPremium: (state, getters) => {
      // console.log('CAMPAIGNS', JSON.parse(JSON.stringify(state.campaigns)));
      const campaignStatus = state.campaigns.find(campaign => campaign.attributes.status === true);
      const campaignBudget = state.campaigns.find(campaign => campaign.attributes.budget > 0);
      const defaultPaymentMethod = getters.defaultPaymentMethod;

       // console.log('campaign status', campaignStatus);
       // console.log('campaignBudget', campaignBudget);
       // console.log('defaultPaymentMethod', defaultPaymentMethod);

      if(defaultPaymentMethod && campaignBudget){
        // console.log('FALSE');
        return false;
      }else{
        // console.log('TRUE');
        return true;
      }
    },
    checkCampaignsIsPremiumClicks: (state, getters) => {
      // console.log('CAMPAIGNS', JSON.parse(JSON.stringify(state.campaigns)));
      const campaignBudget = state.campaigns.find(campaign => campaign.attributes.budget > 0 && campaign.attributes.productId == getters.clickProduct.id);
      const defaultPaymentMethod = getters.defaultPaymentMethod;

       // console.log('campaign status', campaignStatus);
       // console.log('click campaignBudget', campaignBudget);
       // console.log('defaultPaymentMethod', defaultPaymentMethod);

      if(defaultPaymentMethod && campaignBudget){
        // console.log('FALSE');
        return false;
      }else{
        // console.log('TRUE');
        return true;
      }
    },
    checkCampaignsIsPremiumCalls: (state, getters) => {
      // console.log('CAMPAIGNS', JSON.parse(JSON.stringify(state.campaigns)));
      // console.log('CALL PRODUCT', JSON.parse(JSON.stringify(getters.callProduct)))
      const campaignBudget = state.campaigns.find(campaign => campaign.attributes.budget > 0  && parseInt(campaign.attributes.productId) === parseInt(getters.callProduct.id));
      const defaultPaymentMethod = getters.defaultPaymentMethod;

       // console.log('campaign status', campaignStatus);
       // console.log('call campaignBudget', campaignBudget);
       // console.log('defaultPaymentMethod', defaultPaymentMethod);

      if(defaultPaymentMethod && campaignBudget){
        // console.log('FALSE');
        return false;
      }else{
        // console.log('TRUE');
        return true;
      }
    }
  }
}