<template>
    <div>Logging Out...</div>
</template>
<script>
    import {mapActions} from 'vuex'

    export default {
        async created(){
            await this.resetAllState()
            this.$router.push({ name: 'auth.login' })
        },
        methods: {
            ...mapActions(["resetAllState"]),
        }
}
</script>
