<template>
    <div class="container">
            <div class="md-layout">
                <div class="close" @click="close()" v-if="mobile"><md-icon>close</md-icon></div>
                <div class="md-layout-item md-size-45 left-side" v-if="!mobile">

                </div>
                <div class="md-layout-item right-side" :class="{ 'md-size-50': !mobile }">
                    <h2>Ads Made Easy</h2>
                    <h1>Premium Listings</h1>
                    <p class="description">
                        We are here to help you grow. Everything you need to get more new patient connections.
                    </p>


                    <div class="action-buttons-mobile" v-if="mobile">
                        <md-dialog-actions>
                            <md-button
                                    class="md-primary md-raised wide"
                                    @click="$emit('next')"
                                    
                            >See plans and pricing</md-button>
                        </md-dialog-actions>
                    </div>

                    <div class="pricing-and-buttons">
                        <div class="pricing">
                            <p>Premium starting at</p>
                            <div class="price">
                                <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                    <sup>$</sup><span>{{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides | usd}}</span>
                                </template>
                                <template v-else><sup>$</sup><span>{{ subscriptioncosts.overrides | usd}}</span>
                                </template>

                            </div>
                            <div class="price-right">
                                <div class="prev-price"><sup>$</sup><span class="line-blue"><span v-if="facilitySubscriptionCost(goPremiumFunnelInfo.funnelFacility)">{{ getPrevPrice() }}</span></span></div>
                                <div class="per-month">/month</div>
                                <div class="per-month limitedoffer">Limited time offer.*</div>
                            </div>
                            <!--                    <div class="per-facility">PER FACILITY</div>-->
                        </div>

                        <div class="action-buttons" v-if="!mobile">
                            <md-dialog-actions>
<!--                                <md-button-->
<!--                                        class="md-raised"-->
<!--                                        @click="$emit('requestDemo')"-->
<!--                                >Schedule a Demo</md-button>-->

                                <md-button
                                        class="md-primary md-raised wide"
                                        @click="$emit('next')"
                                       
                                >See plans and pricing</md-button>

                            </md-dialog-actions>
                        </div>

                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "Subscription",
        data(){
            return {
                funnelData: null,
                mobile: false
            }
        },
        computed: {
            ...mapGetters([
                'facilitySubscriptionCost',
                'totalSubscriptionCost',
                'facilities',
                'goPremiumFunnelInfo',
                'calculateFacilityBudget',
                'calculateBudget',
                'getFacilityType',
                'subscriptioncosts',
                'subscriptionCostsOverridesByFacility'
            ]),

            fiveBudget(){
                return this.calculateBudget({numInteractions: 5})
            },

            facilityType() {
              return this.getFacilityType(this.funnelData.funnelFacility)
            }
        },
        async created() {

            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }  
            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
            // console.log('STEP 1 MODAL', this.funnelData.funnelFacility)
            await this.$store.dispatch('fetchInteractionCostsLookup', {facilityIds: this.funnelData.funnelFacility, refresh: 1})

            this.funnelData.facilityPricing = this.fiveBudget.facilityBudgets.find(facilityBudget => parseInt(facilityBudget.facilityId) === parseInt(this.funnelData.funnelFacility))
            this.$store.dispatch('updateGoPremiumFunnelInfo', this.funnelData)
        },
        async mounted(){

            await this.fetchSubscriptionCosts();

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        },
        methods: {
             ...mapActions([
                'fetchSubscriptionCosts'
            ]),
            close(){
                this.$parent.$parent.showDialogPremium = false
            },
            getPrevPrice() {
                return this.subscriptioncosts.defaults
            }
        },
        filters: {
            usd(value) {
                let formatter = new Intl.NumberFormat('en-US', {
                    // style: 'currency',
                    // currency: 'USD',
                    // minimumFractionDigits: 0
                    maximumFractionDigits: 0
                })
                if(value % 1 === 0){
                    formatter = new Intl.NumberFormat('en-US', {
                        // style: 'currency',
                        // currency: 'USD',
                        maximumFractionDigits: 0
                    })
                }
                return formatter.format(value)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .container{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        text-align: left;
    }
   
    .right-side{
        margin-left: 25px;
        h2 {
            margin-top: 100px;
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 0;
        }

        h1{
            margin-top: 18px;
            font-size: 42px;
            font-weight: 400;
            display: block;
            padding-top: 0;
            line-height: 32px;
        }

        .description {
            font-size: 16px;
            font-weight: 300;
            line-height: 25px;
            max-width: 365px;
        }

        .pricing-and-buttons {
            margin-top: 57px;
            text-align: left;
            margin-left: 0;
            margin-right: -2px;
            p {
                color: #000000;
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 0px;
            }

            .pricing{
                text-align: right;
                display: inline-block;
                .price {
                    position: relative;
                    top: -5px;
                    text-align: left;
                    display: inline-block;
                    color: #000000;
                    font-size: 36px;
                    font-weight: 300;
                    height: 70px;
                    letter-spacing: -1px;
                    font-family: "Lato";

                    sup {
                        font-size: 20px;
                    }
                }
                .price-right {
                    position: relative;
                    top: -5px;
                    display: inline-block;
                    height: 70px;

                    .prev-price {
                        position: relative;
                        top: 3px;
                        left: 0;
                        font-size: 16px;
                        color: #000000;

                        sup {
                            font-size: 10px;
                            color: #000000;
                        }
                        .line-blue {
                            color: #000000;
                            text-decoration: line-through;
                            span{
                                color: #000;
                            }


                        }

                    }
                    .per-month {
                        font-size: 12px;
                        color: #000000;
                        font-weight: 300
                    }
                    .limitedoffer{
                        position: absolute;
                        width: 111px;
                        left: -70px;
                        padding-top: 11px;
                        font-size: 10px;
                    }
                }
            }


            /*.per-facility {*/
            /*    font-weight: bold;*/
            /*    position: relative;*/
            /*    top: -16px */
            /*letter-spacing: 2px;*/
            /*font-size: 10px;*/
            /*}*/

            .action-buttons{
                text-align: left;
                display: inline-block;
                margin-left: 25px;
                .md-dialog-actions {
                    margin-top: 33px;
                    padding: 0;

                    .md-button {
                        position: relative;
                        top: -25px;
                        float: left;
                        height: 60px;

                        box-shadow: none;
                        color: #0078D3;
                        border: 1px solid #0078D3;
                        text-transform: none;
                        font-size: 18px;
                        font-weight: 400;

                        .md-ripple {
                            /*margin-left: 25px;*/
                            /*margin-right: 25px;*/
                        }
                    }

                    .wide{
                        width: 240px;
                    }

                    .md-primary {
                        color: #fff;
                        border: none;
                        background-color: #0078D3;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .container{
            position: absolute;
        }
         .close{
            position: fixed;
            top: 10px;
            right: 8px;
        }
        .right-side{
            margin-top: 25%;
        }
        .right-side h2{
            margin-top: 0px;
            font-size: 21px;
        }

         .right-side h1{
            font-size: 34px;
        }

        .right-side .description{
            font-size: 17px;
            padding-top: 4px;
        }
        .right-side .pricing-and-buttons{
            margin-top: 30px;
        }
        .right-side .pricing-and-buttons .pricing{
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            text-align: center;
        }
        .md-layout-item.right-side{
            margin-right: 25px;
        }

        .right-side .pricing-and-buttons .pricing .price-right .limitedoffer{
           left: -50%;
            margin-left: -38px;
        }

        .right-side .pricing-and-buttons .action-buttons{
            width: 100%;
            margin: 0;
            margin-top: 20px;

            .md-dialog-actions .wide{
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }
            
        }
        .action-buttons-mobile .md-button{
            width: 100%;
            height: 56px;
            font-size: 17px;
            margin-top: 32px;
        }
        .limitedoffer{
            margin-top: 0px;
        }
    }
</style>