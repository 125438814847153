<template>
    <div>
        <contact-form
            v-if="!loading"
            :loading="loading"
            :original-information="information"
            @submit="attemptSubmit($event)"
        ></contact-form>

        <md-snackbar
            md-position="center"
            :md-duration="Infinity"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import ContactForm from "./Forms/ContactForm";
    import { validationMixin } from 'vuelidate'
    import {
        required,
        minLength,
        maxLength,
        requiredIf,
        url
    } from 'vuelidate/lib/validators'
    import {mapActions, mapGetters} from 'vuex'
    import customValidationMixin from '../../mixins/validationMixin'
    import Multiselect from 'vue-multiselect'

    export default{
        components: {ContactForm},
        mixins: [validationMixin, customValidationMixin],
        name: 'Contact',
        data(){
            return {
                formChanged: false,
                config: config,
                data: null,
                information: {
                    externalId: null,
                    name: null,
                    route: null,
                    addressLineFirst: null,
                    addressLineSecond: null,
                    city: null,
                    cityId: null,
                    state: null,
                    postalCode: null,
                    phone: null,
                    receivingNumber: null,
                    website: null,
                    receivingUrl: null
                },
                contactInfo: [],
                showDialog: false,
                dialog:{
                    option: null,
                    submitted: false
                },
                contactOptions: [],
                cityOptions: [],
                stateOptions: [],
                loading: false,
                users: [],
                showSnackbar: false,
                snackMsg: '',
                citySearchLoading: false,
                tempCity: null,
                submitted: false,
                pendingUpdates: false
            }
        },
        computed: {
            ...mapGetters({
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                checkCampaignsIsPremium: "checkCampaignsIsPremium"
            }),
            campaigns() { // This creates a custom object in which to render the list
                const campaigns = []
                var counter = 1;
                for(let item of this.models){
                    var campaignItem = {
                        counter: counter++,
                        id: item.id,
                        status: item.attributes.status,
                        budget: item.attributes.budget,
                        facilityId: item.attributes.facilityId,
                        startDate: item.attributes.startDate,
                        productId: item.attributes.productId,
                        interactions: [],
                        cost: '',
                        total: 0
                    }
                    campaigns.push(campaignItem)
                }
                return campaigns
            },
        },
        async created(){
            this.getInformation()
        },
        watch: {
            '$route.params.facilityId'(newId, oldId) {
                 this.getInformation()
            },

            loading(newVal, oldVal) {
                this.$emit('loading', newVal);
            }

        },

        methods: {
            ...mapActions([
                'fetchFacilityInformation',
                'fetchFacilityDataChildNames',
                'updateFacilityInformation',
                'updateAdminItem',
                'createAdminItem',
                'deleteAdminItem',
                "searchCities",
                // 'getStates',
                "saveFacilityImage",
                'deleteContentUpdate',
                'fetchContactPageInfo',
                'updateContactPageInfo'
            ]),
            async getInformation(){
                this.loading = true;
                this.facilityId = this.$route.params.facilityId

                // GET FACILITY INFO
                const data = await this.fetchContactPageInfo(this.facilityId);

                this.users = data.users;
                this.contactInfo = data.contactInfo;
                this.cityOptions = data.cityOptions;
                this.contactOptions = data.contactOptions;
                this.information = data.information
                this.stateOptions = data.states
                this.data = data.facilityInfo

                // if(data.pendingUpdate){
                //     this.showPendingSnack()
                // }

                this.loading = false
            },
            showPendingSnack(){
                // this.pendingUpdates = true;
                this.snackMsg = "Your updates are pending review. They will go live once they have been reviewed."
                this.showSnackbar = true;
            },

            async attemptSubmit({information, submitFields}){

                try{
                    this.loading = true;

                    // UPDATE ADDRESS INFORMATION
                    // await this.updateAddressInfo(information);
                    await this.updateContactPageInfo({
                        facilityId: this.facilityId,
                        formData: information,
                        submitFields,
                        bulk: false
                    })

                    await this.getInformation();
                    this.showPendingSnack();

                }catch (e) {
                    console.error(e);
                    await this.getInformation();
                    this.loading = false
                    this.snackMsg = "There was an error updating your information. Please contact support"
                    this.showSnackbar = true;
                }
            },
        }
    }

</script>

<style lang="scss" scoped>
    .md-title{
        display: inline-flex;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }


    .md-tabs-navigation, .md-tab{
        margin-bottom: 0px;
        padding: 0px;
    }


    .md-content.md-tabs-content.md-theme-default {
        height: auto;
        padding-bottom: 20px;
    }

    .multiselect__tags {
        min-height: 46px;

    }

    .state{
        display: none;
    }

    .multiselect__tags{
        min-height: 48px;
    }

    .iconContainer{
        left: 125px;
        position: relative;
        top: -47px;
        margin-left: -2px;
        width: 20px;
        
        .icon{
            color: #c6c6c6 !important;
            
            &:hover{
                cursor: pointer;
            }
            font-size: 18px !important;
        }

    }

    .iconContainerURL{
         left: 102px;
        position: relative;
        top: -47px;
        margin-left: -2px;
        width: 20px;
        
        .icon{
            color: #c6c6c6 !important;
            
            &:hover{
                cursor: pointer;
            }
            font-size: 18px !important;
        }
    }
  
    
    .organicFilter{
        padding-right: 22px !important;
    }
    
    .tooltipbox{
        position: absolute;
        z-index: 6;
        background-color: #fff; 
        width: 220px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;

    }

    .zip-error, .custom-error{
        color: #d93025!important;
        display: block !important;
        position: relative !important;
        left: -5px;
        padding-top: 0 !important;
        padding-top: 46px;
        font-size: 12px;
        .error-icon{
            font-size: 16px!important;
            color: #d93025!important;
        }
    }
    .custom-error {
        margin-left: 160px;
    }

    .md-card-actions {
        padding-top: 18px;
    }

   

</style>

<style>
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .admin .md-invalid .md-error{
            left: 6px!important;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
</style>