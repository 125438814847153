<template>
<div>
    
        <div class="md-layout  md-alignment-top-left">

            <md-list class="md-layout-item md-size-100" v-show="!showCreditCardForm && !showBankAccountForm">
                <span class="intro">Please select a payment method</span>
                <md-list-item
                        @click="showCreditCardForm = !showCreditCardForm"
                        @mouseover="creditCheck = true"
                        @mouseleave="creditCheck = false"
                >
    <!--                                    <md-icon >check</md-icon>-->
                    <span class="md-list-item-text">Add credit or debit card</span>
                </md-list-item>
                <md-divider ></md-divider>
                <md-list-item
                        @click="showBankAccountForm = !showBankAccountForm"
                        @mouseover="bankCheck = true"
                        @mouseleave="bankCheck = false"
                >
    <!--                                    <md-icon v-show="bankCheck">check</md-icon>-->
                    <span class="md-list-item-text">Add a bank account</span>
                </md-list-item>
                <md-divider></md-divider>
            </md-list>
            <credit-card-form
                    v-if="showCreditCardForm"
                    class="md-layout-item"
                    @cardCreated="paymentMethodCreated"
                    @goBack="$emit('goBack', $event)"
                    @showStatusBar="$emit('showStatusBar')"
                    @hideStatusBar="$emit('hideStatusBar')"
                    ref="creditCardForm"
            ></credit-card-form>
            <bank-account-form
                    class="md-layout-item"
                    v-if="showBankAccountForm"
                    @accountCreated="paymentMethodCreated"
                    @goBack="showCreditCardForm = false; showBankAccountForm = false"
            ></bank-account-form>


            <md-card-actions class="actions" md-alignment="left" v-show="!showCreditCardForm && !showBankAccountForm">
                <md-button @click="$emit('goBack')" class="md-raised md-theme-default">Back</md-button>
            </md-card-actions>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import CreditCardForm from './CreditCardForm'
    import BankAccountForm from './BankAccountForm'
    export default {
        name: "NewPaymentMethodForm",
        components: {CreditCardForm, BankAccountForm},
        data() {
            return {
                creditCheck: false,
                bankCheck: false,
                showCreditCardForm: true,
                showBankAccountForm: false,
            }
        },
        methods: {
            paymentMethodCreated(){
                this.$emit('paymentMethodCreated')
            },
            stopLoadingError(error) {
                this.$refs.creditCardForm.stopLoadingError(error);
            }
        }

    }
</script>

<style lang="scss" scoped>

    .md-list-item-content>.md-icon:first-child{
        margin-right: 5px;
    }
    .add-payment .md-alignment-top-left{
        margin-top: 30px;
    }
    .md-card-content:last-of-type{
        padding-bottom: 14px;
    }
    .actions .md-button{
         margin-top: 14px;
         display: block;
         margin-left: 8px;
     }
     .intro{
        margin-bottom: 10px;
        margin-top: -10px;
        display: block;
        text-align: center;
    }
</style>
