import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {startOfDay, subMonths, format} from 'date-fns'

export default {
  actions: {

    async sendEmail(context, {templateName, customProperties}){
      const apiurl = `${apiUrl}/emails`
      const result = await axios.post(apiurl, {
        templateName,
        customProperties
      })
      return result.data
    },

  }
}