<template>
    <form @submit.prevent="attemptSubmitSummary">
        <md-card>
            <md-card-content class="cardContent" >
                <div class="md-layout md-gutter md-size-100">
                    <div class="md-layout-item" :class="{'md-size-100': bulk}">
                        <md-field
                                :class="{'md-invalid': showMdInvalidClass($v.descriptionForm.facilityDescription, submitted)}"
                        >
                            <label v-if="!mobile && !bulk">Summary</label>
                            <textarea
                                    id="facility-description"
                                    name="facility-description"
                                    :rows=inputRows
                                    placeholder="Write a summary about your program."
                                    v-model="descriptionForm.facilityDescription"
                                    class="textArea"
                                    :maxlength="maxLength"
                            ></textarea>
                            <span class="counter">{{descriptionForm.facilityDescription.length}}/{{maxLength}}</span>
                            <span class="md-error textarea-error"><md-icon class="error-icon">error</md-icon> A minimum of 35 characters is required.</span>
                        </md-field>

<!--                        <div id="reset-wrapper">-->
<!--                          <a @click.stop="resetSummary">Reset to default</a>-->
<!--                        </div>-->

                    </div>
                </div>
            </md-card-content>
            <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}" v-if="bulk">
                <md-button
                        v-if="bulk"
                        class="customGrey"
                        @click.prevent="$emit('cancel')"
                >Cancel</md-button>
                <md-button
                        v-if="bulk"
                        class="md-raised md-primary"
                        type="submit"
                        :disabled="descriptionForm.facilityDescription === originalDescription"
                >Save</md-button>
            </md-card-actions>
        </md-card>
    </form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { mapGetters, mapActions } from 'vuex'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import { noUrls, noPhoneNumbers } from '../../../helpers/customValidations'
    import customValidationMixin from '../../../mixins/validationMixin'

    export default {
        name: "SummaryForm",

        data() {
            return {
                descriptionForm: {
                    facilityDescription: ''
                },
                submitted: false,
                originalDescription: '',
                maxLength: 350,
            }
        },

        props: {
            bulk : {
                type: Boolean,
                default: false
            },

            loading: {
                type: Boolean,
                default: false,
            },

            originalForm: {
                type: Object,
                default: function() {
                    return {
                        description: null,
                    }
                }
            },

            summaryPlaceholder: {
                type: String,
                default: ''
            },

            inputRows: {
                type: Number,
                default: 6
            }
        },

        computed: {
            mobile() {
                if( this.windowWidth <= 760 ) {
                    return true;
                }
                return false;
            }
        },

        watch: {
            "descriptionForm.facilityDescription"(newValue, oldValue){
                if(!this.bulk){
                    if(this.descriptionForm.facilityDescription === this.originalDescription){
                        this.$emit('enableSubmit', false)
                    }else {
                        this.$emit('enableSubmit', true);
                    }
                }

            }
        },

        mixins: [validationMixin, customValidationMixin],

        validations: {
            highlights: {
                required,
                min: minLength(1),
                $each: {
                    content:{
                        required,
                        min: minLength(150),
                        noUrls,
                        noPhoneNumbers
                    }
                },
            },
            form:{
                philosophy: {required, min: minLength(3)}
            },
            descriptionForm: {
                facilityDescription: {required, min: minLength(35)}
            }
        },

        mounted() {

            this.descriptionForm = Object.assign(this.descriptionForm, JSON.parse(JSON.stringify(this.originalForm)))
            if(this.descriptionForm.facilityDescription.length > this.maxLength){
                this.maxLength = this.descriptionForm.facilityDescription.length
            }
            this.originalDescription = (' ' + this.descriptionForm.facilityDescription).slice(1);
        },

        methods: {
            // resetSummary(){
            //     this.descriptionForm.facilityDescription = this.summaryPlaceholder
            // },

            validateForm() {
                this.submitted = true;
                this.$v.descriptionForm.$touch();
                if(this.$v.descriptionForm.$error) {
                    //console.error('VALIDATION ERROR', this.$v.descriptionForm)
                    return false
                }
                return true;
            },

            attemptSubmitSummary() {
                if(this.validateForm()){
                    //console.log('passed validations')
                    this.$emit('submit', this.descriptionForm);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .md-tabs .md-card{
        border: none;
    }
    
    .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
        box-shadow: none;
        padding-top: 10px;
        margin-left: 2px;
    }

    .actions {
        width: 599px;
        padding-top: 20px;
        margin-right: 0;
        padding-right: 4px;
        margin-top: 18px;
        .md-primary{
            margin-left: 10px;
        }
    }
    .actionsBulk{
        margin-right: 2px;
        margin-top: 12px;
        padding-right: 20px;
    }
    .reload{
        padding-top:11px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .md-field {
        label {
            top: 0 !important
        }
        textarea {
            margin-left: 100px;
            font-size: 15px;
            font-family: inherit;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 5px;
            color: #43484f;
            width: 599px;
            display: block;
            resize: none;
        }
        textarea:focus {
            outline: none;
            
        }
        .counter {
            color: #8C9296;
            position: absolute;
            right: 5px;
            bottom: 0px;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
        }
    }
    textarea{
        -webkit-appearance: none !important;
    }

    .bulk-admin {
        .md-field {
            textarea {
                margin-top: -3px;
                margin-left: 0;
                width: 556px;
                height: 185px;
            }
            label {
                top: -20px!important;
                position: absolute;
            }
            .counter{
                right: 20px;
            }
        }

    }
    .bulk-admin .md-invalid .textarea-error{
        left: 0;
    }

    .md-invalid > label{
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .md-invalid {
      textarea {
        border-color: rgb(238, 238, 238) !important;
      }
      .counter {
        color: #d93025;
      }
    }

    #reset-wrapper {
      width: 699px;
      display: block;
      text-align: right;
    }

    

</style>