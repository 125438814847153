<template>
  <div>
    <div>
      <div class="fixed-top autologin" v-if="autoLogin">
        <section class="header-match">
          <div class="container-fluid">
            <div class="row">
              <div class="content">
                <div class="left">
                  <a :href="basePath" title="Rehab.com homepage">
                    <img
                      class="logo"
                      src="/images/rehab_logo.svg"
                      alt="Rehab.com logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="progres-box" v-if="autoLogin">
        <div class="progres-box-inside">
          <div data-v-515de9e4="" id="progres" class="progresbar-5"></div>
        </div>
      </div>
      <template v-if="autoLogin">
        <div class="center">
          <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
          <div class="login">Get more calls with premium...</div>
        </div>
      </template>
      <md-snackbar
        md-position="center"
        :md-duration="4000"
        :md-active.sync="showFunnelSnackbar"
        md-persistent
      >
        <span>Thanks for verifying your email address, please login below</span>
        <md-button class="md-primary" @click="showFunnelSnackbar = false"
          >Close</md-button
        >
      </md-snackbar>
      <md-snackbar
        md-position="center"
        :md-duration="Infinity"
        :md-active.sync="errorMsgDialog"
        md-persistent
      >
        <span>{{ errorMsgSnack }}</span>
        <md-button class="md-primary" @click="errorMsgDialog = false"
          >Close</md-button
        >
      </md-snackbar>
      <div class="white" v-if="!autoLogin">
        <div class="logo-container">
          <a :href="basePath" title="Rehab.com homepage">
            <img
              class="logo"
              src="/images/rehab_logo.svg"
              alt="Rehab.com logo"
            />
          </a>
        </div>
        <router-view></router-view>
        <div class="sponsor-info" v-if="!autoLogin">
          New to Rehab.com?
          <span class="sponsored-message">
            <a
              :href="basePath + 'signup'"
              target="_blank "
              title="Sign up"
              class="sponsor-call-trigger"
              >Join now</a
            >
          </span>
        </div>
      </div>
      <footer v-if="!autoLogin">
        <section class="footer-Content">
          <div class="container">
            <div class="row">
              <div class="column">
                <div class="widget">
                  <h3 class="block-title">General</h3>
                  <ul class="menurehab">
                    <li>
                      <a :href="basePath + 'login'" title="Log In">Log In</a>
                    </li>
                    <li>
                      <a :href="basePath + 'registration'" title="Sign Up"
                        >Sign Up</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'community/support'" title="Support"
                        >Support</a
                      >
                    </li>
                  </ul>
                  <h3 class="block-title">Company</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="basePath + 'community/about-us'"
                        title="About Us"
                        >About Us</a
                      >
                    </li>
                    <li>
                      <a
                        :href="basePath + 'community/patrick-nagle'"
                        title="Blog"
                        >Blog</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'community'" title="Community"
                        >Community</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'marketing'" title="Marketing"
                        >Marketing</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'community/news'" title="News"
                        >News</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget">
                  <h3 class="block-title">Legal</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="basePath + 'privacy-policy'"
                        title="Privacy Policy"
                        >Privacy Policy</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'terms-of-use'" title="Terms Of Use"
                        >Terms Of Use</a
                      >
                    </li>
                  </ul>
                  <h3 class="block-title">Products</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="basePath + 'registration'"
                        title="Patient Portal"
                        >Patient Portal</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'signup'" title="Provider Platform"
                        >Provider Platform</a
                      >
                    </li>
                    <li>
                      <a :href="basePath + 'maps'" title="Rehab Maps"
                        >Rehab Maps</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column">
                <div class="widget">
                  <h3 class="block-title">Healthcare</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="basePath + 'addiction-treatment'"
                        title="Addiction Treatment"
                        >Addiction Treatment</a
                      >
                    </li>
                    <li>
                      <a
                        :href="basePath + 'mental-health'"
                        title="Mental Health"
                        >Mental Health</a
                      >
                    </li>
                  </ul>
                  <h3 class="block-title">Resources</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="
                          basePath + 'addiction-treatment/addiction-resources'
                        "
                        title="Addiction Treatment Articles"
                        >Addiction Treatment</a
                      >
                    </li>
                    <li>
                      <a
                        :href="basePath + 'mental-health/treatment-resources'"
                        title="Mental Health Articles"
                        >Mental Health</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column wide">
                <div class="widget">
                  <h3 class="block-title">Listing Directories</h3>
                  <ul class="menurehab">
                    <li>
                      <a
                        :href="basePath + 'directory/drug-rehab-listings'"
                        title="Drug Rehab Centers"
                        >Drug Rehab Centers</a
                      >
                    </li>
                    <li>
                      <a
                        :href="
                          basePath +
                          'directory/mental-health-service-provider-listings'
                        "
                        title="Mental Health Services Providers"
                        >Mental Health Providers</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="site-info mt-4">
              <div class="giving-back">
                <div class="honcode">
                  <div class="d-flex flex-column text-sm--center">
                    <div class="who-we-are">
                      <span class="heading">Who Are We?</span>
                      <p class="paragraph">
                        We are a team of professionals committed to providing
                        exceptional service and support to those seeking to
                        improve their physical and mental health. We are about
                        treating others with the same kindness and respect we
                        would want for ourselves.
                      </p>
                    </div>
                    <div class="why-choose-us">
                      <span class="heading">Why Choose Us?</span>
                      <p class="paragraph">
                        We believe in you and your ability to achieve optimal
                        health and well-being, both physically and mentally. As
                        a compassionate team, we're dedicated to supporting you
                        on your journey towards a happier, healthier life!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rehab-supporters">
                <span class="heading">Government Agencies and Resources:</span>
                <ul>
                  <li class="nih">
                    <a
                      href="https://www.nih.gov"
                      target="_blank"
                      title="NIH"
                      rel="nofollow"
                    >
                      <img src="images/nih.png" alt="NIH" />
                    </a>
                  </li>
                  <li class="carf">
                    <a
                      href="http://www.carf.org/home/"
                      target="_blank"
                      title="CARF"
                      rel="nofollow"
                    >
                      <img
                        src="https://www.rehab.com/assets/images/supporters/carf.png"
                        alt="CARF"
                      />
                    </a>
                  </li>
                  <li class="joint-commision">
                    <a
                      href="https://www.jointcommission.org/"
                      target="_blank"
                      title="The Joint Commission"
                      rel="nofollow"
                    >
                      <img
                        src="https://www.rehab.com/assets/images/supporters/the-joint-commission.png"
                        alt="The Joint Commission"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="copyright text-center">
                  <img
                    alt="Rehab.com"
                    src="https://www.rehab.com/assets/images/v2/us/homepage/logo_home.svg"
                  />
                  <div class="social-section" style="margin-top: 15px">
                    <a
                      href="https://www.facebook.com/TheHelpCompany"
                      target="_blank"
                      rel="nofollow"
                      title="Facebook"
                      ><img
                        class="social-image"
                        alt="Facebook Icon"
                        style="height: 24px; padding-right: 11px"
                        src="/images/icon_fb.png"
                    /></a>
                    <a
                      href="https://twitter.com/rehabguide"
                      target="_blank"
                      rel="nofollow"
                      title="Twitter"
                      ><img
                        class="social-image"
                        alt="Twitter Icon"
                        style="height: 24px; padding-right: 11px"
                        src="/images/icon_tw.png"
                    /></a>
                    <a
                      href="https://www.linkedin.com/company/rehab.com"
                      target="_blank"
                      rel="nofollow"
                      title="LinkedIn"
                      ><img
                        class="social-image"
                        alt="LinkedIn Icon"
                        style="height: 24px"
                        src="/images/icon_li.png"
                    /></a>
                  </div>
                  <div class="copyright-section">
                    All Rights Reserved. Copyright ©
                    <a :href="basePath" title="Rehab.com Home">Rehab.com</a>
                    {{ new Date().getFullYear() }}
                  </div>
                  <div class="disclaimer">
                    Rehab.com receives advertising payments from the rehab
                    providers that answer calls to the toll free numbers listed
                    on the websites and is not associated with any specific
                    rehab provider. Rehab.com is an advertising-supported
                    consumer resource that provides information about a variety
                    of rehab and therapy services and resources. Rehab.com is
                    not a substitute for medical or professional assistance.
                    Rehab.com is not a rehab services provider or treatment
                    facility and does not provide medical advice of any kind.
                    Rehab.com does not guarantee the quality of care provided,
                    or the results to be achieved, by any rehab or therapy
                    service or treatment facility you might find through us.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  </div>
</template>

<script>
import "url-search-params-polyfill";
import LoginForm from "./LoginForm.vue";
import config from "../config.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { addHours, isBefore } from "date-fns";

export default {
  name: "LoginForm",
  components: {
    "c-login-form": LoginForm,
  },
  data: () => ({
    loginError: false,
    config: config,
    autoLogin: false,
    userSaved: false,
    sending: false,
    lastUser: null,
    trackingNumbers: [],
    facilityIds: [],
    showFunnelSnackbar: false,
    recoverPasswordDialog: false,
    recoverPassword: "",
    recoverPasswordConfirm: "",
    recoverPasswordSnackbar: false,
    recoverPasswordDialogWait: false,
    errorMsg: "",
    errorMsgDialog: false,
    errorMsgSnack: null,
    basePath: "https://www.rehab.com/",
  }),
  async mounted() {
    var urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.getAll("token")[0];
    const verified_token = urlParams.getAll("verify")[0];

    if (token && !verified_token) {
      this.autoLogin = true;
      this.login("null", "null", token);
    } else {
      this.checkUser();
    }
    if (window.location.origin === "https://connect-staging.rehab.com") {
      this.basePath = `https://staging.rehab.com/`;
    } else if (window.location.origin === "https://connect-demo.rehab.com") {
      this.basePath = `https://demo.rehab.com/`;
    } else if (window.location.origin === "https://connect-dev.rehab.com") {
      this.basePath = `https://dev.rehab.com/`;
    } else if (window.location.origin === "https://connect.rehab.com") {
      this.basePath = `https://www.rehab.com/`;
    } else {
      this.basePath = `http://local.rehab.com/`;
    }
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions([
      "fetchHubspotContactByEmail",
      "hubspotLoginTracking",
      "resetAllState",
      "loginUser",
    ]),

    ...mapMutations(["setGoV2Facility"]),

    async checkUser() {
      if (
        this.user &&
        this.user.token &&
        this.user.loggedInAt &&
        isBefore(new Date(), addHours(this.user.loggedInAt, 24))
      ) {
        this.$router.push({ name: "dashboard.premium" });
      } else {
        await this.resetAllState();
      }
    },

    async login(user, pass, token) {
      this.sending = true;
      this.loginError = false;

      this.$ls.remove("user");
      await this.resetAllState();
      this.loginUser({
        email: user,
        password: pass,
        token: token,
      })
        .then((response) => {
          let data = response.data;
          if (data && data.token) {
            this.loginSuccess(data);
          } else {
            // Incorrect login details
            this.sending = false;
            this.loginError = true;
          }
        })
        .catch((error) => {
          console.error("ERROR LOGGING IN", error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (
              error.response.data.error === "claimedFacility" ||
              error.response.data.error === "closedFacility"
            ) {
              this.errorMsgSnack = error.response.data.message;
            } else {
              this.errorMsgSnack =
                "There was an error setting up your account. Please contact our support team.";
            }
          } else {
            this.errorMsgSnack =
              "Our service API is currently offline, please contact our support team.";
          }
          this.errorMsgDialog = true;
          this.sending = false;
          this.autoLogin = false;
        });
    },

    async loginSuccess(data) {
      if (this.$route.query.facilityId) {
        this.setGoV2Facility(this.$route.query.facilityId);
      }
      if (data.redirect) {
        return this.$router.push({ name: data.redirect });
      }
      this.$router.push({ name: "dashboard.premium" });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.fade {
  transition: opacity 0.15s linear;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal p {
  font-size: 15px;
  color: #444;
  font-weight: 200;
}

.footer {
  -webkit-font-smoothing: auto;
}
.logo-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 72px;
  width: 100%;
  text-align: center;
  .logo {
    height: 25px;
  }
}

.center {
  background-color: #fff !important;
  min-height: 100vh !important;
}

.md-toolbar.md-theme-default.md-primary,
.md-toolbar.md-theme-default.md-primary .md-icon {
  color: #fff;
  color: var(--md-theme-default-text-primary-on-primary, #fff);
}

.md-toolbar {
  border: 1px solid #eee;
}

.md-toolbar {
  background-color: #fafafa !important;
}
.logo {
  width: 170px;
  .rehab-logo {
    height: 32px;
  }
}

.loaderLogin {
  position: absolute;
  left: 50%;
  margin-left: -36px;
  top: 310px;
  z-index: 10;
}
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 140px;
}
.login {
  padding-top: 25px;
}
.white {
  background-color: #fff;
}

.dialog-actions {
  padding-right: 15px;
  padding-bottom: 10px;
}
.blurItem {
  filter: blur(2px);
}
.errorMsg {
  position: absolute;
  color: red;
}

.facebook-icon {
  width: 25px;
  height: 25px !important;
  margin-right: 13px;
  margin-bottom: 5px;
}
.twitter-icon {
  margin-left: 13px;
  width: 25px;
  height: 25px !important;
  margin-top: 2px;
  margin-bottom: 5px;
}

.progres-box {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  height: 8px;
  background-color: #e7e7e7;
  border-radius: 8px;
  z-index: 10;
  overflow: hidden;
  top: 29px;
  width: 445px;
  margin-left: -223px;
  margin-right: auto;
  left: 50%;
  .progres-box-inside {
    position: relative;
    margin: 0 auto;
    top: 0;
    .progresbar-5[data-v-515de9e4] {
      position: absolute;
      height: 8px;
      width: 80%;
      background-color: #0078d3 !important;
      z-index: 11;
    }
  }
}

.fixed-top {
  position: relative;
  .header-match,
  .header-match .content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .header-match {
    height: 66px;
    background-color: #fbfbfb;
    border-bottom: 1px solid #eee;
    font-family: Lato, sans-serif;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-match .content {
    width: 1140px;
    padding-top: 18px;
  }
  .header-match,
  .header-match .content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .header-match .content .left {
    float: left;
  }
  a {
    color: #1a73e8;
  }
  .header-match .content .right {
    float: right;
  }
  .header-match .content ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .header-match .content ul li {
    display: inline-block;
    margin: 0 0 0 13px;
    color: #2da8e0;
    font-weight: 600;
    font-size: 15px;
  }
  .header-info {
    position: relative;
    height: 62px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    font-family: Lato, sans-serif;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 2;
  }
  .header-info .content {
    position: relative;
    width: 100%;
    padding-top: 9px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .header-info .content ul {
    display: inline-block;
    list-style-type: none;
    padding: 10px 0 0;
  }
  .header-info .content ul li {
    display: inline-block;
    color: #00234b;
    font-weight: 500;
    font-size: 17px;
    margin: 0 23px 0 0;
    font-family: "Lato", sans-serif;
    letter-spacing: 0;
  }
  .header-info .content ul li a {
    color: #00234b;
  }
  .start-for-free {
    font-family: Lato, sans-serif;
    margin-top: 1px;
    display: inline-block;
    background-color: #0078d3;
    color: #fff;
    border-radius: 4px;
    font-size: 15.5px;
    font-weight: 700;
    text-align: center;
    padding-top: 11px;
    line-height: 1.42857143;
    width: 145px;
    height: 44px;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  dl,
  ol,
  ul {
    margin-top: 2px;
  }
  .header-match .content ul li a {
    color: #00234b;
    font-weight: 400;
  }
}
.right ul li {
  letter-spacing: 0px;
  padding-left: 2px;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.md-layout {
  margin: 0;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.md-layout-item {
  height: auto;
  width: 1000px;
  position: relative;
}
.logo {
  width: 168px;
  height: 31px;
  margin-left: 1px;
}

.title {
  background-color: #fff;
  position: relative;
  display: block;
  float: left;
  line-height: 1.2;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: 4px;
  width: 100%;
  padding-bottom: 6px;
  h1 {
    font-family: "Lato", sans-serif;
    font-size: 36px;
    color: #000000;
    font-weight: 200;
    -webkit-font-smoothing: auto;
  }
}

.sponsored-message {
  font-weight: 400;
}

.sponsor-info {
  padding-top: 21px;
  text-align: center;
  font-size: 15px;
  color: #212121;
  font-weight: 300;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: #fff;
  float: left;
  width: 100%;
  line-height: unset;
  height: 200px;
  -webkit-font-smoothing: auto;
}

.tools-info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #fff;
  float: left;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  .col-xs-offset-3 {
    margin-left: auto;
    margin-right: auto;
    width: 560px;
    padding-left: 20px;
    padding-top: 10px;
  }
}
.tools-info .tools-title {
  font-family: "lato", sans-serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
}
.col-xs-offset-3 {
  margin: 0 auto;
}
.tools-info ul {
  display: block;
  float: left;
  width: 95%;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
}
.tools-info ul li {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  display: block;
  float: left;
  width: 38%;
  padding: 30px 0 0;
  text-align: left;
  font-size: 16px;
  margin: 0 0 0 10%;
  height: 64px;
  color: #212121;
  letter-spacing: 0px;
}
.tools-info ul li i {
  margin-right: 15px;
  color: #1553b9 !important;
}

section {
  display: block;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
footer,
footer .footer-Content .copyright img {
  height: 36px;
}
footer .footer-Content {
  background-color: #fafafa;
  padding: 13px 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, Open Sans, Helvetica Neue, "sans-serif";
  border-top: 1px solid #e9e9e9;
  a {
    color: #2b2b2b;
  }
}
.container {
  max-width: 1140px;
  clear: both;
}
.col-lg-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
footer {
  display: block;
  float: left;
  width: 100%;
  -webkit-font-smoothing: auto;
}
footer .footer-Content .widget {
  width: 165px;
}
footer .footer-Content .widget .block-title {
  color: #2b2b2b;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto, Open Sans, Helvetica Neue, "sans-serif";
  margin-bottom: 12px;
  letter-spacing: -0.1px;
}
footer .footer-Content .widget .menurehab {
  display: block;
  margin: 0;
  padding: 0;
  margin-top: 0 !important;
}
footer .footer-Content .widget .menurehab li {
  display: block;
  margin: 0;
  color: #797979;
  white-space: nowrap;
  padding: 0 0 10px;
  width: 150px;
  letter-spacing: -0.1px;
}
footer .footer-Content .widget .menurehab li a {
  color: #2b2b2b;
  font-size: 15px;
  font-weight: 400;
  font-family: Roboto, Open Sans, Helvetica Neue, "sans-serif";
}
.col-lg-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
footer .footer-Content .site-info {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 15pt 0;
}
.col-lg-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
footer .footer-Content .site-info .rehab-supporters {
  text-align: right;
  color: #2b2b2b;
  font-size: 15px;
  letter-spacing: 0.1px;
}

footer .footer-Content .aux-text {
  color: #2b2b2b;
  font-size: 15px;
  letter-spacing: 0.1px;
}

.rehab-supporters {
  padding-top: 4px;
}
.col-md-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
footer .footer-Content .copyright {
  margin-top: 2.5rem !important;
}
.text-center {
  text-align: center !important;
}
footer,
footer .footer-Content .copyright img {
  height: 36px;
}
footer .footer-Content .copyright .copyright-section {
  margin-top: 1rem;
  color: #595959;
  font-weight: 400;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
}
footer .footer-Content .copyright .social-media ul {
  display: block;
  margin: 11px 0 0;
  padding: 0;
  list-style: none;
  font-size: 24px;
  color: #fff;
}
footer .footer-Content .copyright .social-media ul li {
  display: inline-block;
  vertical-align: middle;
}
footer .footer-Content .copyright a {
  color: #1a73e8;
}
footer .footer-Content .copyright .disclaimer {
  margin-top: 6px;
  color: #595959;
  margin-bottom: 30px;
  font-weight: 400;
  padding-right: 15px;
  padding-left: 15px;
}
footer .column {
  padding-right: 9%;
}

.column.wide {
  display: block;
  clear: both;

  .padding-left {
    padding-left: 90px;
  }
}
footer .container {
  margin-right: auto;
  margin-left: auto;
  .column {
    padding-left: 20px;
  }
}
footer .footer-Content .site-info .rehab-supporters ul {
  display: block;
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
}
footer .footer-Content .site-info .rehab-supporters ul li {
  display: inline-block;
  vertical-align: middle;
}
.footer-Content .rehab-supporters li {
  padding-top: 0;
  padding-bottom: 16px;
}
footer .footer-Content .site-info .rehab-supporters ul li {
  display: inline-block;
  vertical-align: middle;
}
.footer-Content .rehab-supporters .nih {
  margin-top: 8px;
}
.footer-Content .rehab-supporters img {
  height: 40px;
  padding-left: 8px;
  margin-top: -14px;
}
.footer-Content .rehab-supporters .nih img {
  height: 58px;
  padding-left: 8px;
  margin-top: 0;
}
.footer-Content .rehab-supporters .carf img {
  height: 50px;
  padding-left: 8px;
  margin-top: -15px;
}
.footer-Content .rehab-supporters .joint-commission img {
  height: 50px;
  padding-left: 8px;
  margin-top: -15px;
}

.footer-Content .rehab-supporters .ryanhampton img {
  height: 24px;
  padding-left: 8px;
  margin-top: -15px;
}
.rehab-supporters {
  float: right;
}
footer .footer-Content .site-info .logo-hon {
  float: left;
  margin-right: 10px;
}
.aux-footer {
  a {
    color: #1a73e8 !important;
  }
}
.honcode {
  //display: inline-table;
  //vertical-align: middle;
  //width: 420px;
  //margin-top: 24px;
}
.rehab-supporters {
  display: inline-table;
  vertical-align: middle;
}

@media (max-width: 1170px) {
  .md-layout-item {
    max-width: 500px;
  }
  .rehab-supporters,
  .honcode {
    padding-bottom: 20px;
    clear: both;
    width: 100%;
    text-align: center !important;
    float: left;
    margin-left: auto;
    margin-right: auto;
    ul {
      text-align: center;

      li {
        clear: both;
        width: 100%;
      }
    }
  }
  .honcode .aux-text,
  .logo-hon {
    width: 100%;
    clear: both;
    margin-bottom: 10px;
  }
  .col-xs-offset-3 {
    padding-left: 0px;
    width: 100%;
  }
  .rehab-supporters ul {
    margin-top: 22px !important;
  }
}

.site-info {
  display: table;
  margin-top: 25px;
  width: 100%;
}

.green .md-icon {
  color: #36ad2c !important;
}

.tools-info ul li i {
  font-size: 18px !important;
  font-weight: 900 !important;
}

/**
 DEV-1669
 */
.footer-Content {
  .giving-back,
  .copyright .copyright-section,
  .copyright .disclaimer,
  .site-info .rehab-supporters .heading {
    color: #2b2b2b;
    font-family: "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
  }

  .giving-back {
    display: inline-table;
    vertical-align: middle;

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    .who-we-are {
      margin-top: 25px;
    }
    .why-choose-us {
      margin-top: 34px;
    }

    .heading,
    .paragraph {
      font-size: 15px;
    }

    .heading {
      color: #2b2b2b;
      font-weight: 700;
    }

    .paragraph {
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 992px) {
      width: 460px;
    }
  }

  .site-info {
    display: flex;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }
    .rehab-supporters {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 992px) {
        align-items: center;
        justify-content: center;
      }
      @media only screen and (min-width: 992px) {
        align-items: flex-end;
        justify-content: center;
      }

      .heading {
        font-weight: 700;
      }
    }
  }

  .giving-back .paragraph,
  .copyright .copyright-section,
  .copyright .disclaimer {
    color: #595959;
  }

  .copyright .copyright-section,
  .copyright .disclaimer {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .logo-container {
    padding-top: 43px;
  }

  footer .container .column {
    padding-left: -0px;
  }
  footer .footer-Content .widget .menurehab li {
    width: unset !important;
  }

  footer .footer-Content .widget {
    width: 210px;
  }
  footer .column {
    padding: unset;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .fixed-top .start-for-free {
    display: none;
  }
  .md-card {
    width: 90%;
  }
  .tools-info {
    overflow: hidden;
  }
  .footer-Content {
    overflow: hidden;
  }
  .disclaimer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-Content .rehab-supporters .nih {
    padding-top: 0px;
    margin-top: 0px;
  }
  .footer-Content .rehab-supporters .ryanhampton {
    padding-top: 10px;
  }
  .autologin {
    display: none;
  }
  .progres-box {
    position: absolute;
    margin: 0;
    text-align: center;
    height: 8px;
    width: 100%;
    background-color: #e7e7e7;
    z-index: 10;
    overflow: hidden;
    margin-top: 0px;
    max-width: unset;
    padding: 0px;
    left: 0;
    top: 0;
    border-radius: 0px;

    .progres-box-inside {
      position: relative;
      margin: 0 auto;
      top: 0;
      .progresbar-5 {
        position: absolute;
        height: 8px;
        width: 80%;
        background-color: #0078d3 !important;
        z-index: 11;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sponsor-info {
    height: unset;
  }

  .white {
    min-height: 100vh;
  }

  footer {
    overflow-y: visible;
  }
  .padding-left {
    padding-left: 0px !important;
  }
  footer .container {
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .tools-info ul {
    width: 85%;
  }
}
@media only screen and (max-width: 440px) {
  .tools-info ul {
    width: 65%;
  }
}
@media only screen and (max-width: 375px) {
  .tools-info ul {
    width: 60%;
  }
}
@media only screen and (max-width: 375px) {
  .tools-info ul {
    width: 60%;
  }
  .header-info li a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 320px) {
  .tools-info ul {
    width: 60%;
  }
  .header-info li a {
    font-size: 14px;
  }
  .tools-info .tools-title {
    font-size: 28px;
  }
  .tools-info ul {
    width: 51%;
  }
  .tools-info ul li {
    font-size: 12px;
  }

  .padding-left {
    margin-left: -30px;
  }
}
</style>
