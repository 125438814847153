<template>
    <div class="container">

        <div class="containerOptions">
            <h1>Choose a plan that’s right for your program</h1>

            <!-- PREMIUM + CAMPAIGNS -->
            <div class="options premium-campaigns" value="Premium">
                <h5>PREMIUM</h5>
                <div class="hr"></div>
                <div class="option-body">
                    <div class="description mobilehide">
                        Industry-leading tools designed to connect with those seeking help.
                    </div>
                    <div class="pricing">
                        <div class="price">{{ totalSubscriptionCost / facilities.length | usd}}</div>
                        <p class="price-monthly">per month per listing</p>
                    </div>
                    <ul>
                        <li><i class="md-icon md-icon-font md-theme-default">done</i> Unlimited Forms</li>
                        <li><i class="md-icon">done</i> Multiple users</li>
                        <li><i class="md-icon md-icon-font md-theme-default">done</i> Premium support</li>
                        <li><i class="md-icon md-icon-font md-theme-default">done</i> Replace third-party ads</li>
                    </ul>
                </div>
                <div class="get-started" @click="$emit('next', 6)">
                    Upgrade now<i class="md-icon">arrow_forward</i>
                </div>
            </div>

            <!-- PREMIUM + BED REQUESTS -->
            <div class="last-step options premium-beds" value="Basic">
                <h5>Basic</h5>
                <div class="hr"></div>

                <div class="option-body">
                    <div class="description mobilehide">Customize your listing details, respond to reviews and more.</div>

                    <div class="pricing">
                        <div class="price">Free</div>
                    </div>

                    <div class="Plan-tableContainer">
                        <table class="Plan-table">
                            <tbody><tr class="Plan-table-row">
                                <td class="Plan-table-cell Plan-table-cell--alternating">More free referrals</td>
                                <td class="Plan-table-cell">Website URL</td>
                            </tr>
                            <tr>
                                
                                <td class="Plan-table-cell">Respond to reviews</td>
                                <td class="Plan-table-cell Plan-table-cell--alternating">Update content</td>
                            </tr>
                            </tbody></table>
                    </div>
                </div>

                <div class="get-started" @click="$emit('closeFunnel')">
                    Get started<i class="md-icon">arrow_forward</i>
                </div>

            </div>
        </div>
<!--    <div class="buttons">-->
<!--        <md-button class="md-raised" v-if="backShow" @click="back()">Back</md-button>-->
<!--        <md-button-->
<!--            class="md-primary md-raised next-btn"-->
<!--            @click="next()"-->
<!--        >Continue</md-button>-->
<!--    </div>-->

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "SubscriptionBasic",
        data(){
            return {
                title: "Subscription Basic",
                backShow: true
            }
        },
        computed: {
            ...mapGetters([
                'facilitySubscriptionCost',
                'totalSubscriptionCost',
                'facilities'
            ])
        },
        mounted(){
             if(this.$parent.$parent.$parent.$parent.$parent.funnelData.currentStep === 'subscriptionOnly'){
                 this.backShow = false;
             }
        },
        methods: {
            back(){
                this.$parent.$parent.$parent.$parent.$parent.funnelData.currentStep= 4;
            },
            next(){
                this.$parent.$parent.$parent.$parent.$parent.funnelData.currentStep = 6
            }
        },
        filters: {
            usd(value) {
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                })
                if(value % 1 === 0){
                    formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                    })
                }
                return formatter.format(value)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .container{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        text-align: center;
    }
    .containerOptions{
        width: 750px;
        display: inline-block;
        text-align: center;

        h2 {
            font-weight: 500;
            font-size: 16px;
        }
    }

    h1{
        font-size: 28px;
        font-weight: bold;
        display: block;
        text-align: center;
        padding-top: 10px
    }
   

    h2{
        font-size: 14px;
        color: #000000;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
    }

    .hr{
        border-top: 1px solid #ccc;
        margin-top: 15px;
        margin-bottom: 6px;
        width: 100%;
        height: 1px;
    }

    .options {

            display: block;
            width: 375px;
            /*max-height: 432px;*/
            padding-top: 16px;
            /*border: 1px solid #ccc;*/
            border-radius: 4px;
            text-align: left;
            margin-top: 16px;
            position: relative;
            float: left;

        .option-body{
            padding-left: 30px;
            padding-right: 30px;
        }
           
        h5 {
            color: #000;
            font-size: 19px;
            padding: 0;
            margin: 0;
            font-weight: 400;
            padding-top: 6px;
            text-align: center;
        }
        h2 {
            font-size: 30px;
            color: #000;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0;
            font-weight: 400;
            text-align: left;
        }
        .description {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            margin:0px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding-right: 10px;
            text-align: center;
        }

    }

    .premium-campaigns{
        /*margin-top: 95px;*/
        border: 1px solid #DCDCDC;
        z-index: 100;
        h5{
            color: #448aff;
        }
        .description{
            color: #525f7f;
        }
        .hr{
             border-top: 1px solid #DCDCDC;
        }

        ul{
            margin-top: 30px;
            padding-bottom: 0px;
            li {
                list-style: none;
                margin-left: -39px;
                line-height: 35px;
                font-size: 14px;
                
                i{
                    color: #3AD612 !important;
                    margin-right: 5px;
                    font-size: 20px !important;
                }
            }
        }

        .pricing {
            text-align: center;
            .price{
                display: inline-flex;
                font-size: 45px;
                line-height: 45px;
                letter-spacing: -.05em;
                color: #32325d;
                margin: 0;

            }
            .plus{
                display: inline-flex;
                position: relative;
                top: -8px;
                left: 11px;
                font-size: 19px;
                color: #32325d;
            }
            .campaigns{
                display: inline-flex;
                position: relative;
                top: -10px;
                left: 19px;
                font-size: 16px;
                color: #32325d;
            }
            .price-monthly{
                color: #8898aa;
                font-size: 14px;
                margin-top: 5px;
            }
        }

        .get-started{
            background: #f6f9fc;
            display: block;
            color: #32325d;
            text-align: center;
            text-decoration: none;
            padding: 16px;
            font-size: 17px;
            font-weight: 600;
            cursor: pointer;
            margin-top: 20px;

            .md-icon{
                font-size: 16px!important;
            }
        }
        .get-started:hover{
            transition: 0.5s;
            background-color: #e6ebf1;
        }
        .get-started:active{
            background-color: #cfd7df;
        }

    }

    .premium-beds {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-top: 35px;
        color: #FFFFFF;
        background: #044389;
        background-position-x: initial;
        background-position-y: initial;
        background-size: initial;
        background-repeat-x: initial;
        background-repeat-y: initial;
        background-attachment: initial;
        background-origin: initial;
        background-clip: initial;
        background-color: rgb(4, 67, 137);
        h5 {
            color: #fff;
        }
        .hr {
            border-top: 2px solid rgb(49, 101, 158);
        }


        .pricing {
            text-align: center;
            .price{
                display: inline-block;
                font-size: 45px;
                line-height: 60px;
                letter-spacing: -.05em;
                color: #fff;
                margin: 0;
            }
            .price-monthly{
                color: #8898aa;
                font-size: 14px;
                margin-top: 0;
            }
        }



        .Plan-tableContainer {
            padding: 0;

            .Plan-table {
                width: 100%;
                max-width: 433px;
                border-bottom: 0;
                border-collapse: collapse;
                margin: 24px auto 40px;
                .Plan-table-cell {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    padding-left: 6px;
                    padding-right: 6px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 50%;
                    text-align: center;
                    color: #e6ebf1;
                    font-size: 13px;
                    line-height: 1.4em;
                    border: 1px solid #31659E;
                }
            }
        }

        .get-started{
            background: rgba(85,90,191,.2);
            color: #87bbfd;
            display: block;
            text-align: center;
            text-decoration: none;
            padding: 16px;
            font-size: 17px;
            font-weight: 600;
            cursor: pointer;
            margin-top: 40px;

            .md-icon{
                font-size: 16px!important;
            }
        }
        .get-started:hover{
            transition: 0.5s;
            background-color: rgba(85,90,191,.35);
        }
        .get-started:active{
            background-color: rgba(85,90,191,.1);
        }

    }


    .buttons{
        position: absolute;
        bottom: 34px;
        right: 98px;
    }
    .scratchOut{
        color: #BDBDBD;
        font-size: 26px;
        text-decoration: line-through;
    }
  
  


</style>