<template>
  <div :class="{ 'blurItem' : blur }">
    <div class="md-layout md-alignment-top-right filters"
         v-if="user.status == 1 && (matches.length > 0 || firstLoad==false)">
      <div class="md-layout-item md-size-15" v-if="facilities.length > 1">
        <md-field>
          <md-select placeholder="Listing" v-model="selectedFacility" name="selectedFacility" id="selectedFacility"
                     @md-selected="onChange(selectedFacility)" mdClass="filtersPaddingTopFacility">
            <md-option :value="0">View all</md-option>
            <md-option v-for="(item) in facilities" :value="item.attributes.facilityId"
                       :key="item.attributes.facilityId">{{ item.attributes.name }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-size-15">
        <md-datepicker v-model="startDate" md-immediately>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-15">
        <md-datepicker v-model="endDate" md-immediately>
        </md-datepicker>
      </div>
    </div>
    <div class="md-layout">
      <template v-if="!loading">
        <md-card v-if="showMatches.length>0">
          <md-table class="md-table-mobile-padding">
            <md-table-row class="white">
              <md-table-head>Name</md-table-head>
              <md-table-head v-if="!mobile">Source</md-table-head>
              <md-table-head>Date</md-table-head>
              <md-table-head v-if="!mobile">Time</md-table-head>
              <md-table-head>Details</md-table-head>
            </md-table-row>
            <md-table-row v-for="(item) in paginationMatches" v-bind:key="item.id" class="grey">
              <md-table-cell>{{ item | matchName }}</md-table-cell>
              <md-table-cell v-if="!mobile">{{ item | matchSource }}</md-table-cell>
              <md-table-cell>{{ item.date |date }}</md-table-cell>
              <md-table-cell v-if="!mobile">{{ item.date |time }}</md-table-cell>
              <md-table-cell><a href="#" @click.prevent="viewDetails(item)">View</a>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div class="md-layout" v-if="showMatches.length > numShowMatches">

            <div class="md-layout-item table-nav">
              <md-button
                  class="md-raised md-primary"
                  :disabled="currentPage === 1"
                  @click="previousPage"
              >Previous</md-button>
              <md-button
                  class="md-raised md-primary"
                  :disabled="nextDisabled"
                  @click="nextPage"
              >Next</md-button>
            </div>
          </div>

        </md-card>

        <div class="container" v-show="showMatches.length === 0 && loading == false && firstLoad==false && user.status == 1">
           <app-activityzero></app-activityzero>
        </div>
        <div class="container" v-show="matches.length == 0 && loading == false && firstLoad==true && user.status == 1">
          <app-matchzero></app-matchzero>
        </div>
        <div class="container" v-show="matches.length == 0 && loading == false && user.status == 3">
          <app-match></app-match>
        </div>
      </template>

      <md-progress-spinner md-mode="indeterminate" class="spinner" v-if="loading"></md-progress-spinner>
    </div>
    <app-matchtooltip></app-matchtooltip>

    <!--  DETAILS MODAL -->
    <md-dialog :md-active.sync="viewDetailsModal" class="match">
      <img width="75" class="logoTop" src="/images/mini_logo.svg"/>
      <md-dialog-title class="title" v-if="viewDetailsMatch">{{ viewDetailsMatch | rawMatchName}}</md-dialog-title>
       <div class="dialog-exit">
            <img src="/images/icons.png">
            <a @click="viewDetailsModal=false"><md-icon>close</md-icon></a>
        </div>

      <md-tabs
          md-alignment="centered"
          class="choice-tabs"
          :md-active-tab="matchTabId"
          @md-changed="tabClick"
      >
        <md-tab id="tab-summary" md-label="Summary">
          <span class="description" v-if="viewDetailsMatch">  
            <template v-if="viewDetailsMatch">
              <template v-if="viewDetailsMatch['first_name'] != 'Treatment'">
                  You matched with <span class="bold">{{ viewDetailsMatch['first_name'] }}</span>,<br>thanks to Rehab.com
              </template>
              <template v-else>
                  You have a new <span class="bold">patient connection</span>, thanks to Rehab.com
              </template>
            </template>
          </span>
          <span class="days" v-if="viewDetailsMatch">{{ viewDetailsMatch.date.date | matchDays }} ago</span>
          <img src="/images/logo_large.png" height="100"/>
          <span class="case" v-if="viewDetailsMatch">  
            <template v-if="viewDetailsMatch">
              <template v-if="viewDetailsMatch['first_name'] != 'Treatment'">
                  Take a minute to learn about {{ viewDetailsMatch['first_name'] }}'s case.
              </template>
              <template v-else>
                  Take a minute to learn more.
              </template>
             </template>
          </span>
          <md-button class="md-raised md-primary ctaCall" id="case-details" @click="changeTab('tab-details')">Case Details</md-button>
        </md-tab>
       
        <md-tab id="tab-details" md-label="Details">
          <span class="description" v-if="viewDetailsMatch">
            <template v-if="viewDetailsMatch">
              <template v-if="viewDetailsMatch['first_name'] != 'Treatment'">
                  Great job! <span class="bold">{{ viewDetailsMatch['first_name'] }}</span> matched with<br>your program.
              </template>
              <template v-else>
                  Great job! You have a new patient connection.
              </template>
            </template>
          </span>
          <img src="/images/logo_large.png" height="100"/>
          <template v-if="viewDetailsMatch">
            <template v-if="viewDetailsMatch['first_name'] != 'Treatment'">
                <span v-if="viewDetailsMatch"  class="case small"><span class="bold">{{ viewDetailsMatch['first_name'] }} {{ viewDetailsMatch['last_name'] }}:</span> {{ viewDetailsMatch.phone }}</span>
                <span v-if="viewDetailsMatch"  class="case">{{ viewDetailsMatch['first_name'] }}, {{ viewDetailsMatch['dob'] | years }} years old from {{ viewDetailsMatch['zip_city'] }}, {{ viewDetailsMatch['state'] }}, connected with {{ viewDetailsMatch.facilityName }} at {{ viewDetailsMatch.date | time }} on {{ viewDetailsMatch.date | dayOfWeek }}, {{ viewDetailsMatch.date | month }}, {{ viewDetailsMatch.date | day }}, {{ viewDetailsMatch.date | year }}. {{ viewDetailsMatch['first_name'] }} needs help {{viewDetailsMatch.how_soon | formatHowSoon}} and intends to pay for care with {{viewDetailsMatch.how_will_you_pay_for_treatment |formatPay}}.</span>
                <span v-if="viewDetailsMatch"  class="case">Rehab.com provided {{ viewDetailsMatch['first_name'] }} with the direct number we have on file for your program: {{ viewDetailsMatch.facilityPhone.content|phone }}</span>
            </template>
            <template v-else>
              <span v-if="viewDetailsMatch"  class="case small"><span class="bold">Contact:</span> {{ viewDetailsMatch.phone }}</span>
              <span v-if="viewDetailsMatch"  class="case">A new patient connection was made with {{ viewDetailsMatch.facilityName }} at {{ viewDetailsMatch.date | time }} on {{ viewDetailsMatch.date | dayOfWeek }}, {{ viewDetailsMatch.date | month }}, {{ viewDetailsMatch.date | day }}, {{ viewDetailsMatch.date | year }}. The name of patient who would like to connect with your prorgam is not known at this time. Please call them at the contact number they provided.</span>
              <span v-if="viewDetailsMatch"  class="case">Rehab.com provided the unnamed patient the direct number we have on file for your program: {{ viewDetailsMatch.facilityPhone.content|phone }}</span>  
            </template>
          </template>
          <div class="hr"></div>
          <span class="case call">Schedule a call to connect cases like this directly to your CRM.</span>
          <a target="_blank" id="schedule-call-now" :href="scheduleLink" class="url" @click="trackScheduleCall"><md-button class="md-raised md-primary ctaCall">Schedule Call Now</md-button></a>
        </md-tab>
      </md-tabs>
    </md-dialog>
  </div>
</template>

<script lang="javascript">

import config from '../config.js'
import {endOfToday, subMonths, format, startOfMonth, parse, distanceInWords, differenceInYears, subMinutes} from 'date-fns'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Match',
  data() {
    return {
      selectedFacility: 0,
      facilities: {},
      facilitiesIDs: [],
      matches: [],
      config: config,
      startDate: startOfMonth(subMonths(new Date(), 48)),
      endDate: endOfToday(),
      loading: false,
      firstLoad: true,
      user: [],
      blur: false,
      mobile: false,
      viewDetailsMatch: null,
      viewDetailsModal: false,
      matchTabId: 'tab-summary',

      numShowMatches:15,
      currentPage: 1,
    }
  },

  watch: {
    windowWidth(value, oldValue) {
      if (value <= 760) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },

    startDate(value, oldValue) {
      if (value.getTime() != oldValue.getTime()) {
        this.update();
        this.firstLoad = false
      }
    },

    endDate(value, oldValue) {
      var valueA = new Date(value)
      valueA = valueA.getDate() + '/' + valueA.getMonth() + '/' + valueA.getFullYear()

      var valueB = new Date(oldValue)
      valueB = valueB.getDate() + '/' + valueB.getMonth() + '/' + valueB.getFullYear()

      if (valueA != valueB) {

        this.update()
        this.firstLoad = false
      }
    },

    viewDetailsModal(value, oldValue){
      if(!value){
        this.matchTabId = 'tab-summary'
      }
    }

  },

  computed: {

    ...mapGetters({
        hubspotCompanyOwner: 'hubspotCompanyOwner'
    }),

    showMatches() {
      if (this.selectedFacility === 0) {
        return this.matches
      }

      return this.matches.filter(match => {
        return !!(match.facilitiesSelected && match.facilitiesSelected.includes(this.selectedFacility));

      })
    },

    paginationMatches() {
      const start = this.numShowMatches * (this.currentPage - 1);
      const end = start + this.numShowMatches;
      return this.showMatches.slice(start, end)
    },
    nextDisabled() {
      if(this.currentPage === Math.ceil(this.showMatches.length / this.numShowMatches)){
        return true;
      }
      return false;
    },
    scheduleLink() {
      // check if company owner is Erin
     // if(this.hubspotCompanyOwner && this.hubspotCompanyOwner.firstName && this.hubspotCompanyOwner.firstName === 'Erin'){
     //   return "https://meetings.hubspot.com/erin-bigelow/schedule-your-introductory-call-with-erin-bigelow"
     // }
      // if not Erin, return Kyle's link
      return "https://meetings.hubspot.com/kyle208/schedule-your-introductory-call-with-kyle-rice"
    }
  },

  async mounted() {
    //populate facilities drop down from the vuex store
    this.user = this.$store.getters.user
    this.facilities = this.$store.getters.facilities
    this.facilities.forEach((item) => {
      this.facilitiesIDs.push(item.attributes.facilityId)
    })
    this.$material.locale.dateFormat = "MM/dd/yyyy";
    if (this.windowWidth <= 760) {
      this.mobile = true
    }

    if (this.user.status == 1) {
      await this.update()
    } else {
      this.loading = false
    }
  },

  beforeRouteLeave(to, from, next){
    if(this.mobile && this.viewDetailsModal){
      this.viewDetailsModal = false;
      return next(false);
    }
    return next();
  },

  filters: {
    phone(phoneNumberString){
        if(!phoneNumberString){
          return null;
        }
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return ['(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    },
    date(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'MM/DD/YYYY')
    },
    time(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'hh:mm a')
    },
    day(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'D')
    },
    year(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'YYYY')
    },
    month(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'MMMM')
    },
    dayOfWeek(date){
        const dateString = date.date;
        const localDate = new Date(`${dateString} UTC`);
        return format(localDate, 'dddd')
    },
    matchName(match) {
      // console.log('THE MATCH', JSON.parse(JSON.stringify(match)));
      if(!match)return;
      if (match.firstName.includes('Treatment') || match.lastName.includes('Seeker')) {
        return 'Undisclosed'
      } else {
        return `${match.firstName} ${match.lastName.charAt(0)}.`
      }
    },
    rawMatchName(match) {
        // console.log('THE MATCH', JSON.parse(JSON.stringify(match)));
        if (match.first_name.includes('Treatment') || match.last_name.includes('Seeker')) {
          return 'Undisclosed'
        } else {
          return `${match.first_name} ${match.last_name.charAt(0)}.`
        }
    },
    matchDays(date){
      let parsedDate = parse(date);
      parsedDate = subMinutes(parsedDate, parsedDate.getTimezoneOffset())

      const now = new Date();
      return distanceInWords(parsedDate, now);
    },
    formatHowSoon(time){
      if(time === 'a_few_days'){
        return 'in a few days'
      }else if(time === 'right_now'){
        return "now"
      }else if(time === 'within_a_week'){
        return "within a week"
      }else if(time === 'within_a_month'){
        return "within a month"
      }
      return time;
    },
    formatWhoNeedsTreatment(who){
      if(who === 'myself'){
        return 'for himself/herself'
      }else {
        return 'a loved one'
      }
    },
    formatPay(paymentType){
      if(!paymentType) return null
      if(paymentType === 'self_pay'){
        return 'self pay'
      }else {
        return paymentType.replace(/^\w/, c => c.toLowerCase());
      }
    },
    snakeToSpaced(val){
      if(!val) return null
      val = val.replace('_', ' ')
      return val.charAt(0).toLowerCase() + val.slice(1);
    },
    matchSource(match){
      return 'Match'
    },
    years(date)
    {
      if(!date) return null
      const newDate = parse(date);
      return differenceInYears(new Date(), newDate)
    }
  },
  methods: {
    ...mapActions([
      'fetchMatches',
      'fetchSingleMatch',
      'fetchSingleLead'
    ]),
    async update() {
      if (this.user.status == 1) {
        await this.getFacilityMatches()
      } else {
        this.loading = false
      }

    },

    getDate(date) {
      let endDate = this.endDate.getFullYear() + '-' + (((this.endDate.getMonth() + 1) < 10) ? '0' : '') + (this.endDate.getMonth() + 1) + '-' + ((this.endDate.getDate() < 10) ? '0' : '') + this.endDate.getDate();
      let startDate = this.startDate.getFullYear() + '-' + (((this.startDate.getMonth() + 1) < 10) ? '0' : '') + (this.startDate.getMonth() + 1) + '-' + ((this.startDate.getDate() < 10) ? '0' : '') + this.startDate.getDate();

      return date == 'startDate' ? startDate : endDate
    },

    onChange(event) {
      this.firstLoad = false
      this.$children.find(child => { return child.$options.name === "ActivityZero"; }).updateFacility();
    },

    async getFacilityMatches() {
      this.loading = true;

      try {
        const matchData = await this.fetchMatches({
          facilityIds: this.facilitiesIDs,
          startDate: format(this.startDate, 'YYYY-MM-DD'),
          endDate: this.formatApiEndDate(this.endDate)
        })

        if (matchData && matchData.data) {
          this.matches = matchData.data;
          this.total_matches = matchData.total;
        } else {
          this.matches = []
          this.total_matches = 0;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e)
      }

    },
    changeTab(tab){
      this.matchTabId = null;
      this.$forceUpdate();
      this.matchTabId = tab;

      const gtmEvent = {
        event: 'case-details', // Event type [default = 'interaction'] (Optional)
        category: "Button",
        action: "click",
        label: "Case Details",
        value: 1,
        noninteraction: false, // Optional
      }
      this.$gtm.trackEvent(gtmEvent);
    },

    tabClick(event){
      this.matchTabId = event;
    },

    async viewDetails(match) {
    
     if(match.serviceLoginDataCaptureId){
        this.viewDetailsMatch = await this.fetchSingleMatch(match.serviceLoginDataCaptureId);
     }else{
        this.viewDetailsMatch = await this.fetchSingleLead(match.id);
     }
      this.viewDetailsModal = true;
    },

    trackScheduleCall(){
      const gtmEvent = {
        event: 'schedule-call-now', // Event type [default = 'interaction'] (Optional)
        category: "Button",
        action: "click",
        label: "Schedule Call Now",
        value: 1,
        noninteraction: false, // Optional
      }

      this.$gtm.trackEvent(gtmEvent);
    },
    previousPage() {
      if(this.currentPage > 1){
        this.currentPage--
      }
    },

    nextPage() {
      this.currentPage++;
    }

  }
}

</script>

<style lang="scss" scoped>

.phone{
  white-space: nowrap;
}

 .match .logoTop{
        padding: 5px;
        width: 64px;
        padding-left: 10px;
        padding-top:7px;
  }

.match .ctaCall{
    background-color: #1860e2;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 5px;
}

.md-dialog-container { 
  img{
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
  }
  .md-title{
    font-size: 14px;
    margin-top: 0;
    padding-top: 0;
    text-align: center;
    margin: 0;
    line-height: 18px;
  } 
  .days{
    font-size: 12px;
    color: #646464;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 4px;
    padding-top: 8px;
  }
  .description{
    font-size: 26px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    margin-top: 43px;
    .bold{
      font-weight: 400;
      display: inline;
      padding: 0;
      margin: 0;
    }
    width: 100%;
  }
}

.choice-tabs{
  margin-top: 22px;
  text-align: center;
  img{
    display: block;
    position: relative;
    margin-top: 0;
  }
  .case{
    font-size: 14px;
    margin-top: 28px;
    margin-bottom: 8px;
  }

  #tab-details{
    .description{
      margin-top: 15px;
      padding-bottom: 0px;
    }
    img{
      height: 146px;
    }
    .case{
      margin-top: 0;
      color: #646464;
      padding-bottom: 4px;
      padding-top: 2px;
      line-height: 22px;
      .bold{
        font-weight: 500;
        display: inline;
        padding: 0;
        margin: 0;
      }
    }
    .case.small{
      font-size: 13px;
      margin-bottom: 4px;
    }
    .case.call{
      margin-top: 24px;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 8px;
    }
  }
  .hr{
    width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #ededed;
    margin-top: 12px;
  }
}

.md-button-content{
    font-family: 'Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif';
    color: #646464;
    font-size: 10px!important;
}

.blurItem {
  filter: blur(2px);
}

span {
  width: 100%;
  padding: 0px;
  display: block;
  background: md-get-palette-color(blue, 200);
}

.notice {
  margin-top: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 50%;

  .title {
    font-size: 22px;
    font-weight: 500;
  }

  .intro {
    font-size: 16px;
    font-weight: 300;

  }

  span {
    height: unset;
  }
}

 .dialog-exit a:hover{
        text-decoration: none;
        color: #dbdbdb;
    }

    .dialog-exit .md-icon.md-theme-default.md-icon-font{
        color: #dbdbdb;
        margin-top: 5px;
    }

.md-app-content .md-card {
  margin-left: 0px;
  width: 100%;
  margin-right: 0px;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 18%;
  position: absolute;
  left: 50%;
  margin-left: -40px;
}

.md-app-content {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
  padding: 0px;
}

.white {
  background-color: #fff;
}

.center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.md-field.md-theme-default:after {
  background-color: #DBDBDB;
}

.container {
  width: 100%;
}

.match.md-dialog{
  border-radius: 10px;
  width: 490px;
  padding: 0 25px 2px;
  /*padding-bottom: 2px;*/
  height: 759px;
  min-height: 759px;
  overflow: scroll;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;

  .md-list-item-text{
    font-size: 14px;
  }
  .dialogList{
    padding: 10px;
    padding-bottom: 0px;
  }
  .md-content{
    padding-left:0px;
    text-align: center;
  }
  .md-button{
    margin-top: 7px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .md-button:hover{
    background-color: none!important;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
  }
  .dialog-exit {
    position: absolute;
    right: 15px;
    top: 22px;
    cursor: pointer;
    img{
      margin-right: 8px;
    }
  }
}

.table-nav {
  text-align: right;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-right: 22px
}

 @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
      .md-dialog-container .md-tab {
        padding: 0;
      }
       .match{
         overflow-x: hidden;
         overflow-y: scroll;
         border-radius: 0!important;
       }
    }
    
     @media only screen and (max-width: 1500px) {
            .md-dialog.match{
                top:417px;
            }
        }

     @media only screen and (min-width: 1500px) {
            .md-dialog.match{
                top:417px;
            }
        }

      a{
          color: #0861E7!important;
      }
</style>