<template>
    <div ref="maindiv" id="pricing-main">

        <div class="progress-bar-container">
            <md-progress-bar md-mode="determinate" :md-value="progressValue"></md-progress-bar>
        </div>

        <div id="waiting">
            <p>Searching live databases for market pricing.</p>
        </div>
        <div class="messages">


            <div class="md-layout md-gutter">
                <div class="md-layout-item feature">
                    Exact Match
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 2.25"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">
                    Search Volume
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 5"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">
                    Paid Search Data
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 7"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
            </div>

            <div class="md-layout md-gutter">
                <div class="md-layout-item feature">
                    Related Keywords
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 9"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">
                   Competition Score
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 11.25"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">
                    Ad History
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 13.5"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
            </div>

            <div class="md-layout md-gutter">
                <div class="md-layout-item feature">
                    Organic Search Data
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 15.75"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">
                    Phrase Match Keywords
                    <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate" class="spinner" v-if="second < 17.5"></md-progress-spinner>
                    <md-icon v-else>check</md-icon>
                </div>
                <div class="md-layout-item feature">

                </div>
            </div>
        </div>


<!--        <md-dialog-actions class="next-div">-->
<!--            <md-button-->
<!--                    :disabled="progressValue !== 100"-->
<!--                    class="md-primary md-raised"-->
<!--                    @click="$emit('next')"-->
<!--            >Next</md-button>-->
<!--        </md-dialog-actions>-->
    </div>
</template>

<script>
    export default {
        name: "KeywordScanBody",
        data(){
            return {
                title: "Comparing price",
                progressValue: 0,
                second: 0,
                interval: null,
            }
        },
        mounted(){
            this.interval = setInterval(() => {
                this.change();
            }, 100)
        },
        methods: {
            change(){
                this.second += .1;
                this.progressValue += 100 / 18 * .1;
                if(this.progressValue > 100){
                    this.progressValue = 100;
                    clearInterval(this.interval);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #pricing-main{
        .progress-bar-container{
            padding-top: 25px;
            width: 95%;
            margin-right: auto;
            margin-left: auto;
            .md-progress-bar{
                height: 11px;
                border-radius: 3px;
            }
        }
        #waiting {
            margin-left: 2.5%;
            padding-top: 9px;
            text-align: left;
            font-weight: 400;
             p{
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -0.3px;
            }
        }
        .messages{

            width: 88%;
            margin-right: auto;
            margin-left: auto;
            margin-top: 30px;
            margin-bottom: 20px;

            .feature {
                line-height: 35px;
                text-align: left;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -0.3px;
            }
            .spinner {
                float:right;
                position: relative;
                top: 10px;
                margin-right: 10px;
            }
            .md-icon {
                margin-top:5px;
                color: #3AD612;
                float: right;
            }
        }

        .next-div{
            position: absolute;
            bottom: 36px;
            right: 5%;
        }

        .md-progress-spinner {
            margin-right: 25px;
        }

        .md-icon {
            margin-right: 6px;
        }
    }

   @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #pricing-main .title{
            display: none;
        }       
        .messages{
             .feature {
                 font-size: 14px!important;
             }
        }
   }

</style>

<style lang="scss">
    .messages{
        .md-progress-spinner {
            &.md-indeterminate {
                &.md-progress-spinner-leave-active {
                    transition-duration: 0s;
                    animation: none;
                }
            }
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .compiling-wrapper, .wrapper{
            height: 100%!important;
            border-radius: 0!important; 
            width: 100%!important;
        }

        

    }

</style>