<template>
    <div class="full-control">
        <div class="list">
            <md-list md-expand-single @click="updateMenu()">
                <template v-if="user.role && user.role.slug === 'admin'">
                    <md-list-item to="/premium" class="GoPremiumMenuButton">
                        <span class="md-list-item-text" title="Access Premium">Premium</span>
                        <md-icon v-if="$route.path.match('premium')">keyboard_arrow_down</md-icon>
                        <md-icon v-else>keyboard_arrow_right</md-icon>
                    </md-list-item>
                    <md-list-item v-if="$route.path.match('premium')" to="/premium/forms">
                        <span class="md-list-item-text margin-left" title="View your leads">Forms</span>
                    </md-list-item>
                    <md-list-item v-if="$route.path.match('premium')" to="/premium/clicks">
                        <span class="md-list-item-text margin-left" title="View your Premium Clicks">Clicks</span>
                    </md-list-item>
                    <md-list-item v-if="$route.path.match('premium')" to="/premium/calls">
                        <span class="md-list-item-text margin-left" title="View your Premium Calls">Calls</span>
                    </md-list-item>
                </template>

                <div class="border-top"></div>

                <template v-if="user.role && user.role.slug === 'admissions'">
                    <md-list-item :to="{ name: 'forms.index' }">
                        <span class="md-list-item-text" title="View your leads">Forms</span>
                    </md-list-item>
                    <md-list-item :to="{ name: 'clicks.index' }">
                        <span class="md-list-item-text" title="View your Premium Clicks">Clicks</span>
                    </md-list-item>
                    <md-list-item :to="{ name: 'calls.index' }">
                        <span class="md-list-item-text" title="View your Premium Calls">Calls</span>
                    </md-list-item>
                </template>

                <md-list-item :to="{ name: 'match.index' }">
                    <span class="md-list-item-text" title="View your matches">Match</span>
                </md-list-item>

                <template v-if="user.role && user.role.slug === 'admin'">
                    <md-list-item to="/reviews">
                        <span class="md-list-item-text" title="View your reviews">Reviews</span>
                    </md-list-item>
                    <div class="border-top"></div>

                    <!--  MULTI FACILITY -->
                    <md-list-item :to="{ name: 'bulk.edit' }" v-if="facilities.length > 1" :class="{'router-link-active':  ['general.index', 'listing.admissions-agent', 'contact.index', 'listing.summary', 'listing.overview', 'media.index', 'bulk.edit'].includes($route.name) }">
                        <span class="md-list-item-text" title="View your listings">My Listings</span>
                    </md-list-item>

                    <!--  SINGLE FACILITY -->
                    <md-list-item :to="{ name: 'general.index', params: { facilityId: menuFacility.attributes.facilityId }}" v-if="facilities.length === 1">
                        <span :title="menuFacility.attributes.name" class="md-list-item-text">{{ menuFacility.attributes.name | facilityName}}</span>
                    </md-list-item>
                </template>


            </md-list>
            <span @click="foxy()" title="I'm here to help" class="fox" v-if="!mobile"><img src="/images/owl.svg"/></span>
            <div class="chatBox" v-if="!mobile"><span v-html="bradySpeak"></span> 
                <div class="arrow-left"></div>
            </div>

        </div>
    </div>
</template>

<script lang="javascript">
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'SideNavigation',
        components: {},
        data() {
            return {
                facilities: {},
                selectedFacilityName: '',
                expand: false,
                expandSettings: false,
                expandItems: [],
                bradySpeak: 'Welcome to Rehab.com Connect. Let us know how we can help, by clicking me at any time.',
                showBeds: false,
                mobile:false,
            }
        },
        filters: {
            facilityName(value) {
              if(value.length < 30){
                return value
              }

              return value.substring(0, 30) + '...';

            }
        },
        created() {
            this.facilities = this.$store.getters.facilities
             if( this.windowWidth <= 760 ) {
                this.mobile = true
            }

            if (this.facilities.length == 1) {
                this.expand = true
            }

            if (this.$route.path.match('facility')) {
                this.expand = true
            }

            if (this.$route.path.match('information')) {
                this.expandItems = {"id": this.$route.params.facilityId, "status": true}
            }
            if (this.$route.path.match('settings')) {
                this.expandSettings = true
            }
            if (this.$route.params.facilityId) {
                this.expandItems = {"id": this.$route.params.facilityId, "status": true}
            }
        },
        computed: {
            ...mapGetters({
                user: 'user',
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                enabledCampaigns: 'enabledCampaigns',
                checkCampaignsIsPremium: 'checkCampaignsIsPremium',
                subscriptions: 'subscriptions'
            }),
            campaigns() { // This creates a custom object in which to render the list
                const campaigns = []
                var counter = 1;
                for (let item of this.models) {
                    var campaignItem = {
                        counter: counter++,
                        id: item.id,
                        status: item.attributes.status,
                        budget: item.attributes.budget,
                        facilityId: item.attributes.facilityId,
                        startDate: item.attributes.startDate,
                        productId: item.attributes.productId,
                        interactions: [],
                        cost: '',
                        total: 0
                    }
                    var itemDate = new Date(item.attributes.startDate);
                    let currentDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()));
                    currentDate.setHours(0, 0, 0, 0)
                    campaigns.push(campaignItem)
                }
                return campaigns
            },
            menuFacility() {
                if(this.facilities.length === 1) {
                    return this.facilities[0]
                }else if(this.facilities.length > 1){
                    if(this.$route.params.facilityId){
                        return this.facilities.find(facility => facility.attributes.facilityId == this.$route.params.facilityId)
                    }
                    return null;
                }else {
                    return null;
                }
            }
        },

        watch: {
            '$route.name': {
              handler: function(name) {
                // console.log('THE ROUTE NAME', name)
                if (name === 'reviews.index') {
                // if (this.$route.path.match('reviews')) {
                  this.bradySpeak = 'Reviews bring a voice to patients and providers!'

                // } else if (this.$route.path.match('clicks')) {
                } else if (name === 'clicks.index') {
                  this.bradySpeak = 'Premium clicks increase visitors to your website.'

                } else if (name === 'calls.index') {
                  this.bradySpeak = 'Premium calls provide an efficient way to make new patient connections.'

                } else if (this.$route.path.match('general')) {
                  this.bradySpeak = 'Learn more about our uncompromising commitment to ethics and transparency at <a class="nodecoration" target="_blank" href="http://rehabvalues.com">RehabValues.com.</a>'

                } else if (this.$route.path.match('contact')) {
                  this.bradySpeak = 'Rehab.com is committed to the visibility of all providers, no matter their size or budget.'

                } else if (this.$route.path.match('description')) {
                  this.bradySpeak = 'Join us as we provide those seeking help with unparalleled access to trustworthy treatment information.'

                } else if (this.$route.path.match('media')) {
                  this.bradySpeak = 'Did you know using video and images can help build brand awareness to our 2M+ annual visitors?'

                } else if (this.$route.path.match('feedback')) {
                  this.bradySpeak = 'Have any questions? Fill out the form above and we will get back to you shortly!'

                } else if (this.$route.path.match('premium')) {
                  this.bradySpeak = 'Diversify your marketing and make new patient connections.'

                } else if (this.$route.path.match('match')) {
                  this.bradySpeak = 'Establish new patient connections.'

                } else {
                  this.bradySpeak = 'Let us know how we can help by clicking me at any time.'
                }
              },
              deep: true,
              immediate: true
            }
        },

        mounted() {
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            this.facilities.forEach((item) => {
                if (this.$route.params.facilityId == item.attributes.facilityId || this.facilities.length == 1) {
                    this.selectedFacilityName = item.attributes.name.split(' ').slice(0, 3).join(' ')
                    this.selectedFacilityAddress = item.attributes.city + ', ' + item.attributes.state
                }
            })

            this.expandItemsCheck(this.$route.params.facilityId)
        },
        methods: {
            ...mapActions([
                'fetchCampaigns',
            ]),
            foxy() {
                this.$router.push({name: 'feedback.general'})
            },
            expandItemsCheck(facilityId) {
                if (this.expandItems.id == facilityId && this.expandItems.status == true) {
                    return true
                } else {
                    return false
                }
            },
            updateMenu(){
                this.$parent.$parent.$parent.$parent.showNavigation = !this.$parent.$parent.$parent.$parent.showNavigation;
            },
        }
    }

</script>

<style lang="scss" scoped>

    .fox {
        img {
            height: 40px;
        }

        position: absolute;
        bottom: 0.5%;
        left: 13%;
        margin-left: -15px;
        cursor: pointer;
    }

    .noblue {
        color: #000;
    }

    .md-button {
        margin: 6px 8px;
    }

    .md-menu-content-bottom-start {
        top: 64px;
        right: 0;
    }

    .address {
        font-size: 10px;
        margin-top: 8px;
        position: absolute;
        right: 69px;
        font-style: italic;
    }

    .name {
        margin-top: -10px;
        width: 100%;
        float: left;

    }

    .md-menu-content {
        max-height: unset;
    }

    .margin-left {
        margin-left: 17px;
    }

    .match {
        display: inline;
        padding-top: 4px;
    }

    .italics {

        display: inline-block;
        font-size: 12px;
        padding-left: 8px;
    }

    .titlenav {
        display: inline-block;
    }

    .border-top {
        border-top: 1px solid #d6d5d5;
        opacity: 0.6;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -2px;
        margin-bottom: -2px;
    }

    .router-link-exact-active {
        border-left: 3px solid var(--md-theme-default-primary);

        .md-list-item-text {
            padding-left: 14px;
        }
    }

    .md-list-item:hover {
        background-color: #eee;
    }

    .md-list.md-theme-default .md-list-item-expand.md-active {
        border: none;
    }

    .md-list.md-theme-default .md-list-item-expand {
        border: none;
    }

    .md-list-item-expand {
        transition: none;
        will-change: unset;
    }

    .md-list-item .md-icon {
        position: absolute;
        right: 30px;
        margin-left: 0px;
    }

    .subList {
        margin-left: -69px;

        .md-list-item {
            margin-left: 43px;
        }

        .router-link-exact-active {
            border: none;
            padding-left: 3px;
        }

        .md-list-item-expand {
            width: 316px;
        }
    }

    .router-link-exact-active .md-list-item-text {
        padding-left: 17px;
    }

    /* .chatBox{
         background-color: #fff;
         height: 59px;
         width: 162px;
         left: 66px;
         position: absolute;
         bottom: 70px;
         padding: 10px;
         color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87));
         box-shadow: 0 3px 1px -4px rgba(0, 0, 0, 0.9), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
     }
 */


    .chatBox {
        position: absolute;
        bottom: 6px;
        padding: 1em;
        left: 80px;

        width: 200px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 1px -4px rgba(0, 0, 0, 0.9), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
    }


    .chatBox::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -14px;;

        left: 0;
        box-sizing: border-box;

        border: 1em solid black;
        border-color: transparent transparent #fff #fff;


        zoom: 0.5;
        transform: rotate(45deg) !important;


        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.12)
    }

    .arrow-left {
        display: none;
    }


</style>