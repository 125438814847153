<template>
     <div v-if="firstTimeEditAdmin" class="md-dialog tooltip">

        <template v-if="step==1">
            <div class="container step1" id="receiving-tooltip">
                <div class="close" @click="closeTooltip()"><md-icon>close</md-icon></div>
                <md-dialog-title class="title">Confirm your receiving number and website</md-dialog-title>
                <md-dialog-content>
                    <span>Please take a moment to confirm your receiving number and landing page url below.</span>
                    <ul>
                      <li><strong>A receiving number</strong> is a phone number that Rehab.com will forward calls to when a user calls a tracking number. It can be a mobile number, a landline, or the number for your call center.
</li>
                        <li><strong>A landing page</strong> is a page on your website where users land after clicking a URL from your listing.</li>
                    </ul>    
                </md-dialog-content>
                <div class="arrow-down"></div>
                <span v-if="steps>step" class="steps">{{ step }}/{{ steps }}</span>
                <md-dialog-actions>
                    <!-- <md-button class="secondary" v-if="step<=4 && step>1" @click="step--">Back</md-button>
                    <md-button class="active" v-if="step<4" @click="step++">Next</md-button> -->
                    <md-button class="active" v-if="step==steps" @click="closeTooltip()">Got It</md-button>
                </md-dialog-actions>
            </div>
        </template>
       
     </div>
</template>

<script lang="javascript">

    import config from '../../config.js'
    import {mapGetters, mapActions} from 'vuex'

    export default{
        name: 'ReceivingTooltip',
        data(){
            return {
                selectedFacility: 0,
                config: config,
                firstTimeEditAdmin: false,
                step:1,
                steps:1
            }
        },
        computed: {
            ...mapGetters([
                'verifiedPhoneOrUrls',
                'receivingTooltip',
                'goPremiumFunnelCompleteThisLogin',
                'goPremiumFunnelTooltip',
                'enabledCampaigns',
                'campaigns',
                'receivingTooltipViewed'
            ])
        },
        mounted() {
            if(this.verifiedPhoneOrUrls && this.goPremiumFunnelCompleteThisLogin){
               if(this.verifiedPhoneOrUrls.length < this.enabledCampaigns.length){
                    this.firstTimeEditAdmin = true
                }
                this.$store.commit('SetReceivingTooltip', true)
            }
           
          

           this.$root.$on('receivingTooltip', () => {
               // console.log('JUST GOT EMITTED');
               if(this.verifiedPhoneOrUrls.length < this.enabledCampaigns.length && !this.receivingTooltipViewed){
                   this.firstTimeEditAdmin = true
               }
            })
        },
        methods: {
            closeTooltip(){
                this.firstTimeEditAdmin = false
                this.$store.commit('SetReceivingTooltip', false)
                if(!this.goPremiumFunnelTooltip){
                    //this.$root.$emit('GoPremiumTooltipFunnel')
                }
                this.$store.commit('setReceivingTooltipViewed')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        width: 90%;
    }
    
    .md-dialog {
        overflow: visible;
        top: -20px;
        left: 70px;

        .container{
            width: 440px;
            height: 336px; 
            background-color:var(--md-theme-default-primary);
            color: #fff;
            padding:4px;
            padding-bottom: 10px;
            text-align: left;
            overflow: visibe;
            padding-top: 22px;
            overflow: visible;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

            .md-dialog-actions{
                position: absolute;
                right: 0px;
                top: 280px;
            }
           
            .md-dialog-title{
                float: left;
                padding-top: 0px;
            }
        }

        .close{
            color: #1E152A;
            position: absolute;
            right: 20px;
            top:18px;
            font-size: 24px;
            .md-icon{
                color: #fff;
            }
            :hover{
                cursor: pointer;
            }
        }
        .md-dialog-content a{
            color: #fff;
        }
    
        .steps{
            padding-left: 24px;
            position: absolute;
            top:174px;
        }
        .active{
            color: #fff;
        }
        .secondary{
            color: #9fc1f9;
        }
        .md-dialog-actions{
            padding-right: 14px;
        }
        .arrow-up {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-bottom: 5px solid var(--md-theme-default-primary);
        }
        .arrow-down {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            
            border-top: 10px solid var(--md-theme-default-primary);
            margin-left: 200px;
            position: absolute;
            top: 336px;
        }
        .arrow-right {
            width: 0; 
            height: 0; 
            border-top: 60px solid transparent;
            border-bottom: 60px solid transparent;
            
            border-left: 60px solid var(--md-theme-default-primary);
            
        }
        .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            
            border-right: 10px solid var(--md-theme-default-primary);
            margin-left: -14px;
            position: absolute;
            top: 31px;
        }
      
         .step1{
            top: 158px;
            left: 10px;
            position: absolute;
            z-index: 6;
            .arrow-left{
                top: 54px;
            }
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .md-dialog {
            overflow: visible;
            top: -20px;
            left: 0px;
            z-index: 100000;
            .step1{
                width: 95%;
            }
        }

        .md-dialog .container{
            height: 384px;
        }
        .md-dialog .container .md-dialog-actions{
            top: 330px;
        }
        .md-dialog .step1{
            top: 165px;
        }
        .md-dialog .arrow-down {
            margin-left: 172px;
            top: 384px;
        }

    }
    @media only screen and (max-width: 321px) and (max-height: 568px) and (-webkit-device-pixel-ratio: 2){
      .md-dialog .step1 {
        top: 148px;
        height: 400px;
      }
      .md-dialog .arrow-down {
        top: 400px;
      }
    }


</style>