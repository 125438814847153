<template>
  <div>
    <failed-payment-banner></failed-payment-banner>
    <div class="page-container">

      <div class="md-layout">

        <div class="md-layout-item" :class="{ 'md-size-80':!mobile, 'md-size-100':mobile}" v-show="!showSpinner">
          <div class="md-layout balance">
            <div class="md-layout-item md-size-100">
              <md-card>
                <md-card-header class="header">
                  <div class="md-title">Your balance</div>
                  <div class="md-headline">{{ balance | formatPrice }}</div>
                  <div class="md-caption" v-if="balance<=0">No balance due</div>
                  <div class="md-subtitle" v-if="balance<=0">You will not be charged until there is a balance due.</div>
                  <div class="md-subtitle" v-if="balance>0">You will be charged when budget is met or every month, whichever comes first.</div>
                </md-card-header>
                <md-card-content>
                  <md-progress-bar md-mode="buffer" :md-value="progressBalance" :md-buffer="100"></md-progress-bar>
                  <div class="md-caption" v-if="periodBalance==0 && Number(budget) >0">Your entire {{budget | formatPrice}} budget threshold is available.</div>
                  <div class="md-caption" v-if="periodBalance>0 && Number(budget) >0">You have {{  Number(budget) - Number(periodBalance) | formatPrice }} budget remaining.</div>
                  <div class="md-caption" v-if="Number(budget)==0">You do not have any active campaigns.</div>
                </md-card-content>
                <md-card-actions>
                  <md-button
                      class="md-raised md-primary"
                      :disabled="paymentButtonDisabled"
                      @click="paymentDialogActive = true"
                  >Make a payment</md-button>
                </md-card-actions>
              </md-card>
            </div>
          </div>
          <div class="md-layout transactions">
            <div class="md-layout-item md-size-49 left" :class="{ 'md-size-100': mobile }">
              <md-card>
                <md-card-header class="header">
                  <div class="md-title">Transactions</div>
                </md-card-header>
                <md-card-content class="content">
                  <div v-if="payments && payments.length==0" class="md-subtitle">You do not have any transactions yet.</div>
                  <table v-if="payments && payments.length">
                    <tr class="transaction"
                        v-for="(item, index) in payments.slice(0, 3)"
                        v-bind:key="item.id"
                        @click="$router.push({name: 'settings.payment-receipt', params: {paymentId: item.id}})"
                        style="cursor: pointer"
                        v-if="index <4"
                    >
                      <td>{{ new Date(item.date) | dateFilter}}</td>
                      <td>{{ item | paymentStatus}}</td>
                      <td class='right'>{{ item.amount | formatPrice }}</td>
                    </tr>
                  </table>
                </md-card-content>
                <div class="hr"></div>
                <md-card-actions>
                  <md-button :to="{name: 'settings.transactionslist'}" v-if="payments && payments.length !== 0" class="md-primary">View transactions</md-button>
                  <md-button v-else disabled>View transactions</md-button>
                </md-card-actions>
              </md-card>
            </div>
            <div class="md-layout-item md-size-50 payment" :class="{ 'md-size-100': mobile }">
              <md-card>
                <md-card-header class="header">
                  <div class="md-title">How you pay</div>
                </md-card-header>
                <md-card-content class="content">
                  <div v-if="defaultPaymentMethod">
                    <div v-if="defaultPaymentMethod.paymentMethod === 'Credit Card'">
                      <md-icon class="md-size-4x">credit_card</md-icon>
                      <div class="card-info">
                        <p class="cc-header">{{defaultPaymentMethod.type}} •••• {{defaultPaymentMethod.endNumbers}}</p>
                        <p>Expires: {{defaultPaymentMethod.expireDate | ccDate}}</p>
                      </div>
                    </div>
                    <div v-else-if="defaultPaymentMethod.paymentMethod === 'Bank Account'">
                      <md-icon class="md-size-4x">account_balance</md-icon>
                      <div class="card-info">
                        <p class="cc-header">Bank •••• {{defaultPaymentMethod.endNumbers}}</p>
                      </div>
                    </div>

                  </div>
                  <div v-else>
                    <div class="md-subtitle">You do not have any payment methods set up.</div>
                  </div>
                  <!--{{creditCards}}-->
                </md-card-content>
                <div class="hr"></div>
                <md-card-actions>
                  <md-button :to="{name: 'settings.payment-methods'}" class="md-primary">Manage payment methods</md-button>
                </md-card-actions>
              </md-card>
            </div>
          </div>

          <!---<div class="md-layout transactions" v-if="subscriptions.length>0">
              <div class="md-layout-item md-size-50 left">
                  <md-card>
                      <md-card-header class="header">
                          <div class="md-title">Subscription</div>
                      </md-card-header>
                      <md-card-content class="content">
                          <div class="md-subtitle">View and manage your subscription settings</div>
                      </md-card-content>
                      <div class="hr"></div>
                      <md-card-actions>
                          <md-button :to="{name: 'settings.subscriptionsList'}" class="md-primary">VIEW SUBSCRIPTIONS</md-button>
                      </md-card-actions>
                  </md-card>
              </div>
          </div>-->
        </div>
      </div>
      <md-dialog-confirm
          :md-active.sync="paymentDialogActive"
          md-title="Make a payment"
          :md-content="`Please confirm that you would like to make a payment of <strong>$${balance}</strong>`"
          md-confirm-text="Make Payment"
          md-cancel-text="Cancel"
          @md-cancel="paymentDialogActive = false"
          @md-confirm="makeAPayment()"
      />

      <md-snackbar
          md-position="center"
          :md-duration="Infinity"
          :md-active.sync="showSnackbar"
          md-persistent
      >
        <span>{{ snackMsg }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
      </md-snackbar>

      <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="showSpinner"></md-progress-spinner>
    </div>
  </div>

</template>

<script lang="javascript">

    import config from '../config.js'
    import {mapGetters, mapActions} from 'vuex'
    import {format, subDays, startOfToday, startOfTomorrow, startOfDay, isBefore, isAfter, getDate, subMonths, setDate, isSameDay, parse} from 'date-fns'
    import FailedPaymentBanner from "./Payments/FailedPaymentBanner"

    export default {
        components: {
          FailedPaymentBanner
        },
        name: 'Settings',
        data() {
            return {
                config: config,
                payments: [],
                interactionCosts: [],
                calls: [],
                latestDate: null,
                balance: 0,
                periodBalance: 0,
                progressBalance: 0,
                balanceCalls: 0,
                balanceMatches: 0,
                campaigns: [],
                clicks: [],
                balanceClicks: 0,
                budget: 0,
                account: [],
                paymentDialogActive: false,
                showSnackbar: false,
                snackMsg: '',
                showSpinner:false,
                mobile: false
            }
        },
        filters: {
            dateFilter(date) {
                return format(date, 'MM/DD/YYYY')
            },
            ccDate(dateString) {
                return dateString.slice(5, 7) + '/' + dateString.slice(2, 4);
            },
            formatPrice(value) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                })
                return formatter.format(value)
            },
            paymentStatus(payment) {
                if(payment.success == true){
                    return "Paid"
                } else if ((!payment.paymentTerm || (payment.paymentTerm && payment.paymentTerm.slug === 'receipt')) && !payment.success) {
                    return "Failed"
                } else if (payment.paymentTerm && payment.paymentTerm.slug.includes('invoice')){
                    return "Invoice Sent"
                }
            }
        },
        beforeRouteEnter(to, from, next){
            next(async vm => {
                await vm.fetchCampaigns()
            })
        },
        async created() {
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }

            this.showSpinner = true;
            // FETCH DATA
            const promises = [];
            promises.push(this.getPayments())
            promises.push(this.addCampaigns())
            promises.push(this.$store.dispatch('fetchDefaultPaymentMethod'))
            const results = await Promise.all(promises);
            this.payments = results[0];

            await this.update()
            this.showSpinner = false;


        },
        mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        },
        computed: {
            ...mapGetters({
                defaultPaymentMethod: 'defaultPaymentMethod',
                user: 'user',
                // calls: 'allFacilityCalls'
                calculateBudget: 'calculateBudget',
                subscriptions:  'subscriptions',
                originalCampaigns: 'campaigns',
                callProduct: 'callProduct',
                clickProduct: 'clickProduct',
                getFacilityPeriodStartDate: 'getFacilityPeriodStartDate',
            }),

            paymentButtonDisabled() {
                // console.log('THE USER', this.user);
                if(Number(this.balance)===0){
                    return true
                }
                if(this.user.account.free && parse(this.user.account.free) > new Date){
                    return true
                }
                return false
            }
        },
        methods: {
            ...mapActions([
                'fetchCallTrackingMetricsApi',
                'fetchCampaigns',
                'makePayment',
                'fetchSubscriptions',
                'fetchFacilityClicks',
                'fetchAccountTotal',
                'getPayments'
            ]),

            // Create custom object for current campaigns
            addCampaigns() {
                // console.log('ORIGINAL CAMPAIGNS', JSON.parse(JSON.stringify(this.originalCampaigns)));
                for(const campaign of this.originalCampaigns) {
                    var campaignItem = {
                        id: campaign.id,
                        status: campaign.attributes.status,
                        budget: '$' + campaign.attributes.budget,
                        facilityId: campaign.attributes.facilityId,
                        startDate: campaign.attributes.startDate,
                        productId: campaign.attributes.productId,
                    }
                    if (campaign.attributes.status) {
                        this.budget += Number(campaign.attributes.budget)

                    }
                    var itemDate = parse(campaign.attributes.startDate);
                    let campaignStartDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()));
                    campaignStartDate.setHours(0, 0, 0, 0)
                    // Start date is set to start at campaign StartDate OR from latest payment which you can see on line 350 ish
                    this.campaigns.push(campaignItem)
                }

                // console.log('addCampaigns done 1')
            },


            async update() {
                const totalsData = await this.fetchAccountTotal();
                // console.log('totals data', totalsData);

                this.balanceCalls = totalsData.callTotal;
                this.balanceClicks = totalsData.clickTotal;
                this.balance = totalsData.total;
                this.periodBalance = totalsData.total;
                this.periodBalance = this.balance;

                if(totalsData.periodTotal) {
                    this.periodBalance = totalsData.periodTotal
                }

                // console.log('PERIOD BALANCE', this.periodBalance);
                // console.log('BUDGET', this.budget);

                this.progressBalance = (this.periodBalance * 100) / this.budget

                // console.log('PROGRESS BALANCE', this.progressBalance);
            },

            async makeAPayment(){
                try{
                    this.showSpinner = true;
                    const payment = await this.makePayment()
                    //console.log('PAYMENT COMPLETE', payment);
                    this.payments = await this.getPayments()
                    //console.log('GOT PAYMENTS', this.payments);
                    await this.update();
                    this.snackMsg = 'Payment successful'
                    this.showSnackbar = true;
                    this.showSpinner = false;
                }catch (e) {
                    this.showSpinner = false;
                    console.error('ERROR MAKING PAYMENT', e.response);
                    this.snackMsg = e.message

                    if(e.response && e.response.data && e.response.data.message)
                        this.snackMsg = e.response.data.message

                    this.showSnackbar = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .md-progress-bar {
        height: 14px;
    }
    .balance {
        .md-card{
            padding-right: 24px;
            padding-left: 10px;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        .md-card-actions{
            padding-right: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .md-card-content{
           padding-right: 0px;
        }
        .md-caption{
            padding-top: 8px;
        }
        .md-subtitle{
            padding-top: 8px;
        }
        .md-accent{
            margin-top: 30px;
        }
        .md-headline{
            position: absolute;
            right: 24px;
            top:30px
        }
        .header .md-caption{
            position: absolute;
            right: 24px;
            top:52px
        }
    }

    .transactions{
       
        .md-card{
            padding-bottom: 10px;
            margin-bottom: 20px;
            min-height: 240px;
        }
        .content{
            min-height: 100px
        }
        .md-layout-item.left{
            float: left;
        }
        .content{
            width: 100%;
            position: relative;
            display: inline-block
        }
        .payment-results{
            display: inline-flex;
            width: 100%;
        }
        table{
            width: 100%
        }
        table tr{
            text-align: left
        }
        .right{
            text-align: right
        }
        .md-card-content{
            min-height: 120px;
            display: block;
        }
        .md-card-actions{
            padding-right: 20px;
            padding-top: 8px;
            padding-bottom: 0px;
        }
    }
    .payment{
        .md-card-content {
            .card-info{
                display:inline-block;
                margin-left: 10px;
                .cc-header{
                    font-weight: bold;
                }
                p{
                    margin: 2px 0px 0;
                }  
            }
        } 
        .md-card-actions{
            padding-right: 20px;
            padding-top: 8px;
            padding-bottom: 0px;
        }
    }
    .hr{
        border-bottom:1px solid #eee;
    }
    #paymentForm .__PrivateStripeElement-input{
        width: 400px;
    }
    .md-app-content .md-card{
        margin-left: 11px;
        margin-right: 11px;
    }

    .spinner{
        top:37%;
        margin-top: -50px;
        left: 50%;
        margin-left: -40px;
        position: absolute;
        z-index: 999;
    }
    .blur{
        filter: blur(1px);
    }
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .md-subtitle{
            font-size: 14px;
            margin-top: 20px;
        }
        .transactions .md-card{
            min-height: 100px;
        }
        .transactions .md-card-content{
            min-height: 60px;
        }

        .balance .md-headline{
            top: 26px;
        }
        .balance .header .md-caption{
            top:46px;
        }
    }
   
</style>