import config from '../../config'
const apiUrl = config.apiUrl;
import axios from 'axios'
import {format} from "date-fns";

export default {
  state: () => ({
    welcomeTooltip: false,
    GoPremiumTooltip: false,
    blur: false
  }),
  actions: {

  },
  mutations: {
    setWelcomeTooltip(state, status){
      state.welcomeTooltip = status;
    },
    setBlur(state, status){
      state.blur = status;
    },
    setGoPremiumTooltip(state, status){
      state.GoPremiumTooltip = status;
    }
  },
  getters: {
    welcomeTooltip: (state) => state.welcomeTooltip,
    blur: (state) => state.blur,
    GoPremiumTooltip: (state) => state.GoPremiumTooltip
  }
}