<template>
    <form novalidate @submit.prevent="attemptSubmit" v-if="!loading">
        <md-card>
            <md-card-content class="cardContent">
                <div class="md-layout md-gutter md-size-100">
                    <div class="md-layout-item" :class="{'md-size-100': bulk}">
                        <md-field :class="{'md-invalid': showMdInvalidClass($v.form.overview, submitted)}">
                            <label v-if="!mobile && !bulk">Overview</label>

                            <textarea
                                    :rows="inputRows"
                                    class="textArea"
                                    v-model="form.overview"
                                    id="facility-philosophy"
                                    placeholder="Write an overview about your program."
                                    name="facility-philosophy"
                                    value="form.overview"
                            ></textarea>

                            <!-- CHARACTER COUNTER-->
                            <span v-if="form.overview" class="counter">{{form.overview.length}}/{{maxLength | formatNumber}}</span>
                            <span v-else class="counter">0/{{maxLength | formatNumber}}</span>

                            <span class="md-error textarea-error"><md-icon class="error-icon">error</md-icon> A minimum of 35 characters is required.</span>


                        </md-field>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}" v-if="bulk">
                <md-button
                        v-if="bulk"
                        class="customGrey"
                        @click.prevent="$emit('cancel')"
                >Cancel</md-button>
                <md-button
                        v-if="bulk"
                        class="md-raised md-primary"
                        type="submit"
                        :disabled="form.overview === originalForm.overview"
                >Save</md-button>
            </md-card-actions>
        </md-card>
    </form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { mapGetters, mapActions } from 'vuex'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import { noUrls, noPhoneNumbers } from '../../../helpers/customValidations'
    import customValidationMixin from '../../../mixins/validationMixin'

    export default {
        name: "OverviewForm",

        data() {
            return {
                form: {
                    overview: null
                },
                submitted: false,
                maxLength: 10000,

            }
        },

        props: {
            loading: {
                type: Boolean,
                default: false,
            },

            originalForm: {
                type: Object,
                default: function() {
                    return {
                        overview: null,
                    }
                }
            },

            bulk: {
                type: Boolean,
                default: false
            },

            inputRows: {
                type: Number,
                default: 6
            }
        },

        computed: {
            mobile() {
                return this.windowWidth <= 760;
            }
        },

        watch: {
            "form.overview"(newValue, oldValue) {
                if(this.form.overview === this.originalForm.overview){
                    this.$emit('enableSubmit', false)
                }else {
                    this.$emit('enableSubmit', true);
                }
            }
        },

        mixins: [validationMixin, customValidationMixin],

        filters: {
            formatNumber(num){
              return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        validations: {
            highlights: {
                required,
                min: minLength(1),
                $each: {
                    content:{
                        required,
                        min: minLength(150),
                        noUrls,
                        noPhoneNumbers
                    }
                },
            },
            form:{
                overview: {required, min: minLength(35), max: maxLength(10000)}
            },
            descriptionForm: {
                facilityDescription: {required, min: minLength(20)}
            }
        },

        mounted() {
            this.form = Object.assign(this.form, JSON.parse(JSON.stringify(this.originalForm)))
        },

        methods: {
            validateForm() {
                this.submitted = true;
                this.$v.form.$touch();
                if(this.$v.form.$error) {
                    console.error('VALIDATION ERROR', this.$v.descriptionForm)
                    return false
                }
                return true;
            },
            attemptSubmit() {
                if(this.validateForm()) {
                    this.$emit('submit', this.form);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
        box-shadow: none;
        padding-top: 10px;
        margin-left: 2px;
    }

    .actions {
        width: 599px;
        padding-top: 20px;
        margin-right: 0;
        margin-top: 18px;
        padding-right: 2px;
        .md-primary{
            margin-left: 10px;
        }
    }
    .actionsBulk{
      margin-right: 2px;
      margin-top: 12px;
      padding-right: 20px;
    }
      .md-tabs .md-card{
        border: none;
    }

    .reload{
        padding-top:11px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    .md-field {
        label {
            top: 0 !important
        }
        textarea {
            margin-left: 100px;
            font-size: 15px;
            font-family: inherit;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 5px;
            color: #43484f;
            width: 599px;
            height: 400px;
            resize: none;
        }
        textarea:focus {
            outline: none;

        }

        .counter {
          color: #8C9296;
          position: absolute;
          right: 5px;
          bottom: 0px;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
        }
    }
    textarea{
        -webkit-appearance: none;
    }

    .bulk-edit-modal{
         .md-field {
            textarea {
            height: 350px;
            }
        }
    }

    .bulk-admin {
      .md-field {
        textarea {
          margin-top: -3px;
          margin-left: 0;
          width: 556px;
          height: 185px;
        }
        .counter{
          right: 20px;
        }
      }

    }

    #overview-tab .md-error.textarea-error{
        left: 101px!important;
        top: 376px!important;
    }

    .md-invalid > label{
      color: rgba(0, 0, 0, 0.87) !important;
    }

    .md-invalid {
      textarea {
        border-color: rgb(238, 238, 238) !important;
      }
      .counter {
        color: #d93025;
      }
    }
    

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #overview-tab .md-error.textarea-error{
            left: 0px!important;
            top: 434px!important;       
        }
    }

    @media(max-width: 992px) {
        .counter {
          bottom:5px !important;
        }
    }

</style>
<style>
    #overview-modal.bulk-admin .md-error{
        left: 0px!important;
        top: 358px!important;
        margin-left: 0px;
    }
</style>