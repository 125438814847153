<template>
    <div>
        <div v-if="!loading">

            <summary-form
                    v-if="$route.name === 'listing.summary'"
                    ref="summaryForm"
                    :loading="loading"
                    :original-form="descriptionForm"
                    :summary-placeholder="summaryPlaceholder"
                    :input-rows="bulk ? 6 : 8"
                    :bulk="bulk"
                    @submit="attemptSubmitSummary($event)"
                    @enableSubmit="enableSubmit = $event"
            ></summary-form>

            <overview-form
                    ref="overviewForm"
                    v-if="!loading && $route.name === 'listing.overview'"
                    :loading="loading"
                    :original-form="overviewForm"
                    :input-rows="bulk ? 6 : 8"
                    :bulk="bulk"
                    @submit="attemptSubmitOverview($event)"
                    @enableSubmit="enableSubmit = $event"
            ></overview-form>

            <md-card>
                <md-card-actions class="actions">
                    <md-button
                            :disabled="!enableSubmit"
                            class="md-raised md-primary"
                            @click="save()"
                    >Save</md-button>
                </md-card-actions>
            </md-card>


            <md-snackbar
                    md-position="center"
                    :md-duration="Infinity"
                    :md-active.sync="showSnackbar"
                    md-persistent
            >
                <span>{{ snackMsg }}</span>
                <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
            </md-snackbar>
        </div>

        <md-progress-spinner md-mode="indeterminate" class="spinner" v-if="loading"></md-progress-spinner>
    </div>

</template>

<script lang="javascript">
    import SummaryForm from "./Forms/SummaryForm";
    import OverviewForm from "./Forms/OverviewForm";
    import config from '../../config.js'
    // import { validationMixin } from 'vuelidate'
    import { mapGetters, mapActions } from 'vuex'
    // import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import { noUrls, noPhoneNumbers } from '../../helpers/customValidations'
    import customValidationMixin from '../../mixins/validationMixin'

    export default{
        name: 'Description',
        components: {SummaryForm, OverviewForm},
        data(){
            return {
                facilityId: null,
                config: config,
                errors: null,
                highlights: [],
                overviewForm: {
                    overview: null,
                },
                descriptionForm: {
                    facilityDescription: null
                },
                descriptionFormSubmit: false,
                highlightSubmit: false,
                originalDescription: null,
                originalOverview: null,
                originalHighlights: null,
                facilityDataChildNames: [],
                facilityTherapyPrograms: [],
                facilityAdmissionsData: [],
                philosophyChildNames: [],
                generalData: [],
                users: [],
                loading: false,
                showSnackbar: false,
                snackMsg: '',
                facilities: [],
                facility: {},
                treatmentFocuses: [],
                treatmentFocusOptions: [],
                descriptionPendingUpdates: false,
                overviewPendingUpdates: false,
                tabId: 'summary',
                enableSubmit: false
            }
        },
        computed: {
            ...mapGetters([
                'generalPageInfo',
                'getFacilityType'
            ]),
            facilityType() {
                if(this.$route && this.$route.params){
                  return this.getFacilityType(this.$route.params.facilityId);
                }

                return ('addiction_rehab')

            },
            summaryPlaceholder(){
                if(!this.bulk) {
                    if(this.facilityType.slug === 'addiction_rehab'){

                        let focusList = this.treatmentFocuses.filter(focus => focus.sectionName && focus.sectionName.length);
                        focusList = focusList.map(focus => focus.sectionName)
                        let focuses = this.stringifyList(focusList)
                        let placeholder = `${this.facility.name} is a rehab facility located in ${this.facility.city}, ${this.facility.state}. ${this.facility.name} specializes in the treatment of ${focuses}.`

                        return placeholder
                    } else if(this.facilityType.slug === 'physical_therapy'){
                        const specialtyList = this.generalData.originalSpecialties.data.map(specialty => specialty.sectionName);
                        let specialties = this.stringifyList(specialtyList.slice(0,3));
                        let treatments = this.stringifyList(this.facilityTherapyPrograms.map(program => program.sectionName).slice(0,3));
                        let program = this.stringifyList(this.facilityAdmissionsData && this.facilityAdmissionsData.data ? this.facilityAdmissionsData.data.map(admissions => admissions.sectionName).slice(0,1) : [])

                        return `${this.facility.name} is a ${specialties} clinic located in ${this.facility.city}, ${this.facility.state}, that provides a ${program} including ${treatments}.`


                    }

                }
                return ''
            },
        },

        props: {
            bulk: {
                type: Boolean,
                default: false,
            },
            selectedFacilities: {
                type: Array,
                default: () => []
            }
        },

        watch: {
            loading(newVal, oldVal) {
                this.$emit('loading', newVal);
            },
            '$route.params.facilityId'(newId, oldId) {
                if(newId !== oldId){
                    this.loadData()
                }
            }

        },

        async created(){
            if(!this.bulk) {
                await this.loadData();
            }

        },
        methods: {
            ...mapActions([
                'updateAdminItem',
                'deleteAdminItem',
                'createAdminItem',
                'fetchFacilityOverviewInfo',
                'fetchFacilityDataChildNames',
                'fetchFacilityDescription',
                'fetchFacilityTreatmentFocuses',
                'deleteContentUpdate',
                'fetchDescriptionPageInfo',
                'updateFacilitySummary',
                'updateFacilityOverview',
                'loadFacilityGeneralPageInfo',
                'fetchFacilityTherapyPrograms',
                'fetchFacilityAdmissions'
            ]),
            async loadData() {
                this.loading = true;
                this.users = this.$store.getters.user
                this.facilities = this.$store.getters.facilities
                this.facilities.forEach((item) => {
                    if(this.$route.params.facilityId == item.attributes.facilityId){
                        this.facility = item.attributes
                    }
                })
                this.facilityId = this.$route.params.facilityId



                // GET DATA FROM STORE
                const data = await this.fetchDescriptionPageInfo(this.facilityId);
                this.generalData = this.generalPageInfo(this.facilityId)
                // console.log('GENERAL DATA', JSON.parse(JSON.stringify(this.generalData)))

                if(this.facilityType.slug === 'physical_therapy') {
                    if (!this.generalData) {
                        await this.loadFacilityGeneralPageInfo(this.facilityId)
                        this.generalData = this.generalPageInfo(this.facilityId)
                    }
                    this.facilityTherapyPrograms = await this.fetchFacilityTherapyPrograms(this.facilityId);
                    this.facilityAdmissionsData = await this.fetchFacilityAdmissions(this.facilityId)
                    // console.log('THERAPY PROGRAMS', this.facilityTherapyPrograms ? JSON.parse(JSON.stringify(this.facilityTherapyPrograms)): null);
                    // console.log('FACILITY ADMISSIONS DATA', this.facilityAdmissionsData ? JSON.parse(JSON.stringify(this.facilityAdmissionsData)): null);
                }
                // console.log('OVERVIEW DATA', JSON.parse(JSON.stringify(data)))
                this.facilityDataChildNames = data.facilityDataChildNames;
                this.philosophyChildNames = data.philosophyChildNames;
                this.overviewForm = data.overviewForm;
                this.descriptionForm = data.descriptionForm;
                this.originalOverview = data.originalOverview;
                this.originalHighlights = data.originalHighlights;
                this.highlights = data.highlights;
                this.origfacilityDescription = data.origfacilityDescription;
                this.treatmentFocuses = data.treatmentFocuses;
                this.descriptionPendingUpdates = data.descriptionPendingUpdates;
                this.overviewPendingUpdates = data.overviewPendingUpdates;

                // SHOW SUMMARY PLACEHOLDER IF NO DATA.
                if(!this.descriptionForm.facilityDescription){
                    this.descriptionForm.facilityDescription = this.summaryPlaceholder
                }

                // if(this.descriptionPendingUpdates || this.overviewPendingUpdates){
                //     this.showPendingReviewSnackbar()
                // }

                this.loading = false
            },


            async save() {
                  if(this.$route.name === 'listing.summary'){
                      const summaryFormValidated = this.$refs.summaryForm.validateForm();
                      if(summaryFormValidated){
                          this.$refs.summaryForm.attemptSubmitSummary();
                      }
                  }

                  else {
                      const overviewFormValidated = this.$refs.overviewForm.validateForm();
                      if(overviewFormValidated){
                          this.$refs.overviewForm.attemptSubmit();
                      }
                  }
            },

            async attemptSubmitSummary(formData){
                try {
                    this.loading = true

                    let facilityIds = [this.$route.params.facilityId]
                    if(this.bulk){
                        facilityIds = this.selectedFacilities;
                    }

                    for(const facilityId of facilityIds){
                        await this.updateFacilitySummary({
                            facilityId: facilityId,
                            facilityDescription: formData.facilityDescription
                        })
                    }

                    if(!this.bulk){
                        await this.loadData();
                        this.descriptionPendingUpdates = true;
                    }

                    this.$emit('close')
                    this.showPendingReviewSnackbar()

                }catch (e) {
                    console.error(e);
                    this.loading = false
                    this.showSnackbar = true
                    this.snackMsg = "There was an error submitting the description. Please contact support.";
                }
            },

            async attemptSubmitOverview(formData) {
                try {
                    this.loading = true;

                    let facilityIds = [this.$route.params.facilityId]
                    if(this.bulk){
                        facilityIds = this.selectedFacilities;
                    }

                    for(const facilityId of facilityIds){
                        await this.updateFacilityOverview({
                            facilityId,
                            formData
                        });
                    }

                    if(!this.bulk){
                        await this.loadData();
                        this.overviewPendingUpdates = true;
                    }

                    this.$emit('close')
                    this.showPendingReviewSnackbar()
                } catch (e) {
                    console.error(e);
                    this.loading = false
                    this.showSnackbar = true
                    this.snackMsg = "There was an error submitting the form. Please contact support.";
                }
            },


            showPendingReviewSnackbar(){
                this.snackMsg = "Your updates are pending review. They will go live once they have been reviewed."
                this.showSnackbar = true;
            },

            stringifyList(items){
              let string = ''
              for(const [index, name] of items.entries()){
                if(index !== items.length - 1){
                  string += `${name}, `
                } else if(items.length === 1) {
                  string += `${name}`
                }else {
                  string += `and ${name}`
                }
              }
              return string.toLowerCase();
            }
        }
    }

</script>

<style lang="scss" scoped>
    .error-message{
        color: red;
    }
       .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }

    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .md-tab{
        padding: 0;

    }

    .actions {
        width: 599px;
        padding-bottom: 20px;
        .md-primary{
            margin-left: 10px;
        }
    }
   .md-card{
       box-shadow: none;
   }
    .textArea{
        font-size: 15px;
        font-family: inherit;
        padding-left: 20px;
        padding-right: 20px;
        color: #43484f;
    }

    .cardContent{
        height: 580px;
    }

    .actions{
        width: 726px
    }
    .reload{
        padding-top:11px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

   
</style>

<style>
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .admin .md-field{
            display: block;
        }

        #summary-tab .md-layout-item, #overview-tab .md-layout-item{
            padding-right: 0!important;
        }
        .md-field label{
            margin-bottom: 10px;
        }
        #summary-tab .md-card, #overview-tab .md-card{
            padding-right: 10px;
            padding-left: 10px;
        }
        #summary-tab label, #overview-tab label{
            padding-left: 0!important;
        }
        #summary-tab .md-invalid .md-error{
            left: 0px!important;
            top: 336px!important;
        }
        #overview-tab .md-invalid .md-error{
            left: 0px!important;
            top: 336px!important;
        }
    }
</style>